import React from 'react'

import { MeetingSettingsTips } from 'types/componentTips'
import TipMinButton from 'components/atoms/TipMinButton/TipMinButton'
import ScreenTipCard from 'components/molecules/ScreenTipCard/ScreenTipCard'
import { useAnalytics, EventData, EventName } from 'context/Analytics/AnalyticsContext'
// import { useTips } from 'context/TipsContext/TipsContext'

interface ScreenTipsProps {
  showScreenTip?: MeetingSettingsTips;
  minTip?: boolean;
  onButton?: () => void;
  onClose?: () => void;
  onMinTip?: () => void;
}

export const ScreenTips: React.FC<ScreenTipsProps> = ({ minTip, showScreenTip, onMinTip, onButton, onClose }) => {
  // const { storeTips } = useTips()
  const { logEvent } = useAnalytics()

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: { ...eventData },
    })
  }

  // const componentStoreTips = storeTips?.meetingSettings

  return (
    <div>
      <TipMin
        show={minTip}
        onButton={() => {
          logTap({ component: 'TipMin', button: 'MinTip' })
          onMinTip && onMinTip() }} />
      <TipTitle
        show={showScreenTip === MeetingSettingsTips.title}
        onClose={() => {
          logTap({ component: 'TipTitle', button: 'Close' })
          onClose && onClose() }}
        onButton={() => {
          logTap({ component: 'TipTitle', button: 'GotIt' })
          onButton && onButton() }} />
      <TipDuration
        show={showScreenTip === MeetingSettingsTips.duration}
        onClose={() => {
          logTap({ component: 'TipDuration', button: 'Close' })
          onClose && onClose() }}
        onButton={() => {
          logTap({ component: 'TipDuration', button: 'GotIt' })
          onButton && onButton() }} />
      <TipDateRange
        show={showScreenTip === MeetingSettingsTips.dateRange}
        onClose={() => {
          logTap({ component: 'TipDateRange', button: 'Close' })
          onClose && onClose() }}
        onButton={() => {
          logTap({ component: 'TipDateRange', button: 'GotIt' })
          onButton && onButton() }} />
      <TipTimeRange
        show={showScreenTip === MeetingSettingsTips.timeRange}
        onClose={() => {
          logTap({ component: 'TipTimeRange', button: 'Close' })
          onClose && onClose() }}
        onButton={() => {
          logTap({ component: 'TipTimeRange', button: 'GotIt' })
          onButton && onButton() }} />
    </div>
  )
}

interface ContainerProps {
  show?: boolean;
  onClose?: () => void;
  onButton?: () => void;
}

export const TipMin: React.FC<ContainerProps> = ({ show, onButton }) => {
  return (
    <TipMinButton
      show={show}
      bottom={80}
      onClick={onButton} />
  )
}

export const TipTitle: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'First, your meeting needs a title.'
  const subtext = 'Perhaps a "🧶 Knitting Night"?'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      buttonLabel='got it'
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipDuration: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'How long do you intend to meet for?'
  const subtext = 'Tap to change the duration, or Next to continue with the default setting.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      buttonLabel='got it'
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipDateRange: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Select the date range you want to schedule within.'
  const subtext = 'Tapping Next will continue with the default of a week from today.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      buttonLabel='got it'
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipTimeRange: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Specify the time of day to schedule this meeting.'
  const subtext = 'Time outside of the restricted hours is considered invalid, and will not be considered when scheduling.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      buttonLabel='got it'
      onClose={onClose}
      onButton={onButton} />
  )
}
