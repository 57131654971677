import React from 'react'
import { Participant } from 'types'
import { IonIcon, IonItem, IonLabel, IonNote, IonSpinner, IonText } from '@ionic/react'

import { Avatar } from '@material-ui/core'
import { useUser } from 'context/UserContext/UserContext'
import { removeCircleOutline } from 'ionicons/icons'

const avatarStyle = {
  width: 36,
  height: 36,
  backgroundColor: '#92949C',
  marginRight: 15,
}

const noteStyle = {
  fontSize: 14,
}

export interface ParticipantListItemProps {
  participant: Participant;
  participants?: Participant[];
  lines: boolean;
  showRemoving: boolean;
  onSelect: (participant: Participant) => void;
}

const selfName = 'You'

const ParticipantListItem: React.FC<ParticipantListItemProps> = (
  {
    participant, participants, lines, onSelect, showRemoving,
  }) => {
  const { admin, displayName, userId: displayUser, invitedByUserHandle, photo, userHandle } = participant
  let invitationName
  let name = displayName

  const { user } = useUser()

  // discard blank spaces in between
  const initials = displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase())

  if (displayUser === user?.id) {
    name = selfName
  }

  if (!admin && participants) {
    const invitationParticipant = participants.find(p => p.userHandle === invitedByUserHandle)

    if (invitationParticipant) {
      if (!invitationParticipant.admin) {
        if (invitationParticipant.userId === user?.id) {
          invitationName = selfName
        } else {
          invitationName = invitationParticipant.displayName
        }
      }
    } else {
      invitationName = `@${invitedByUserHandle}`
    }
  }

  let canRemove = false

  if (participants) {
    const userParticipant = participants.find(p => p.userId === user?.id)

    if (!admin && userParticipant && userParticipant.admin) {
      canRemove = true
    }
  }

  // console.log('ParticipantListItem: participant: ', participant)
  function selected(): void {
    console.log('selected participant: ', participant)
    console.log('canRemove: ', canRemove)
    !admin && canRemove && onSelect(participant)
  }

  return (
    <IonItem
      lines={lines ? 'inset' : 'none'}
      onClick={selected}>
      <Avatar
        slot='start'
        src={photo}
        style={avatarStyle}>
        <IonText style={{ fontSize: 16 }}>
          {initials}
        </IonText>
      </Avatar>
      <IonLabel>
        <h2>{displayName}</h2>
        <p>@{userHandle}</p>
      </IonLabel>
      {admin &&
        <IonNote
          slot='end'
          style={noteStyle}>
          Admin
        </IonNote>}
      {invitationName && !showRemoving &&
        <IonNote
          slot='end'
          style={noteStyle}>
          by {invitationName}
        </IonNote>}
      {showRemoving &&
        <IonNote
          slot='end'
          style={noteStyle}>
          <IonSpinner name='dots' />
        </IonNote>}
      {!showRemoving && canRemove &&
        <IonIcon
          slot='end'
          icon={removeCircleOutline}
          color='danger' />}
    </IonItem>
  )
}

export default ParticipantListItem
