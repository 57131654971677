import React, { useState } from 'react'
import {
  IonContent, IonFooter, IonModal, IonSpinner,
} from '@ionic/react'

import 'screens/Settings.css'
import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'
import { CalendarAccount, SubCalendar } from 'types'
import SelectCalendar from 'components/molecules/SelectCalendar/SelectCalendar'
import { UpdatePreferencesInput } from 'services/api'
import { usePreferences } from 'context/PreferencesContext/PreferencesContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { Spring } from 'react-spring/renderprops-universal'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

const title = 'Schedule On Calendar'

interface ContainerProps {
  calendars: CalendarAccount[];
  selectedCalendar: SubCalendar;
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const DefaultCalendar: React.FC<ContainerProps> = ({ calendars, selectedCalendar, onClose, presentingElement }) => {
  const [selected, setSelected] = useState(selectedCalendar)
  const [edited, setEdited] = useState(false)
  const [showActivity, setShowActivity] = useState(false)

  const { updatePreferences } = usePreferences()
  const { logEvent } = useAnalytics()

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'DefaultBookCalendar',
      },
    })
  }

  async function updateDefaultCalendar(): Promise<void> {
    console.log('Update default calendar to: ', selected)

    if (updatePreferences) {
      setShowActivity(true)

      const calendar = calendars.find(account => account.calendars.find(cal => cal.calendarId === selected.id))

      if (calendar) {
        const input: UpdatePreferencesInput = {
          bookCalendar: {
            account: calendar.id,
            calendarId: selected.id,
          },

        }

        await updatePreferences(input)

        console.log('Updated default calendar', input)
      }

      onClose()
    }
  }

  function renderHeader(): JSX.Element {
    return (
      <ModalHeader
        title={title} />
    )
  }

  function renderFooter(): JSX.Element | undefined {
    if (showActivity) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton>
            <IonSpinner name='dots' />
          </FooterButton>
        </IonFooter>
      )
    }

    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}>
        {props =>
          <div style={props}>
            <IonFooter className='screenFooterButton'>
              {edited &&
                <FooterButton
                  onClick={() => {
                    logTap({ button: 'Done' })
                    edited ? updateDefaultCalendar() : onClose()
                  }}>
                  Done
                </FooterButton>}
              <FooterButton
                fill='clear'
                onClick={() => {
                  logTap({ button: 'Cancel' })
                  onClose()
                }}>
                Cancel
              </FooterButton>
            </IonFooter>
          </div>}
      </Spring>
    )
  }

  // we disable freeBusy just for the visual SelectCalendar component
  // so that it doesn't show the freeBusy label
  const showSelected = [
    {
      ...selected,
      enabled: true,
      freeBusy: false,
    },
  ]

  return (
    <IonModal
      isOpen
      swipeToClose
      presentingElement={presentingElement}
      onDidDismiss={onClose}>
      {renderHeader()}
      <IonContent>
        <SelectCalendar
          calendars={calendars}
          selected={showSelected}
          writable
          selectBookCalendar
          onSelect={(cal) => {
            logTap({ button: 'SelectCalendar' })
            setSelected(cal)
            setEdited(true)
          }} />
      </IonContent>
      {renderFooter()}
    </IonModal>
  )
}

export default DefaultCalendar
