import React from 'react'
import { IonItem, IonLabel, IonIcon } from '@ionic/react'
import { mailOutline } from 'ionicons/icons'

export interface EmailLinkButtonProps {
  link: string;
  onClick?: () => void;
}

const EmailLinkButton: React.FC<EmailLinkButtonProps> = ({ link, onClick }) => {
  return (
    <IonItem
      data-ci-id='email-link-button'
      href={link}
      onClick={onClick}
      detail={false}
      lines='none'>
      <IonLabel>Email</IonLabel>
      <IonIcon
        icon={mailOutline}
        slot='end' />
    </IonItem>
  )
}

export default EmailLinkButton
