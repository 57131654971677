export const paths = {
  welcome: '/welcome',
  meetUser: '/meet/:userHandle',
  meetUserUnauthenticated: '/meetUserUnauthenticated/:userHandle',
  invite: '/invitation/:id',
  inviteUnauthenticated: '/inviteUnauthenticated/:id',
  addContact: '/addContact',
  newUserName: '/newUserName',
  login: '/login',
  newUserAddContact: '/newUserAddContact',
  startFirstMeeting: '/startFirstMeeting',
  meetingsList: '/meetings/list',
  pastMeetings: '/meetings/past',
  meeting: '/meeting/:id',
  invitationsList: '/tabs/meetings/invitations',
  inviteAuthenticated: '/tabs/meetings/invitations/:id',
  meetUserAuthenticated: '/meetUser/:userHandle',
  connections: '/connections',
  // meetWithUser: 'Meet With User',
  meetingSettings: '/tabs/meetings/meetingSettings',
  meetingDescription: '/tabs/meetings/meetingDescription',
  meetingCalendarSettings: '/tabs/meetings/meetingCalendarSettings',
  chooseAvailability: '/tabs/meetings/chooseAvailability',
  askConnectCalendar: '/tabs//meetings/askConnectCalendars',
  addParticipants: '/tabs/meetings/addParticipants',
  // dayAvailability: 'Day Availability',
  // addParticipants: 'Add Participants',
  // shareMeetingInvite: 'Share Meeting Invite',
  // meetingInvite: 'Meeting Invite',
  // meetingParticipants: 'Meeting Participants',
  settings: '/settings',
  // account: 'Account',
  // calendarAccounts: 'Calendar Accounts',
  connectCalendar: '/connectCalendar',
  calendarSettingsFreeBusy: '/calendarSettingsFreeBusy',
  calendarSettingsBookCalendar: '/calendarSettingsBookCalendar',
  calendarSettingsAutoTimes: '/calendarSettingsAutoTimes',
  calendarSettingsDoneAddAnother: '/calendarSettingsDoneAddAnother',
  tipsList: '/tips/list',
  tipsAvailabilityCalendar: '/tips/tipsAvailabilityCalendar',
  tipsAvailabilityCalendarAuto: '/tips/tipsAvailabilityCalendarAuto',
  tipsConnectCalendars: '/tips/tipsConnectCalendars',
  tipsGooglePermissions: '/tips/tipsGooglePermissions',
  tipsConfigureCalendar: '/tips/tipsConfigureCalendar',
  // devices: 'Devices',
  // about: 'About',
}

export default paths
