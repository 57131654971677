import React, { CSSProperties, useEffect, useState } from 'react'
import {
  IonContent, IonModal, IonItem, IonText, IonInput, isPlatform, IonButton, IonSpinner, IonAlert,
} from '@ionic/react'

import 'screens/Settings.css'
import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'
import { useUser } from 'context/UserContext/UserContext'
import { UpdateUserError } from 'services/api'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

const title = 'userHandle'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const titleStyle = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

const messageBox: CSSProperties = {
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
}

const messageStyle = {
  fontSize: 14,
}

interface ContainerProps {
  onClose: () => void;
  presentingElement?: HTMLElement | undefined;
}

const ChangeIDModal: React.FC<ContainerProps> = ({ onClose, presentingElement }) => {
  const [showActivity, setShowActivity] = useState(false)
  const [showAlert, setShowAlert] = useState('')
  const [handle, sethandle] = useState('')
  const { user, updateUser } = useUser()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    if (user?.handle) {
      sethandle(user.handle)
    }
  }, [])

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'ChangeIdModal',
      },
    })
  }

  function renderHeader(): JSX.Element {
    return (
      <ModalHeader
        title={title}
        doneButton='Done'
        onClose={() => {
          logTap({ button: 'HeaderClose' })
          onClose()
        }}
        onDone={() => {
          logTap({ button: 'HeaderDone' })
          onClose()
        }}
        cancel={false} />
    )
  }

  function renderInput(): JSX.Element | undefined {
    return (
      <div style={{ marginTop: 20 }}>
        <IonText
          style={titleStyle}
          color='medium'>
          SECURE-ID
        </IonText>
        <IonItem
          lines='full'
          style={{ marginTop: 5 }}>
          <IonInput
            autocapitalize='none'
            clearInput
            placeholder='Enter new handle'
            enterkeyhint='done'
            value={handle}
            onIonChange={e => {
              const { value } = e.detail

              if (value) {
                const formatted = value.replace(/[^a-z0-9-_]/gi, '').toLowerCase()

                sethandle(formatted)
              } else {
                sethandle('')
              }
            }} />
        </IonItem>
      </div>
    )
  }

  async function updatehandle(): Promise<void> {
    try {
      setShowActivity(true)

      await updateUser({ handle })
      setShowActivity(false)
      onClose()
    } catch (error) {
      if (error instanceof Error) {
        console.log('updateUserError: ')

        if (error.message === UpdateUserError.handleNotAvailable) {
          setShowAlert('handle not available.')
        }
      }
    }
  }

  function renderUpdateButton(): JSX.Element | undefined {
    if (showActivity) {
      return (
        <IonButton
          shape='round'
          fill='solid'
          expand='block'>
          <IonSpinner name='dots' />
        </IonButton>
      )
    }

    if (user?.handle && handle) {
      return (
        <IonButton
          shape='round'
          fill='solid'
          expand='block'
          disabled={user.handle === handle}
          onClick={() => {
            logTap({ button: 'Changehandle' })
            updatehandle()
          }}>
          Change handle
        </IonButton>
      )
    }
  }

  return (
    <IonModal
      isOpen
      swipeToClose
      presentingElement={presentingElement}
      onDidDismiss={() => {
        logTap({ button: 'GestureClose' })
        onClose()
      }}>
      {renderHeader()}
      <IonContent>
        <div
          style={container}
          className='titleIconBox'>
          <div style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              People can connect with you using your Handle. <br />
              You can change it at any time.
            </IonText>
          </div>
          {renderInput()}
          <div style={{ marginTop: 40, padding: 20 }}>
            {renderUpdateButton()}
          </div>
        </div>
      </IonContent>
      <IonAlert
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert('')}
        header='Error'
        message={showAlert}
        buttons={[
          {
            text: 'OK',
            handler: () => {
              setShowAlert('')
              setShowActivity(false)

              if (user?.handle) {
                sethandle(user.handle)
              }
            },
          },
        ]} />
    </IonModal>
  )
}

export default ChangeIDModal
