import React, { useState, CSSProperties } from 'react'
import moment from 'moment'

import { IonBadge, IonButton, IonItem, IonItemDivider, IonLabel, IonSpinner } from '@ionic/react'

import MeetingCard from 'components/organisms/MeetingCard'
import { Meeting, MeetingTime } from 'types'
import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import { compareTimes } from 'services/time'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import AnimatedMeeting from 'components/atoms/AnimatedMeeting/AnimatedMeeting'
import AnimatedPeople from 'components/atoms/AnimatedPeople/AnimatedPeople'
import Icon from '@mdi/react'
import { mdiUnfoldLessHorizontal, mdiUnfoldMoreHorizontal } from '@mdi/js'
import { useScreenLogEvent } from 'context/ScreenLogEventContext/ScreenLogEventContext'
import { EventData } from 'context/AnalyticsContext/AnalyticsContext'

const dividerStyle: CSSProperties = {
  // paddingTop: 5,
  // paddingBottom: 5,
}

interface MeetingListProps {
  reload: number;
  meetingId?: string;
  firstJoinedMeeting?: boolean;
  onEdit: (meeting: Meeting) => void;
  onInvite: (meeting: Meeting, byLink?: boolean) => void;
  onChooseAvailability: (meeting: Meeting, time?: string, autoBook?: boolean) => void;
  onMeetingTime: (meeting: Meeting, meetingTime: MeetingTime) => void;
  onParticipants: (meeting: Meeting) => void;
  onDelete?: (meeting: Meeting) => void;
  onMoreActions: (meeting: Meeting) => void;
  onReloadDone: () => void;
  onAddCalendar: (meeting: Meeting) => void;
  onCalendarSettings?: (meeting: Meeting) => void;
  onDescription?: (meeting: Meeting) => void;
  onShowPastMeetings: () => void;
  renderWithProviders?: (card: JSX.Element) => JSX.Element
}

const MeetingsList: React.FC<MeetingListProps> =
  ({
    firstJoinedMeeting, meetingId, onEdit, onInvite, onChooseAvailability,
    onParticipants, onMeetingTime, onDelete, onMoreActions, onShowPastMeetings,
    onAddCalendar, onCalendarSettings, onDescription,
    renderWithProviders,
  }) => {
    const { loading, meetings = [] } = useMeetings()
    const [showPending, setShowPending] = useState(true)
    const [showUpcoming, setShowUpcoming] = useState(true)
    const [showHappeningNow, setShowHappeningNow] = useState(true)
    const { logTapEvent } = useScreenLogEvent()

    if (loading) {
      return (
        <ScreenCenter height='80%'>
          <IonSpinner name='dots' />
        </ScreenCenter>
      )
    }

    // filter and sort meetings active in the last RECENT_RANGE time
    // recent first
    let firstCard = 0
    // const recentlyActive: Meeting[] = []

    const recentlyActive = meetings
      .filter(meeting => {
        const { meetingTimes } = meeting

        if (meetingTimes?.length) {
          const confirmed = meetingTimes.find(mt => mt.status === 'confirmed')

          if (confirmed) {
            const timeA = meeting.updateTime || meeting.createTime

            const RECENT_RANGE = 5 // 5 minutes

            // if the update or createTime is within the last 5minutes
            if (moment(timeA).valueOf() > moment().subtract(RECENT_RANGE, 'minutes').valueOf()) {
              return true
            }
          }
        }
      })
      .sort((a: Meeting, b: Meeting) => {
        const timeA = a.updateTime || a.createTime
        const timeB = b.updateTime || b.createTime

        return compareTimes(timeB, timeA)
      })

    const happeningNow = meetings
      .filter(meeting => {
        if (!recentlyActive.find(m => m.id === meeting.id)) {
          const { meetingTimes } = meeting

          if (meetingTimes?.length) {
            const confirmed = meetingTimes.find(mt => mt.status === 'confirmed')

            if (confirmed) {
              const start = moment(confirmed.startTime).valueOf()
              const end = moment(confirmed.endTime).valueOf()
              const current = moment().valueOf()

              if (start <= current && end > current) {
                return true
              }
            }
          }
        }
      })

      .sort((a: Meeting, b: Meeting) => {
        if (a.meetingTimes && b.meetingTimes) {
          const timeA = a.meetingTimes[0].startTime
          const timeB = b.meetingTimes[0].startTime

          return compareTimes(timeA, timeB)
        }

        return 0
      })

    const pendingMeetings = meetings
      .filter(meeting => {
        if (!recentlyActive.find(m => m.id === meeting.id)) {
          const { meetingTimes, dayRanges } = meeting

          // if the lastDay range of the meeting is in the future
          if (dayRanges?.length) {
            const sorted = dayRanges.slice().sort((a, b) => {
              const timeA = moment(a.startTime).valueOf()
              const timeB = moment(b.startTime).valueOf()

              return timeA - timeB
            })

            const lastDay = sorted[sorted.length - 1]

            if (moment(lastDay.endTime).valueOf() > moment().valueOf()) {
              if (!meetingTimes?.length ||
                !meetingTimes.find(mt => mt.status === 'confirmed')) {
                return true
              }
            }
          }
        }
      })
      .sort((a: Meeting, b: Meeting) => {
        const timeA = a.updateTime || a.createTime
        const timeB = b.updateTime || b.createTime

        return compareTimes(timeB, timeA)
      })

    const upcomingMeetings = meetings
      .filter(meeting => {
        const { meetingTimes } = meeting

        if (meetingTimes?.length) {
          const confirmed = meetingTimes.find(mt => mt.status === 'confirmed')

          if (confirmed && moment(confirmed.startTime).valueOf() > moment().valueOf()) {
            if (!recentlyActive.find(m => m.id === meeting.id)) {
              return true
            }
          }
        }
      })

      .sort((a: Meeting, b: Meeting) => {
        if (a.meetingTimes && b.meetingTimes) {
          const timeA = a.meetingTimes[0].startTime
          const timeB = b.meetingTimes[0].startTime

          return compareTimes(timeA, timeB)
        }

        return 0
      })

    const pastMeetings = meetings
      .filter(meeting => {
        const { meetingTimes, dayRanges } = meeting

        if (!recentlyActive.find(m => m.id === meeting.id)) {
          // a meeting is in the past if it's confirmed timeRange is in the past
          // OR if the meetingDayRange end is in the past
          if (meetingTimes?.length) {
            const meetingTime = meetingTimes[0]

            if (meetingTime.status === 'confirmed' &&
              moment(meetingTime.endTime).valueOf() < moment().valueOf()) {
              return true
            }
          }

          if (dayRanges?.length) {
            const sorted = dayRanges.slice().sort((a, b) => {
              const timeA = moment(a.startTime).valueOf()
              const timeB = moment(b.startTime).valueOf()

              return timeA - timeB
            })

            const lastDay = sorted[sorted.length - 1]

            if (moment(lastDay.endTime).valueOf() < moment().valueOf()) {
              return true
            }
          }
        }
      })

    function logTap(eventData: EventData): void {
      logTapEvent({
        ...eventData,
        component: 'MeetingsList',
      })
    }

    function renderMeeting(meeting: Meeting): JSX.Element {
      firstCard++

      return (
        <MeetingCard
          key={meeting.id}
          meeting={meeting}
          showBestTime
          showVideo
          showTips={firstCard === 1}
          firstJoinedMeeting={firstJoinedMeeting && firstCard === 1}
          onChooseAvailability={onChooseAvailability}
          onMeetingTime={onMeetingTime}
          onParticipants={onParticipants}
          onInvite={onInvite}
          onEdit={onEdit}
          onDelete={onDelete}
          onMoreActions={onMoreActions}
          onAddCalendar={onAddCalendar}
          onCalendarSettings={onCalendarSettings}
          onDescription={onDescription}
          renderWithProviders={renderWithProviders} />
      )
    }

    function renderRecentlyConfirmed(): JSX.Element | undefined {
      if (meetingId) {
        return
      }

      if (recentlyActive?.length) {
        return (
          <div>
            <IonItemDivider
              mode='ios'
              color='success'
              style={dividerStyle}
              className='meetingCardItemBorderBottom'
              sticky>
              <IonBadge
                mode='ios'
                color='light'>
                {recentlyActive.length}
              </IonBadge>
              <IonLabel style={{ marginLeft: 20 }}>
                Confirmed
              </IonLabel>
              <IonButton
                fill='clear'
                slot='end'
                onClick={() => {
                  logTap({ button: showHappeningNow ? 'CloseHappeningNowSection' : 'OpenHappeningNowSection' })
                  setShowHappeningNow(!showHappeningNow)
                }}>
                <Icon
                  path={showHappeningNow ? mdiUnfoldLessHorizontal : mdiUnfoldMoreHorizontal}
                  size={1}
                  color='gray' />
              </IonButton>
            </IonItemDivider>
            <div style={showHappeningNow ? {} : { display: 'none' }}>
              {recentlyActive.map((meetingId) => renderMeeting(meetingId))}
            </div>
          </div>
        )
      }
    }

    function renderHappeningNow(): JSX.Element | undefined {
      if (meetingId) {
        return
      }

      if (happeningNow?.length) {
        return (
          <div>
            <IonItemDivider
              mode='ios'
              color='light'
              style={dividerStyle}
              className='meetingCardItemBorderBottom'
              sticky>
              <IonBadge
                mode='ios'
                color='danger'>
                {happeningNow.length}
              </IonBadge>
              <IonLabel style={{ marginLeft: 20 }}>
                Happening Now
              </IonLabel>
              <IonButton
                fill='clear'
                slot='end'
                onClick={() => {
                  logTap({ button: showHappeningNow ? 'CloseHappeningNowSection' : 'OpenHappeningNowSection' })
                  setShowHappeningNow(!showHappeningNow)
                }}>
                <Icon
                  path={showHappeningNow ? mdiUnfoldLessHorizontal : mdiUnfoldMoreHorizontal}
                  size={1}
                  color='gray' />
              </IonButton>
            </IonItemDivider>
            <div style={showHappeningNow ? {} : { display: 'none' }}>
              {happeningNow.map((meetingId) => renderMeeting(meetingId))}
            </div>
          </div>
        )
      }
    }

    // function renderNow (): JSX.Element | undefined {
    //   if (happeningNow?.length) {
    //     return (
    //       <div>
    //         <IonItemDivider
    //           color='light'
    //           style={dividerStyle}
    //           sticky>
    //           Happening Now
    //         </IonItemDivider>
    //         {recentlyActive.map((meetingId) => renderMeeting(meetingId))}
    //       </div>
    //     )
    //   }
    // }

    function renderSelected(): JSX.Element | undefined {
      if (meetingId) {
        const selectedMeeting = meetings.find(meeting => meeting.id === meetingId)

        if (selectedMeeting) {
          return (
            <div>
              {renderMeeting(selectedMeeting)}
            </div>
          )
        }
      }
    }

    function renderPending(): JSX.Element | undefined {
      // meetings that have their last day range in the future and not confirmed yet
      // sorted with recent first
      if (meetingId) {
        return
      }

      if (pendingMeetings?.length) {
        return (
          <div>
            <IonItemDivider
              mode='ios'
              color='light'
              style={dividerStyle}
              className='meetingCardItemBorderBottom'
              sticky
              onClick={() => {
                logTap({ button: showPending ? 'CloseSchedulingSection' : 'OpenSchedulingSection' })

                setShowPending(!showPending)
              }}>
              <IonBadge
                mode='ios'
                color='warning'>
                {pendingMeetings.length}
              </IonBadge>
              <IonLabel style={{ marginLeft: 20 }}>
                Scheduling
              </IonLabel>
              <IonButton
                fill='clear'
                slot='end'>
                <Icon
                  path={showPending ? mdiUnfoldLessHorizontal : mdiUnfoldMoreHorizontal}
                  size={1}
                  color='gray' />
              </IonButton>
            </IonItemDivider>
            <div style={showPending ? {} : { display: 'none' }}>
              {pendingMeetings.map((meetingId) => renderMeeting(meetingId))}
            </div>
          </div>
        )
      }
    }

    function renderUpcoming(): JSX.Element | undefined {
      // meetings that are confirmed and startTime is in future
      // sorted with recent first

      if (meetingId) {
        return
      }

      // first let's find meetings that have meetingTimes and are not currently in active list
      if (upcomingMeetings.length) {
        return (
          <div>
            <IonItemDivider
              mode='ios'
              color='light'
              style={dividerStyle}
              className='meetingCardItemBorderBottom'
              sticky
              onClick={() => {
                logTap({ button: showUpcoming ? 'CloseUpcomingSection' : 'OpenUpcomingSection' })

                setShowUpcoming(!showUpcoming)
              }}>
              <IonBadge
                mode='ios'
                color='success'>
                {upcomingMeetings.length}
              </IonBadge>
              <IonLabel style={{ marginLeft: 20 }}>
                Upcoming
              </IonLabel>
              <IonButton
                fill='clear'
                slot='end'>
                <Icon
                  path={showUpcoming ? mdiUnfoldLessHorizontal : mdiUnfoldMoreHorizontal}
                  size={1}
                  color='gray' />
              </IonButton>
            </IonItemDivider>
            <div style={showUpcoming ? {} : { display: 'none' }}>
              {upcomingMeetings.map((meetingId) => renderMeeting(meetingId))}
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <IonItemDivider
              mode='ios'
              color='light'
              style={dividerStyle}
              className='meetingCardItemBorderBottom'
              sticky
              onClick={() => setShowUpcoming(!showUpcoming)}>
              <IonBadge
                mode='ios'
                color='success'>
                0
              </IonBadge>
              <IonLabel style={{ marginLeft: 20 }}>
                Upcoming
              </IonLabel>
            </IonItemDivider>
          </div>
        )
      }
    }

    function renderPast(): JSX.Element | undefined {
      if (meetingId) {
        return
      }

      // first let's find meetings that have meetingTimes and are not currently in active list

      if (pastMeetings.length) {
        return (
          <IonItem
            detail
            mode='ios'
            lines='full'
            onClick={() => {
              logTap({ button: 'PastMeetings' })

              onShowPastMeetings()
            }}>
            <IonBadge
              slot='start'
              mode='ios'
              color='medium'>
              {pastMeetings.length}
            </IonBadge>
            <IonLabel>
              Past
            </IonLabel>
          </IonItem>
        )
      }
    }

    function renderPeople(): JSX.Element | undefined {
      if (meetingId) {
        return
      }

      if (!recentlyActive?.length && !happeningNow.length &&
        !pendingMeetings?.length && !upcomingMeetings?.length) {
        return (
          <ScreenCenter height='100%'>
            <AnimatedPeople
              loop={1}
              style={{ height: 300, width: 300 }} />
            <AnimatedMeeting
              loop={1}
              style={{ height: 100, width: 100 }} />
          </ScreenCenter>
        )
      }
    }

    if (meetings?.length) {
      return (
        <div>
          {renderSelected()}
          {renderRecentlyConfirmed()}
          {renderHappeningNow()}
          {renderPending()}
          {renderUpcoming()}
          {renderPast()}
          {renderPeople()}
        </div>
      )
    } else if (firstJoinedMeeting) {
      return (
        <ScreenCenter height='80%'>
          <IonSpinner name='dots' />
        </ScreenCenter>
      )
    } else {
      return (
        <ScreenCenter height='80%'>
          <AnimatedPeople
            loop={1}
            style={{ height: 300, width: 300 }} />
          <AnimatedMeeting
            loop={1}
            style={{ height: 100, width: 100 }} />
        </ScreenCenter>
      )
    }
  }

export default MeetingsList
