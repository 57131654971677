import React from 'react'
import {
  IonContent, IonFooter, IonModal,
} from '@ionic/react'

import 'screens/Settings.css'
import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'
import { CalendarAccount, SubCalendar } from 'types'
import SelectCalendar from 'components/molecules/SelectCalendar/SelectCalendar'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { Spring } from 'react-spring/renderprops-universal'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

const title = 'Meeting Calendar'

interface ContainerProps {
  calendars: CalendarAccount[];
  selectedCalendar: SubCalendar;
  onClose: () => void;
  onSelect: (selected: SubCalendar) => void;
  presentingElement: HTMLElement | undefined;
}

const SelectMeetingCalendar: React.FC<ContainerProps> = ({ calendars, selectedCalendar, onClose, onSelect, presentingElement }) => {
  const { logEvent } = useAnalytics()

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'SelectMeetingCalendar',
      },
    })
  }
  function renderHeader (): JSX.Element {
    return (
      <ModalHeader
        title={title} />
    )
  }

  function renderFooter (): JSX.Element | undefined {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}>
        {props =>
          <div style={props}>
            <IonFooter className='screenFooterButton'>
              <FooterButton
                onClick={() => {
                  logTap({ button: 'Done' })
                  onClose()
                }}>
                Done
              </FooterButton>
              <FooterButton
                fill='clear'
                onClick={() => {
                  logTap({ button: 'Cancel' })
                  onClose()
                }}>
                Cancel
              </FooterButton>
            </IonFooter>
          </div>}
      </Spring>
    )
  }

  // we disable freeBusy just for the visual SelectCalendar component
  // so that it doesn't show the freeBusy label
  const selected = [
    {
      ...selectedCalendar,
      enabled: true,
      freeBusy: false,
    },
  ]

  return (
    <IonModal
      isOpen
      swipeToClose
      presentingElement={presentingElement}
      onDidDismiss={() => {
        logTap({ button: 'GestureClose' })
        onClose()
      }}>
      {renderHeader()}
      <IonContent>
        <SelectCalendar
          calendars={calendars}
          selected={selected}
          selectBookCalendar
          writable
          onSelect={(cal) => {
            logTap({ button: 'SelectCalendar' })
            onSelect(cal)
            onClose()
          }} />
      </IonContent>
      {renderFooter()}
    </IonModal>
  )
}

export default SelectMeetingCalendar
