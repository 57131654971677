import React, { useEffect } from 'react'
import { IonContent, IonPage } from '@ionic/react'

import 'theme/styles.css'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import TipsConfigureCalendarSlides from 'components/organisms/TipsConfigureCalendarSlides/TipsConfigureCalendarSlides'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.

const title = 'Calendar Preferences'

interface ComponentProps {
  goBack: () => void;
}

const TipsConfigureCalendar: React.FC<ComponentProps> = ({ goBack }) => {
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'TipsConfigureCalendar' },
    })
  }, [])

  function renderHeader (): JSX.Element {
    return (
      <ScreenHeader
        title={title}
        onBack={goBack} />
    )
  }

  return (
    <IonPage>
      {renderHeader()}
      <IonContent>
        <TipsConfigureCalendarSlides onlyTips />
      </IonContent>
    </IonPage>
  )
}

export default TipsConfigureCalendar
