import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'

import { CreateVerifyTokenInput, CREATE_VERIFY_TOKEN } from 'services/api'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

export interface TokensContextValue {
  loading: boolean;
  getVerifyToken?: () => Promise<string | undefined>;
}

const initialValue: TokensContextValue = {
  loading: false,
  getVerifyToken: async () => {
    console.log('getVerifyToken: ')

    return undefined
  },
}

// create and initialize context
export const TokensContext = React.createContext<TokensContextValue>(initialValue)

export function useTokens (): TokensContextValue {
  return useContext(TokensContext)
}

export type TokensMockContextValue = Partial<TokensContextValue>

type MockProps = {
  value?: Partial<TokensContextValue>
}

export const TokensMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <TokensContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </TokensContext.Provider>
  )
}

const TokensProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const { logEvent } = useAnalytics()

  const [createVerifyToken] = useMutation(CREATE_VERIFY_TOKEN)

  async function getVerifyToken (): Promise<string | undefined> {
    setLoading(true)

    const input: CreateVerifyTokenInput = {
      deviceDetails: {
        browser: navigator.userAgent,
      },
    }

    const { data } = await createVerifyToken({ variables: { input } })

    if (data?.createVerifyToken?.verifyToken) {
      const { verifyToken } = data.createVerifyToken

      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'createVerifyToken',
        },
      })

      console.log('GOT VERIFY TOKEN: ', verifyToken)
      localStorage.setItem('verifyToken', verifyToken)

      return verifyToken
    }

    setLoading(false)
  }

  return (
    <TokensContext.Provider value={{ loading, getVerifyToken }}>
      {children}
    </TokensContext.Provider>
  )
}

export default TokensProvider
