import React, { CSSProperties, useEffect, useState } from 'react'
import {
  IonButtons,
  IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonText, IonTitle, IonToolbar,
} from '@ionic/react'

import { CreateContactInput, CreateContactError } from 'services/api'
import { addContact, UserInfo } from 'services/firebase'
import { checkmarkCircle, warning } from 'ionicons/icons'
import { useTabs } from 'navigation/TabsContext'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useContacts } from 'context/ContactsContext/ContactsContext'
import ScreenBackButton from 'components/atoms/ScreenBackButton/ScreenBackButton'
import AnimatedCheckmark from 'components/atoms/AnimatedCheckmark/AnimatedCheckmark'
import Fireworks from 'components/atoms/Fireworks/Fireworks'
import ScreenTipCard from 'components/molecules/ScreenTipCard/ScreenTipCard'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { EventName, EventData, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

const container: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  padding: 40,
}

const iconBox: CSSProperties = {
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const checkmarkBox: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const box: CSSProperties = {
  marginTop: 20,
  padding: 20,
  textAlign: 'center',
}

const iconStyle: CSSProperties = {
  fontSize: 50,
}

const successMessage: CSSProperties = {
  fontSize: 20,
}

const messageStyle: CSSProperties = {
  fontSize: 16,
}

enum TipTypes {
  'noTip',
  'notifyTip',
}

type ContactInfo = {
  email?: string
  phone?: string
  provider?: string
}

interface ComponentProps {
  verifyToken: string;
  contactType: string;
  callbackUrl: string;
  inCallback: boolean;
  newUser?: string;
  onBack: () => void;
  onNext?: () => void;
}

const AddContactFirebase: React.FC<ComponentProps> = ({ verifyToken, contactType, callbackUrl, inCallback, newUser, onBack, onNext }) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [userInfo, setUserInfo] = useState<ContactInfo>()
  const [showError, setShowError] = useState<string>()
  const [differentDevice, setDifferentDevice] = useState(false)
  const [showTip, setShowTip] = useState(TipTypes.noTip)
  const [showFireworks, setShowFireworks] = useState(false)
  const { setShowTabs } = useTabs()

  const { createContact, createCompleted } = useContacts()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'AddContact' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'AddContactFirebase',
      },
    })
  }

  function onGetStarted (): void {
    console.log('onGetStarted newUser: ', newUser)

    if (newUser === 'invitee') {
      setShowTabs && setShowTabs(true)
    }

    onNext && onNext()
  }
  async function onSuccess (info: UserInfo): Promise<void> {
    console.log('Verify Firebase User: ', info)

    setUserInfo({
      email: info.email,
      phone: info.phone,
      provider: info.provider,
    })

    const { uid, idToken, email, phone, photo, provider } = info
    const input: CreateContactInput = {
      email,
      phone,
      photo,
      verifyFirebase: {
        uid,
        idToken,
      },
      verifyToken,
      provider,
    }

    console.log('CREATE CONTACT INPUT: ', input)

    try {
      const contact = await createContact(input)

      if (contact) {
        console.log('Created Contact: ', contact)

        if (inCallback) {
          setDifferentDevice(true)
          setShowTabs && setShowTabs(false)
        } else {
          setShowSuccess(true)

          if (newUser) {
            // setShowTip(TipTypes.notifyTip)
            // setShowFireworks(true)
          }
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setShowError(error.message)
      }
    }
  }

  function showFirebase (): void {
    const el = document.getElementById('firebaseui')

    if (el) {
      console.log('Login callback URL: ', callbackUrl)
      addContact('#firebaseui', contactType, callbackUrl, onSuccess)
    } else {
      setTimeout(() => {
        console.log('Login callback URL: ', callbackUrl)
        addContact('#firebaseui', contactType, callbackUrl, onSuccess)
      }, 1000)
    }
  }

  useEffect(() => {
    if (verifyToken && contactType) {
      showFirebase()
    }
  }, [verifyToken, contactType])

  useEffect(() => {
    console.log('Create completed: ', createCompleted)

    if (createCompleted && createCompleted.verifyToken === verifyToken) {
      const { email, phone } = createCompleted

      if (!inCallback) {
        setShowSuccess(true)
        setUserInfo({
          email,
          phone,
        })

        if (newUser) {
          // setShowTip(TipTypes.notifyTip)
          // setShowFireworks(true)
        }
      }
    }
  }, [createCompleted])

  function renderNewUserSuccess (): JSX.Element | undefined {
    console.log('render New user success: userInfo', userInfo)

    if (userInfo) {
      let contact = ''
      let msg = ''
      let type = ''

      if (contactType === 'email' && userInfo.email) {
        msg = 'Your email was successfully verified!'
        contact = userInfo.email
        type = 'email id'
      } else if (contactType === 'phone' && userInfo.phone) {
        msg = 'Your phone was successfully verified!'
        contact = userInfo.phone
        type = 'phone number'
      } else if (contactType === 'google' && userInfo.email) {
        msg = 'Your google account was successfully verified!'
        contact = userInfo.email
      }

      const text = `You will be notified when any changes happen to your meetings.
    You can also use this ${type} to sign-in into your account from any device.`

      if (contact) {
        return (
          <div
            style={container}>
            <div
              style={checkmarkBox}>
              <AnimatedCheckmark
                loop
                style={{ height: 100, width: 100 }} />
            </div>
            <div style={box}>
              <IonText
                style={successMessage}>
                You&apos;re All Set!
              </IonText>
            </div>
            <div style={box}>
              <IonText
                color='medium'
                style={messageStyle}>
                {msg}<br /><br />
              </IonText>
              <IonText
                style={messageStyle}>
                {contact} <br /><br />
              </IonText>
              <IonText
                color='medium'
                style={messageStyle}>
                {text}<br />
              </IonText>
            </div>
            <div style={box} />
          </div>
        )
      }
    }
  }

  function renderSuccess (): JSX.Element | undefined {
    if (differentDevice && userInfo) {
      const { email, phone, provider } = userInfo

      let msg = ''

      if (provider === 'google') {
        msg = 'Google account has been linked to your profile'
      } else if (email) {
        msg = 'Email has been added to your profile '
      } else if (phone) {
        msg = 'Phone has been added to your profile'
      }

      return (
        <div
          style={{ flex: 1, height: '100%' }}>
          <div
            style={iconBox}>
            <IonIcon
              style={iconStyle}
              icon={checkmarkCircle}
              color='success' />
            <IonLabel style={{ textAlign: 'center', marginTop: 10 }}>
              <h2>{email || phone}</h2>
              {provider && <p>@{provider}</p>}
            </IonLabel>
          </div>
          <IonItem
            lines='none'
            style={{ textAlign: 'center' }}>
            <IonLabel>
              <IonText
                style={messageStyle}>
                <strong>{msg}</strong><br /><br />
                You added this contact in another tab or browser<br />
                You can safely close this tab and continue there.
              </IonText>
            </IonLabel>
          </IonItem>
        </div>
      )
    }

    if (showSuccess) {
      if (newUser) {
        return renderNewUserSuccess()
      } else {
        let msg = ''

        if (contactType === 'email') {
          msg = 'Email has been added to your profile '
        } else if (contactType === 'phone') {
          msg = 'Phone has been added to your profile'
        } else if (contactType === 'google') {
          msg = 'Google account has been linked to your profile'
        }

        return (
          <div
            style={{ flex: 1, height: '100%' }}>
            <div
              style={iconBox}>
              <IonIcon
                style={iconStyle}
                icon={checkmarkCircle}
                color='success' />
            </div>
            <IonItem
              lines='none'
              style={{ textAlign: 'center' }}>
              <IonLabel>
                <IonText
                  color='medium'
                  style={messageStyle}>
                  {msg}
                </IonText>
              </IonLabel>
            </IonItem>
          </div>
        )
      }
    }
  }

  function renderError (): JSX.Element | undefined {
    if (showError && userInfo) {
      const { email, phone, provider } = userInfo

      let msg = ''

      if (showError === CreateContactError.contactInUse) {
        if (provider === 'google') {
          msg = 'Google account is in use'
        } else if (email) {
          msg = 'Email is in use '
        } else if (phone) {
          msg = 'Phone is in use'
        }
      }

      return (
        <div
          style={{ flex: 1, height: '100%' }}
          className='titleIconBox'>
          <div
            style={iconBox}>
            <IonIcon
              style={iconStyle}
              icon={warning}
              color='warning' />
            <IonLabel style={{ textAlign: 'center', marginTop: 10 }}>
              <h2>{email || phone}</h2>
              {provider && <p>@{provider}</p>}
            </IonLabel>
          </div>
          <IonItem
            lines='none'
            style={{ textAlign: 'center' }}>
            <IonLabel>
              <IonText
                color='medium'
                style={messageStyle}>
                {msg}
              </IonText>
            </IonLabel>
          </IonItem>

        </div>
      )
    }
  }

  // function renderHeader (): JSX.Element | undefined {
  //   if (differentDevice) {
  //     return (
  //       <ScreenHeader
  //         title='MeetingTime' />
  //     )
  //   } else {
  //     return (
  //       <ScreenHeader
  //         title='Verify Contact'
  //         onBack={goBack} />
  //     )
  //   }
  // }

  function renderNotifyTip (): JSX.Element | undefined {
    const text = `You will be notified when any changes happen to your meetings.
    You can also use this contact to access your account from any device.`

    return (
      <ScreenTipCard
        show={showTip === TipTypes.notifyTip}
        text={text}
        buttonLabel='got it'
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          setShowTip(TipTypes.noTip)
        }}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'GotIt' })
          setShowTip(TipTypes.noTip)
        }} />
    )
  }

  function renderFireworks (): JSX.Element | undefined {
    if (showFireworks) {
      return (
        <div
          style={{
            position: 'absolute',
            bottom: '30%',
            background: 'transparent',
          }}>
          <Fireworks
            loop={1}
            onComplete={() => setShowFireworks(false)} />
        </div>
      )
    }
  }

  function renderGetStartedButton (): JSX.Element | undefined {
    if (showSuccess && newUser && !inCallback) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ component: 'FooterButton', button: 'Done' })
              onGetStarted()
            }}>
            Done
          </FooterButton>
        </IonFooter>
      )
    }
  }

  function renderHeader (): JSX.Element {
    if (differentDevice) {
      return (
        <ScreenHeader
          title='MeetingTime' />
      )
    } else {
      if (newUser) {
        return (
          <IonHeader>
            <IonToolbar>
              {!showSuccess &&
                <IonButtons slot='start'>
                  <ScreenBackButton
                    onClick={() => onBack && onBack()} />
                </IonButtons>}
              <IonTitle>Notifications</IonTitle>
            </IonToolbar>
          </IonHeader>
        )
      }

      return (
        <ScreenHeader
          title='Verified Contact'
          onBack={onBack} />
      )
    }
  }

  return (
    <IonPage>
      {renderHeader()}
      <IonContent>
        {!showSuccess &&
          <div
            style={{ marginTop: 40 }}>
            <div id='firebaseui' />
          </div>}
        {renderSuccess()}
        {renderError()}
      </IonContent>
      {renderNotifyTip()}
      {renderFireworks()}
      {renderGetStartedButton()}
    </IonPage>
  )
}

export default AddContactFirebase
