import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import './CalendarAccounts.css'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'services/store'
import 'screens/Settings.css'
import CalendarAccounts from './CalendarAccounts'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import PreferencesProvider from 'context/PreferencesContext/PreferencesContext'
import { updateScreens } from 'services/store/screens/actions'
import paths from 'navigation/paths'

const CalendarAccountsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { calendarAccounts, allScreens } = useSelector((state: RootState) => state.screens)
  const dispatch = useDispatch()

  function onAddCalendar (authUrl: string): void {
    console.log('onAddCalendar: ', authUrl)
    window.open(authUrl, '_self')
  }

  function onBack (): void {
    if (!allScreens?.newInvitee || !allScreens.newInvitee) {
      dispatch(updateScreens({ calendarAccounts: { previous: '', nextScreen: '' } }))
    }
  }

  function onNext (): void {
    if (calendarAccounts?.nextScreen) {
      if (!allScreens?.newInvitee) {
        history.push(paths.addParticipants)
      } else {
        history.replace(paths.meetingsList)
      }

      if (!allScreens?.newInvitee || !allScreens.newInvitee) {
        dispatch(updateScreens({ calendarAccounts: { previous: '', nextScreen: '' } }))
      }
    }
  }

  return (
    <PreferencesProvider>
      <CalendarsProvider>
        <CalendarAccounts
          onAddCalendar={onAddCalendar}
          onBack={onBack}
          onNext={calendarAccounts?.nextScreen ? onNext : undefined}
          goBackTo={calendarAccounts?.previous || paths.settings} />
      </CalendarsProvider>
    </PreferencesProvider>
  )
}

export default CalendarAccountsScreen
