
import React, { CSSProperties } from 'react'
import { CalendarEvent as CalendarEventType, TimeRange as TimeRangeType } from 'types'

import 'theme/CalendarA.css'
import moment from 'moment'
import { IonText } from '@ionic/react'
import { useCalendarView } from 'components/organisms/ScheduleCalendar/CalendarViewContext'

const box1: CSSProperties = {
  flex: 1,
  position: 'relative',
}
const overlayBox: CSSProperties = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
}

const slot: CSSProperties = {
  flex: 1,
  borderRadius: '3px',
  display: 'flex',
  paddingTop: '5px',
  paddingLeft: '4px',
  overflow: 'hidden',
  borderLeftStyle: 'solid',
  borderLeftWidth: '5px',
}

const summaryStyle: CSSProperties = {
  fontSize: 11,
  textOverflow: 'ellipsis',
  WebkitUserSelect: 'none',
}
// Changed props to CalendarEventProps
export interface CalendarEventProps {
  dayStartTime: string;
  scale: number;
  split?: string | boolean;
  timeRange: TimeRangeType;
  events: CalendarEventType[];
  onDelete?: () => void;
}

const CalendarEvents: React.FC<CalendarEventProps> = ({ events, timeRange, dayStartTime, scale, split }) => {
  const { timeSlotHeight = 50 } = useCalendarView()

  const { startTime, endTime } = timeRange
  const top =
    moment(startTime)
      .diff(moment(dayStartTime), 'minutes') * (timeSlotHeight / scale)

  const minutes = moment(endTime).diff(moment(startTime), 'minutes')
  const height = minutes * (timeSlotHeight / scale)
  const container: CSSProperties = {
    position: 'absolute',
    top: top,
    height,
    width: split ? (typeof split === 'string' ? split : '40%') : '100%',
    display: 'flex',
    paddingBottom: '1px',
    paddingRight: '2px',
  }
  const max = split ? events.length > 2 ? 2 : events.length : events.length

  let summary = events[0].summary

  if (events.length > 1) {
    summary = `${events.length.toString()}`
  }

  return (
    <div
      style={container}>
      <div style={box1}>
        {events.map((event, i) => {
          if (i < max) {
            return (
              <EventBox
                key={i}
                rank={i}
                event={event}
                height={height}
                summary={i === max - 1 ? summary : ''} />
            )
          }
        })}
      </div>
    </div>
  )
}

interface EventBoxProps {
  event: CalendarEventType;
  rank: number;
  height: number;
  summary: string;
}

const EventBox: React.FC<EventBoxProps> = ({ rank, summary, height }) => {
  const container: CSSProperties = {
    ...overlayBox,
    paddingLeft: rank * 8,
  }
  const box: CSSProperties = {
    ...slot,
  }

  // if (event.backgroundColor) {
  //   box.borderColor = event.backgroundColor
  // }

  if (rank > 0) {
    box.backgroundColor = 'transparent'
  }

  return (
    <div
      style={container}>
      <div
        style={box}
        className='CalendarACalendarEvent'>
        {summary && height > 20 &&
          <IonText
            style={summaryStyle}
            className='CalendarACalendarEventText'>
            {summary}
          </IonText>}
      </div>
    </div>

  )
}

export default CalendarEvents
