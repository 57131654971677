import React, { CSSProperties, useEffect } from 'react'
import {
  IonContent, IonPage,
  IonLabel, IonItem, IonText, IonItemGroup, IonIcon, IonCardContent, IonCard,
} from '@ionic/react'

import 'screens/Settings.css'
import { informationCircle } from 'ionicons/icons'
import { getThisVersion, getPreviousVersions } from 'services/version'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { EventName, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

const title = 'About'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const iconStyle: CSSProperties = {
  fontSize: 50,
}

const iconBox: CSSProperties = {
  padding: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const sectionBox: CSSProperties = {
  paddingLeft: 20,
  paddingRight: 20,
}

const messageBox: CSSProperties = {
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
}

const messageStyle = {
  fontSize: 14,
}

const titleStyle: CSSProperties = {
  fontSize: 12,
}

const detailsStyle = {
  fontSize: 14,
}

export interface ComponentProps {
  goBack: () => void
}

const About: React.FC<ComponentProps> = ({ goBack }) => {
  const thisVersion = getThisVersion()
  const previousVersions = getPreviousVersions()

  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'About' },
    })
  }, [])

  function renderPreviousVersions (): JSX.Element {
    return (
      <div>
        <div style={sectionBox}>
          <IonText
            color='medium'
            style={titleStyle}>
            PREVIOUS VERSIONS
          </IonText>
        </div>
        {previousVersions.map((version, i) => {
          return (
            <IonCard key={i}>
              <IonItem lines='full'>
                <IonLabel>
                  v{version.version}
                </IonLabel>
                <IonText slot='end'>
                  {version.releaseDate}
                </IonText>
              </IonItem>
              <IonCardContent style={{ paddingLeft: 10, paddingRight: 10 }}>
                {version.whatsNew.map((value, i) => {
                  return (
                    <IonText
                      key={i}
                      style={detailsStyle}>
                      • {value}<br />
                    </IonText>
                  )
                })}
              </IonCardContent>
            </IonCard>
          )
        })}
      </div>
    )
  }

  return (
    <IonPage>
      <ScreenHeader
        title={title}
        onBack={goBack} />
      <IonContent>
        <div
          style={container}
          className='titleIconBox'>
          <div style={iconBox}>
            <IonIcon
              style={iconStyle}
              icon={informationCircle}
              color='secondary' />
          </div>
          <div style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              Thank you for using MeetingTime. <br />
              MeetingTime is continuously improving to bring you the best experience.
            </IonText>
          </div>

          <IonItemGroup style={{ marginTop: 20 }}>
            <IonItem
              lines='inset'>
              <IonLabel>This Version</IonLabel>
              <IonText slot='end'>{thisVersion.version}</IonText>
            </IonItem>
            <IonItem
              lines='none'>
              <IonLabel>Release Date</IonLabel>
              <IonText slot='end'>{thisVersion.releaseDate}</IonText>
            </IonItem>
          </IonItemGroup>

          <IonCard>
            <IonItem lines='full'>
              <IonLabel>
                New in this version 🚀
              </IonLabel>
            </IonItem>
            <IonCardContent>
              {thisVersion.whatsNew.map((value, i) => {
                return (
                  <IonText
                    key={i}
                    style={detailsStyle}>
                    • {value}<br />
                  </IonText>
                )
              })}
            </IonCardContent>
          </IonCard>
          {renderPreviousVersions()}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default About
