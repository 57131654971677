import React, { CSSProperties } from 'react'
import { IonIcon } from '@ionic/react'
import { star } from 'ionicons/icons'

import { blue, success } from 'theme/styles'

export interface InitiatorBadgeProps {
  checked?: boolean
  selected?: boolean
  miniBadge?: boolean
}

const avatarColor = '#92949C'

const InitiatorBadge: React.FC<InitiatorBadgeProps> = ({ checked, selected, miniBadge }) => {
  const container: CSSProperties = {
    top: 25,
    right: miniBadge ? undefined : 0,
    left: miniBadge ? 16 : undefined,
    position: 'absolute',
    zIndex: 1,
  }

  const circle: CSSProperties = {
    height: miniBadge ? 16 : 24,
    width: miniBadge ? 16 : 24,
    borderRadius: miniBadge ? 8 : 12,
    border: '2px solid white',
    backgroundColor: checked ? success : (selected ? blue : avatarColor),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const icon: CSSProperties = {
    color: 'white',
    fontSize: miniBadge ? 8 : 12,
  }

  return (
    <div
      style={container}>
      <div
        style={circle}>
        <IonIcon
          icon={star}
          style={icon} />
      </div>
    </div>
  )
}

export default InitiatorBadge
