import { MeetingTime } from 'types'
import { AppResetAction } from '../types'

export const ADD_MEETING = 'ADD_MEETING'
export const UPDATE_MEETING = 'UPDATE_MEETING'
export const REMOVE_MEETING = 'REMOVE_MEETING'

export interface Meeting {
  id: string;
  meetingTimes?: MeetingTime[];
}

interface AddMeetingAction {
  type: typeof ADD_MEETING;
  meeting: Meeting;
}

interface UpdateMeetingAction {
  type: typeof UPDATE_MEETING;
  meeting: Meeting;
}

interface RemoveMeetingAction {
  type: typeof REMOVE_MEETING;
  meeting: Meeting;
}

export type MeetingActions = AddMeetingAction | UpdateMeetingAction | RemoveMeetingAction | AppResetAction
