import React from 'react'

import TipMinButton from 'components/atoms/TipMinButton/TipMinButton'
import { useTips } from 'context/TipsContext/TipsContext'
import { useAnalytics, EventData, EventName } from 'context/Analytics/AnalyticsContext'

import { SelectMeetingTimesTips } from 'types/componentTips'
import { SelectMeetingTimes } from 'services/store/tips/types'
import ScreenTipCard from 'components/molecules/ScreenTipCard/ScreenTipCard'

interface ScreenTipsProps {
  minTip?: boolean;
  showScreenTip?: SelectMeetingTimesTips;
  onButton?: () => void;
  onClose?: () => void;
  onMinTip?: () => void;
}

export const ScreenTips: React.FC<ScreenTipsProps> = ({ minTip, showScreenTip, onMinTip, onButton, onClose }) => {
  // console.log('ScreenTips showScreenTip', showScreenTip)

  const { storeTips, updateStoreTips } = useTips()
  const { logEvent } = useAnalytics()

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: { ...eventData },
    })
  }

  function updateTips (tip: SelectMeetingTimes): void {
    if (updateStoreTips) {
      updateStoreTips({
        selectMeetingTimes: {
          ...storeTips?.selectMeetingTimes, ...tip,
        },
      })
    }
  }

  return (
    <div>
      <TipMin
        show={minTip}
        onButton={() => {
          logTap({ component: 'TipMin', button: 'MinTip' })
          onMinTip && onMinTip()
        }} />
      <TipSelectTimes
        show={!minTip && showScreenTip === SelectMeetingTimesTips.selectTimes}
        onClose={() => {
          logTap({ component: 'TipSelectTimes', button: 'Close'})
          updateTips({ selectTimes: true })
          onClose && onClose()
        }}
        onButton={() => {
          logTap({ component: 'TipSelectTimes', button: 'GotIT'})
          updateTips({ selectTimes: true })
          onButton && onButton()
        }} />
    </div>
  )
}

interface ContainerProps {
  show?: boolean;
  onClose?: () => void;
  onButton?: () => void;
}

export const TipMin: React.FC<ContainerProps> = ({ show, onButton }) => {
  return (
    <TipMinButton
      show={show}
      bottom={120}
      onClick={onButton} />
  )
}

export const TipSelectTimes: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Tap to select or create a time range'
  const subtext = 'Drag the edges to fine-tune it\'s duration'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      buttonLabel='got it'
      onClose={onClose}
      onButton={onButton} />
  )
}
