import React, { CSSProperties, useEffect, useState } from 'react'
import { IonText, IonLabel } from '@ionic/react'

import 'theme/styles.css'
import { logoColor, success } from 'theme/styles'
import { useTips } from 'context/TipsContext/TipsContext'

import { CalendarAccount } from 'types'
import { Avatar } from '@material-ui/core'
import AnimatedCheckmark from 'components/atoms/AnimatedCheckmark/AnimatedCheckmark'

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.

const slide: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
}

const imageBox: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
}
const screenShot: CSSProperties = {
  maxWidth: '50%',
}
const titleBox: CSSProperties = {
  marginTop: '5%',
  paddingLeft: '10%',
  paddingRight: '10%',
  marginBottom: '5%',
  textAlign: 'center',
}
const textBox: CSSProperties = {
  marginTop: '5%',
  paddingLeft: '10%',
  paddingRight: '10%',
  marginBottom: '5%',
}

const cardTitle: CSSProperties = {
  fontSize: 20,
  fontWeight: 'bold',
}

const messageBox: CSSProperties = {
  marginTop: '5%',
  alignItems: 'flex-start',
  textAlign: 'start',
}

const cardMessage: CSSProperties = {
  fontSize: 15,
}

interface ComponentProps {
  calendar?: CalendarAccount,
  onlyTips?: boolean;
  onLastSlide?: (last: boolean) => void;
}

const TipsConfigureCalendarSlides: React.FC<ComponentProps> = ({ calendar, onlyTips }) => {
  const [prefersDark, setPrefersDark] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)
  const { storeTips } = useTips()

  function handleDarkMode(event: MediaQueryListEvent): void {
    setPrefersDark(event.matches)
  }

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addListener(handleDarkMode)

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeListener(handleDarkMode)
    }
  }, [])

  const completed = !!storeTips?.tipsConfigureCalendar?.completed

  return (
    <div>
      {calendar &&
        <ConnectSuccess
          calendar={calendar}
          completed={completed} />}
      {/* {(onlyTips || !completed) && <SelectFreeBusy darkMode={prefersDark} />}
      {(onlyTips || !completed) && <DefaultBookCalendar darkMode={prefersDark} />}
      {(onlyTips || !completed) && <AutoTimes darkMode={prefersDark} />} */}
      {(onlyTips) && <SelectFreeBusy darkMode={prefersDark} />}
      {(onlyTips) && <DefaultBookCalendar darkMode={prefersDark} />}
      {(onlyTips) && <AutoTimes darkMode={prefersDark} />}
    </div>
  )
}

interface ConnectSuccessProps {
  calendar: CalendarAccount
  completed?: boolean
}

export const ConnectSuccess: React.FC<ConnectSuccessProps> = ({ calendar }) => {
  const { service, account, photo } = calendar

  const titleBox: CSSProperties = {
    width: '100%',
    paddingTop: '5%',
    paddingBottom: '5%',
  }

  const iconBox: CSSProperties = {
    padding: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const box2: CSSProperties = {
    width: '100%',
    padding: '5%',
    alignItems: 'flex-start',
    textAlign: 'start',
  }

  const avatarStyle: CSSProperties = {
    width: '15%',
    height: '15%',
    backgroundColor: '#92949C',
  }

  return (
    <div style={slide}>
      <div
        style={titleBox}
        className='titleIconBox'>
        <div
          style={iconBox}>
          <Avatar
            src={photo}
            style={avatarStyle} />
          <IonLabel style={{ textAlign: 'center', marginTop: 10 }}>
            <h2>{account}</h2>
            <p>{service}</p>
          </IonLabel>
          <div style={iconBox}>
            <AnimatedCheckmark
              loop={2} />
            <IonText
              color='medium'
              style={{ textAlign: 'center' }}>
              Your calendar was successfully connected!
            </IonText>
          </div>
        </div>
      </div>
      <div style={box2}>
        <IonText style={cardTitle}>
          Calendar Preferences
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            You have total control over how MeetingTime can use your calendar for Automagic Scheduling.
            <br /><br />Next, establish your preferences for scheduling with this calendar.
          </IonText>
        </div>
      </div>
    </div>
  )
}

interface ContainerProps {
  darkMode?: boolean;
}

export const SelectFreeBusy: React.FC<ContainerProps> = ({ darkMode }) => {
  const txt = `On this screen you can tell MeetingTime how to use your connected calendars.
  Free-Busy calendars will be used to determine your availability for a meetingId, whereas events on View-Only calendars are visible as reference; MeetingTime will not consider you busy for those times.
  `

  return (
    <div style={slide}>
      <div style={titleBox}>
        <IonText style={cardTitle}>
          Free-Busy or View-Only Calendars
        </IonText>
      </div>
      <div style={imageBox}>
        <img
          src={`assets/images/configCalendarFreeBusy.${darkMode ? 'dark' : 'light'}.png`}
          style={screenShot}
          alt='' />
      </div>
      <div style={textBox}>
        <IonText style={cardMessage}>
          {txt}
        </IonText>
      </div>
    </div>
  )
}

export const DefaultBookCalendar: React.FC<ContainerProps> = ({ darkMode }) => {
  return (
    <div style={slide}>
      <div style={titleBox}>
        <IonText style={cardTitle}>
          Book Events on Calendar
        </IonText>
      </div>
      <div style={imageBox}>
        <img
          src={`assets/images/configCalendarBookCalendar.${darkMode ? 'dark' : 'light'}.png`}
          style={screenShot}
          alt='' />
      </div>
      <div style={textBox}>
        <IonText style={cardMessage}>
          Select which calendar you want MeetingTime to book your events on.<br /><br />
          When the status of a meeting becomes <span style={{ color: logoColor }}>Tentative</span> or <span style={{ color: success }}>Confirmed</span> MeetingTime will create an event on your Calendar.
        </IonText>
      </div>
    </div>
  )
}

export const AutoTimes: React.FC<ContainerProps> = ({ darkMode }) => {
  return (
    <div style={slide}>
      <div style={titleBox}>
        <IonText style={cardTitle}>
          Automagic Schedule Times
        </IonText>
      </div>
      <div style={imageBox}>
        <img
          src={`assets/images/configCalendarTimes.${darkMode ? 'dark' : 'light'}.png`}
          style={screenShot}
          alt='' />
      </div>
      <div style={textBox}>
        <IonText style={cardMessage}>
          Select the default time and day ranges you want MeetingTime to use to automagically schedule meetings,
          based on your availabilty from free-busy calendars. These settings can be changed per meeting.
        </IonText>
      </div>
    </div>
  )
}
export default TipsConfigureCalendarSlides
