import { UPDATE_PARTICIPANTS, StoreParticipants, ParticipantActions } from './types'
import { APP_RESET } from '../types'

export default function participantsReducer (
  state = {},
  action: ParticipantActions,
): StoreParticipants {
  switch (action.type) {
    case UPDATE_PARTICIPANTS:
      return { ...state, ...action.participants }
    case APP_RESET:
      return {}
    default:
      return state
  }
}
