import React, { CSSProperties } from 'react'
import { IonItem, IonLabel, IonText, IonIcon, isPlatform } from '@ionic/react'
import { SubCalendar } from 'types'
import { ellipse } from 'ionicons/icons'

const messageBox: CSSProperties = {
  padding: 20,
}

const messageStyle = {
  fontSize: 14,
}

const titleStyle: CSSProperties = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

export interface MeetingBookCalendarProps {
  calendar: SubCalendar;
  onClick: () => void;
}

const MeetingCalendar: React.FC<MeetingBookCalendarProps> = ({ calendar, onClick }) => {
  const text = 'Select which calendar you\'d like this meeting to be scheduled on.'

  return (
    <div>
      <div style={messageBox}>
        <IonText
          color='medium'
          style={messageStyle}>
          {text}
        </IonText>
      </div>
      <IonText
        color='medium'
        style={titleStyle}>
        SCHEDULE ON CALENDAR
      </IonText>
      <IonItem
        detail
        onClick={onClick}
        lines='full'
        style={{ marginTop: 5 }}>
        <IonIcon
          slot='start'
          size='small'
          style={{ color: calendar.backgroundColor }}
          icon={ellipse} />
        <IonLabel>{calendar.name}</IonLabel>
      </IonItem>
    </div>
  )
}

export default MeetingCalendar
