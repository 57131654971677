import React, { CSSProperties, useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import lottieJson from './lock.json'

interface ContainerProps {
  delay?: number;
  loop?: number | boolean;
  style?: CSSProperties;
  onComplete?: () => void;
}

const AnimatedLock: React.FC<ContainerProps> = ({ delay, loop, style }) => {
  const [play, setPlay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setPlay(true)
    }, 1000)
  }, [])

  return (
    <Lottie
      delay={delay}
      loop={loop}
      play={play}
      animationData={lottieJson}
      style={style || { width: 100, height: 100 }} />
  )
}
export default AnimatedLock
