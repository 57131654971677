
import React from 'react'
import { IonCard, IonText, isPlatform } from '@ionic/react'
import MessageLinkButton from '../../atoms/MessageLinkButton/MessageLinkButton'
import EmailLinkButton from '../../atoms/EmailLinkButton/EmailLinkButton'
import InviteLink from '../InviteLink/InviteLink'

const titleStyle = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

export interface ShareOptionsProps {
  invitation: string;
  copy: string;
  sms: string;
  email: string;
  tip?: boolean;
  onCopy: () => void;
  onMessage?: () => void;
  onEmail?: () => void;
}

const ShareOptions: React.FC<ShareOptionsProps> = ({ invitation, tip, copy, sms, email, onCopy, onMessage, onEmail }) => {
  return (
    <div
      data-ci-id='share-options'
      style={{ marginTop: 20 }}>
      <IonText
        style={titleStyle}
        color='medium'>
        {'Personal Invite Link'.toUpperCase()}
      </IonText>
      <IonCard style={{ marginTop: 10 }}>
        <InviteLink
          invitation={invitation}
          copy={copy}
          tip={tip}
          onCopy={onCopy} />
        <MessageLinkButton
          link={sms}
          onClick={onMessage} />
        <EmailLinkButton
          link={email}
          onClick={onEmail} />
      </IonCard>
    </div>
  )
}

export default ShareOptions
