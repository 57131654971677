import React, { useEffect } from 'react'

import 'screens/Settings.css'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import paths from 'navigation/paths'
import { CSSProperties } from '@material-ui/styles'
import { calendar, flash, shieldCheckmark } from 'ionicons/icons'
import { logoColor } from 'theme/styles'
import Icon from '@mdi/react'
import { mdiCalendarEdit } from '@mdi/js'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

const title = 'Topics'

const largeHeader =
  <IonHeader collapse='condense'>
    <IonToolbar>
      <IonTitle size='large'>{title}</IonTitle>
    </IonToolbar>
  </IonHeader>

const iconStyle: CSSProperties = {
  height: 40,
  width: 40,
}

const iconBox: CSSProperties = {
  marginTop: 10,
  marginBottom: 10,
  padding: 16,
  borderRadius: 10,
  marginRight: 20,
  // backgroundColor: '#f4f5f8',
}

const labelStyle: CSSProperties = {
  fontSize: 17,
  // fontWeight: 'bold',
}

interface ComponentProps {
  onGoTo: (path: string) => void;
}

const TipsList: React.FC<ComponentProps> = ({ onGoTo }) => {
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'TipsList' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'TipsList',
      },
    })
  }

  function renderAutoTips (): JSX.Element {
    const icon = {
      ...iconStyle,
      color: logoColor,
    }

    return (
      <IonItem
        detail
        lines='full'
        onClick={() => {
          logTap({ component: 'IonItem', button: 'AutoTips' })
          onGoTo(paths.tipsConnectCalendars)
        }}>
        <div
          slot='start'
          style={iconBox}
          className='tipsTopicsIconBox'>
          <IonIcon
            icon={flash}
            style={icon} />
        </div>
        <IonLabel style={labelStyle}>
          <b>Automagic Scheduling</b>
          <p>5 tips</p>
        </IonLabel>
      </IonItem>
    )
  }

  function renderCalendarTips (): JSX.Element {
    const icon = {
      ...iconStyle,
      color: logoColor,
    }

    return (
      <IonItem
        detail
        lines='full'
        onClick={() => {
          logTap({ component: 'IonItem', button: 'CalendarTips' })
          onGoTo(paths.tipsAvailabilityCalendar)
        }}>
        <div
          slot='start'
          style={iconBox}
          className='tipsTopicsIconBox'>
          <IonIcon
            icon={calendar}
            style={icon} />
        </div>
        <IonLabel style={labelStyle}>
          <b>Manual Calendar</b>
          <p>4 tips</p>
        </IonLabel>
      </IonItem>
    )
  }

  function renderCalendarTipsAuto (): JSX.Element {
    const icon = {
      ...iconStyle,
      color: logoColor,
    }

    return (
      <IonItem
        detail
        lines='full'
        onClick={() => {
          logTap({ component: 'IonItem', button: 'CalendarTipsAuto' })
          onGoTo(paths.tipsAvailabilityCalendarAuto)
        }}>
        <div
          slot='start'
          style={iconBox}
          className='tipsTopicsIconBox'>
          <IonIcon
            icon={calendar}
            style={icon} />
        </div>
        <IonLabel style={labelStyle}>
          <b>Automagic Calendar </b>
          <p>5 tips</p>
        </IonLabel>
      </IonItem>
    )
  }

  function renderGooglePermissions (): JSX.Element {
    return (
      <IonItem
        detail
        lines='full'
        onClick={() => {
          logTap({ component: 'IonItem', button: 'GooglePermissions' })
          onGoTo(paths.tipsGooglePermissions)
        }}>
        <div
          slot='start'
          style={iconBox}
          className='tipsTopicsIconBox'>
          <IonIcon
            icon={shieldCheckmark}
            color='success'
            style={iconStyle} />
        </div>
        <IonLabel style={labelStyle}>
          <b>Google Permissions</b>
          <p>4 tips</p>
        </IonLabel>
      </IonItem>
    )
  }

  function renderCalendarPreferences (): JSX.Element {
    return (
      <IonItem
        detail
        lines='full'
        onClick={() => {
          logTap({ component: 'IonItem', button: 'CalendarPrefs' })
          onGoTo(paths.tipsConfigureCalendar)
        }}>
        <div
          slot='start'
          style={iconBox}
          className='tipsTopicsIconBox'>
          <Icon
            path={mdiCalendarEdit}
            style={iconStyle}
            color={logoColor} />
        </div>
        <IonLabel style={labelStyle}>
          <b>Calendar Preferences</b>
          <p>3 tips</p>
        </IonLabel>
      </IonItem>
    )
  }

  return (
    <IonPage>
      <ScreenHeader title={title} />
      <IonContent>
        {largeHeader}
        {renderAutoTips()}
        {renderGooglePermissions()}
        {renderCalendarPreferences()}
        {renderCalendarTipsAuto()}
        {renderCalendarTips()}
      </IonContent>
    </IonPage>
  )
}

export default TipsList
