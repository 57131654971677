import React, { useState, useRef, useEffect, CSSProperties } from 'react'
import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSpinner, IonIcon, IonText, IonAlert, IonFooter,
} from '@ionic/react'
import moment from 'moment-timezone'

import 'screens/Settings.css'
import { CreateCalendarInput, CalendarService, CreateCalendarError, ActivateCalendarInput } from 'services/api'
import { CalendarAccount } from 'types'
import { calendarOutline } from 'ionicons/icons'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import TipsConfigureCalendarSlides from 'components/organisms/TipsConfigureCalendarSlides/TipsConfigureCalendarSlides'
import { Spring } from 'react-spring/renderprops'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { useTips } from 'context/TipsContext/TipsContext'
import { EventName, EventData, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

const title = 'Automagic Preferences'

const iconStyle: CSSProperties = {
  fontSize: 50,
}

const iconBox: CSSProperties = {
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

interface ContainerProps {
  authCode: string;
  onDone: () => void;
  goBack: () => void;
  onSelectFreeBusy: (calendar: string) => void;
  onSelectAutoTimes: (calendar: string) => void;
}

const ConnectCalendar: React.FC<ContainerProps> = ({ authCode, onSelectFreeBusy, onSelectAutoTimes, onDone }) => {
  const [loading, setLoading] = useState(true)
  const [showAlert, setShowAlert] = useState('')
  const [showCalendarDetails, setShowCalendarDetails] = useState<CalendarAccount>()

  const { updateStoreTips } = useTips()

  const pageRef = useRef()

  const { calendars, createCalendar, activateCalendar } = useCalendars()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'ConnectCalendar' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'ConnectCalendar',
      },
    })
  }

  async function addGoogleCalendar (authCode: string): Promise<void> {
    console.log('addGoogleCalendar: ')

    const timeZone = moment.tz.guess()

    const input: CreateCalendarInput = {
      service: CalendarService.Google,
      authCode,
      timeZone,
    }

    try {
      const calendar = await createCalendar(input)

      console.log('ADDED GOOGLE CALENDAR', calendar)

      if (calendar) {
        setShowCalendarDetails(calendar)
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log('CREATE CALENDAR ERROR: ', error.message)

        if (error.message === CreateCalendarError.calendarExists) {
          console.log('CALENDAR ALREADY EXISTS')
          setShowCalendarDetails(undefined)
          setShowAlert('This calendar is in use')
        }
      }
    }

    setLoading(false)
  }

  async function onActivateCalendar (): Promise<void> {
    if (showCalendarDetails) {
      console.log('addGoogleCalendar: ')

      const input: ActivateCalendarInput = {
        id: showCalendarDetails?.id,
        active: true,
      }

      try {
        const calendar = await activateCalendar(input)

        console.log('ACTIVATE GOOGLE CALENDAR', calendar)
      } catch (error) {
        if (error instanceof Error) {
          console.log('ACTIVATE CALENDAR ERROR: ', error.message)
        }
      }
    }
  }

  async function onNext (): Promise<void> {
    if (showCalendarDetails && updateStoreTips) {
      console.log('addGoogleCalendar: ')

      updateStoreTips({
        tipsConfigureCalendar: {
          completed: true,
        },
      })

      if (showCalendarDetails.calendars.length > 1) {
        onSelectFreeBusy(showCalendarDetails.id)
      } else {
        await onActivateCalendar()

        if (calendars?.length === 1) {
          onSelectAutoTimes(showCalendarDetails.id)
        } else {
          onDone()
        }
      }
    }
  }

  useEffect(() => {
    if (authCode) {
      addGoogleCalendar(authCode)
    }
  }, [])

  function renderConnecting (): JSX.Element | undefined {
    if (loading) {
      return (
        <div>
          <div style={iconBox}>
            <IonIcon
              style={iconStyle}
              icon={calendarOutline}
              color='secondary' />
          </div>
          {loading &&
            <div style={{ marginTop: 10, textAlign: 'center' }}>
              <IonText
                color='medium'
                style={{ marginTop: 40 }}>
                Connecting Calendar
              </IonText>
              <div>
                <IonSpinner
                  name='dots'
                  color='medium' />
              </div>
            </div>}
        </div>
      )
    }
  }

  function renderConnectSuccess (): JSX.Element | undefined {
    if (showCalendarDetails) {
      return (
        <TipsConfigureCalendarSlides
          calendar={showCalendarDetails} />
      )
    }
  }

  function renderFooter (): JSX.Element | undefined {
    // if (storeTips?.tipsConfigureCalendar?.completed) {
    //   return (
    //     <Spring
    //       from={{ opacity: 0, bottom: -600 }}
    //       to={{ opacity: 1, bottom: 0 }}>
    //       {props =>
    //         <div style={props}>
    //           <IonFooter className='screenFooterButton'>
    //             <FooterButton onClick={() => onNext()}>
    //               Next
    //             </FooterButton>
    //           </IonFooter>
    //         </div>}
    //     </Spring>
    //   )
    // } else {
    //   const txt = lastSlide ? 'Got It' : 'Skip'

    //   return (
    //     <Spring
    //       from={{ opacity: 0, bottom: -600 }}
    //       to={{ opacity: 1, bottom: 0 }}>
    //       {props =>
    //         <div style={props}>
    //           <IonFooter className='screenFooterButton'>
    //             <FooterButton onClick={() => onNext()}>
    //               {txt}
    //             </FooterButton>
    //           </IonFooter>
    //         </div>}
    //     </Spring>
    //   )
    // }
    return (
      <Spring
        from={{ opacity: 0, bottom: -600 }}
        to={{ opacity: 1, bottom: 0 }}>
        {props =>
          <div style={props}>
            <IonFooter className='screenFooterButton'>
              <FooterButton
                onClick={() => {
                  logTap({ component: 'FooterButton', button: 'Next' })
                  onNext()
                }}>
                Next
              </FooterButton>
            </IonFooter>
          </div>}
      </Spring>
    )
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {renderConnecting()}
        {renderConnectSuccess()}
      </IonContent>
      {renderFooter()}
      <IonAlert
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert('')}
        header='Error'
        message={showAlert}
        buttons={[
          {
            text: 'OK',
            handler: () => {
              setShowAlert('')
              onDone()
            },
          },
        ]} />
    </IonPage>
  )
}

export default ConnectCalendar
