import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateScreens } from 'services/store/screens/actions'
import 'screens/Settings.css'
import Connections from './Connections'
import ConnectionsProvider from 'context/Connections/ConnectionsContext'
import paths from 'navigation/paths'

const ConnectionsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch()

  function onNewMeeting (addParticipants: string[]): void {
    dispatch(updateScreens({ meetingSettings: { addParticipants } }))
    history.replace(paths.meetingSettings)
  }

  return (
    <ConnectionsProvider>
      <Connections onNewMeeting={onNewMeeting} />
    </ConnectionsProvider>
  )
}

export default ConnectionsScreen
