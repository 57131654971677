import { createStore, Store } from 'redux'
import { persistStore, persistReducer, Persistor } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage' // defaults to localStorage for web
import sessionStorage from 'redux-persist/lib/storage/session' // defaults to localStorage for web

import { rootReducer } from './index'

const sessionConfig = {
  key: 'session',
  storage: sessionStorage,
}

const localConfig = {
  key: 'local',
  storage: localStorage,
}

let localStore: Store
let sessionStore: Store

export function getLocalStore(): Store {
  return localStore
}

export default (): { store: Store, persistor: Persistor } => {
  localStore = createStore(persistReducer(localConfig, rootReducer))
  const localPersistor = persistStore(localStore)

  sessionStore = createStore(persistReducer(sessionConfig, rootReducer))

  const sessionPersistor = persistStore(sessionStore)

  if (process.env.REACT_APP_USE_SESSION_STORE) {
    console.log('*** USING SESSION STORAGE ***')
    return { store: sessionStore, persistor: sessionPersistor }
  } else {
    console.log('*** USING LOCAL STORAGE ***')
    return { store: localStore, persistor: localPersistor }
  }
}
