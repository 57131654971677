import React from 'react'
import {
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/react'

import 'screens/Settings.css'
import { Avatar } from '@material-ui/core'
import { ellipsisVertical } from 'ionicons/icons'
import InitiatorBadge from 'components/atoms/InitiatorBadge/InitiatorBadge'
import TipTarget from 'components/atoms/TipTarget/TipTarget'

const avatarColor = '#92949C'

const avatarStyle = {
  width: 26,
  height: 26,
  backgroundColor: avatarColor,
  border: '2px solid #d7d8da',
  marginRight: 5,
}

const avatarTxt = {
  fontSize: 12,
}

export interface MeetingCreatorProps {
  name: string;
  you: boolean;
  photo?: string;
  tip?: boolean;
  onMore?: () => void;
}

const MAX_NAME_LENGTH = 20

const MeetingCreator: React.FC<MeetingCreatorProps> = ({ name, you, photo, tip, onMore }) => {
  let displayName = name.split(' ').find(t => t)?.substring(0, MAX_NAME_LENGTH)
  const initials = name.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

  // if initials is > 2 characters then just pick first and last
  const displayInitials = initials.length > 2
    ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

  if (you) {
    displayName = 'You'
  }

  return (
    <IonItem
      detail
      detailIcon={ellipsisVertical}
      style={{ marginLeft: -5, overflow: 'visible' }}
      lines='none'
      onClick={onMore}>
      {tip &&
        <IonText
          slot='end'
          style={{ overflow: 'visible' }}>
          <TipTarget />
        </IonText>}
      <Avatar
        src={photo}
        style={avatarStyle}>
        <IonText style={avatarTxt}>
          {displayInitials}
        </IonText>
      </Avatar>
      <IonLabel color='medium'>
        {displayName}
      </IonLabel>
      <InitiatorBadge miniBadge />

      {/* <IonIcon
        icon={ellipsisVertical}
        color='medium'
        style={{ fontSize: 18 }}
        slot='end' /> */}
    </IonItem>
  )
}

export default MeetingCreator
