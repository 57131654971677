import { AccountActions, ADD_ACCOUNT, REMOVE_ACCOUNT, Account, UPDATE_ACCOUNT } from './types'

export default function accountsReducer(
  state: Account[] = [],
  action: AccountActions,
): Account[] {
  switch (action.type) {
    case ADD_ACCOUNT:
      console.log('ADD ACCOUNT CALLED: ', action)

      return [
        action.account,
        ...state,
      ]
    case UPDATE_ACCOUNT:
      return state.map(account => {
        if (account.id === action.account.id) {
          return action.account
        }

        return account
      })
    case REMOVE_ACCOUNT:
      return state.filter(account => account.handle !== action.account.handle)
    // case APP_RESET:
    // We don't want to zap the various accounts when the app resets
    //   return []
    default:
      return state
  }
}
