import React, { CSSProperties, useState } from 'react'
import {
  IonBackdrop,
  IonContent, IonFooter, IonModal, IonText,
} from '@ionic/react'
import { notifications } from 'ionicons/icons'
import { useSpring } from 'react-spring'

import { CalendarEvent, Participant } from 'types'
import ActionCard from '../ActionCard/ActionCard'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useTokens } from 'context/TokensContext/TokensContext'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import AnimatedBell from 'components/atoms/AnimatedBell/AnimatedBell'
import AnimatedLock from 'components/atoms/AnimatedLock/AnimatedLock'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: CalendarEvent[];
}

const iconBox: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const messageBox: CSSProperties = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const titleStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 'bold',
  textAlign: 'center',
}

const messageStyle: CSSProperties = {
  fontSize: 14,
}

interface ContainerProps {
  newUser: string
  onClose: () => void;
  onAddContact: (url: string) => void;
  presentingElement: HTMLElement | undefined;
}

const NewUserAddContactModal: React.FC<ContainerProps> = ({ newUser, onAddContact, onClose }) => {
  const [showCard] = useState(true)

  const { getVerifyToken } = useTokens()
  const { logEvent } = useAnalytics()
  const { meeting } = useMeetings()

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'NewUserAddContact',
      },
    })
  }

  async function addContact(contactType: string): Promise<void> {
    if (getVerifyToken) {
      const verifyToken = await getVerifyToken()

      if (verifyToken) {
        const url = `/addContact?contactType=${contactType}&verifyToken=${verifyToken}&newUser=${newUser}`

        onAddContact(url)
      }
    }
  }

  function renderBody(): JSX.Element | undefined {
    return (
      <div className='ion-text-start'>
        <div style={messageBox}>
          <div style={{ ...iconBox, marginRight: '1%' }}>
            <AnimatedBell
              style={{ width: 40, height: 40 }} />
          </div>
          <IonText style={titleStyle}>
            How would you like to be notified?
          </IonText>
        </div>
        <div style={messageBox}>
          <IonText style={{ ...messageStyle, textAlign: 'center' }}>
            You can use the same contact to access your account from any device.
          </IonText>
        </div>
        <div style={messageBox}>
          <div style={iconBox}>
            <AnimatedLock
              style={{ width: 60, height: 60 }} />
          </div>
          <IonText
            color='medium'
            style={messageStyle}>
            <span style={{ fontWeight: 'bold' }}>
              We keep your information private and secure, never to be shared.
            </span>
            &nbsp;Past logging in, it is only used to notify you of meeting updates.
          </IonText>
        </div>
      </div>
    )
  }

  function renderActionButtons(): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          testId='new-user-add-contact-phone'
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'AddPhone' })
            addContact('phone')
          }}>
          By Phone
        </FooterButton>
        <FooterButton
          testId='new-user-add-contact-email'
          fill='outline'
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'AddEmail' })
            addContact('email')
          }}>
          By Email
        </FooterButton>
      </IonFooter>
    )
  }

  const popoverProps = useSpring({
    ...(showCard ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderActionsCard(): JSX.Element | undefined {
    if (meeting) {
      return (
        <ActionCard
          title='Notifications'
          icon={notifications}
          animatedProps={popoverProps}
          onClose={onClose}>
          {renderBody()}
          {renderActionButtons()}
        </ActionCard>
      )
    }
  }

  return (
    <IonModal
      data-ci-id='new-user-add-contact-modal'
      isOpen
      backdropDismiss
      cssClass='meetingTimeModal'
      onDidDismiss={onClose}>
      <IonContent>
        <IonBackdrop
          data-ci-id='new-user-add-contact-backdrop'
          tappable
          visible />
        {renderActionsCard()}
      </IonContent>
    </IonModal>
  )
}

export default NewUserAddContactModal
