import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateScreens } from 'services/store/screens/actions'
import 'screens/Settings.css'
import MeetingInviteProvider from 'context/MeetingInviteContext/MeetingInviteContext'
import StartInvite from './StartInvite'
import TokensProvider from 'context/TokensContext/TokensContext'
import paths from 'navigation/paths'

type ScreenProps = RouteComponentProps<{
  id: string;
}>

const StartInviteScreen: React.FC<ScreenProps> = ({ history, match }) => {
  const invitation = match.params.id

  const dispatch = useDispatch()

  function onNewUser(): void {
    dispatch(updateScreens({
      newUserName: {
        invitation,
      },
      allScreens: {
        newInvitee: true,
      },
    }))
    history.push(paths.newUserName)
  }

  function onLogin(verifyToken: string): void {
    const url = `/login?verifyToken=${verifyToken}&invite=${invitation}`

    history.push(url)
  }

  function onSelectAccounts(): void {
    dispatch(updateScreens({ startScreen: { invitation } }))
    history.replace(paths.welcome)
  }

  function onDecline(): void {
    history.replace(paths.welcome)
  }

  return (
    <MeetingInviteProvider invitationId={invitation}>
      <TokensProvider>
        <StartInvite
          invitation={invitation}
          onNewUser={onNewUser}
          onLogin={onLogin}
          onSelectAccounts={onSelectAccounts}
          onDecline={onDecline} />
      </TokensProvider>
    </MeetingInviteProvider>
  )
}

export default StartInviteScreen
