import React from 'react'
import { IonModal } from '@ionic/react'

import 'screens/Settings.css'
import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'

import { TimeRange } from 'types'
import AutoBookSettings, { AutoBookSettingsProps } from './AutoBookSettings'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'
// import { AutoBookSettings as StoreTips } from 'services/store/tips/types'

const title = 'AutoMagic Times'

export interface ContainerProps {
  enabled: boolean;
  timeRanges?: TimeRange[];
  days?: string[];
  noShowSwitch?: boolean;
  timeZone?: string;
  onUpdated: (timeRanges: TimeRange[], days: string[], enabled: boolean) => void;
}

interface ComponentProps extends AutoBookSettingsProps {
  presentingElement?: HTMLElement | undefined;
}

const AutoBookSettingsModal: React.FC<ComponentProps> = (props) => {
  const { presentingElement, onClose } = props
  const { logEvent } = useAnalytics()

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'AutoBookSettingsModal',
      },
    })
  }

  function renderHeader (): JSX.Element {
    return (
      <ModalHeader
        title={title} />
    )
  }

  return (
    <IonModal
      isOpen
      swipeToClose
      presentingElement={presentingElement}
      onDidDismiss={() => {
        logTap({ button: 'GestureClose' })
        onClose && onClose()
      }}>
      {renderHeader()}
      <AutoBookSettings {...props} />
    </IonModal>
  )
}

export default AutoBookSettingsModal
