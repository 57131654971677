export enum OS {
  'iPhone' = 'iPhone',
  'Android' = 'Android',
  'Mozilla' = 'Mozilla',
  'Chrome' = 'Chrome',
}

export function browserName (userAgent: string = navigator.userAgent): string | undefined {
  if (userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome'
  }

  if (userAgent.indexOf('Safari') !== -1) {
    return 'Safari'
  }

  if (userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox'
  }

  if (userAgent.indexOf('Opera') !== -1) {
    return 'Opera'
  }

  if (userAgent.indexOf('Mozilla') !== -1) {
    return 'Mozilla'
  }

  return ''
}

export function platformName (userAgent: string = navigator.userAgent): string | undefined {
  if (userAgent.indexOf('iPhone') !== -1) {
    return 'iPhone'
  }

  if (userAgent.indexOf('Android') !== -1) {
    return 'Android'
  }

  if (userAgent.indexOf('Mac') !== -1) {
    return 'Mac'
  }

  if (userAgent.indexOf('Windows') !== -1) {
    return 'Windows'
  }
}

// export function isPlatform (os: OS): boolean {
//   return navigator.userAgent.indexOf(os) !== -1
// }
