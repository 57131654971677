import React from 'react'

import 'screens/Settings.css'
import ParticipantDetailsModal from './ParticipantDetailsModal'
import NotifyParticipantsProvider from 'context/NotifyParticipantsContext/NotifyParticipantsContext'

interface ContainerProps {
  meetingParticipant: string;
  onMore?: () => void;
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const ParticipantModalScreen: React.FC<ContainerProps> = ({ meetingParticipant, onMore, onClose, presentingElement }) => {
  return (
    <NotifyParticipantsProvider>
      <ParticipantDetailsModal
        meetingParticipant={meetingParticipant}
        onMore={onMore}
        onClose={onClose}
        presentingElement={presentingElement} />
    </NotifyParticipantsProvider>

  )
}

export default ParticipantModalScreen
