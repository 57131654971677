import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'services/store'
import CalendarSettingsAutoTimes from './CalendarSettingsAutoTimes'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import PreferencesProvider from 'context/PreferencesContext/PreferencesContext'
import paths from 'navigation/paths'

const CalendarSettingsAutoTimesScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { calendarSettings } = useSelector((state: RootState) => state.screens)

  function onDone (): void {
    history.push(paths.calendarSettingsDoneAddAnother)
  }

  return (
    <PreferencesProvider>
      <CalendarsProvider>
        <CalendarSettingsAutoTimes
          calendarId={calendarSettings?.calendar}
          onDone={onDone} />
      </CalendarsProvider>
    </PreferencesProvider>
  )
}

export default CalendarSettingsAutoTimesScreen
