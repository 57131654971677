import React, { useContext, useEffect } from 'react'
import { ApolloError, useMutation, useQuery, useSubscription, useApolloClient } from '@apollo/client'

import { CreateTimeSlotInput, CREATE_TIME_SLOT, DeleteTimeSlotInput, DELETE_TIME_SLOT, GET_TIME_SLOTS, TIMESLOTS_SUBSCRIPTION, UpdateTimeSlotInput, UPDATE_TIME_SLOT } from 'services/api'
import { TimeSlot } from 'types'
import { EventName, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

export interface TimeSlotsContextValue {
  loading?: boolean
  timeSlots?: TimeSlot[]
  createTimeSlot: (input: CreateTimeSlotInput) => Promise<TimeSlot | undefined>
  updateTimeSlot: (input: UpdateTimeSlotInput) => Promise<TimeSlot | undefined>
  deleteTimeSlot: (input: DeleteTimeSlotInput) => Promise<string | undefined>
}

const initialValue: TimeSlotsContextValue = {
  loading: false,
  timeSlots: [],
  createTimeSlot: async (input: CreateTimeSlotInput) => {
    console.log('createTimeSlot: ', input)

    return undefined
  },
  updateTimeSlot: async (input: UpdateTimeSlotInput) => {
    console.log('updateTimeSlot: ', input)

    return undefined
  },
  deleteTimeSlot: async (input: DeleteTimeSlotInput) => {
    console.log('deleteTimeSlot: ', input)

    return undefined
  },
}

// create and initialize context
export const TimeSlotsContext = React.createContext<TimeSlotsContextValue>(initialValue)

export function useTimeSlots(): TimeSlotsContextValue {
  return useContext(TimeSlotsContext)
}

export type TimeSlotsMockContextValue = Partial<TimeSlotsContextValue>

type MockProps = {
  value?: Partial<TimeSlotsContextValue>
}

export const TimeSlotsMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <TimeSlotsContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </TimeSlotsContext.Provider>
  )
}

interface ProviderProps {
  meetingId?: string,
}

const TimeSlotsProvider: React.FC<ProviderProps> = ({ meetingId, children }) => {
  const client = useApolloClient()

  const { logEvent } = useAnalytics()

  function onError(error: ApolloError): void {
    if (error.graphQLErrors.find(err => err.extensions?.code === 'UNAUTHENTICATED')) {
      console.log('TIME SLOT CONTEXT: USER UNAUTHENTICATED')
    }
  }

  const { loading, data, refetch: refetchTimeSlots } = useQuery(GET_TIME_SLOTS, {
    variables: { meetingId },
    fetchPolicy: 'cache-and-network',
    onError,
  })

  const { data: subscriptionData } = useSubscription(TIMESLOTS_SUBSCRIPTION)

  useEffect(() => {
    console.log('TimeSlot Updated subsriptionData ', subscriptionData)
    console.log('meetingId', meetingId)

    if (subscriptionData && subscriptionData.timeSlotsUpdated) {
      const { action, createdTimeSlots, deletedTimeSlots, updatedTimeSlots, meetingId: meeting } = subscriptionData.timeSlotsUpdated

      // console.log('HIT THE CACHE')
      if (meeting === meetingId) {
        if (createdTimeSlots || deletedTimeSlots || updatedTimeSlots) {
          const result = client.readQuery(
            {
              query: GET_TIME_SLOTS,
              variables: { meetingId },
            })

          if (result?.timeSlots) {
            const timeSlots = result.timeSlots as [TimeSlot]
            let cachedTimeSlots = timeSlots.slice()

            if (createdTimeSlots?.length) {
              console.log('CREATED TIME SLOTS: ', createdTimeSlots)

              const newTimeSlots = createdTimeSlots.filter((timeSlot: TimeSlot) => !timeSlots.find(t => t.id === timeSlot.id))

              cachedTimeSlots = cachedTimeSlots.concat(newTimeSlots)
            }

            if (deletedTimeSlots?.length) {
              console.log('DELETED TIME SLOTS: ', deletedTimeSlots)
              deletedTimeSlots.forEach((timeSlot: TimeSlot) => {
                const index = cachedTimeSlots.findIndex(t => t.id === timeSlot.id)

                if (index > -1) {
                  cachedTimeSlots.splice(index, 1)
                }
              })
            }

            if (updatedTimeSlots?.length) {
              // firest remove the slots from the cache
              console.log('UPDATED TIME SLOTS: ', updatedTimeSlots)
              updatedTimeSlots.forEach((timeSlot: TimeSlot) => {
                const index = cachedTimeSlots.findIndex(t => t.id === timeSlot.id)

                if (index > -1) {
                  cachedTimeSlots.splice(index, 1)
                }
              })
              // insert the updated slots into cache
              cachedTimeSlots = cachedTimeSlots.concat(updatedTimeSlots)
            }

            client.writeQuery({
              query: GET_TIME_SLOTS,
              variables: { meetingId },
              data: { timeSlots: cachedTimeSlots },
            })
          }
        } else if (action && (action === 'user exited' || action === 'reload')) {
          refetchTimeSlots()
          // refetchParticipants()
        }
      }
    }
  }, [subscriptionData, refetchTimeSlots, client, meetingId])

  const [updateTimeSlotMutation] = useMutation(UPDATE_TIME_SLOT)
  const [createTimeSlotMutation] = useMutation(CREATE_TIME_SLOT,
    {
      update(cache, { data: { createTimeSlot } }) {
        // console.log('create timeslot update cache')

        const { timeSlots } = cache.readQuery(
          { query: GET_TIME_SLOTS, variables: { meetingId } }) as { timeSlots: [TimeSlot] }

        // console.log('cached meetings: ', meetings)
        // console.log('mutation data: ', createTimeSlot)

        // console.log('Cached timeSlots length: ', timeSlots.length)
        if (!timeSlots.find(t => t.id === createTimeSlot.id)) {
          cache.writeQuery({
            query: GET_TIME_SLOTS,
            variables: { meetingId },
            data: { timeSlots: timeSlots.concat([createTimeSlot]) },
          })
        } else {
          // console.log('probably already got added by')
        }
      },
    })

  const [deleteTimeSlotMutation] = useMutation(DELETE_TIME_SLOT,
    {
      update(cache, { data: { deleteTimeSlot } }) {
        // console.log('Update cache')

        const { timeSlots } = cache.readQuery(
          { query: GET_TIME_SLOTS, variables: { meetingId } }) as { timeSlots: [TimeSlot] }

        // console.log('mutation data: ', deleteTimeSlot)
        // console.log('Cached timeSlots length: ', timeSlots.length)
        cache.writeQuery({
          query: GET_TIME_SLOTS,
          variables: { meetingId },
          data: { timeSlots: timeSlots.filter(timeSlot => timeSlot.id !== deleteTimeSlot.id) },
        })
      },
    })

  async function createTimeSlot(input: CreateTimeSlotInput): Promise<TimeSlot | undefined> {
    const { data } = await createTimeSlotMutation({ variables: { input } })

    if (data?.createTimeSlot?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'createTimeSlot',
          meetingId: input.meetingId,
          timeSlot: data.createTimeSlot.id,
        },
      })

      return data.createTimeSlot
    }
  }

  async function updateTimeSlot(input: UpdateTimeSlotInput): Promise<TimeSlot | undefined> {
    const { data } = await updateTimeSlotMutation({ variables: { input } })

    if (data?.updateTimeSlot?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'updateTimeSlot',
          timeSlot: data.updateTimeSlot.id,
        },
      })

      return data.updateTimeSlot
    }
  }

  async function deleteTimeSlot(input: DeleteTimeSlotInput): Promise<string | undefined> {
    const { data } = await deleteTimeSlotMutation({ variables: { input } })

    if (data?.deleteTimeSlot?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'deleteTimeSlot',
          timeSlot: data.deleteTimeSlot.id,
        },
      })

      return data.deleteTimeSlot.id
    }
  }

  return (
    <TimeSlotsContext.Provider
      value={{
        loading,
        timeSlots: data?.timeSlots,
        createTimeSlot,
        updateTimeSlot,
        deleteTimeSlot,
      }}>
      {children}
    </TimeSlotsContext.Provider>
  )
}

export default TimeSlotsProvider
