import React, { CSSProperties, useEffect, useState } from 'react'
import {
  IonContent, IonPage, IonFooter, IonItem, IonText, isPlatform, IonSpinner, IonLabel,
} from '@ionic/react'
import Linkify from 'react-linkify'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'

import FooterButton from 'components/atoms/FooterButton/FooterButton'
import TextAreaInput from 'components/atoms/TextAreaInput/TextAreaInput'
import { UpdateMeetingInput } from 'services/api'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

const title = 'Meeting Description'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const titleStyle = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

interface MeetingDescriptionProps {
  goBack: () => void;
}

const MeetingDescription: React.FC<MeetingDescriptionProps> = ({ goBack }) => {
  const [description, setDescription] = useState('')
  const [edited, setEdited] = useState(false)
  const [showActivity, setShowActivity] = useState(false)

  const { meeting, updateMeeting } = useMeetings()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'MeetingDescription', meetingId: meeting?.id },
    })
  }, [])

  useEffect(() => {
    if (meeting?.description && !edited && !description) {
      setDescription(meeting.description)
    }
  }, [meeting])

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'MeetingDescription',
        meetingId: meeting?.id,
      },
    })
  }

  async function updateMeetingDescription(): Promise<void> {
    if (meeting && updateMeeting) {
      setShowActivity(true)

      const update: UpdateMeetingInput = {
        id: meeting.id,
        description,
      }

      await updateMeeting(update)
      setShowActivity(false)
      goBack()
    }
  }

  function renderFooter(): JSX.Element | undefined {
    if (showActivity) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton>
            <IonSpinner name='dots' />
          </FooterButton>
        </IonFooter>
      )
    }

    if (edited) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ component: 'FooterButton', button: 'UpdateMeeting' })
              updateMeetingDescription()
            }}>
            Update Meeting
          </FooterButton>
          <FooterButton
            fill='clear'
            onClick={() => {
              logTap({ component: 'FooterButton', button: 'Cancel' })
              setEdited(false)
            }}>
            Cancel
          </FooterButton>
        </IonFooter>
      )
    } else {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ component: 'FooterButton', button: 'EditDescription' })
              setEdited(true)
            }}>
            Edit Description
          </FooterButton>
        </IonFooter>
      )
    }
  }

  // function renderDescription (): JSX.Element | undefined {
  //   if (!edited) {
  //     const formatted = linkify(description)

  //     return (
  //       <ListItem
  //         title='meeting description'
  //         label={formatted}
  //         textWrap />
  //     )
  //   }
  // }

  function renderDescription(): JSX.Element | undefined {
    if (!edited) {
      return (
        <div style={{ paddingTop: 20 }}>
          <IonText
            color='medium'
            style={titleStyle}>
            MEETING DESCRIPTION
          </IonText>
          <IonItem
            lines='none'
            style={{ marginTop: 5 }}>
            <IonLabel>
              <IonText style={{ whiteSpace: 'pre-wrap' }}>
                <Linkify>
                  {description}
                </Linkify>
              </IonText>
            </IonLabel>
          </IonItem>
        </div>

      )
    }
  }

  function renderInput(): JSX.Element | undefined {
    if (edited) {
      return (
        <TextAreaInput
          title='meeting description'
          placeholder='Tap here to enter meeting description'
          value={description}
          onChange={(value) => {
            logTap({ component: 'TextAreaInput', button: 'EditDescription' })

            if (value) {
              // const formatted = value.replace(/[^a-z0-9-_]/gi, '').toLowerCase()

              setDescription(value)
            } else {
              setDescription('')
            }

            setEdited(true)
          }} />
      )
    }
  }

  return (
    <IonPage>
      <ScreenHeader
        title={meeting?.title || title}
        onBack={goBack} />
      <IonContent>
        <div
          style={container}
          className='titleIconBox'>
          {renderDescription()}
          {renderInput()}
        </div>
      </IonContent>
      {renderFooter()}
    </IonPage>
  )
}

export default MeetingDescription
