// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase'
import * as firebaseui from 'firebaseui'

// Add the Firebase services that you want to use
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

import 'firebaseui/dist/firebaseui.css'

// Tried overriding the firebaseui theme for dark mode.
// Input border is not working yet!
import './firebaseui.css'
// TODO: https://github.com/firebase/firebaseui-web-react

// import firebaseui from 'firebaseui'

import firebaseConfig from './config'

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)

// Initialize analytics
const analytics = firebase.analytics(app)

const auth = firebase.auth()

auth.setPersistence(firebase.auth.Auth.Persistence.NONE)

const ui = new firebaseui.auth.AuthUI(auth)

export type UserInfo = {
  uid: string;
  idToken: string;
  email?: string;
  phone?: string;
  photo?: string;
  provider?: string;
}

// https://firebase.google.com/docs/auth/web/manage-users
async function getUserInfo(onSuccess: (userId: UserInfo) => void, provider?: string): Promise<void> {
  const { currentUser } = firebase.auth()

  if (currentUser) {
    // const { uid, email, phoneNumber, providerData, photoURL } = currentUser
    const { uid, email, phoneNumber, photoURL } = currentUser
    const idToken = await currentUser.getIdToken(true)

    const info: UserInfo = {
      uid,
      idToken,
    }

    if (email) info.email = email

    if (phoneNumber) info.phone = phoneNumber

    if (photoURL) info.photo = photoURL

    if (provider) info.provider = provider

    // if (providerData) {
    //   providerData.forEach(profile => {
    //     if (profile) {
    //       console.log('Sign-in provider: ' + profile.providerId)
    //       console.log('  Provider-specific UID: ' + profile.uid)
    //       console.log('  Name: ' + profile.displayName)
    //       console.log('  Email: ' + profile.email)
    //       console.log('  Phone: ' + profile.phoneNumber)
    //       console.log('  Photo URL: ' + profile.photoURL)
    //     }
    //   })
    // }

    console.log('FIREBASE USER: ', info)
    onSuccess(info)
  }
}

export function firebaseAuth(): firebase.auth.Auth {
  return auth
}
// This is our firebaseui configuration object

// This adds firebaseui to the page
// It does everything else on its own
export function firebaseLogin(elementId: string, url: string, onSuccess: (userId: UserInfo) => void): void {
  const loginConfig: firebaseui.auth.Config = ({
    callbacks: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      signInSuccessWithAuthResult: (): boolean => {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        getUserInfo(onSuccess)

        return false
      },
    },
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        emailLinkSignIn: () => {
          return {
            url,
          }
        },
      },
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
  })

  ui.start(elementId, loginConfig)
}

function addEmail(elementId: string, url: string, onSuccess: (userId: UserInfo) => void): void {
  const emailConfig: firebaseui.auth.Config = ({
    callbacks: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // signInSuccessWithAuthResult: (authResult: any, redirectUrl: string): boolean => {
      signInSuccessWithAuthResult: (): boolean => {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        console.log('SIGN IN with EMAIL SUCCESS: ')

        getUserInfo(onSuccess)

        return false
      },
    },
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        fullLabel: 'Add Email',
        emailLinkSignIn: () => {
          return {
            url,
          }
        },
      },
    ],
  })

  ui.start(elementId, emailConfig)
}

function addPhone(elementId: string, onSuccess: (userId: UserInfo) => void): void {
  // https://github.com/firebase/firebaseui-web#configure-phone-provider
  const phoneConfig: firebaseui.auth.Config = ({
    callbacks: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // signInSuccessWithAuthResult: (authResult: any, redirectUrl: string): boolean => {
      signInSuccessWithAuthResult: (): boolean => {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        console.log('SIGN IN with PHONE SUCCESS: ')
        // getUserInfo(onSuccess)
        getUserInfo(onSuccess)

        return false
      },
    },
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        recaptchaParameters: {
          size: 'invisible',
        },
        fullLabel: 'Add Phone',
      },
    ],
  })

  ui.start(elementId, phoneConfig)
}

function addGoogle(elementId: string, onSuccess: (userId: UserInfo) => void): void {
  const googleConfig: firebaseui.auth.Config = ({
    callbacks: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      signInSuccessWithAuthResult: (): boolean => {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        console.log('SIGN IN with Google SUCCESS: ')
        // getUserInfo(onSuccess)
        getUserInfo(onSuccess, 'google')

        return false
      },
    },
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
  })

  ui.start(elementId, googleConfig)
}

export function addContact(elementId: string, contact: string, url: string, onSuccess: (userId: UserInfo) => void): void {
  if (contact === 'email') {
    addEmail(elementId, url, onSuccess)
  } else if (contact === 'phone') {
    addPhone(elementId, onSuccess)
  } else if (contact === 'google') {
    addGoogle(elementId, onSuccess)
  }
}

export async function verfiyEmailStart(email: string, token: string): Promise<void> {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: 'http://localhost:8100/finishVerifyEmail?token=' + token,
    // This must be true.
    handleCodeInApp: true,
    // dynamicLinkDomain: 'example.page.link',
  }

  try {
    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem('emailForSignIn', email)
  } catch (error) {
    // Some error occurred, you can inspect the code: error.code
    console.log('verfiyEmailStart: FIREBASE ERROR: ', error)
  }
}

export async function verifyEmailFinish(): Promise<void> {
  // Confirm the link is a sign-in with email link.
  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn')

    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation')
    }

    try {
      if (email) {
        // The client SDK will parse the code from the link for you.
        const result = await firebase.auth().signInWithEmailLink(email, window.location.href)

        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn')
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        console.log('VERIFIED USER: ', result)
      }
    } catch (error) {
      console.log('verifyEmailFinish: FIREBASE ERROR: ', error)
    }
  }
}

export function setUser(userId: string): void {
  analytics.setUserId(userId)
}

export function logEvent(event: string, data?: firebase.analytics.CustomParams): void {
  analytics.logEvent(event, data)
}
