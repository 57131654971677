import React, { CSSProperties, useState } from 'react'
import {
  IonBackdrop,
  IonContent, IonModal, IonText,
} from '@ionic/react'
import { useSpring } from 'react-spring'
import { CalendarEvent, Participant } from 'types'
import ActionCard from '../ActionCard/ActionCard'

import AnimatedInviteSent from 'components/atoms/AnimatedInviteSent/AnimatedInviteSent'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: CalendarEvent[];
}

const iconBox: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const titleStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 'bold',
  textAlign: 'center',
}

const messageBox: CSSProperties = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
}

const messageStyle: CSSProperties = {
  fontSize: 14,
}

interface ContainerProps {
  names: string[];
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const InvitationsSentModal: React.FC<ContainerProps> = ({ names, onClose }) => {
  const [showCard] = useState(true)
  const { meeting } = useMeetings()

  function renderStatus(): JSX.Element | undefined {
    const message = 'Meeting invitation has been sent to\n'
    const to = names.join(', ')

    return (
      <div
        className='ion-text-start'>
        <div style={iconBox}>
          <AnimatedInviteSent
            style={{ width: 100, height: 100 }}
            loop={4} />
          <IonText
            style={titleStyle}>
            Invitations Sent!
          </IonText>
          <div
            style={messageBox}>
            <IonText
              style={messageStyle}>
              {message}
              {to}
            </IonText>
          </div>
        </div>
      </div>
    )
  }

  const popoverProps = useSpring({
    ...(showCard ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderActionsCard(): JSX.Element | undefined {
    if (meeting) {
      return (
        <ActionCard
          title={meeting?.title}
          icon=''
          onClose={onClose}
          animatedProps={popoverProps}>
          {renderStatus()}
        </ActionCard>
      )
    }
  }

  return (
    <IonModal
      isOpen
      backdropDismiss
      cssClass='meetingTimeModal'
      onDidDismiss={onClose}>
      <IonContent>
        <IonBackdrop
          tappable
          visible />
        {renderActionsCard()}
      </IonContent>
    </IonModal>
  )
}

export default InvitationsSentModal
