import React, { CSSProperties, useState } from 'react'
import {
  IonContent, IonModal, IonItem, IonText, IonInput, IonIcon, isPlatform,
} from '@ionic/react'

import 'screens/Settings.css'
import { videocamOutline } from 'ionicons/icons'
import ModalHeader from '../ModalHeader/ModalHeader'
import { VideoConference } from 'types'

const title = 'Meeting Conference'

const container: CSSProperties = {
  marginTop: 10,
}

const titleStyle = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

export interface EditMeetingConferenceProps {
  videoConference?: VideoConference
  onClose: () => void;
  onDone: (video: VideoConference) => void;
  presentingElement: HTMLElement | undefined;
}

const EditMeetingConference: React.FC<EditMeetingConferenceProps> = ({ videoConference, onClose, onDone, presentingElement }) => {
  const [videoLink, setVideoLink] = useState(videoConference?.link || '')
  const [edited, setEdited] = useState(false)

  function isValidHttpUrl (link: string): boolean {
    let url

    try {
      url = new URL(link)
    } catch (_) {
      return false
    }

    return url.protocol === 'https:'
  }

  function validateLink (link: string): void {
    if (link) {
      if (isValidHttpUrl(link)) {
        setEdited(true)
      } else {
        setEdited(false)
      }
    }
  }

  function renderHeader (): JSX.Element {
    if (edited) {
      return (
        <ModalHeader
          title={title}
          doneButton='Done'
          onClose={onClose}
          onDone={() => onDone({ link: videoLink })} />
      )
    }

    return (
      <ModalHeader
        title={title}
        onClose={onClose} />
    )
  }

  return (
    <IonModal
      isOpen
      swipeToClose
      presentingElement={presentingElement}
      onDidDismiss={onClose}>
      {renderHeader()}
      <IonContent>
        <div style={container}>
          <IonText
            style={titleStyle}
            color='medium'>
            VIDEO CONFERENCE
          </IonText>
          <IonItem
            lines='full'
            style={{ marginTop: 5 }}>
            <IonIcon
              icon={videocamOutline}
              style={{ left: -5 }}
              slot='start'
              color='primary'
              size='medium' />
            <IonInput
              autocapitalize='none'
              value={videoLink}
              onIonChange={e => {
                if (e.detail.value) {
                  setVideoLink(e.detail.value)
                  validateLink(e.detail.value)
                } else {
                  setVideoLink('')
                  validateLink('')
                }
              }} />
          </IonItem>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default EditMeetingConference
