import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'services/store'

import 'screens/Settings.css'

import ProfilePhotoProvider from 'context/ProfilePhotoContext/ProfilePhotoContext'
import NewUserAddContact from './NewUserAddContact'
import ContactsProvider from 'context/ContactsContext/ContactsContext'
import TokensProvider from 'context/TokensContext/TokensContext'

const NewUserAddContactScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { allScreens } = useSelector((state: RootState) => state.screens)

  function onAddContact (url: string): void {
    history.push(url)
  }

  const newUser = allScreens?.newInvitee ? 'invitee' : 'initiator'

  return (
    <ProfilePhotoProvider>
      <ContactsProvider>
        <TokensProvider>
          <NewUserAddContact
            newUser={newUser}
            onAddContact={onAddContact}
            goBack={() => history.goBack()} />
        </TokensProvider>
      </ContactsProvider>
    </ProfilePhotoProvider>
  )
}
export default NewUserAddContactScreen
