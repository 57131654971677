import React, { CSSProperties, useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import lottieJson from './lightning-logocolor.json'

export interface AnimatedLightningProps {
  delay?: number;
  loop?: number | boolean;
  style?: CSSProperties;
  onComplete?: () => void;
}

const AnimatedLightning: React.FC<AnimatedLightningProps> = ({ delay, style, loop }) => {
  const [play, setPlay] = useState(!delay)

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setPlay(true)
      }, delay)
    }
  }, [])

  return (
    <Lottie
      loop={loop || 1}
      play={play}
      speed={0.75}
      animationData={lottieJson}
      style={style || { width: 120, height: 120 }} />
  )
}
export default AnimatedLightning
