import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'services/store'

import 'screens/Settings.css'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import MeetingShareProvider from 'context/MeetingShareContext/MeetingShareContext'
import ContactsProvider from 'context/ContactsContext/ContactsContext'
import MeetingInvite from './MeetingInvite'
import paths from 'navigation/paths'

const MeetingInviteScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { shareMeetingInvite } = useSelector((state: RootState) => state.screens)

  function onAddContact(): void {
    history.push(paths.newUserAddContact)
  }

  function goBack(): void {
    history.goBack()
  }

  return (
    <ContactsProvider>
      <MeetingsProvider meetingId={shareMeetingInvite?.meetingId}>
        <InviteesProvider meetingId={shareMeetingInvite?.meetingId}>
          <MeetingShareProvider meetingId={shareMeetingInvite?.meetingId}>
            <MeetingInvite
              nextScreen={shareMeetingInvite?.nextScreen}
              onAddContact={onAddContact}
              goBack={goBack} />
          </MeetingShareProvider>
        </InviteesProvider>
      </MeetingsProvider>
    </ContactsProvider>
  )
}

export default MeetingInviteScreen
