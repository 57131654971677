import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'services/store'
import 'screens/Settings.css'
import MeetingCalendarSettings from './MeetingCalendarSettings'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import PreferencesProvider from 'context/PreferencesContext/PreferencesContext'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import { updateScreens } from 'services/store/screens/actions'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import StoreParticipantsProvider from 'context/StoreParticipantsContext/StoreParticipantsContext'
import paths from 'navigation/paths'

const MeetingSettingsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { meetingCalendarSettings } = useSelector((state: RootState) => state.screens)
  const storeTips = useSelector((state: RootState) => state.tips)

  const dispatch = useDispatch()

  const meetingId = meetingCalendarSettings?.meetingId

  function onChooseAvailability(): void {
    if (meetingId) {
      if (storeTips?.tipsAvailabilityCalendar?.completed) {
        dispatch(updateScreens({
          chooseAvailability: {
            meetingId,
            startEdit: true,
            previous: paths.meetingsList,
          },
          meetingCalendarSettings: { meetingId: '' },
        }))
        history.replace(paths.chooseAvailability)
      } else {
        dispatch(updateScreens({
          chooseAvailability: {
            meetingId,
            startEdit: true,
          },
          tipsAvailabilityCalendar: {
            nextScreen: paths.chooseAvailability,
          },
        }))
        history.replace(paths.tipsAvailabilityCalendar)
      }
    }
  }

  function onAddParticipants(): void {
    if (meetingId) {
      dispatch(updateScreens({
        addParticipants: {
          meetingId,
          previous: paths.meetingsList,
          nextScreen: paths.meetingsList,
        },
      }))
      history.push(paths.addParticipants)
    }
  }

  function goBack(): void {
    history.goBack()
    // NOTE the reason we clear the screen params state after a delay is
    // that if we clear it sooner it re-renders the current screen with the new params and it causes flicker
    // before switching back to previous screen
    setTimeout(() => {
      dispatch(updateScreens({ meetingCalendarSettings: { meetingId: '' } }))
    }, 1000)
  }

  function onDone(): void {
    history.replace(paths.meetingsList)
  }

  return (
    <PreferencesProvider>
      <CalendarsProvider>
        <MeetingsProvider meetingId={meetingCalendarSettings?.meetingId}>
          <InviteesProvider meetingId={meetingCalendarSettings?.meetingId}>
            <ParticipantsProvider meetingId={meetingCalendarSettings?.meetingId}>
              <StoreParticipantsProvider>
                <MeetingCalendarSettings
                  onChooseAvailability={onChooseAvailability}
                  onAddParticipants={onAddParticipants}
                  initiator={meetingCalendarSettings?.initiator}
                  meetMeInitiator={meetingCalendarSettings?.meetMeInitiator}
                  editOnly={meetingCalendarSettings?.editOnly}
                  goBack={goBack}
                  onDone={onDone}
                  goBackTo={meetingCalendarSettings?.previous} />
              </StoreParticipantsProvider>
            </ParticipantsProvider>
          </InviteesProvider>
        </MeetingsProvider>
      </CalendarsProvider>
    </PreferencesProvider>
  )
}

export default MeetingSettingsScreen
