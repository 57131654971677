import { MeetingActions, ADD_MEETING, REMOVE_MEETING, UPDATE_MEETING, Meeting } from './types'
import { APP_RESET } from '../types'

export default function meetingsReducer(
  state: Meeting[] = [],
  action: MeetingActions,
): Meeting[] {
  switch (action.type) {
    case ADD_MEETING:
      return [
        action.meeting,
        ...state,
      ]
    case UPDATE_MEETING:
      return state.map(meeting => {
        if (meeting.id === action.meeting.id) {
          return action.meeting
        }

        return meeting
      })
    case REMOVE_MEETING:
      return state.filter(meeting => meeting.id !== action.meeting.id)
    case APP_RESET:
      return []
    default:
      return state
  }
}
