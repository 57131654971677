import React, { useContext } from 'react'

export enum AuthTypes {
  'loading',
  'notAuthenticated',
  'authenticated',
}

type RouteContextValue = {
  authenticated: AuthTypes,
  setAuthenticated: React.Dispatch<React.SetStateAction<AuthTypes>>
}

const RouteContext = React.createContext<Partial<RouteContextValue>>({})

export function useRoutes (): Partial<RouteContextValue> {
  return useContext(RouteContext)
}

export default RouteContext
