import React, { CSSProperties, useState, useEffect } from 'react'
import {
  IonContent, IonLabel, IonItem, IonIcon, IonModal, IonActionSheet, IonSpinner, IonButton, IonFooter, IonNote,
} from '@ionic/react'

import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'
import { Avatar } from '@material-ui/core'
import { CalendarAccount, SubCalendar } from 'types'
import { ellipse, checkmarkCircle, eye, eyeOff } from 'ionicons/icons'
import { CalendarService, UpdateCalendarInput } from 'services/api'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import { ScreenTips } from './ScreenTips'
import { CalendarAccountDetailsTips } from 'types/componentTips'
import { useTips } from 'context/TipsContext/TipsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'

const title = 'Calendar Account'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const avatarStyle = {
  width: 60,
  height: 60,
  backgroundColor: '#92949C',
}

const iconBox: CSSProperties = {
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

interface ContainerProps {
  calendar: CalendarAccount;
  onClose: () => void;
  onRemove: () => void;
  onDone?: () => void;
  presentingElement: HTMLElement | undefined;
}

const CalendarAccountDetails: React.FC<ContainerProps> = ({ calendar, onClose, presentingElement }) => {
  const { service, account, photo } = calendar
  // const [showTip, setShowTip] = useState(TipTypes.noTip)
  const [showScreenTip, setShowScreenTip] = useState(CalendarAccountDetailsTips.noTip)
  const [minTip, setMinTip] = useState(false)
  const [subCalendars, setSubCalendars] = useState<SubCalendar[]>()
  const [showCalendarActions, setCalendarActions] = useState<SubCalendar>()
  const [showRemoveActions, setShowRemoveActions] = useState(false)

  const [loading, setLoading] = useState(false)
  const [removing, setRemoving] = useState(false)

  const { getCalendarDetails, updateCalendar, removeCalendar } = useCalendars()
  const { storeTips } = useTips()

  async function getCalendars(): Promise<void> {
    const calendars = await getCalendarDetails(calendar)

    setSubCalendars(calendars)
    setLoading(false)
    startTips()
  }

  function startTips(): void {
    if (storeTips?.startMinTip) {
      setNextTip(CalendarAccountDetailsTips.noTip)
    } else {
      setNextTip(CalendarAccountDetailsTips.sequence)
    }
  }

  useEffect(() => {
    if (service === CalendarService.Google) {
      setLoading(true)
      getCalendars()
    }
  }, [])

  function setNextTip(tip: CalendarAccountDetailsTips): void {
    // console.log('Meeting screen setNextTip: ', tip)

    switch (tip) {
      // eslint-disable-next-line no-fallthrough
      case CalendarAccountDetailsTips.sequence:
      case CalendarAccountDetailsTips.selectCalendar:
        setShowScreenTip(CalendarAccountDetailsTips.selectCalendar)
        break
      // eslint-disable-next-line no-fallthrough
      case CalendarAccountDetailsTips.noTip:
      default:
        // pass it back to meetings screen
        setShowScreenTip(CalendarAccountDetailsTips.noTip)
        setMinTip(false)
        break
    }
  }

  function updateUserCalendar(enabled: boolean, freeBusy: boolean): void {
    console.log('update calendar: ', showCalendarActions)

    if (showCalendarActions && subCalendars) {
      const input: UpdateCalendarInput = {
        id: calendar.id,
        calendarId: showCalendarActions.id,
        enabled,
        freeBusy,
      }

      updateCalendar(input)

      setSubCalendars(subCalendars.map(c => {
        if (c.id === showCalendarActions.id) {
          return {
            ...c,
            enabled,
            freeBusy,
          }
        }

        return c
      }))
    }
  }

  function renderSubCalendar(subCalendar: SubCalendar, last: boolean): JSX.Element {
    const { primary, name, backgroundColor, enabled, freeBusy } = subCalendar

    function note(): string {
      if (enabled && freeBusy) {
        return 'free-busy'
      } else if (enabled && !freeBusy) {
        return 'view-only'
      } else {
        return 'ignore'
      }
    }

    function icons(): JSX.Element {
      if (enabled && freeBusy) {
        return (
          <IonIcon
            slot='end'
            color='primary'
            style={{ fontSize: 22 }}
            icon={checkmarkCircle} />
        )
      } else if (enabled && !freeBusy) {
        return (
          <IonIcon
            slot='end'
            color='primary'
            style={{ fontSize: 22 }}
            icon={eye} />
        )
      } else {
        return (
          <IonIcon
            slot='end'
            color='medium'
            style={{ fontSize: 22 }}
            icon={eyeOff} />
        )
      }
    }

    return (
      <IonItem
        key={subCalendar.id}
        lines={last ? 'none' : 'inset'}
        onClick={() => {
          setCalendarActions(subCalendar)
        }}>
        <IonIcon
          slot='start'
          size='small'
          style={{ color: backgroundColor }}
          icon={ellipse} />
        <IonLabel>
          {primary ? 'Primary' : name}
        </IonLabel>
        <IonNote
          slot='end'>
          {note()}
        </IonNote>
        {icons()}
      </IonItem>
    )
  }

  function renderSubCalendars(): JSX.Element[] | undefined {
    if (subCalendars && subCalendars.length) {
      const items = subCalendars.filter(c => c.enabled || (!c.hidden && c.selected))

      return items.map((subCalendar, i) => renderSubCalendar(subCalendar, i === items.length - 1))
    }
  }

  // function renderSubCalendars (): JSX.Element[] | undefined {
  //   if (subCalendars && subCalendars.length) {
  //     const items = subCalendars.filter(c => c.enabled || (!c.hidden && c.selected))

  //     return items.map((subCalendar, i) => {
  //       const { primary, name, backgroundColor, enabled, freeBusy } = subCalendar

  //       return (
  //         <IonItem
  //           key={i}
  //           lines={i === items.length - 1 ? 'none' : 'inset'}
  //           onClick={() => {
  //             if (!componentStoreTips?.selectCalendar) {
  //               updateTip({ selectCalendar: true })
  //             }

  //             setCalendarActions(subCalendar)
  //           }}>
  //           {(i === 0 && !minTip && showScreenTip === CalendarAccountDetailsTips.selectCalendar) &&
  //             <TipTarget
  //               style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
  //           <IonIcon
  //             slot='start'
  //             size='small'
  //             style={{ color: backgroundColor }}
  //             icon={ellipse} />
  //           <IonLabel>
  //             {primary ? 'Primary' : name}
  //           </IonLabel>
  //           {enabled && freeBusy &&
  //             <IonText
  //               slot='end'
  //               color='medium'>
  //               free-busy
  //             </IonText>}
  //           {enabled && !freeBusy &&
  //             <IonText
  //               slot='end'
  //               color='medium'>
  //               view-only
  //             </IonText>}
  //           {!enabled && !freeBusy &&
  //             <IonText
  //               slot='end'
  //               color='medium'>
  //               disabled
  //             </IonText>}
  //           {enabled &&
  //             <IonIcon
  //               slot='end'
  //               color='secondary'
  //               style={{ fontSize: 22 }}
  //               icon={checkmark} />}

  //         </IonItem>
  //       )
  //     })
  //   }
  // }

  function renderScreenTips(): JSX.Element | undefined {
    return (
      <ScreenTips
        minTip={minTip}
        showScreenTip={showScreenTip}
        onClose={() => {
          setMinTip(true)
        }}
        onMinTip={() => {
          setMinTip(false)
          setNextTip(showScreenTip + 1)
        }}
        onButton={() => {
          setMinTip(true)
          setNextTip(showScreenTip + 1)
        }} />
    )
  }

  function renderFooter(): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          onClick={onClose}>
          Done
        </FooterButton>
      </IonFooter>
    )
  }

  console.log('CalendarAccountDetails: subCalendars: ', subCalendars)

  return (
    <IonModal
      isOpen
      swipeToClose
      presentingElement={presentingElement}
      onDidDismiss={onClose}>
      <ModalHeader
        title={title} />
      <IonContent>
        <div
          style={container}
          className='titleIconBox'>
          <div
            style={iconBox}
            className='titleIconBox'>
            <Avatar
              src={photo}
              style={avatarStyle} />
            <IonLabel style={{ textAlign: 'center', marginTop: 10 }}>
              <h2>{account}</h2>
              <p>{service}</p>
            </IonLabel>
          </div>

          {loading &&
            <IonItem
              lines='full'
              style={{ textAlign: 'center' }}>
              <IonLabel color='secondary'>
                <IonSpinner
                  name='dots' />
              </IonLabel>

            </IonItem>}

          {renderSubCalendars()}

          {!loading &&
            <div style={{ marginTop: 20, padding: 20 }}>
              <IonButton
                shape='round'
                expand='block'
                fill='clear'
                color='danger'
                onClick={() => {
                  setShowRemoveActions(true)
                }}>
                {!removing &&
                  'Disconnect Calendar'}
                {removing &&
                  <IonSpinner
                    name='dots'
                    style={{ color: 'red' }} />}
              </IonButton>
            </div>}
        </div>
      </IonContent>
      {renderFooter()}
      {renderScreenTips()}

      <IonActionSheet
        isOpen={showRemoveActions}
        onDidDismiss={() => setShowRemoveActions(false)}
        buttons={[{
          text: 'Disconnect Calendar',
          role: 'destructive',
          handler: async () => {
            setRemoving(true)
            await removeCalendar(calendar)
            setRemoving(false)
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          },
        }]} />
      <IonActionSheet
        isOpen={!!showCalendarActions}
        onDidDismiss={() => setCalendarActions(undefined)}
        buttons={[
          {
            text: 'Use for free-busy',
            handler: () => updateUserCalendar(true, true),
          },
          {
            text: 'View only',
            handler: () => updateUserCalendar(true, false),
          },
          {
            text: 'Ignore',
            role: 'destructive',
            handler: () => updateUserCalendar(false, false),
          }, {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked')
            },
          }]} />
    </IonModal>
  )
}

export default CalendarAccountDetails
