import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'services/store'
import CalendarSettingsBookCalendar from './CalendarSettingsBookCalendar'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import PreferencesProvider from 'context/PreferencesContext/PreferencesContext'
import paths from 'navigation/paths'

const CalendarSettingsBookCalendarScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { calendarSettings } = useSelector((state: RootState) => state.screens)

  function onDone (): void {
    history.push(paths.calendarSettingsAutoTimes)
  }

  return (
    <PreferencesProvider>
      <CalendarsProvider>
        <CalendarSettingsBookCalendar
          calendarId={calendarSettings?.calendar}
          onDone={onDone} />
      </CalendarsProvider>
    </PreferencesProvider>
  )
}

export default CalendarSettingsBookCalendarScreen
