import { UPDATE_SCREEN, Screens, ScreenActions } from './types'
import { APP_RESET } from '../types'

export default function screensReducer (
  state = {},
  action: ScreenActions,
): Screens {
  switch (action.type) {
    case UPDATE_SCREEN:
      return { ...state, ...action.screens }
    case APP_RESET:
      return {}
    default:
      return state
  }
}
