import React from 'react'

import 'screens/Settings.css'
import TokensProvider from 'context/TokensContext/TokensContext'
import LoginModal from './LoginModal'

interface ContainerProps {
  onNewUser: () => void;
  onLogin: (verifyToken: string) => void;
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const LoginModalScreen: React.FC<ContainerProps> = ({ onNewUser, onLogin, onClose, presentingElement }) => {
  return (
    <TokensProvider>
      <LoginModal
        onNewUser={onNewUser}
        onLogin={onLogin}
        onClose={onClose}
        presentingElement={presentingElement} />
    </TokensProvider>
  )
}

export default LoginModalScreen
