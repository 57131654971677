import React from 'react'
import { IonItem, IonLabel, IonItemGroup, IonText } from '@ionic/react'
import { Avatar } from '@material-ui/core'
import { CalendarAccount } from 'types'

const avatarStyle = {
  width: 36,
  height: 36,
  backgroundColor: '#92949C',
  marginRight: 15,
}

interface ContainerProps {
  calendar: CalendarAccount;
  onSelect: (calendar: string) => void;
}

const CalendarListItem: React.FC<ContainerProps> = ({ calendar, onSelect }) => {
  const { service, account, photo, calendars } = calendar

  console.log('CalendarListItem: photo ', photo)

  const activeCalendars = calendars.filter(c => c.enabled || c.freeBusy)

  return (
    <IonItemGroup>
      <IonItem
        detail
        lines='full'
        onClick={() => onSelect(calendar.id)}>
        <Avatar
          slot='start'
          src={photo}
          style={avatarStyle} />
        <IonLabel>
          <h2>{account}</h2>
          <p>{service}</p>
        </IonLabel>
        <IonText
          slot='end'
          color='medium'>
          {activeCalendars?.length}
        </IonText>
      </IonItem>
    </IonItemGroup>
  )
}

export default CalendarListItem
