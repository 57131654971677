import React from 'react'
import { IonItem, IonLabel, IonIcon } from '@ionic/react'
import { chatboxEllipsesOutline } from 'ionicons/icons'

export interface MessageLinkButtonProps {
  link: string;
  onClick?: () => void;
}

const MessageLinkButton: React.FC<MessageLinkButtonProps> = ({ link, onClick }) => {
  return (
    <IonItem
      data-ci-id='message-link-button'
      href={link}
      onClick={onClick}
      lines='full'
      detail={false}>
      <IonLabel>Message</IonLabel>
      <IonIcon
        icon={chatboxEllipsesOutline}
        slot='end' />
    </IonItem>
  )
}

export default MessageLinkButton
