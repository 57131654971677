import React, { CSSProperties } from 'react'
import { TimeSlot as TimeSlotType, CalendarEvent, Participant, InviteeStatus } from 'types'
import moment from 'moment'

import DayColumn from '../DayColumn/DayColumn'

import { useUser } from 'context/UserContext/UserContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useCalendarView } from '../ScheduleCalendar/CalendarViewContext'
import { useInvitees } from 'context/InviteesContext/InviteesContext'

// time grid height in minutes
const scale = 60
const calendarBoxId = 'participantDaySchedules'

type PopoverInfo = {
  time: string;
  participants?: Participant[];
  startTime?: string;
  endTime?: string;
  calendarEvents?: CalendarEvent[];
}
interface ComponentProps {
  id?: string;
  selectedTime: string;
  tip?: boolean;
  onScroll: (top: number, left: number, id: string) => void;
}

const disableScroll = false

const ParticipantDaySchedules: React.FC<ComponentProps> =
  ({ id = 'participantDaySchedules', tip, selectedTime, onScroll }) => {
    const { user } = useUser()
    const { meeting } = useMeetings()
    const { timeSlots } = useTimeSlots()
    const { participants } = useParticipants()
    const { meetingInvitees } = useInvitees()

    const { calendarTopPadding } = useCalendarView()

    // selected time is in ISO string
    const startOfDay = moment().startOf('day').toISOString()
    const selectedDay = moment(selectedTime).startOf('day').diff(moment(startOfDay), 'days')

    function doScroll(): void {
      const el = document.getElementById(id)

      if (el) {
        onScroll(el.scrollTop, el.scrollLeft, id)
      }
    }

    // let totalParticipants = 0

    // if (participants?.length) {
    //   totalParticipants = participants.length
    // } else if (meeting && meeting.participants) {
    //   totalParticipants = meeting.participants.length
    // }

    function getSlots(): TimeSlotType[] {
      // console.log('TIME SLOTS: ', timeSlots)

      if (timeSlots?.length) {
        return timeSlots
      }

      return []
    }

    function getDaySlots(): TimeSlotType[] {
      // 12AM start time - 1 minute so that 12AM selection fits in the isAfter range below.
      const dayStart = moment(selectedTime).startOf('day').valueOf()
      const dayEnd = moment(dayStart).add(24, 'hours').valueOf()

      // first find local changes
      let slots = getSlots()

      // issue #411 show timeSlots only if the user has selected some times
      // if (user?.id && slots.find(t => t.userId=== user?.id)) {
      if (user?.id) {
        slots = slots.filter((t: TimeSlotType) => {
          const start = moment(t.startTime).valueOf()
          const end = moment(t.endTime).valueOf()

          // Note: a timeSlot can span across midnight
          // so include timeSlot if either start or end are within the day range
          if ((start >= dayStart && start < dayEnd) ||
            (end > dayStart && end <= dayEnd)) {
            return true
          }
        })

        return slots
      }

      // if (slots.length) {
      //   console.log('DAY SLOTS: for day:', moment(selectedTime).startOf('day').toISOString(), slots)
      // }

      return []
    }

    const daySlots = getDaySlots()

    function renderDayColumn(participant: Participant, key: number): JSX.Element | undefined {
      // console.log('userId: ', user)

      if (meeting?.id && participants && participant?.userId && user?.id && participant.userId !== user.id) {
        // console.log('renderDayColumn: for participant: ', participant)

        const slots = daySlots.filter(t => t.userId === participant.userId)

        return (
          <DayColumn
            key={key}
            parentId={calendarBoxId}
            userId={user?.id || ''}
            startDay={selectedDay}
            day={0}
            scale={scale}
            meeting={meeting}
            participants={participants}
            meetingTimeMarkers
            timeSlots={slots}
            calendarEvents={[]} />
        )
      }
    }

    function renderExpectingMore(): JSX.Element | undefined {
      if (participants?.length && meeting?.participantsExpected && meetingInvitees) {
        if (meeting?.participantsExpected > participants?.length) {
          const invitees = meetingInvitees?.filter(invitee => {
            if (invitee.status === InviteeStatus.accepted ||
              invitee.status === InviteeStatus.declined) {
              return false
            }

            if (participants && participants.find(p => p.userId === invitee.userId)) {
              return false
            }

            return true
          })

          let count = meeting.participantsExpected - participants.length

          if (invitees.length && invitees.length <= count) {
            count = count - invitees.length
          }

          if (count) {
            return (
              <DayColumn
                parentId={calendarBoxId}
                userId=''
                startDay={selectedDay}
                day={0}
                scale={scale}
                meeting={meeting}
                participants={participants}
                meetingTimeMarkers
                timeSlots={[]}
                calendarEvents={[]} />
            )
          }
        }
      }
    }

    function renderParticipants(): (JSX.Element | undefined)[] | undefined {
      // console.log('Participants: ', participants)

      if (participants && participants?.length > 1) {
        return participants.map((participant, i) => {
          return renderDayColumn(participant, i)
        })
      }
    }

    function renderInvitees(): (JSX.Element | undefined)[] | undefined {
      // console.log('Participants: ', participants)

      const invitees = meetingInvitees?.filter(invitee => {
        // when an invitee is marked accepted  go ahead and render it as an invitee
        // until it's added to participant list as there might be a delay between
        // being marked as accepted and added to participants list
        // so to hide that delay show accepted invitee. Once it's added to participant
        // list we fill filter it out below.
        if (invitee.status === InviteeStatus.declined) {
          return false
        }

        if (participants && participants.find(p => p.userId === invitee.userId)) {
          return false
        }

        return true
      })

      // render placeholder column for each invitee
      if (meeting && participants && invitees?.length) {
        return invitees.map((invitee, i) => {
          return (
            <DayColumn
              key={i}
              parentId={calendarBoxId}
              userId=''
              startDay={selectedDay}
              day={0}
              scale={scale}
              meeting={meeting}
              participants={participants}
              meetingTimeMarkers
              timeSlots={[]}
              calendarEvents={[]} />
          )
        })
      }
    }

    const container: CSSProperties = {
      flex: 1,
      display: 'flex',
      overflowX: 'scroll',
      overflowY: 'scroll',
      flexWrap: 'nowrap',
      paddingTop: calendarTopPadding,
    }

    if (disableScroll) {
      container.overflowX = 'hidden'
      container.overflowY = 'hidden'
    }

    if (tip) {
      container.borderLeft = '2px solid #3880ff'
      container.borderTop = '2px solid #3880ff'
      container.borderBottom = '2px solid #3880ff'
    }

    return (
      <div
        id={id || 'participantDaySchedules'}
        style={container}
        onScroll={doScroll}>
        {renderParticipants()}
        {renderInvitees()}
        {renderExpectingMore()}
      </div>
    )
  }

export default ParticipantDaySchedules
