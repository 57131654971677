import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from 'services/store'
import { updateScreens } from 'services/store/screens/actions'
import UserProvider from 'context/UserContext/UserContext'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import NewUserName from './NewUserName'
import paths from 'navigation/paths'

const NewUserNameScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { newUserName: params } = useSelector((state: RootState) => state.screens)

  const dispatch = useDispatch()

  // useEffect(() => {
  //   if (location.search) {
  //     console.log('NewUserName: url: ', location.search)

  //     const params = queryString.parse(location.search)

  //     const invite = params.invite as string

  //     if (invite) {
  //       setInvitation(invite)
  //     }

  //     const meet = params.meet as string

  //     if (meet) {
  //       setMeetUser(meet)
  //     }
  //   }
  // }, [location.search])

  function onNewMeeting(): void {
    if (params?.meetUser) {
      // this is important to reset history so that
      // for a new meeting when user taps back button from
      // downstream screens we don't come back to meeting setting
      // DO NOT REMOVE THIS LINE
      history.replace('/tabs/meetings')

      dispatch(updateScreens({
        allScreens: {
          newUser: true,
        },
        meetingSettings: {
          steps: true,
          tips: true,
          addParticipants: [params.meetUser],
          meetMeInitiator: true,
          newUser: true,
        },
      }))
      history.replace(paths.meetingSettings)
    } else {
      dispatch(updateScreens({
        allScreens: {
          newUser: true,
        },
      }))
      history.replace(paths.meetingsList)
    }
  }

  function onJoinMeeting(meetingId: string): void {
    dispatch(updateScreens({
      allScreens: {
        newUser: true,
        newInvitee: true,
      },
      chooseAvailability: {
        meetingId,
        newUser: true,
      },
      tipsAvailabilityCalendar: {
        nextScreen: paths.chooseAvailability,
        newUser: true,
      },
    }))
    history.replace(paths.tipsAvailabilityCalendar)
  }

  return (
    <UserProvider>
      <MeetingsProvider>
        <InviteesProvider>
          <NewUserName
            invitation={params?.invitation}
            goBack={() => history.goBack()}
            onNewMeeting={onNewMeeting}
            onJoinMeeting={onJoinMeeting} />
        </InviteesProvider>
      </MeetingsProvider>
    </UserProvider>
  )
}
export default NewUserNameScreen
