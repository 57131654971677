import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import lottieJson from './fireworks.json'

export interface FireworksProps {
  delay?: number;
  loop?: number;
  onComplete?: () => void;
}

const Fireworks: React.FC<FireworksProps> = ({ delay, loop, onComplete }) => {
  const [play, setPlay] = useState(!delay)

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setPlay(true)
      }, delay)
    }
  }, [])

  return (
    <Lottie
      data-ci-id='fireworks'
      loop={loop || 1}
      play={play}
      animationData={lottieJson}
      onComplete={(() => {
        console.log('FIREWORKS COMPLETED')
        onComplete && onComplete()
      })}
      style={{ width: '100%', height: '100%' }} />
  )
}
export default Fireworks
