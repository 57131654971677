
import React, { CSSProperties } from 'react'
import { TimeRange as TimeRangeType } from 'types'

import moment from 'moment'
import { useCalendarView } from 'components/organisms/ScheduleCalendar/CalendarViewContext'
import { IonLabel } from '@ionic/react'

export interface TimeMarkerProps {
  dayStartTime: string;
  scale: number;
  timeRange: TimeRangeType;
}

const label: CSSProperties = {
  fontSize: 10,
  fontWeight: 'bold',
  userSelect: 'none',
  WebkitUserSelect: 'none',
}

const TimeMarker: React.FC<TimeMarkerProps> =
({
  dayStartTime, scale = 60, timeRange,
}) => {
  const { timeSlotHeight = 50 } = useCalendarView()
  const top =
    moment(timeRange.startTime)
      .diff(moment(dayStartTime), 'minutes') * (timeSlotHeight / scale)
  const { startTime, endTime } = timeRange
  const minutes = moment(endTime).diff(moment(startTime), 'minutes')
  const height = minutes * (timeSlotHeight / scale)

  const container: CSSProperties = {
    position: 'absolute',
    top,
    height,
    width: '100%',
    display: 'flex',
    border: '1px solid red',
  }

  return (
    <div
      style={container}>
      <TimeLabel />
      <Marker />
    </div>
  )
}

const TimeLabel: React.FC = () => {
  const container: CSSProperties = {
    height: 10,
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // zIndex: 1,
  }

  container.top = -16

  return (
    <div
      style={container}>
      <IonLabel
        color='medium'
        style={label}>
        NOW
      </IonLabel>
    </div>
  )
}

const Marker: React.FC = () => {
  const container: CSSProperties = {
    position: 'absolute',
    bottom: -5,
    left: -1,
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: 'red',
    zIndex: 2,
  }

  return (
    <div
      style={container} />
  )
}

export default TimeMarker
