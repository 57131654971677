import React, { CSSProperties } from 'react'
import moment from 'moment-timezone'
import { TimeRange as TimeRangeType } from 'types'
import ListItem from 'components/atoms/ListItem/ListItem'

const container: CSSProperties = {
  marginTop: 10,
}

export interface MeetingTimesProps {
  title?: string;
  timeRanges: TimeRangeType[];
  tip?: boolean;
  timeZone? : string;
  disabled?: boolean;
  onClick?: () => void;
}

const MeetingTimes: React.FC<MeetingTimesProps> = ({ title, timeRanges, timeZone, disabled, tip, onClick }) => {
  let label = ''

  if (timeRanges.length) {
    timeRanges.forEach((range, i) => {
      const start = moment(range.startTime).format('h:mm a')
      const end = moment(range.endTime).format('h:mm a')

      label += `${start} - ${end}`

      if (i < timeRanges.length - 1) {
        label += ', '
      }
    })
  } else {
    label = 'Anytime'
  }

  if (disabled) {
    label = 'Disabled'
  }

  const timeZoneLabel = timeZone ? `(${moment.tz(timeZone).format('z')})` : ''

  const itemStyle: CSSProperties = { marginTop: 5 }

  if (tip) {
    itemStyle.overflow = 'visible'
    itemStyle.border = '1px solid #3880ff'
  }

  return (
    <div style={container}>
      <ListItem
        testId='day-times'
        title={title}
        tip={tip}
        detail={!!onClick}
        textWrap
        label={`${label} ${timeZoneLabel}`}
        onClick={onClick} />
    </div>
  )
}

export default MeetingTimes
