import React from 'react'
import { IonButton } from '@ionic/react'

export interface FooterButtonProps {
  testId?: string
  color?: string;
  disabled?: boolean;
  fill?: 'solid' | 'clear' | 'outline';
  onClick?: () => void;
}

const FooterButton: React.FC<FooterButtonProps> = ({ testId, color, fill, disabled, onClick, children }) => {
  return (
    <IonButton
      {...(testId && { 'data-ci-id': testId })}
      mode='ios'
      disabled={disabled}
      shape='round'
      color={color || 'primary'}
      fill={fill || 'solid'}
      expand='block'
      onClick={onClick}>
      {children}
    </IonButton>
  )
}

export default FooterButton
