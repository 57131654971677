import React, { CSSProperties, useEffect, useState } from 'react'
import moment from 'moment'
import { v1 as uuidv1 } from 'uuid'
import { TimeSlot as TimeSlotType, TimeRange as TimeRangeType, CalendarEvent as CalendarEventType, Meeting, Participant } from 'types'

import { getTimeRanges, getUserTimeSlot, findUserTimeRange, withinTimeRanges, getUserMeetingTime, withinDayRanges, adjustTimeRangesForDay } from '../ScheduleCalendar/utils'

import TimeRange from 'components/molecules/TimeRange/TimeRange'
import CalendarEvents from '../../atoms/CalendarEvent/CalendarEvent'
import DayColumnGestures from '../../atoms/DayColumnGestures/DayColumnGestures'
import TimeMarker from '../../atoms/TimeMarker/TimeMarker'
import GridBox from '../../atoms/CalendarGridBox/GridBox'
import { stringToMinutes } from 'services/time'
import { IonAlert, IonToast } from '@ionic/react'
import TipTarget from 'components/atoms/TipTarget/TipTarget'
import { useCalendarView } from '../ScheduleCalendar/CalendarViewContext'
import MeetingTimeMarkerLines from 'components/atoms/MeetingTimeMarkerLines/MeetingTimeMarkerLine'
import { MeetingBookCalendar } from 'context/CalendarsContext/CalendarsContext'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

interface ComponentProps {
  parentId: string;
  userId: string;
  startDay: number;
  day: number;
  scale: number;
  meeting: Meeting;
  participants: Participant[];
  timeSlots: TimeSlotType[];
  calendarEvents: CalendarEventType[];
  editCalendar?: boolean;
  autoBook?: boolean;
  showActivity?: TimeSlotType;
  showOpen?: boolean;
  editSlot?: TimeSlotType;
  tip?: number;
  tipColumn?: boolean;
  currentTimeMarker?: boolean;
  showMeetingTimes?: boolean;
  meetingTimeMarkers?: boolean;
  shadow?: boolean;
  bestRange?: number;
  bookCalendar?: MeetingBookCalendar;
  setEditSlot?: (timeSlot?: TimeSlotType) => void;
  onEdit?: () => void;
  onEditEnd?: () => void;
  onDragStart?: (fromTime: string) => void;
  onDragEnd?: () => void;
  onSelectTime?: (time: string) => void;
  onCreateTimeSlot?: (startTime: string, endTime: string) => void;
  onUpdateTimeSlot?: (timeSlot: TimeSlotType) => void;
  onDeleteTimeSlot?: (timeSlot: TimeSlotType, noDisableEdit?: boolean) => void;
  onBusyTimeSlot?: (timeSlot: TimeSlotType) => void;
  onInfo?: (time: string, timeRange?: TimeRangeType) => void;
}

let timeSlotEdited = 0
let dragActiveFrom = ''
let disableDrag = false

const DayColumn: React.FC<ComponentProps> =
  ({
    parentId, userId, startDay, day, scale, meeting, participants, timeSlots, shadow, editCalendar, bookCalendar,
    calendarEvents, showActivity, editSlot, showOpen, tip, tipColumn, currentTimeMarker, showMeetingTimes, meetingTimeMarkers,
    setEditSlot, onEdit, onCreateTimeSlot, onUpdateTimeSlot, onDeleteTimeSlot, onEditEnd, onInfo,
    onBusyTimeSlot, onSelectTime, onDragStart, onDragEnd,
  }) => {
    const [showAlert, setShowAlert] = useState('')
    const [showToast, setShowToast] = useState('')

    const { timeSlotWidth = 90, timeSlotHeight = 50 } = useCalendarView()
    const { logEvent } = useAnalytics()

    let totalParticipants = 0
    let timeSlotRanges: TimeRangeType[]
    let calendarRanges: TimeRangeType[]

    if (participants) {
      totalParticipants = participants.length
    } else if (meeting && meeting.participantUserIds) {
      totalParticipants = meeting.participantUserIds.length
    }

    const dayStartTime = moment()
      .add((startDay + day), 'days')
      .startOf('day')
      .toISOString()

    const dayEndTime = moment(dayStartTime).add(24, 'hours').toISOString()

    const duration = Number(stringToMinutes(meeting.duration))

    useEffect(() => {
      if (!editCalendar) {
        if (editSlot) {
          dragEnd()
        }
      }
    }, [editCalendar])

    function logTap(eventData: EventData): void {
      logEvent({
        eventName: EventName.buttonTap,
        eventData: { ...eventData },
      })
    }

    function updateTimeSlot(timeSlot: TimeSlotType): void {
      // console.log('SET EDIT TIME SLOT: ', timeSlot)
      setEditSlot && setEditSlot(timeSlot)
    }

    function deleteTimeSlot(timeSlot: TimeSlotType): void {
      onDeleteTimeSlot && onDeleteTimeSlot(timeSlot)
    }

    function newSlot(time: string): void {
      // this should be in multiples of 15min
      const minutes = moment(time).minutes()

      // get closest startingMinutes in multiples of duration
      const startMinutes = Math.floor(minutes / duration) * duration

      const startTime = moment(time)
        .startOf('hour')
        .add(startMinutes, 'minutes').toISOString()

      let endTime = moment(startTime).add(duration, 'minutes').toISOString()

      if (meeting.timeRanges?.length) {
        let withinRange = withinTimeRanges(endTime, meeting.timeRanges)

        if (!withinRange) {
          withinRange = withinTimeRanges(startTime, meeting.timeRanges)

          if (withinRange) {
            endTime = withinRange.endTime
          } else {
            // both start and end times are outside range
            return
          }
        }
      }
      // a timeSlot can span across midnight so
      // don't restrict it to end of day
      // if (moment(endTime).isAfter(dayEndTime)) {
      //   endTime = dayEndTime
      // }

      let update

      const prevSlot = getUserTimeSlot(startTime, userId, timeSlots, editSlot)

      // issue #384 merge with adjacent slot only if they are also marked as available
      if (prevSlot && !prevSlot.autoCreated) {
        // if we are overlapping or contiguous with the prev slot then
        // update the prev slot with the end time of this new time slot
        update = {
          ...prevSlot,
        }

        update.endTime = endTime
        // startTime = prevSlot.endTime
      }

      const nextSlot = getUserTimeSlot(endTime, userId, timeSlots, editSlot)
      let deleteSlot: TimeSlotType | undefined

      // issue #384 merge with adjacent slot only if they are also marked as available
      if (nextSlot && !nextSlot.autoCreated) {
        if (update) {
          update.endTime = nextSlot.endTime
          // merge with the next slot and delete next slot
          deleteSlot = nextSlot
        } else {
          update = {
            ...nextSlot,
          }

          update.startTime = startTime
        }
      }

      console.log('MERGED UPDATED SLOT: ', update)

      if (update) {
        // console.log('newSlot: UPDATING SLOT', update)
        updateTimeSlot(update)
        onUpdateTimeSlot && onUpdateTimeSlot(update)
      } else {
        // console.log(`startTime: ${startTime} endTime: ${endTime}`)
        onCreateTimeSlot && onCreateTimeSlot(startTime, endTime)
      }

      if (deleteSlot) {
        deleteTimeSlot(deleteSlot)
      }
    }

    function deleteEditSlot(): void {
      // console.log('DELETE EDIT SLOT')

      if (editSlot) {
        deleteTimeSlot(editSlot)
        setEditSlot && setEditSlot(undefined)
      }
    }

    function busyEditSlot(): void {
      // console.log('DELETE EDIT SLOT')

      if (editSlot) {
        onBusyTimeSlot && onBusyTimeSlot(editSlot)
        setEditSlot && setEditSlot(undefined)
      }
    }

    function onSingleTapConfirmAuto(time: string): void {
      // if (autoBook) {
      //   setShowAlert(time)
      // } else {
      //   onSingleTap(time)
      // }
      onSingleTap(time)
    }

    function showOutOfRangeToast(): void {
      if (meeting.timeRanges?.length) {
        const { timeRanges } = meeting
        let label = ''

        timeRanges.forEach((range, i) => {
          const start = moment(range.startTime).format('h:mm a')
          const end = moment(range.endTime).format('h:mm a')

          label += `${start} - ${end}`

          if (i < timeRanges.length - 1) {
            label += ', '
          }
        })

        const toast = 'Outside meeting time restrictions:\n' +
          `${label}`

        setShowToast(toast)
      }
    }

    function onSingleTap(time: string): void {
      console.log('onSingleTap time:', time)

      const timeValue = moment(time).valueOf()

      if (meeting.timeRanges?.length) {
        if (!withinTimeRanges(time, meeting.timeRanges)) {
          showOutOfRangeToast()

          return
        }
      }

      if (onSelectTime && !editCalendar) {
        // are there time slots by other people at this time?
        const others = timeSlots.find(slot => {
          const start = moment(slot.startTime).valueOf()
          const end = moment(slot.endTime).valueOf()

          if (slot.userId !== userId && timeValue >= start && timeValue <= end) {
            return true
          }
        })

        const userTime = timeSlots.find(slot => {
          const start = moment(slot.startTime).valueOf()
          const end = moment(slot.endTime).valueOf()

          if (slot.userId === userId && timeValue >= start && timeValue <= end) {
            return true
          }
        })

        // if time slots by other people then open up dayDetail view
        if (others || userTime) {
          // get all user's time ranges
          const timeRange = findUserTimeRange(time, timeSlots, editSlot)

          // console.log('Found time range ', timeRange)
          onInfo && onInfo(time, timeRange)
          // onSelectTime(time)

          return
        }

        if (calendarEvents?.length) {
          const event = calendarEvents.find(event => {
            const eventStart = moment(event.startTime).valueOf()
            const eventEnd = moment(event.endTime).valueOf()

            if (timeValue >= eventStart && timeValue <= eventEnd) {
              return true
            }
          })

          // if time slots by other people then open up dayDetail view
          if (event) {
            // get all user's time ranges
            const timeRange = findUserTimeRange(time, timeSlots, editSlot)

            // console.log('Found time range ', timeRange)
            onInfo && onInfo(time, timeRange)
            // onSelectTime(time)

            return
          }
        }
        // else flow down to edit in the calendar view
      }

      if (editCalendar) {
        if (editSlot) {
          // check if user single tapped inside the editing slot
          const selectedTime = moment(time).valueOf()
          const startTime = moment(editSlot.startTime).valueOf()
          const endTime = moment(editSlot.endTime).valueOf()

          // if selected time is within range of editSlot don't do anything
          if (selectedTime >= startTime && selectedTime <= endTime) {
            return
          }

          setEditSlot && setEditSlot(undefined)
        }

        const timeSlot = getUserTimeSlot(time, userId, timeSlots)

        // that function will return a timeSlot if the time is exactly on the boundary
        // so make sure the selected time is actually inside the range and only then delete it
        // do'nt merge with busy slots
        if (timeSlot && !timeSlot.autoCreated &&
          moment(time).valueOf() >= moment(timeSlot.startTime).valueOf() &&
          moment(time).valueOf() < moment(timeSlot.endTime).valueOf()) {
          // disableDrag = !timeSlot.available
          setEditSlot && setEditSlot(timeSlot)
        } else {
          const meetingTime = getUserMeetingTime({ startTime: time, userId, meetingTimes: meeting.meetingTimes })

          if (meetingTime) {
            disableDrag = true
            setEditSlot && setEditSlot({
              meetingId: meeting.id,
              startTime: meetingTime.startTime,
              endTime: meetingTime.endTime,
            })
          } else {
            newSlot(time)
          }
        }
      }
    }

    function onLongTap(time: string): void {
      console.log('LONG TAP at: ', time)

      // get all user's time ranges
      const timeRange = findUserTimeRange(time, timeSlots, editSlot)

      // console.log('Found time range ', timeRange)
      onInfo && onInfo(time, timeRange)
    }

    function getOverlapSlot(timeSlot: TimeSlotType, slots: TimeSlotType[]):
      TimeSlotType | undefined {
      const startA = moment(timeSlot.startTime).valueOf()
      const endA = moment(timeSlot.endTime).valueOf()

      return slots.find(slot => {
        const startB = moment(slot.startTime).valueOf()
        const endB = moment(slot.endTime).valueOf()

        if (startA <= endB && startB <= endA) {
          return true
        }
      })
    }

    function mergeAdjacentSlots(updateSlot: TimeSlotType): void {
      console.log('UPDATE TIME SLOT: ', updateSlot)

      if (updateSlot) {
        let slots = timeSlots.slice()

        // get user slots
        if (userId) {
          slots = slots.filter(slot => slot.userId === userId && slot.available)
        }

        // don't include the edit slot
        if (updateSlot.id) {
          slots = slots.filter(slot => slot.id !== updateSlot.id)
        } else {
          slots = slots.filter(slot => slot.localId !== updateSlot.localId)
        }

        const update = {
          ...updateSlot,
        }

        // merge with overlapping slots
        let slot = getOverlapSlot(update, slots)
        const deletedSlots: TimeSlotType[] = []

        while (slot && !slot.autoCreated) {
          disableDrag = true

          if (update.available === slot.available) {
            // merge with the prev slot
            if (slot.startTime < update.startTime) {
              update.startTime = slot.startTime
            }

            if (slot.endTime > update.endTime) {
              update.endTime = slot.endTime
            }

            // deleteTimeSlot(slot)
            deletedSlots.push(slot)

            // remove this slot from the array
            if (slot.localId) {
              slots = slots.filter(s => s.localId !== slot?.localId)
            } else {
              slots = slots.filter(s => s.id !== slot?.id)
            }

            slot = getOverlapSlot(update, slots)
          }
        }

        updateTimeSlot(update)
        setTimeout(() => {
          deletedSlots.forEach(s => deleteTimeSlot(s))
        }, 300)
      }
    }

    function onDrag(fromTime: string, toTime: string): void {
      // console.log(`onDrag: fromTime ${fromTime} toTime ${toTime}`)
      // console.log('editSlot: ', editSlot)
      // console.log('dragActiveFrom: ', dragActiveFrom)

      if (meeting.timeRanges?.length) {
        if (!withinTimeRanges(toTime, meeting.timeRanges)) {
          // withinRange does not include range.endTime
          // if user drags to the end of the range we want it to snap to the end
          const withinRange = withinTimeRanges(fromTime, meeting.timeRanges)

          if (!withinRange) {
            // both start and end times are outside range
            return
          } else {
            // check which direction we are dragging. If going down then
            // limit to range.endTime else limit to range.startTime
            if (moment(toTime).valueOf() > moment(fromTime).valueOf()) {
              toTime = withinRange.endTime
            } else {
              toTime = withinRange.startTime
            }
          }
        }
      }

      if (editSlot && !disableDrag) {
        // console.log('onDrag: check edit slot: ', editSlot)

        const from = moment(fromTime).valueOf()
        const to = moment(toTime).valueOf()
        const startTime = moment(editSlot.startTime).valueOf()
        const endTime = moment(editSlot.endTime).valueOf()

        // console.log('slot start: ', editSlot.startTime)
        // console.log('slot end: ', editSlot.endTime)

        // console.log('DELTA Y ', deltaY)

        // console.log(` DRAG start: ${editSlot.startTime} end: ${editSlot.endTime} from: ${fromTime} to: ${toTime}`)

        if (!dragActiveFrom) {
          // +/- 15 minute range
          const buffer = 15 * 60 * 1000

          if (from >= startTime - buffer && from <= startTime + buffer) {
            dragActiveFrom = 'top'
          } else if (from >= endTime - buffer && from <= endTime + buffer) {
            dragActiveFrom = 'bottom'
          }
        }

        // if selected time is within range of editSlot
        if (dragActiveFrom) {
          onEdit && onEdit()
          timeSlotEdited = from

          const updateSlot = {
            ...editSlot,
          }

          // console.log(` drag edit slot start: ${editSlot.startTime} end: ${editSlot.endTime} from: ${fromTime} to ${toTime}`)

          if (dragActiveFrom === 'top') {
            // console.log('TOP HALF')

            if (to < endTime) {
              dragActiveFrom = 'top'
              updateSlot.startTime = toTime
            } else {
              dragActiveFrom = 'bottom'
              updateSlot.endTime = toTime
            }
          } else {
            // console.log('BOTTOM HALF')

            if (to > startTime) {
              dragActiveFrom = 'bottom'
              updateSlot.endTime = toTime
            } else {
              dragActiveFrom = 'top'
              updateSlot.startTime = toTime
            }
          }

          if (updateSlot.startTime !== editSlot.startTime ||
            updateSlot.endTime !== editSlot.endTime) {
            mergeAdjacentSlots(updateSlot)
          }
        } else {
          console.log('DRAG FROM TIME OUT OF RANGE')
          console.log(` edit slot start: ${editSlot.startTime} end: ${editSlot.endTime} drag from: ${fromTime} to: ${toTime}`)
        }
      }

      onDragStart && onDragStart(fromTime)
    }

    function onEditDone(): void {
      if (editSlot && timeSlotEdited) {
        timeSlotEdited = 0
        console.log('onEditDone: ', editSlot)
        onUpdateTimeSlot && onUpdateTimeSlot(editSlot)
      }

      dragActiveFrom = ''
      onEditEnd && onEditEnd()
      disableDrag = false
    }

    function dragEnd(): void {
      // console.log('DRAG ENG')
      onEditDone()
      setEditSlot && setEditSlot(undefined)
      onDragEnd && onDragEnd()
    }

    function onTouchEndDelay(): void {
      console.log('TOUCH END TIMEOUT')
      setEditSlot && setEditSlot(undefined)
    }

    const confirmedTimeSlotId = uuidv1()
    const tentativeTimeSlotId = uuidv1()
    const trendingTimeSlotId = uuidv1()

    function getMeetingConfirmedTimeSlots(): TimeSlotType[] {
      const dayStart = moment(dayStartTime).valueOf()
      const dayEnd = moment(dayEndTime).valueOf()
      const slots: TimeSlotType[] = []

      // console.log('MEETING TIMES: ', meeting.meetingTimes)

      if (meeting.meetingTimes && showMeetingTimes) {
        meeting.meetingTimes.forEach((meetingTime) => {
          let { startTime, endTime, status } = meetingTime
          const start = moment(startTime).valueOf()
          const end = moment(endTime).valueOf()

          if ((start >= dayStart && start < dayEnd) ||
            (end > dayStart && end <= dayEnd)) {
            if (start < dayStart) {
              startTime = dayStartTime
            }

            if (end > dayEnd) {
              endTime = dayEndTime
            }

            if (status === 'confirmed') {
              slots.push(
                {
                  meetingId: 'meeting',
                  startTime,
                  endTime,
                  userId: confirmedTimeSlotId,
                },
              )
            } else if (status === 'tentative') {
              slots.push(
                {
                  meetingId: 'meeting',
                  startTime,
                  endTime,
                  userId: tentativeTimeSlotId,
                },
              )
            } else if (status === 'trending') {
              slots.push(
                {
                  meetingId: 'meeting',
                  startTime,
                  endTime,
                  userId: trendingTimeSlotId,
                },
              )
            }
          }
        })
      }

      // console.log('MEETING TIMES: ', slots)

      return slots
    }

    function checkBusyRange(timeRange: TimeRangeType): boolean {
      // find overlapping userTimeslot for this range
      const { startTime, endTime } = timeRange
      const start = moment(startTime).valueOf()
      const end = moment(endTime).valueOf()

      const slot = timeSlots.find(s => {
        if (s.userId === userId) {
          const slotStart = moment(s.startTime).valueOf()
          const slotEnd = moment(s.endTime).valueOf()

          if ((start >= slotStart && start < slotEnd) &&
            (end > slotStart && end <= slotEnd)) {
            return true
          }
        }
      })

      if (slot && slot.available === false) {
        return true
      }

      if (editSlot && editSlot.available === false) {
        const slotStart = moment(editSlot.startTime).valueOf()
        const slotEnd = moment(editSlot.endTime).valueOf()

        if ((start >= slotStart && start < slotEnd) &&
          (end > slotStart && end <= slotEnd)) {
          return true
        }
      }

      return false
    }

    function renderTimeRanges(): JSX.Element[] | null {
      // 12AM start time - 1 minute so that 12AM selection fits in the isAfter range below.
      const meetingTimeSlots = getMeetingConfirmedTimeSlots()

      timeSlotRanges = getTimeRanges({
        userId,
        timeSlots: timeSlots.filter(s => !s.disabled).concat(meetingTimeSlots),
        editSlot,
        allowedTimeRanges: meeting.timeRanges,
      })

      const components: JSX.Element[] = []

      const dayStart = moment(dayStartTime).valueOf()
      const dayEnd = moment(dayEndTime).valueOf()

      timeSlotRanges.forEach((timeRange: TimeRangeType, i) => {
        // let others = !showOpen
        let others = false
        let active = false
        let busy = false
        let split = false
        let name = ''
        // let highlight = false
        let confirmed = false
        let tentative = false
        let trending = false

        let { startTime, endTime, participantUserIds: rangeParticipants } = timeRange

        if (moment(startTime).valueOf() < dayStart) {
          startTime = dayStartTime
        }

        if (moment(endTime).valueOf() > dayEnd) {
          endTime = dayEndTime
        }

        const range = {
          ...timeRange,
          startTime,
          endTime,
        }

        if (rangeParticipants && !showOpen) {
          if (rangeParticipants.find(u => u === confirmedTimeSlotId)) {
            confirmed = true
            // remove the confirmedTimeSlotId from range.participants
            rangeParticipants = rangeParticipants.filter(u => u !== confirmedTimeSlotId)
          } else if (rangeParticipants.find(u => u === tentativeTimeSlotId)) {
            tentative = true
            // remove the confirmedTimeSlotId from range.participants
            rangeParticipants = rangeParticipants.filter(u => u !== tentativeTimeSlotId)
          } else if (rangeParticipants.find(u => u === trendingTimeSlotId)) {
            trending = true
            // remove the confirmedTimeSlotId from range.participants
            rangeParticipants = rangeParticipants.filter(u => u !== trendingTimeSlotId)
          }
          // else if (meetingTimeSlots.length === 0 &&
          //   totalParticipants > 2 && bestRange && bestRange > 1 &&
          //   bestRange === rangeParticipants.length) {
          //   // highlight a range only if there are no meetingTimes
          //   highlight = true
          // }

          if (rangeParticipants.find(u => u === userId)) {
            active = true
          }

          if (rangeParticipants.find(u => u !== userId)) {
            others = true
          }

          if ((active && rangeParticipants.length === 2) ||
            (others && rangeParticipants.length === 1)) {
            // if exactly one other participant then
            // show their name
            const p = rangeParticipants.find(u => u !== userId)

            if (p) {
              const participant = participants.find(par => par.userId === p)

              if (participant) {
                name = participant.displayName
              }
            }
          }

          range.participantUserIds = rangeParticipants
        }

        const start = moment(startTime).valueOf()
        const end = moment(startTime).add(30, 'minutes').valueOf()

        if (calendarEvents?.length) {
          calendarEvents.every(event => {
            const eventStart = moment(event.startTime).valueOf()
            const eventEnd = moment(event.endTime).valueOf()

            if ((start >= eventStart && start < eventEnd) ||
              (end > eventStart && end <= eventEnd)) {
              split = true

              return false
            }

            return true
          })
        }

        if (checkBusyRange(timeRange)) {
          busy = true
          active = true
        }

        /*
        // find out if it's a consecutive active range
        let consecutive = active
  
        if (active) {
          // (moment(s.startTime).valueOf() === moment(startTime).valueOf() ||
          // moment(s.endTime).valueOf() === moment(endTime).valueOf()))) {
          if (editSlot && moment(editSlot.startTime).valueOf() === moment(startTime).valueOf()) {
            consecutive = false
          } else if (timeSlots.find(s => s.userId=== user &&
            moment(s.startTime).valueOf() === moment(startTime).valueOf())) {
            consecutive = false
          }
        }
        */
        // console.log('RANGE: ', timeRange)
        // console.log('active: ', active)
        // console.log('other: ', others)
        components.push(
          <TimeRange
            key={i}
            dayStartTime={dayStartTime}
            scale={scale}
            timeRange={range}
            others={others}
            open={showOpen}
            active={active}
            split={split}
            busy={busy}
            name={name}
            best={tentative || trending}
            confirmed={confirmed}
            tentative={tentative}
            trending={trending}
            totalParticipants={totalParticipants} />,
        )

        // if (highlight) {
        //   components.push(
        //     <TimeRange
        //       key={`${i}.3`}
        //       best
        //       dayStartTime={dayStartTime}
        //       scale={scale}
        //       timeRange={range}
        //       split={split}
        //       totalParticipants={totalParticipants} />,
        //   )
        // }
      })

      // highlight a tentative range
      meetingTimeSlots.forEach((meetingTime, i) => {
        const { startTime, endTime } = meetingTime

        const range = {
          startTime,
          endTime,
          participantUserIds: [],
        }

        components.push(
          <TimeRange
            key={`${i}.3`}
            best
            confirmed={meetingTime.userId === confirmedTimeSlotId}
            tentative={meetingTime.userId === tentativeTimeSlotId}
            dayStartTime={dayStartTime}
            scale={scale}
            timeRange={range}
            totalParticipants={totalParticipants} />,
        )
      })

      // if (meeting.meetingTimes && showMeetingTimes) {
      //   meeting.meetingTimes.forEach((meetingTime, i) => {
      //     let { startTime, endTime, status, participants } = meetingTime
      //     let start = moment(startTime).valueOf()
      //     let end = moment(endTime).valueOf()

      //     if ((start >= dayStart && start < dayEnd) ||
      //       (end > dayStart && end <= dayEnd)) {
      //       if (start < dayStart) {
      //         startTime = dayStartTime
      //       }

      //       if (end > dayEnd) {
      //         endTime = dayEndTime
      //       }

      //       const timeRange = {
      //         startTime,
      //         endTime,
      //         participants,
      //       }

      //       let active = false

      //       if (participants && participants.find(u => u === user)) {
      //         active = true
      //       }

      //       let split = false

      //       start = moment(startTime).valueOf()
      //       end = moment(startTime).add(30, 'minutes').valueOf()

      //       if (calendarEvents?.length) {
      //         calendarEvents.every(event => {
      //           const eventStart = moment(event.startTime).valueOf()
      //           const eventEnd = moment(event.endTime).valueOf()

      //           if ((start >= eventStart && start < eventEnd) ||
      //             (end >= eventStart && end < eventEnd)) {
      //             split = true

      //             return false
      //           }

      //           return true
      //         })
      //       }

      //       let busy = false
      //       const timeSlot = getUserTimeSlot(startTime, user, timeSlots)

      //       if (timeSlot && !timeSlot.available) {
      //         // that function will return a timeSlot if the time is exactly on the boundary
      //         // so make sure it's within the boundary
      //         if (start >= moment(timeSlot.startTime).valueOf() && start < moment(timeSlot.endTime).valueOf()) {
      //           busy = true
      //         }
      //       }

      //       components.push(
      //         <TimeRange
      //           key={`${i}.1`}
      //           dayStartTime={dayStartTime}
      //           scale={scale}
      //           timeRange={timeRange}
      //           active={active}
      //           confirmed={status === 'confirmed'}
      //           tentative={status === 'tentative'}
      //           split={split}
      //           busy={busy}
      //           totalParticipants={totalParticipants} />,
      //       )
      //     }
      //   })
      // }

      let calendarTimeSlots: TimeSlotType[] = []

      if (calendarEvents?.length) {
        // convert calendar events into time slots
        // sort such that meetings with longer duration will be ranked lower so
        // they will appear left and bottom of overlapped events
        const sortedEvents = calendarEvents.slice().sort((a: CalendarEventType, b: CalendarEventType) => {
          const durationA = moment(a.endTime).valueOf() - moment(a.startTime).valueOf()
          const durationB = moment(b.endTime).valueOf() - moment(b.startTime).valueOf()

          return durationB - durationA
        })

        calendarTimeSlots = sortedEvents.map(event => {
          return {
            startTime: event.startTime,
            endTime: event.endTime,
            userId: event.id,
            meetingId: 'meeting',
          }
        })

        calendarRanges = getTimeRanges({ timeSlots: calendarTimeSlots })

        calendarRanges.forEach((timeRange: TimeRangeType, i) => {
          let { startTime, endTime, participantUserIds } = timeRange

          if (moment(startTime).valueOf() < dayStart) {
            startTime = dayStartTime
          }

          if (moment(endTime).valueOf() > dayEnd) {
            endTime = dayEndTime
          }

          const range = {
            ...timeRange,
            startTime,
            endTime,
          }

          const events: CalendarEventType[] = []

          if (participantUserIds?.length) {
            participantUserIds.forEach(id => {
              const event = calendarEvents.find(event => id === event.id)

              if (event) {
                events.push(event)
              }
            })
          }

          let split = false
          const eventStart = moment(startTime).valueOf()
          const eventEnd = moment(endTime).valueOf()

          if (timeSlotRanges?.length) {
            timeSlotRanges.every(r => {
              const start = moment(r.startTime).valueOf()
              const end = moment(r.endTime).valueOf()

              if ((eventStart <= start && eventEnd > start) ||
                (eventStart > start && eventEnd < end) ||
                (eventStart < end && eventEnd >= end)) {
                split = true

                return false
              }

              return true
            })
          }

          // if (!split) {
          //   if (meeting.meetingTimes?.length) {
          //     meeting.meetingTimes.every(meetingTime => {
          //       const start = moment(meetingTime.startTime).valueOf()
          //       const end = moment(meetingTime.endTime).valueOf()

          //       if ((eventStart <= start && eventEnd > start) ||
          //         (eventStart > start && eventEnd < end) ||
          //         (eventStart < end && eventEnd >= end)) {
          //         split = true

          //         return false
          //       }

          //       return true
          //     })
          //   }
          // }

          components.push(
            <CalendarEvents
              key={`${i}.2`}
              scale={scale}
              split={split}
              dayStartTime={dayStartTime}
              timeRange={range}
              events={events} />,
          )
        })
      }

      return components
    }

    function renderEditSlot(): JSX.Element | null {
      if (editSlot) {
        const { startTime, endTime, id, localId, autoCreated } = editSlot

        const start = moment(startTime).valueOf()
        const end = moment(endTime).valueOf()
        const dayStart = moment(dayStartTime).valueOf()
        const dayEnd = moment(dayEndTime).valueOf()

        if ((start >= dayStart && start < dayEnd) ||
          (end > dayStart && end <= dayEnd)) {
          const timeRange = {
            startTime,
            endTime,
          }

          // Note that meetingTimes don't have an id
          // so if it's a legit timeSlot then go ahead and edit it
          if (id || localId) {
            if (autoCreated) {
              return (
                <TimeRange
                  dayStartTime={dayStartTime}
                  scale={scale}
                  timeRange={timeRange}
                  onBusy={
                    onBusyTimeSlot
                      ? () => {
                        logTap({ component: 'TimeRange', button: 'BusySlot' })
                        busyEditSlot()
                      } : undefined
                  }
                  totalParticipants={totalParticipants} />
              )
            }

            return (
              <TimeRange
                dayStartTime={dayStartTime}
                scale={scale}
                timeRange={timeRange}
                edit
                onDelete={
                  onDeleteTimeSlot
                    ? () => {
                      logTap({ component: 'TimeRange', button: 'DeleteSlot' })
                      deleteEditSlot()
                    }
                    : undefined
                }
                totalParticipants={totalParticipants} />
            )
          }
          // else if (getUserMeetingTime({ startTime, endTime, user, meetingTimes: meeting.meetingTimes })) {
          //   return (
          //     <TimeRange
          //       dayStartTime={dayStartTime}
          //       scale={scale}
          //       timeRange={timeRange}
          //       active
          //       onBusy={onBusyTimeSlot ? () => busyEditSlot() : undefined}
          //       totalParticipants={totalParticipants} />
          //   )
          // }
        }
      }

      return null
    }

    function renderActivity(): JSX.Element | null {
      if (showActivity) {
        const { startTime, endTime } = showActivity
        const start = moment(startTime).valueOf()
        const end = moment(endTime).valueOf()
        const dayStart = moment(dayStartTime).valueOf()
        const dayEnd = moment(dayEndTime).valueOf()

        if ((start >= dayStart && start < dayEnd) ||
          (end > dayStart && end <= dayEnd)) {
          const timeRange = {
            startTime,
            endTime,
          }

          return (
            <TimeRange
              dayStartTime={dayStartTime}
              scale={scale}
              timeRange={timeRange}
              activity
              totalParticipants={totalParticipants} />
          )
        }
      }

      return null
    }

    function renderTimeMarker(): JSX.Element | undefined {
      // 12AM start time - 1 minute so that 12AM selection fits in the isAfter range below.

      if (currentTimeMarker) {
        const now = moment().valueOf()
        const dayStart = moment(dayStartTime).valueOf()
        const dayEnd = moment(dayStartTime).add(24, 'hours').valueOf()

        if (now >= dayStart && now < dayEnd) {
          const startTime = moment().toISOString()
          const timeRange = {
            startTime,
            endTime: startTime,
          }

          // console.log('Time marker range: ', timeRange)

          return (
            <TimeMarker
              dayStartTime={dayStartTime}
              scale={scale}
              timeRange={timeRange} />
          )
        }
      }
    }

    function renderMeetingTimeMarkers(): JSX.Element[] | undefined {
      // 12AM start time - 1 minute so that 12AM selection fits in the isAfter range below.

      const markers: JSX.Element[] = []

      if (meeting.meetingTimes && meetingTimeMarkers) {
        const dayStart = moment(dayStartTime).valueOf()
        const dayEnd = moment(dayStartTime).add(24, 'hours').valueOf()

        meeting.meetingTimes.forEach((meetingTime, i) => {
          let { startTime, endTime } = meetingTime
          const start = moment(startTime).valueOf()
          const end = moment(endTime).valueOf()

          if ((start >= dayStart && start < dayEnd) ||
            (end > dayStart && end <= dayEnd)) {
            if (start < dayStart) {
              startTime = dayStartTime
            }

            if (end > dayEnd) {
              endTime = dayEndTime
            }

            markers.push(
              <MeetingTimeMarkerLines
                key={i}
                meetingTime={meetingTime} />,
            )
          }
        })
      }

      return markers
    }

    function renderDisabledRanges(): JSX.Element[] | null {
      const ranges: TimeRangeType[] = []

      timeSlots.forEach(slot => {
        if (slot.disabled) {
          ranges.push({
            startTime: slot.startTime,
            endTime: slot.endTime,
          })
        }
      })

      return ranges.map((range, i) => {
        return (
          <TimeRange
            key={i}
            dayStartTime={dayStartTime}
            scale={scale}
            timeRange={range}
            disabled
            totalParticipants={totalParticipants} />
        )
      })
    }

    function renderGrid(disabled?: boolean): JSX.Element[] {
      // if (disabled) {
      return Array(...Array(24)).map((v, i) =>
        <GridBox
          key={i}
          disable={disabled} />,
      )
      // } else {
      //   if (meeting.timeRanges?.length) {
      //     const timeRanges = meeting.timeRanges

      //     return Array(...Array(24)).map((v, i) => {
      //       const startTime = moment(dayStartTime).add(i, 'hours').add(1, 'minute').toISOString()
      //       const withinRange = withinTimeRanges(startTime, timeRanges)

      //       return <GridBox
      //         key={i}
      //         disable={!withinRange} />
      //     })
      //   } else {
      //   return Array(...Array(24)).map((v, i) => {
      //     return <GridBox
      //       key={i} />
      //   })
      //   }
      // }
    }

    function renderRestrictedRanges(): JSX.Element[] | undefined {
      if (meeting.timeRanges?.length) {
        const ranges = adjustTimeRangesForDay(dayStartTime, meeting.timeRanges)

        const timeSlots: TimeSlotType[] = ranges.map((range) => {
          return {
            meetingId: meeting.id,
            userId: 'allowed',
            startTime: range.startTime,
            endTime: range.endTime,
          }
        })

        timeSlots.push({
          meetingId: meeting.id,
          userId: 'restricted',
          startTime: dayStartTime,
          endTime: moment(dayStartTime).add(24, 'hours').toISOString(),
        })

        // generate restricted ranges from meetingTimeRanges which don't overlap with 'allowed' ranges
        const restrictedRanges = getTimeRanges({ timeSlots: timeSlots, editSlot }).filter(range => range.participantUserIds?.length === 1)

        return restrictedRanges.map((range, i) => {
          return (
            <TimeRange
              key={i}
              dayStartTime={dayStartTime}
              scale={scale}
              timeRange={range}
              disabled
              totalParticipants={totalParticipants} />
          )
        })
      }
    }

    function renderAutoRanges(): JSX.Element[] | undefined {
      if (bookCalendar?.autoBook && bookCalendar?.autoDays && bookCalendar?.autoTimes) {
        const { autoTimes, autoDays } = bookCalendar

        const day = moment(dayStartTime).format('ddd').toLowerCase()

        if (autoDays.find(d => d === day)) {
          const autoRanges = adjustTimeRangesForDay(dayStartTime, autoTimes)

          return autoRanges.map((range, i) => {
            return (
              <TimeRange
                key={i}
                dayStartTime={dayStartTime}
                scale={scale}
                timeRange={range}
                auto
                totalParticipants={totalParticipants} />
            )
          })
        }
      }
    }

    function renderTip(): JSX.Element | undefined {
      if (tip) {
        const container: CSSProperties = {
          position: 'absolute',
          top: timeSlotHeight * tip,
          zIndex: 1,
        }

        return (
          <div
            style={container}>
            <TipTarget style={{ left: timeSlotWidth / 4 }} />
          </div>
        )
      }
    }

    function renderToast(): JSX.Element {
      return (
        <IonToast
          isOpen={!!showToast}
          color='warning'
          onDidDismiss={() => setShowToast('')}
          message={showToast}
          duration={2000} />
      )
    }

    const container: CSSProperties = {
      minWidth: timeSlotWidth,
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      position: 'relative',
      boxShadow: shadow ? '2px 0 5px -2px #808289' : '',
    }

    const withinRange = withinDayRanges(dayStartTime, dayEndTime, meeting?.dayRanges)

    if (withinRange) {
      return (
        <div
          style={container}>
          {renderTip()}
          <DayColumnGestures
            parentId={parentId}
            startDay={startDay}
            day={day}
            scale={scale}
            tip={tipColumn}
            onSingleTap={(time) => {
              logTap({ component: 'DayColumnGestures', button: 'SingleTap' })
              console.log('single tap at time: ', time)
              onSingleTapConfirmAuto(time)
            }}
            onLongTap={(time) => {
              logTap({ component: 'DayColumnGestures', button: 'LongTap' })
              // onLongTap(time)
              console.log('long tap at time: ', time)
              onSingleTapConfirmAuto(time)
            }}
            onDrag={(fromTime, toTime) => {
              logTap({ component: 'DayColumnGestures', button: 'Drag' })
              onDrag(fromTime, toTime)
            }}
            onDragEnd={dragEnd}
            onTouchEndDelay={onTouchEndDelay}>
            {renderGrid()}
            {renderAutoRanges()}
            {renderRestrictedRanges()}
            {renderTimeRanges()}
            {renderDisabledRanges()}
            {renderActivity()}
            {renderEditSlot()}
            {renderTimeMarker()}
            {renderMeetingTimeMarkers()}
          </DayColumnGestures>
          <GridBox last />
          {renderToast()}
          <IonAlert
            isOpen={!!showAlert}
            onDidDismiss={() => setShowAlert('')}
            header='Automagic Scheduling is On'
            message='Selecting a time will turn off automagic scheduling'
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  logTap({ component: 'IonAlert', button: 'Cancel' })
                  console.log('Confirm Cancel: blah')
                },
              },
              {
                text: 'Continue',
                handler: () => {
                  logTap({ component: 'IonAlert', button: 'Continue' })
                  onSingleTap(showAlert)
                },
              },
            ]} />
        </div>
      )
    }

    return (
      <div
        style={container}>
        {renderGrid(true)}
        <GridBox last />
      </div>
    )
  }

export default DayColumn
