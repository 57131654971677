
import React, { CSSProperties } from 'react'
import { IonButton, IonCard, IonCardContent, IonIcon, IonText } from '@ionic/react'
import { close } from 'ionicons/icons'
import { animated } from 'react-spring'

export const tipBox: CSSProperties = {
  position: 'absolute',
  width: '100%',
  paddingLeft: 10,
  paddingRight: 10,
  zIndex: 10,
}

const card: CSSProperties = {
  // border: `1px solid ${logoColor}`,
  // border: '1px solid gold',
  borderRadius: 10,
  display: 'flex',
}

const box1: CSSProperties = {
  flex: 1,
  // padding: 16,
}

const rightBox: CSSProperties = {
  display: 'flex',
  // alignItems: 'flex-end',
  // border: '1px solid red',
}

const bottomBox: CSSProperties = {
  display: 'flex',
  // the IonCardContent above bottom box has a bottom padding of 20
  // and the buttons in bottombar have their own padding which add up
  // so lift up the bottom bar so that only the button padding is sufficient
  // marginTop: -10,
  justifyContent: 'flex-end',
  // border: '1px solid red',
}

export const closeButton: CSSProperties = {
  fontSize: 20,
  color: 'white',
  // position: 'absolute',
  // right: 4,
  // bottom: 8,
}

export interface NotifyCardProps {
  boxStyle?: CSSProperties,
  cardStyle?: CSSProperties,
  cardContentStyle?: CSSProperties,
  className?: string;
  onClose?: () => void,
  onButton?: () => void,
  buttonLabel?: string;
  animatedProps?: CSSProperties,
  goBackTo?: string;
}

const NotifyCard: React.FC<NotifyCardProps> = (
  { boxStyle, className, cardStyle, cardContentStyle, buttonLabel, animatedProps, children, goBackTo, onClose, onButton }) => {
  // const tips = useSelector((state: RootState) => state.tips) as Tips

  function renderClose (): JSX.Element | undefined {
    if (onClose) {
      return (
        <div style={rightBox}>
          <IonButton
            fill='clear'
            size='small'
            onClick={() => onClose()}>
            <IonIcon
              slot='icon-only'
              icon={close}
              style={closeButton} />
          </IonButton>
        </div>
      )
    }
  }

  function renderButton (): JSX.Element | undefined {
    if (onButton) {
      const button = buttonLabel || 'Got It'

      return (
        <div style={bottomBox}>
          <IonButton
            mode='md'
            fill='clear'
            color='dark'
            size='small'
            routerLink={goBackTo}
            onClick={() => onButton()}>
            <IonText style={{ color: 'white' }}>
              {button}
            </IonText>
          </IonButton>
        </div>
      )
    }
  }

  return (
    <animated.div
      style={{ ...tipBox, ...boxStyle, ...animatedProps }}>
      <IonCard
        color='primary'
        className={className}
        style={{ ...card, ...cardStyle }}>
        <div style={box1}>
          <IonCardContent style={{ padding: 16, ...cardContentStyle }}>
            {children}
          </IonCardContent>
          {renderButton()}
        </div>
        {renderClose()}
      </IonCard>
    </animated.div>
  )
}

export default NotifyCard
