import React, { CSSProperties } from 'react'

import { CalendarAccount, SubCalendar } from 'types'
import CalendarItem from './CalendarItem'

const container: CSSProperties = {
  flex: 1,
}

export interface SelectCalendarProps {
  calendars: CalendarAccount[];
  selected?: SubCalendar[];
  writable?: boolean;
  selectBookCalendar?: boolean;
  onSelect: (selected: SubCalendar) => void;
}

const SelectCalendar: React.FC<SelectCalendarProps> = ({ calendars, selected, writable, selectBookCalendar, onSelect }) => {
  return (
    <div style={container}>
      {calendars.map((calendar: CalendarAccount, i: number) => (
        <CalendarItem
          key={i}
          calendar={calendar}
          selected={selected}
          writable={writable}
          selectBookCalendar={selectBookCalendar}
          onSelect={onSelect} />
      ))}
    </div>
  )
}

export default SelectCalendar
