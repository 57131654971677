import React, { useState } from 'react'
import {
  IonBackdrop,
  IonContent, IonFooter, IonLabel, IonModal, IonText,
} from '@ionic/react'

import { Invitee, InviteeStatus, Participant } from 'types'
import { useSpring } from 'react-spring'
import ActionCard from '../ActionCard/ActionCard'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useUser } from 'context/UserContext/UserContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import { useNotifyParticipants } from 'context/NotifyParticipantsContext/NotifyParticipantsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { NotifyParticipantsInput, NotifyParticipantsType } from 'services/api'
import { CSSProperties } from '@material-ui/styles'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import { Avatar } from '@material-ui/core'
import { withinDayRanges, withinTimeRanges } from '../ScheduleCalendar/utils'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: boolean;
}

const avatarColor = '#92949C'

const photoBox: CSSProperties = {
  paddingTop: 10,
  paddingBottom: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const avatarStyle: CSSProperties = {
  width: 44,
  height: 44,
  backgroundColor: avatarColor,
  border: '2px solid #d7d8da',
  marginBottom: 5,
}

const avatarTxt: CSSProperties = {
  fontSize: 22,
}

const messageBox: CSSProperties = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
}

const messageStyle = {
  fontSize: 14,
  whiteSpace: 'pre-wrap',
}

interface ContainerProps {
  meetingParticipant: string;
  onClose: () => void;
  onMore?: () => void;
  presentingElement: HTMLElement | HTMLDivElement | undefined | null
}

const ParticipantDetailsModal: React.FC<ContainerProps> = ({ meetingParticipant, onClose }) => {
  const [showDetails] = useState(true)

  const { user } = useUser()
  const { meeting } = useMeetings()
  const { meetingInvitees } = useInvitees()
  const { participants } = useParticipants()
  const { timeSlots } = useTimeSlots()
  const { notifyParticipants } = useNotifyParticipants()

  // const [activity, setActivity] = useState(false)

  function renderParticipant(participant: Participant): JSX.Element {
    const { displayName, photo } = participant
    const initials = displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

    // if initials is > 2 characters then just pick first and last
    const displayInitials = initials.length > 2
      ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

    let name = displayName

    if (participant.userId === user?.id) {
      name = 'You'
    }

    return (
      <div
        style={photoBox}
        className='titleIconBox'>
        <Avatar
          src={photo}
          style={avatarStyle}>
          <IonText style={avatarTxt}>
            {displayInitials}
          </IonText>
        </Avatar>
        <IonLabel>{name}</IonLabel>
      </div>
    )
  }

  function renderInvitee(invitee: Invitee): JSX.Element {
    const { displayName, photo } = invitee
    const initials = displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

    // if initials is > 2 characters then just pick first and last
    const displayInitials = initials.length > 2
      ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

    return (
      <div
        style={photoBox}
        className='titleIconBox'>
        <Avatar
          src={photo}
          style={avatarStyle}>
          <IonText style={avatarTxt}>
            {displayInitials}
          </IonText>
        </Avatar>
        <IonLabel>{displayName}</IonLabel>
      </div>
    )
  }

  function renderUser(): JSX.Element | undefined {
    if (participants) {
      const participant = participants.find(p => p.userId === meetingParticipant)

      if (participant) {
        return renderParticipant(participant)
      }
    }

    if (meetingInvitees) {
      const invitee = meetingInvitees.find(i => i.userId === meetingParticipant)

      if (invitee) {
        return renderInvitee(invitee)
      }
    }
  }

  function nudgeParticipant(): void {
    if (meeting && meetingParticipant && notifyParticipants) {
      const input: NotifyParticipantsInput = {
        meetingId: meeting.id,
        meetingUsers: [meetingParticipant],
        notifyType: NotifyParticipantsType.nudge,
      }

      notifyParticipants(input)
    }

    onClose()
  }

  function nudgeEveryone(): void {
    if (meeting && meetingParticipant && notifyParticipants) {
      const input: NotifyParticipantsInput = {
        meetingId: meeting.id,
        notifyType: NotifyParticipantsType.nudge,
        allAwaiting: true,
      }

      notifyParticipants(input)
    }

    onClose()
  }

  function renderNudgeParticipant(name: string, pickMoreTimes?: boolean): JSX.Element | undefined {
    const msg = pickMoreTimes
      ? `Send ${name} a friendly nudge to pick some more times`
      : `Send ${name} a friendly nudge to pick some times`

    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <IonText
            color='medium'
            style={messageStyle}>
            {msg}
          </IonText>
        </div>
        <FooterButton onClick={() => nudgeParticipant()}>
          Nudge
        </FooterButton>
      </div>
    )
  }

  function renderNudgeEveryone(nudge: boolean): JSX.Element | undefined {
    const msg = 'Send a friendly nudge to everyone who has not picked any times'

    return (
      <div>
        <div style={{ marginTop: nudge ? 20 : 10, marginBottom: 10 }}>
          <IonText
            color='medium'
            style={messageStyle}>
            {msg}
          </IonText>
        </div>
        <FooterButton
          fill={nudge ? 'outline' : 'solid'}
          onClick={() => nudgeEveryone()}>
          Nudge Everyone
        </FooterButton>
      </div>
    )
  }

  function renderStatus(): JSX.Element | undefined {
    if (meeting && meetingInvitees && participants) {
      let status = ''
      let nudge = false
      let name = ''
      let pickMoreTimes = false
      const confirmedTime = meeting.meetingTimes?.find(mt => mt.status === 'confirmed')

      const participant = participants.find(p => p.userId === meetingParticipant)

      const invitee = meetingInvitees.find(i => i.userId === meetingParticipant)

      if (participant) {
        const { displayName } = participant

        name = displayName.split(' ').find(t => t) || ''

        if (participant.userId === user?.id) {
          name = 'You'
        }

        // const selectedTime = timeSlots?.find(timeSlot => timeSlot.userId=== meetingParticipant)

        const selectedTime =
          timeSlots?.filter(timeSlot => timeSlot.userId === meetingParticipant)
            .filter(t => withinDayRanges(t.startTime, t.endTime, meeting?.dayRanges))
            .find(t => {
              if (!meeting?.timeRanges?.length) {
                // if meeting.timeRanges are not set then all time slots withinDayRanges are valid
                return true
              }

              if (withinTimeRanges(t.startTime, meeting?.timeRanges) || withinTimeRanges(t.endTime, meeting?.timeRanges)) {
                return true
              }
            })

        if (confirmedTime) {
          if (confirmedTime.participantUserIds.find(participant => participant === meetingParticipant)) {
            if (participant.userId === user?.id) {
              status = 'You are attending this meeting at the confirmed time \n'
            } else {
              status = `${displayName} is attending this meeting at the confirmed time \n`
            }
          } else {
            if (participant.userId === user?.id) {
              status = 'You are not attending this meeting at the confirmed time \n'
            } else {
              status = `${displayName} is not attending this meeting at the confirmed time \n`
              nudge = true
              pickMoreTimes = !!selectedTime
            }
          }
        } else if (meeting?.createdByUserId === meetingParticipant) {
          status = `${displayName} initiated this meeting \n`
          nudge = participant.userId !== user?.id
          pickMoreTimes = !!selectedTime
        } else {
          if (selectedTime) {
            if (participant.userId === user?.id) {
              status += 'You have picked some times'
            } else {
              status += `${displayName} has picked some times`
              nudge = true
            }
          } else {
            if (participant.userId === user?.id) {
              status += 'You have not picked any times'
            } else {
              status += `${displayName} has not picked any times`
              nudge = true
            }
          }

          pickMoreTimes = !!selectedTime
        }
      } else {
        if (invitee) {
          const { displayName } = invitee

          name = displayName.split(' ').find(t => t) || ''

          switch (invitee.status) {
            case InviteeStatus.invited:
              status = `${name} has been invited`
              break
            case InviteeStatus.received:
              status = `${name} has received the invite but has not accepted it`
              break
            case InviteeStatus.seen:
              status = `${name} has seen the invite but has not accepted it`
              break
            case InviteeStatus.declined:
              status = `${name} has declined to attend`
              break
          }

          nudge = true
        }
      }

      if (status) {
        return (
          <div>
            <div style={messageBox}>
              <IonText
                color='medium'
                style={messageStyle}>
                {status}
              </IonText>
            </div>
            <IonFooter className='screenFooterButton'>
              {nudge && renderNudgeParticipant(name, pickMoreTimes)}
              {!confirmedTime && renderNudgeEveryone(nudge)}
            </IonFooter>
          </div>
        )
      }
    }
  }

  const popoverProps = useSpring({
    ...(showDetails ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderDetailsCard(): JSX.Element | undefined {
    if (meeting) {
      return (
        <ActionCard
          title={meeting.title}
          icon=''
          onClose={onClose}
          animatedProps={popoverProps}>
          {renderUser()}
          {renderStatus()}
          {/* {renderActionButtons()} */}
        </ActionCard>
      )
    }
  }

  return (
    <IonModal
      isOpen
      backdropDismiss
      cssClass='cardModal'
      onDidDismiss={onClose}>
      <IonContent>
        <IonBackdrop
          tappable
          visible />
        {renderDetailsCard()}
      </IonContent>
    </IonModal>
  )
}

export default ParticipantDetailsModal
