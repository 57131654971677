import React, { useState, useEffect } from 'react'
import {
  IonActionSheet,
  IonContent, IonFooter, IonModal, IonSpinner,
} from '@ionic/react'

import 'screens/Settings.css'
import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'
import { CalendarAccount, SubCalendar, Participant } from 'types'
import SelectCalendar from 'components/molecules/SelectCalendar/SelectCalendar'

import { UpdateParticipantInput, ParticipantCalendarInput } from 'services/api'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { Spring } from 'react-spring/renderprops'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

const title = 'Meeting View Calendars'

interface ContainerProps {
  participant: Participant;
  accounts: CalendarAccount[];
  onClose: () => void;
  onUpdated: () => void;
  presentingElement: HTMLElement | undefined;
}

const SelectFreeBusyCalendars: React.FC<ContainerProps> = ({ participant, accounts, onClose, onUpdated, presentingElement }) => {
  const [selected, setSelected] = useState<SubCalendar[]>([])
  const [showCalendarActions, setCalendarActions] = useState<SubCalendar>()
  const [edited, setEdited] = useState(false)
  const [showActivity, setShowActivity] = useState(false)

  const { calendars: participantCalendars } = participant

  const { updateParticipant } = useParticipants()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    const enabled: SubCalendar[] = []

    accounts.forEach(account => {
      account.calendars.forEach(c => {
        if (Array.isArray(participantCalendars)) {
          const calendar = participantCalendars.find(cal => cal.calendarId === c.calendarId && cal.account === account.id)

          if (calendar) {
            enabled.push({
              id: c.calendarId,
              account: account.id,
              enabled: calendar.enabled,
              freeBusy: calendar.freeBusy,
            })

            return
          }
        }

        enabled.push({
          id: c.calendarId,
          account: account.id,
          enabled: c.enabled,
          freeBusy: c.freeBusy,
        })
      })
    })
    // console.log('Participant CALS: ', enabled)
    setSelected(enabled)
  }, [])

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'SelectFreeBusyCalendars',
      },
    })
  }

  async function updateViewCalendars(): Promise<void> {
    // console.log('Update default calendar to: ', selected)

    setShowActivity(true)

    const calendars: ParticipantCalendarInput[] = []

    accounts.forEach(account => {
      account.calendars.forEach(cal => {
        const calendar = selected.find(c => c.id === cal.calendarId && c.account === account.id)

        if (calendar) {
          calendars.push({
            calendarId: calendar.id,
            account: account.id,
            enabled: calendar.enabled || false,
            freeBusy: calendar.freeBusy || false,
          })
        }
      })
    })

    // console.log('New DISABLED calendars: ', disabled)

    if (calendars && updateParticipant) {
      const input: UpdateParticipantInput = {
        id: participant.id,
        calendars,
      }

      const data = await updateParticipant(input)

      console.log('Updated meeting calendar', data)
      onUpdated()

      return
    }

    onClose()
  }

  function renderHeader(): JSX.Element {
    return (
      <ModalHeader
        title={title} />
    )
  }

  function renderFooter(): JSX.Element | undefined {
    if (showActivity) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton>
            <IonSpinner name='dots' />
          </FooterButton>
        </IonFooter>
      )
    }

    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}>
        {props =>
          <div style={props}>
            <IonFooter className='screenFooterButton'>
              {edited &&
                <FooterButton
                  onClick={() => {
                    logTap({ button: 'Done' })
                    updateViewCalendars()
                  }}>
                  Done
                </FooterButton>}
              <FooterButton
                fill='clear'
                onClick={() => {
                  logTap({ button: 'Cancel' })
                  onClose()
                }}>
                Cancel
              </FooterButton>
            </IonFooter>
          </div>}
      </Spring>
    )
  }

  return (
    <IonModal
      isOpen
      swipeToClose
      presentingElement={presentingElement}
      onDidDismiss={onClose}>
      {renderHeader()}
      <IonContent>
        <SelectCalendar
          calendars={accounts}
          selected={selected}
          onSelect={(cal) => {
            logTap({ button: 'SelectCalendar' })
            console.log('on selected: ', cal)
            setEdited(true)
            setCalendarActions(cal)
          }} />
      </IonContent>
      {renderFooter()}
      <IonActionSheet
        isOpen={!!showCalendarActions}
        onDidDismiss={() => setCalendarActions(undefined)}
        buttons={[
          {
            text: 'Use for free-busy',
            handler: () => {
              logTap({ button: 'UseForFreeBusy' })
              setSelected(selected.map(c => {
                if (c.id === showCalendarActions?.id) {
                  return {
                    ...c,
                    enabled: true,
                    freeBusy: true,
                  }
                }

                return c
              }))
            },
          },
          {
            text: 'View only',
            handler: () => {
              logTap({ button: 'ViewOnly' })
              setSelected(selected.map(c => {
                if (c.id === showCalendarActions?.id) {
                  return {
                    ...c,
                    enabled: true,
                    freeBusy: false,
                  }
                }

                return c
              }))
            },
          },
          {
            text: 'Ignore',
            role: 'destructive',
            handler: () => {
              logTap({ button: 'Ignore' })
              setSelected(selected.map(c => {
                if (c.id === showCalendarActions?.id) {
                  return {
                    ...c,
                    enabled: false,
                    freeBusy: false,
                  }
                }

                return c
              }))
            },
          }, {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              logTap({ button: 'Cancel' })
              console.log('Cancel clicked')
            },
          }]} />
    </IonModal>
  )
}

export default SelectFreeBusyCalendars
