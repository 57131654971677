import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import 'screens/Settings.css'

import DevicesContextProvider from 'context/DevicesContext/DevicesContext'
import Devices from './Devices'
import { fingerprint } from 'services/accounts'

const DevicesScreen: React.FC<RouteComponentProps> = ({ history }) => {
  function goBack (): void {
    history.goBack()
  }

  return (
    <DevicesContextProvider>
      <Devices
        myDevice={fingerprint}
        goBack={goBack} />
    </DevicesContextProvider>
  )
}

export default DevicesScreen
