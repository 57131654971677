import React, { useContext } from 'react'

export type TabsContextValue = {
  showTabs: boolean,
  setShowTabs: React.Dispatch<React.SetStateAction<boolean>>
}

const TabsContext = React.createContext<Partial<TabsContextValue>>({})

export function useTabs (): Partial<TabsContextValue> {
  return useContext(TabsContext)
}

export default TabsContext
