import React, { CSSProperties, useEffect, useState } from 'react'
import { IonSlide, IonContent, IonPage, IonText, IonFooter, IonIcon, IonItem, IonLabel, IonThumbnail, IonToast } from '@ionic/react'

import 'theme/styles.css'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { blue, logoColor, success } from 'theme/styles'
import { time, trendingUp } from 'ionicons/icons'
import { useTips } from 'context/TipsContext/TipsContext'
import { useTabs } from 'navigation/TabsContext'

import AnimatedSwipeLeft from 'components/atoms/AnimatedSwipeLeft/AnimatedSwipeLeft'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.

const slide: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  // padding: 40,
  // paddingTop: 10,
  // alignItems: 'center',
}

const title = 'Tips'

const imageBox: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
}
const screenShot: CSSProperties = {
  maxWidth: '50%',
}
const titleBox: CSSProperties = {
  marginTop: '5%',
  paddingLeft: '10%',
  paddingRight: '10%',
  marginBottom: '5%',
  textAlign: 'center',
}
const textBox: CSSProperties = {
  marginTop: '2.5%',
  paddingLeft: '10%',
  paddingRight: '10%',
  marginBottom: '5%',
}

const cardTitle: CSSProperties = {
  fontSize: 20,
  fontWeight: 'bold',
}

const messageBox: CSSProperties = {
  marginTop: '5%',
  alignItems: 'flex-start',
  textAlign: 'start',
}

const cardMessage: CSSProperties = {
  fontSize: 15,
}

const swipeLeft: CSSProperties = {
  position: 'absolute',
  top: '60%',
  right: 10,
}

interface ComponentProps {
  newUser?: boolean;
  goBack: () => void;
  onNext?: () => void;
}

const TipsAvailabilityCalendar: React.FC<ComponentProps> = ({ newUser, goBack, onNext }) => {
  const [prefersDark, setPrefersDark] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)
  const [showToast, setShowToast] = useState(false)
  const { updateStoreTips } = useTips()
  const { setShowTabs } = useTabs()
  const { logEvent } = useAnalytics()

  function handleDarkMode (event: MediaQueryListEvent): void {
    setPrefersDark(event.matches)
  }

  useEffect(() => {
    if (onNext) {
      setTimeout(() => {
        setShowToast(true)
      }, 1000)
    }

    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'TipsAvailabilityCalendar' },
    })

    window.matchMedia('(prefers-color-scheme: dark)').addListener(handleDarkMode)

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeListener(handleDarkMode)
    }
  }, [])

  useEffect(() => {
    console.log('NEW USER: ', newUser)

    if (newUser) {
      setShowTabs && setShowTabs(false)
    }
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'TipsAvailabilityCalendar',
      },
    })
  }

  function renderFooter (): JSX.Element | undefined {
    if (newUser || onNext) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            testId='next-button'
            onClick={() => {
              logTap({ component: 'FooterButton', button: 'GotIt' })

              if (updateStoreTips) {
                updateStoreTips({ tipsAvailabilityCalendar: { completed: true } })
              }

              onNext && onNext()
            }}>
            Got It
          </FooterButton>
        </IonFooter>
      )
    }
  }

  // function renderHeader (): JSX.Element {
  //   if (lastSlide || !onNext) {
  //     return (
  //       <ScreenHeader
  //         title={title}
  //         onBack={goBack} />
  //     )
  //   }

  //   return (
  //     <ScreenHeader
  //       title={title}
  //       nextButton='Skip'
  //       onBack={goBack}
  //       onNext={onNext} />
  //   )
  // }

  // for now disable the skip button
  function renderHeader (): JSX.Element {
    return (
      <ScreenHeader
        title={title}
        onBack={newUser ? undefined : goBack} />
    )
  }

  function renderToast (): JSX.Element | undefined {
    if (showToast) {
      return (
        <IonToast
          isOpen={showToast}
          color='warning'
          onDidDismiss={() => setShowToast(false)}
          message='Scroll down to see all the tips'
          duration={2000} />
      )
    }
  }

  return (
    <IonPage>
      {renderHeader()}
      <IonContent>
        <TipSelectTimes darkMode={prefersDark} />
        <OverlappingTimes darkMode={prefersDark} />
        {renderFooter()}
        {renderToast()}
      </IonContent>
    </IonPage>
  )
}

interface ContainerProps {
  darkMode?: boolean;
}

export const TipSelectTimes: React.FC<ContainerProps> = ({ darkMode }) => {
  return (
    <div style={slide}>
      <div style={titleBox}>
        <IonText style={cardTitle}>
          Selecting Your Availability
        </IonText>
      </div>
      <div style={imageBox}>
        <img
          src={`assets/images/myTimes.${darkMode ? 'dark' : 'light'}.png`}
          style={screenShot}
          alt='' />
      </div>
      <div style={textBox}>
        <IonText style={cardMessage}>
          Tap to create time ranges you are available for.
          &nbsp;<b>Your choices are labeled &quot;You&quot;, and shown in <span style={{ color: blue }}>blue</span></b>. Drag the edges to fine-tune your free time.
          {/* <IonIcon
            icon={time}
            style={{ marginLeft: 5, marginBottom: -4, fontSize: 18 }}
            color='medium' /> */}
        </IonText>
      </div>
    </div>
  )
}

export const OverlappingTimes: React.FC<ContainerProps> = ({ darkMode }) => {
  return (
    <div style={slide}>
      <div style={titleBox}>
        <IonText style={cardTitle}>
          Overlapping Times
        </IonText>
      </div>

      <div style={imageBox}>
        <img
          src={`assets/images/OtherTimes.${darkMode ? 'dark' : 'light'}.png`}
          style={screenShot}
          alt='' />
      </div>

      <div style={messageBox}>
        {/* <IonItem lines='none'>
          <IonThumbnail
            slot='start'
            style={{ height: 30, width: 50 }}>
            <img
              src='assets/images/MyTime.png'
              alt='' />
          </IonThumbnail>
          <IonLabel
            className='ion-text-wrap'
            style={{ fontSize: 15 }}>
            Your time choices are shown in <span style={{ color: blue }}>blue</span> and labeled <b><span style={{ color: blue }}>{textYou}</span></b>
          </IonLabel>
        </IonItem> */}
        <IonItem lines='none'>
          <IonThumbnail
            slot='start'
            style={{ height: 30, width: 50 }}>
            <img
              src='assets/images/OthersTime.png'
              alt='' />
          </IonThumbnail>
          <IonLabel
            className='ion-text-wrap'
            style={{ fontSize: 15 }}>
            Another participant&apos;s choice
          </IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonThumbnail
            slot='start'
            style={{ height: 30, width: 50 }}>
            <img
              src='assets/images/MeAndOthers.png'
              alt='' />
          </IonThumbnail>
          <IonLabel
            className='ion-text-wrap'
            style={{ fontSize: 15 }}>
            A time that is common among <span style={{ color: blue }}>you</span> and others
          </IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonThumbnail
            slot='start'
            style={{ height: 30, width: 50 }}>
            <img
              src='assets/images/OthersTrending.png'
              alt='' />
          </IonThumbnail>
          <IonLabel
            className='ion-text-wrap'
            style={{ fontSize: 15 }}>
            <IonIcon
              icon={trendingUp}
              style={{ marginRight: 5, marginBottom: -4, fontSize: 18, color: logoColor }} />
            A&nbsp;<span style={{ color: logoColor }}>trending</span> time that is earliest and most common among 3 others
          </IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonThumbnail
            slot='start'
            style={{ height: 30, width: 50 }}>
            <img
              src='assets/images/MeAndOthersTrending.png'
              alt='' />
          </IonThumbnail>
          <IonLabel
            className='ion-text-wrap'
            style={{ fontSize: 15 }}>
            A&nbsp;<span style={{ color: logoColor }}>trending</span> time that is common for you and 2 others
          </IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonThumbnail
            slot='start'
            style={{ height: 30, width: 50 }}>
            <img
              src='assets/images/ConfirmedTime.png'
              alt='' />
          </IonThumbnail>
          <IonLabel
            className='ion-text-wrap'
            style={{ fontSize: 15 }}>
            🎉&nbsp;&nbsp;A&nbsp;<span style={{ color: success }}>confirmed</span> time to meet!
          </IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonThumbnail
            slot='start'
            style={{ height: 30, width: 50 }}>
            <img
              src='assets/images/OthersConfirmed.png'
              alt='' />
          </IonThumbnail>
          <IonLabel
            className='ion-text-wrap'
            style={{ fontSize: 15 }}>
            A&nbsp;<span style={{ color: success }}>confirmed</span> time that you have not marked as available
          </IonLabel>
        </IonItem>
        {/* <IonItem lines='none'>
          <IonThumbnail
            slot='start'
            style={{ height: 30, width: 50 }}>
            <img
              src='assets/images/ConfirmedTime.png'
              alt='' />
          </IonThumbnail>
          <IonLabel
            className='ion-text-wrap'
            style={{ fontSize: 15 }}>
            A&nbsp;<span style={{ color: success }}>confirmed</span> time that you have not marked as available
          </IonLabel>
        </IonItem> */}
      </div>
      <div style={{ marginBottom: 20 }} />
    </div>
  )
}

export const TrendingTimes: React.FC<ContainerProps> = ({ darkMode }) => {
  return (
    <IonSlide style={slide}>
      <img
        src={`assets/images/TrendingTimes.${darkMode ? 'dark' : 'light'}.png`}
        style={screenShot}
        alt='' />
      <div style={textBox}>
        <IonText style={cardTitle}>
          Trending Times
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            Until all participants have chosen their times, the
            <b> earliest 3 times that work for most number of participants are marked as Trending and highlighted with <span style={{ color: logoColor }}>orange</span>. </b>
            Note that Robin and Ranjit can do 9AM on Tuesday but you are not available then. We are waiting for Holger to pick his times.
            <IonIcon
              icon={time}
              style={{ marginLeft: 5, marginBottom: -4, fontSize: 18 }}
              color='medium' />
          </IonText>
        </div>
      </div>
      <div
        style={swipeLeft}>
        <AnimatedSwipeLeft
          delay={2000}
          loop />
      </div>
    </IonSlide>
  )
}

export const ConfirmedTime: React.FC<ContainerProps> = ({ darkMode }) => {
  const text = 'Woohoo! Holger has now picked his times and MeetingTime found a time that works for everyone. '

  return (
    <IonSlide style={slide}>
      <img
        src={`assets/images/ConfirmedTime.${darkMode ? 'dark' : 'light'}.png`}
        style={screenShot}
        alt='' />
      <div style={textBox}>
        <IonText style={cardTitle}>
          Confirmed Time
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            {text}
          </IonText>
        </div>
      </div>
    </IonSlide>
  )
}

export default TipsAvailabilityCalendar
