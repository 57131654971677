import React, { CSSProperties, useState, useRef, useEffect } from 'react'
import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar, isPlatform,
  IonButtons, IonButton, IonIcon, IonToast, IonFooter, IonFab, IonFabButton, IonText, IonLabel,
} from '@ionic/react'

import './ChooseAvailability.css'

import ParticipantAvatarsRow from 'components/organisms/ParticipantAvatarsRow/ParticipantAvatarsRow'
import { chevronBackOutline, createOutline, pencil, people } from 'ionicons/icons'

import ScheduleCalendar from 'components/organisms/ScheduleCalendar/ScheduleCalendar'
import { useTabs } from 'navigation/TabsContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useTips } from 'context/TipsContext/TipsContext'
import { ChooseAvailability as Tips } from 'services/store/tips/types'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import ScheduleCalendarList from 'components/organisms/ScheduleCalendarList/ScheduleCalendarList'
import { useConnections } from 'context/Connections/ConnectionsContext'
import { useScreens } from 'context/ScreensContext/ScreensContext'
import Fireworks from 'components/atoms/Fireworks/Fireworks'
import { ChooseAvailabilityTips, ComponentTips } from 'types/componentTips'
import { NotifyTypes, useMeetingNotify } from 'context/MeetingNotifyContext/MeetingNotifyContext'
import ScreenTipsContext from 'context/ScreenTipsContext/ScreenTipsContextNew'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import { useUser } from 'context/UserContext/UserContext'
import { useContacts } from 'context/ContactsContext/ContactsContext'
import { MeetingBookCalendar, useCalendars } from 'context/CalendarsContext/CalendarsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import ParticipantDetailsModal from 'components/organisms/ParticipantDetailsModal'
import GetNotifiedModal from 'components/organisms/GetNotifiedModal/GetNotifiedModal'
import NewUserAddContactModal from 'components/organisms/NewUserAddContactModal/NewUserAddContactModal'
import { ScreenTipCard } from 'components/molecules/ScreenTipCard/ScreenTipCard'
import InviteParticipantsReminderModal from 'components/organisms/InviteParticipantsReminderModal/InviteParticipantsReminderModal'
import { withinDayRanges, withinTimeRanges } from 'components/organisms/ScheduleCalendar/utils'
import { useStoreParticipants } from 'context/StoreParticipantsContext/StoreParticipantsContext'
import { EventData, EventName, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'
import ScreenLogEventContext from 'context/ScreenLogEventContext/ScreenLogEventContext'
import AddToDeviceCalendarModal from 'components/organisms/AddToDeviceCalendarModal/AddToDeviceCalendarModal'
import { DeviceCalendarAction } from 'services/api'
import moment from 'moment'
import { minutesToString } from 'services/time'
// const minTipButton: CSSProperties = {
//   borderRadius: 27,
//   border: `1px solid ${logoColor}`,
// }

const calendarBox: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}

interface ComponentProps {
  newUser?: boolean;
  goBackTo?: string;
  onMeetingSettings: () => void;
  onMeetingInvite: (byLink: boolean) => void;
  onCalendarAccounts: () => void;
  onSettings: () => void;
  onAddContact: (url?: string) => void;
  showList?: boolean;
  onDayAvailability: (selectedTime: string) => void;
  onParticipants: () => void;
  goBack: () => void;
}
// let appTimer: number

const ChooseAvailability: React.FC<ComponentProps> = (
  { showList = false, newUser, onParticipants, onMeetingSettings, onMeetingInvite, onDayAvailability, onAddContact, goBackTo, goBack }) => {
  // const [reloadCalendar, setReloadCalendar] = useState(0)
  const [showTip, setShowTip] = useState(false)
  // const [showScreenTip, setShowScreenTip] = useState(ChooseAvailabilityTips.noTip)
  const [showScreenTip, setShowScreenTip] = useState<ComponentTips>({})
  const [tipCounter, setTipCounter] = useState(0)
  const [minTip, setMinTip] = useState(false)
  const [editCalendar, setEditCalendar] = useState(false)
  const [showInviteReminder, setShowInviteReminder] = useState(false)
  const [showGetNotifiedModal, setShowGetNotifiedModal] = useState(false)
  const [showNewUserAddContactModal, setShowNewUserAddContactModal] = useState(false)
  const [showBackButton, setShowBackButton] = useState(false)
  const [showAddToCalendarModal, setShowAddToCalendarModal] = useState(false)
  const [showParticipantDetails, setShowParticipantDetails] = useState('')
  const [showToast, setShowToast] = useState('')
  const [bookCalendar, setBookCalendar] = useState<MeetingBookCalendar>()
  const [showListView] = useState(showList)
  const { showTabs, setShowTabs } = useTabs()
  const pageRef = useRef()
  const { meeting, meetings } = useMeetings()
  const { calendars, loading: calendarsLoading, getMeetingCalendar } = useCalendars()
  const { user } = useUser()
  const { participant, participants, updateParticipant } = useParticipants()
  const { storeTips, updateStoreTips } = useTips()
  const { timeSlots } = useTimeSlots()
  const { connections } = useConnections()
  const { contacts } = useContacts()
  const { screens } = useScreens()
  const { showFireworks, setShowNotify, setShowFireworks } = useMeetingNotify()
  const { storeParticipants, updateStoreParticipants } = useStoreParticipants()
  const { logEvent } = useAnalytics()
  // function setAppResumeTimer (): void {
  //   if (!appTimer) {
  //     appTimer = new Date().getTime()

  //     setInterval(function () {
  //       const now = new Date().getTime()

  //       if (now - appTimer > 3000) { // if it's been more than 5 seconds
  //         console.log('APP RESUMED')
  //         setReloadCalendar(now)

  //         if (refetch) {
  //           refetch()
  //         }
  //       }

  //       appTimer = now
  //     }, 1000)
  //   }
  // }

  // const newInvitee = screens?.allScreens?.newInvitee
  // const newInitiator = screens?.allScreens?.newInitiator
  // const newUser = screens?.allScreens?.newUser

  useEffect(() => {
    setTimeout(() => {
      if (screens?.chooseAvailability?.startEdit) {
        setShowTip(true)
        startEditCalendar()
      } else if (newUser) {
        setShowTip(true)
      }
    }, 2000)
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'ChooseAvailability', meetingId: meeting?.id },
    })

    // disable tab bar to get max calendar view
    setShowTabs && setShowTabs(false)
  }, [])

  useEffect(() => {
    console.log('NEW USER: ', newUser)

    if (newUser) {
      setShowTabs && setShowTabs(false)
    }
  }, [newUser])

  useEffect(() => {
    // console.log('MEETING PARTICIPANTS: ', participants?.length)

    if (!calendarsLoading && participant && participants) {
      getBookCalendar()
      // else if (calendars &&
      // participants?.length === 1 &&
      // timeSlots?.length === 0 &&
      // !screens?.chooseAvailability?.disableAutoEdit) {
      //   startEditCalendar()
      // }
    }
  }, [calendarsLoading, calendars, participant, participants, timeSlots])

  useEffect(() => {
    if (participant?.readyToSchedule) {
      if (!participant.readyToSchedule.ready && storeParticipants && updateStoreParticipants) {
        if (!storeParticipants.notReadyToSchedule?.find(id => participant.id === id)) {
          updateStoreParticipants({
            notReadyToSchedule: storeParticipants.notReadyToSchedule
              ? [...storeParticipants.notReadyToSchedule, participant.id] : [participant.id],
          })
        }
      }
    }
  }, [participant])

  const confirmed = meeting?.meetingTimes?.find(meetingTime => meetingTime.status === 'confirmed')
  // const userTimeSlot = (user && timeSlots) ? timeSlots.find(s => s.userId=== user.id) : undefined
  const otherTimeSlot = (user && timeSlots) ? timeSlots.find(s => s.userId !== user.id) : undefined

  // const invitees = meetingInvitees?.filter(invitee => {
  //   if (invitee.status === InviteeStatus.declined) {
  //     return false
  //   }

  //   return true
  // })

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'ChooseAvailability',
        userId: user?.id,
        meetingId: meeting?.id,
      },
    })
  }

  async function getBookCalendar(): Promise<void> {
    if (participant && getMeetingCalendar) {
      const calendar = await getMeetingCalendar(participant)

      if (calendar) {
        setBookCalendar(calendar)

        if (calendar.autoBook && !confirmed) {
          setShowNotify && setShowNotify(NotifyTypes.autoBookOn)

          return
        }
      }

      // auto enable edit mode only if the selectTimes tip has been shown
      if (participants?.length === 1 && timeSlots?.length === 0 &&
        !screens?.chooseAvailability?.disableAutoEdit &&
        storeTips?.chooseAvailability?.selectTimes) {
        startEditCalendar()
      }
    }
  }

  async function setReadyToSchedule(): Promise<void> {
    if (participant && updateParticipant) {
      if (participant.readyToSchedule && !participant.readyToSchedule.ready) {
        await updateParticipant({
          id: participant.id,
          readyToSchedule: true,
        })
      }
    }
  }

  function updateTip(tip: Tips): void {
    if (updateStoreTips) {
      updateStoreTips({
        chooseAvailability: {
          ...storeTips?.chooseAvailability, ...tip,
        },
      })
    }
  }

  function startEditCalendar(): void {
    if (!editCalendar) {
      setEditCalendar(true)
      setShowToast('You are now editing your times')
    }
  }
  // function renderConfirmedTimeNotify (): JSX.Element | undefined {
  //   return (
  //     <ConfirmedTimeNotify
  //       show={showNotify === NotifyTypes.confirmedTime}
  //       message={notifyMessage}
  //       onButton={() => {
  //         setShowNotify && setShowNotify(NotifyTypes.none)
  //         setEditCalendar(false)

  //         if (contacts && !contacts.length) {
  //           onAddContact()
  //         }
  //       }} />
  //   )
  // }

  // function renderDoneSelectingTimesNotify (): JSX.Element | undefined {
  //   if (newInitiator || participants?.length === 1) {
  //     const message = 'You\'re all set to invite people to this meeting. You will be notified when everyone has selected their times and MeetingTime finds a time that works for everybody. You can come back to this screen at any time and change your availability.'

  //     return (
  //       <DoneSelectingTimesNotify
  //         show={showNotify === NotifyTypes.doneSelectingTimes}
  //         message={message}
  //         onButton={() => {
  //           setShowNotify && setShowNotify(NotifyTypes.none)

  //           if (participants?.length === 1) {
  //             setShowAddParticipants(true)
  //           }
  //           // let users tap on the invite button
  //           // if (connections?.length) {
  //           //   onMeetingInvite(false)
  //           // } else {
  //           //   console.log('chooseAvailability: onMeetingInvite')
  //           //   onMeetingInvite(true)
  //           // }
  //         }} />
  //     )
  //   } else {
  //     const message = 'You are done for now! You will be notified when everyone has selected their times and MeetingTime finds a time that works for everybody. You can come back to this screen at any time and change your availability for this meeting.'

  //     return (
  //       <DoneSelectingTimesNotify
  //         show={showNotify === NotifyTypes.doneSelectingTimes}
  //         message={message}
  //         goBackTo={paths.meetingsList}
  //         onButton={() => {
  //           setShowNotify && setShowNotify(NotifyTypes.none)
  //         }} />
  //     )
  //   }
  // }

  // function renderSelectAllTimesNotify (): JSX.Element | undefined {
  //   return (
  //     <SelectAllTimesNotify
  //       show={showNotify === NotifyTypes.selectAllTimes}
  //       onButton={() => {
  //         setShowNotify && setShowNotify(NotifyTypes.none)
  //       }} />
  //   )
  // }

  // function renderAutoBookNotify (): JSX.Element | undefined {
  //   if (showNotify === NotifyTypes.autoBookOn && bookCalendar?.autoBook && participants) {
  //     let message = 'You have automagic scheduling ON! MeetingTime will use your up-to-date availability and find a time for this meeting based on everyone\'s choices.'

  //     if (participants.length < 2) {
  //       message += '\nYou are all set to invite people to this meeting.'
  //     }

  //     return (
  //       <AutoBookNotify
  //         show={showNotify === NotifyTypes.autoBookOn}
  //         message={message}
  //         onButton={() => {
  //           setShowNotify && setShowNotify(NotifyTypes.none)
  //         }} />
  //     )
  //   }
  // }

  function renderFireworks(): JSX.Element | undefined {
    if (showFireworks) {
      return (
        <div
          style={{
            position: 'absolute',
            bottom: '30%',
            background: 'transparent',
          }}>
          <Fireworks
            onComplete={() => setShowFireworks && setShowFireworks(false)} />
        </div>
      )
    }
  }

  // function onDone (): void {
  //   if (screens && updateScreens) {
  //     if (newInvitee) {
  //       updateScreens({ allScreens: { ...screens.allScreens, newInvitee: false } })
  //     }

  //     if (newInitiator) {
  //       updateScreens({ allScreens: { ...screens.allScreens, newInitiator: false } })
  //     }
  //   }

  //   if (goBackTo && !newInvitee) {
  //     if (connections?.length) {
  //       onMeetingInvite(false)
  //     } else {
  //       console.log('chooseAvailability: onMeetingInvite')
  //       onMeetingInvite(true)
  //     }
  //   }
  // }

  // function renderNext (): JSX.Element | undefined {
  //   if (newInvitee || newInitiator) {
  //     return (
  //       <IonButton
  //         routerLink={newInvitee ? goBackTo : undefined}
  //         onClick={() => {
  //           console.log('chooseAvailability: onNext goBackTo: ', goBackTo)

  //           if (screens && updateScreens) {
  //             if (newInvitee) {
  //               updateScreens({ allScreens: { ...screens.allScreens, newInvitee: false } })
  //             }

  //             if (newInitiator) {
  //               updateScreens({ allScreens: { ...screens.allScreens, newInitiator: false } })
  //             }
  //           }

  //           if (goBackTo && !newInvitee) {
  //             if (connections?.length) {
  //               onMeetingInvite(false)
  //             } else {
  //               console.log('chooseAvailability: onMeetingInvite')
  //               onMeetingInvite(true)
  //             }
  //           }
  //         }}
  //         color='primary'>
  //         Next
  //       </IonButton>
  //     )
  //   }
  // }

  // function resetNewUserFlags (): void {
  //   if (screens && screens.allScreens && updateScreens) {
  //     const updatedAllScreens = screens.allScreens

  //     if (newInvitee) {
  //       updatedAllScreens.newInvitee = false
  //     }

  //     if (newInitiator) {
  //       updatedAllScreens.newInitiator = false
  //     }

  //     if (newUser) {
  //       updatedAllScreens.newUser = false
  //     }

  //     updateScreens({ allScreens: updatedAllScreens })
  //   }
  // }

  function renderHeader(): JSX.Element {
    return (
      <IonHeader>
        <IonToolbar>
          {(!newUser || showBackButton) &&
            <IonButtons slot='start'>
              <IonButton
                data-ci-id='back-button'
                fill='clear'
                routerLink={goBackTo}
                routerDirection='back'
                onClick={() => {
                  // setShowTip(TipTypes.noTip)

                  // resetNewUserFlags()

                  if (!showTabs) {
                    setShowTabs && setShowTabs(true)
                  }

                  !goBackTo && goBack && goBack()
                }}>
                <IonIcon
                  slot='icon-only'
                  icon={chevronBackOutline}
                  color='dark' />
              </IonButton>
            </IonButtons>}
          <IonTitle
            data-ci-id='meeting-title'
            onClick={() => {
              logTap({ component: 'IonTitle', button: 'MeetingTitle' })
              onMeetingSettings()
            }}>
            {meeting?.title} <br />
            {meeting?.duration &&
              <IonText
                color='medium'
                style={{ fontSize: 12 }}>
                {minutesToString(meeting?.duration)}
              </IonText>}
          </IonTitle>
          <IonButton
            data-ci-id='meeting-settings-button'
            fill='clear'
            size='small'
            slot='end'
            onClick={onMeetingSettings}>
            <IonIcon
              slot='icon-only'
              icon={createOutline} />
          </IonButton>
        </IonToolbar>
      </IonHeader>
    )
  }

  function renderCalendar(): JSX.Element | undefined {
    if (meeting?.id) {
      return (
        <div
          style={calendarBox}>
          <ParticipantAvatarsRow
            autoBook={bookCalendar?.autoBook}
            tip={!minTip && showScreenTip?.chooseAvailability === ChooseAvailabilityTips.invitePeople}
            onParticipant={participant => {
              logTap({ button: 'Participant', component: 'ParticipantAvatarsRow' })
              // setShowParticipantDetails(participant)
              onParticipants()
            }}
            onInvite={(byLink) => {
              logTap({ button: 'AddParticipant', component: 'ParticipantAvatarsRow' })

              if (byLink) {
                onMeetingInvite(true)
              } else if (connections?.length) {
                const notParticipanting = connections.filter(connection => {
                  if (participants?.find(p => p.userId === connection.connectedToUserId)) {
                    return false
                  }

                  return true
                })

                if (notParticipanting.length) {
                  onMeetingInvite(false)
                } else {
                  onMeetingInvite(true)
                }
              } else {
                onMeetingInvite(true)
              }

              updateTip({ invitePeople: true })
              // setNextTip(showScreenTip + 1)
            }} />
          <ScheduleCalendar
            tip={!minTip && showScreenTip?.chooseAvailability === ChooseAvailabilityTips.selectTimes}
            hide={showListView}
            meeting={meeting}
            meetings={meetings}
            editCalendar={editCalendar}
            onStartEditCalendar={() => {
              logTap({ component: 'ScheduleCalendar', button: 'StartEdit' })
              setEditCalendar(true)
            }}
            onSelectDay={(time) => {
              logTap({ component: 'ScheduleCalendar', button: 'SelectDay' })
              console.log('selected day: ', time)
              onDayAvailability(time)
            }}
            onSelectTime={(time) => {
              // if (screens?.allScreens?.newInitiator) {
              //   updateTip({ selectTimesInitiator: true })
              // } else if (screens?.allScreens?.newInvitee) {
              //   updateTip({ selectTimesInvitee: true })
              // }

              // updateTip({ othersTimes: true })
              // setNextTip(showScreenTip + 1)
              logTap({ component: 'ScheduleCalendar', button: 'SelectTime' })
              onDayAvailability(time)
            }} />
          {showListView &&
            <div
              style={{
                flex: 1,
                overflowY: 'scroll',
              }}>
              <ScheduleCalendarList />
            </div>}
        </div>
      )
    }
  }

  // function renderFooter (): JSX.Element | undefined {
  //   if (!bookCalendar?.autoBook && showNotify !== NotifyTypes.doneSelectingTimes && meetingId) {
  //     if (!confirmed) {
  //       if (showAddParticipants) {
  //         return (
  //           <IonFooter className='screenFooterButton'>
  //             <FooterButton
  //               onClick={() => onMeetingInvite(false)}>
  //               Invite Participants
  //             </FooterButton>
  //           </IonFooter>
  //         )
  //       } else {
  //         const txt = 'I\'m done selecting all my Times'

  //         return (
  //           <IonFooter className='screenFooterButton'>
  //             <FooterButton
  //               onClick={() => {
  //                 if (user && timeSlots && setShowNotify) {
  //                   const selectedTime = timeSlots.find(timeSlot => timeSlot.userId=== user.id)

  //                   if (selectedTime) {
  //                     resetNewUserFlags()

  //                     setShowNotify(NotifyTypes.doneSelectingTimes)

  //                     return
  //                   }

  //                   setShowNotify(NotifyTypes.selectAllTimes)
  //                 }
  //               }}>
  //               {txt}
  //             </FooterButton>
  //           </IonFooter>
  //         )
  //       }
  //     }
  //   }
  // }

  function showNextModal(): void {
    if (meeting && participant) {
      if (!bookCalendar?.autoBook) {
        const { deviceCalendar } = participant

        console.log('device calendar: ', deviceCalendar)
        console.log('meetingId: ', meeting)

        if (deviceCalendar &&
          deviceCalendar?.action === DeviceCalendarAction.updated &&
          moment(deviceCalendar.updateTime).isBefore(meeting.updateTime) && !isPlatform('android')) {
          setShowAddToCalendarModal(true)
        } else if (confirmed && (!deviceCalendar ||
          deviceCalendar.action === DeviceCalendarAction.deleted) && !isPlatform('android')) {
          setShowAddToCalendarModal(true)
        } else if (contacts && !contacts.length) {
          setShowGetNotifiedModal(true)
        }
      }
    }
  }

  function renderFooter(): JSX.Element | undefined {
    if (user && timeSlots && participants) {
      // const selectedTime = timeSlots.find(timeSlot => timeSlot.userId=== user.id)
      const setupNotifications = !contacts?.length
      const inviteParticipants = meeting?.participantsExpected === 1

      const selectedTime =
        timeSlots?.filter(timeSlot => timeSlot.userId === user.id)
          .filter(t => withinDayRanges(t.startTime, t.endTime, meeting?.dayRanges))
          .find(t => {
            if (!meeting?.timeRanges?.length) {
              // if meeting.timeRanges are not set then all time slots withinDayRanges are valid
              return true
            }

            if (withinTimeRanges(t.startTime, meeting?.timeRanges) || withinTimeRanges(t.endTime, meeting?.timeRanges)) {
              return true
            }
          })

      if (editCalendar) {
        return (
          <IonFooter className='screenFooterButton'>
            <FooterButton
              testId='done-edit-calendar'
              onClick={() => {
                logTap({ button: 'DoneEditCalendar', component: 'FooterButton' })
                setEditCalendar(false)
                setShowToast('')
                setShowTip(false)
                setReadyToSchedule()

                if (inviteParticipants && !confirmed) {
                  setShowInviteReminder(true)
                } else if (!inviteParticipants) {
                  showNextModal()
                }
              }}>
              Done
            </FooterButton>
          </IonFooter>
        )
      }

      if (!bookCalendar?.autoBook) {
        if (selectedTime) {
          return (
            <IonFooter className='screenFooterButton'>
              {/* <FooterButton
                fill='outline'
                onClick={() => {
                  startEditCalendar()
                }}>
                <IonIcon icon={create} />Change Your Avalability
              </FooterButton> */}
              {inviteParticipants && !confirmed && !showInviteReminder &&
                <FooterButton
                  onClick={() => {
                    logTap({ button: 'InviteParticipants', component: 'FooterButton' })
                    onMeetingInvite(!connections?.length)
                  }}>
                  <IonIcon
                    icon={people}
                    style={{ marginRight: 5 }} />
                  Invite Participants
                </FooterButton>}
              {setupNotifications && !inviteParticipants && !showGetNotifiedModal &&
                <FooterButton
                  fill='solid'
                  onClick={() => {
                    logTap({ button: 'SetupNotification', component: 'FooterButton' })
                    setShowNewUserAddContactModal(true)
                  }}>
                  Setup Notifications
                </FooterButton>}
            </IonFooter>
          )
        }

        // return (
        //   <IonFooter className='screenFooterButton'>
        //     <FooterButton
        //       fill='outline'
        //       onClick={() => {
        //         startEditCalendar()
        //       }}>
        //       <IonIcon icon={create} />Select Your Avalability
        //     </FooterButton>
        //   </IonFooter>
        // )
      } else {
        // return (
        //   <IonFooter className='screenFooterButton'>
        //     <FooterButton
        //       fill='outline'
        //       onClick={() => {
        //         startEditCalendar()
        //       }}>
        //       <IonIcon icon={create} />Change Your Availability
        //     </FooterButton>
        //   </IonFooter>
        // )
      }
    }
  }

  // function renderScreenTips (): JSX.Element | undefined {
  //   return (
  //     <ScreenTips />
  //   )
  // }

  function renderEditButton(): JSX.Element | undefined {
    if (!editCalendar) {
      return (
        <IonFab
          vertical='bottom'
          horizontal='end'
          slot='fixed'>
          <IonFabButton
            color='logocolor'
            data-ci-id='edit-fab'
            onClick={() => {
              logTap({ component: 'FabButton', button: 'EditCalendar' })
              startEditCalendar()
            }}>
            <IonIcon
              icon={pencil}
              color='light' />
          </IonFabButton>
        </IonFab>
      )
    }
  }

  function renderTip(): JSX.Element | undefined {
    if (showTip && !storeTips?.chooseAvailability?.selectTimes) {
      if (!otherTimeSlot) {
        const text = 'Tap anywhere on the calendar to select your availability. Please select all your available times.'
        const subtext = 'Drag block edges to change the durations. Times outside the meeting\'s set time range are marked unavailable.'

        return (
          <ScreenTipCard
            show
            text={text}
            subtext={subtext}
            onClose={() => {
              logTap({ component: 'ScreenTipCard', button: 'Close' })
              setShowTip(false)
              updateTip({ selectTimes: true })
              startEditCalendar()
            }}
            buttonLabel='got it'
            onButton={() => {
              logTap({ component: 'ScreenTipCard', button: 'GotIt' })
              setShowTip(false)
              updateTip({ selectTimes: true })
              startEditCalendar()
            }} />
        )
      } else {
        const text = 'Tap edit button on bottom right and then anywhere on the calendar to select your preferred times. Please select all your available times.'
        const subtext = 'Drag block edges to change the durations. Once you have selected your availability, tap "Done".'

        return (
          <ScreenTipCard
            show
            text={text}
            subtext={subtext}
            onClose={() => {
              logTap({ component: 'ScreenTipCard', button: 'Close' })
              setShowTip(false)
              updateTip({ selectTimes: true })
            }}
            buttonLabel='got it'
            onButton={() => {
              logTap({ component: 'ScreenTipCard', button: 'GotIt' })
              setShowTip(false)
              updateTip({ selectTimes: true })
            }} />
        )
      }
    }
  }

  function renderParticipantDetailsModal(): JSX.Element | undefined {
    if (showParticipantDetails) {
      return (
        <ParticipantDetailsModal
          presentingElement={pageRef.current}
          meetingParticipant={showParticipantDetails}
          onClose={() => {
            logTap({ component: 'ParticipantDetailsModal', button: 'Close' })
            setShowParticipantDetails('')
          }} />
      )
    }
  }

  function renderGetNotifiedModal(): JSX.Element | undefined {
    if (showGetNotifiedModal) {
      return (
        <GetNotifiedModal
          presentingElement={pageRef.current}
          onGetNotified={() => {
            logTap({ component: 'GetNotifiedModal', button: 'GetNotified' })
            setShowGetNotifiedModal(false)
            setShowNewUserAddContactModal(true)
          }}
          onClose={() => {
            logTap({ component: 'GetNotifiedModal', button: 'Close' })
            setShowBackButton(true)
            setShowGetNotifiedModal(false)
          }} />
      )
    }
  }

  function renderNewUserAddContactModal(): JSX.Element | undefined {
    if (showNewUserAddContactModal) {
      return (
        <NewUserAddContactModal
          newUser={newUser ? 'invitee' : 'initiator'}
          onAddContact={(url: string) => {
            logTap({ component: 'NewUserAddContactModal', button: 'AddContact' })
            onAddContact(url)
          }}
          presentingElement={pageRef.current}
          onClose={() => {
            logTap({ component: 'NewUserAddContactModal', button: 'Close' })
            setShowBackButton(true)
            setShowNewUserAddContactModal(false)
          }} />
      )
    }
  }

  function renderInviteReminder(): JSX.Element | undefined {
    if (showInviteReminder) {
      return (
        <InviteParticipantsReminderModal
          presentingElement={pageRef.current}
          onInviteParticipants={() => {
            logTap({ component: 'InviteParticipantsReminderModal', button: 'InviteParticipants' })
            setShowInviteReminder(false)
            // give it some time for the modal to get dismissed and then navigate to next screen
            // else we get can't update state when component is unmounted warning
            setTimeout(() => {
              onMeetingInvite(!connections?.length)
            }, 1000)
          }}
          onClose={() => {
            logTap({ component: 'InviteParticipantsReminderModal', button: 'Close' })
            setShowInviteReminder(false)
          }} />
      )
    }
  }

  function renderAddToCalendarModal(): JSX.Element | undefined {
    if (showAddToCalendarModal) {
      return (
        <AddToDeviceCalendarModal
          presentingElement={pageRef.current}
          onAddToCalendar={() => {
            logTap({ component: 'AddToDeviceCalendarModal', button: 'AddToCalendar' })
            setShowAddToCalendarModal(false)

            if (contacts && !contacts.length) {
              setShowGetNotifiedModal(true)
            }
          }}
          onClose={() => {
            logTap({ component: 'AddToDeviceCalendarModal', button: 'Close' })
            setShowAddToCalendarModal(false)

            if (contacts && !contacts.length) {
              setShowGetNotifiedModal(true)
            }
          }} />
      )
    }
  }
  // function renderScreenTips (): JSX.Element | undefined {
  //   return (
  //     <ScreenTips
  //       minTip={minTip}
  //       showScreenTip={showScreenTip}
  //       onMinTip={() => {
  //         setMinTip(false)

  //         // if (!componentStoreTips?.endTips) {
  //         //   updateTip({ endTips: false })
  //         // }
  //         // if we've not shown endTips then go ahead and start the sequence immediately
  //         if (!componentStoreTips?.endTips) {
  //           setNextTip(ChooseAvailabilityTips.sequence)
  //         }
  //       }}
  //       onButton={(restartTips) => {
  //         if (showScreenTip === ChooseAvailabilityTips.endTips) {
  //           if (restartTips) {
  //             console.log('Restart TIPS!')
  //             setNextTip(ChooseAvailabilityTips.sequence, true)
  //           } else {
  //             setNextTip(ChooseAvailabilityTips.noTip)
  //             setMinTip(true)
  //           }
  //         } else {
  //           if (showScreenTip === ChooseAvailabilityTips.tentativeTimes) {
  //             // note that redux store update takes time so we can't depend
  //             // on the tentativeTimes state to change so skip tentativeTimesAuto tip
  //             setNextTip(showScreenTip + 2)
  //           } else {
  //             setNextTip(showScreenTip + 1)
  //           }
  //         }
  //       }} />
  //   )
  // }

  return (
    <ScreenTipsContext.Provider
      value={{ showScreenTip, minTip, tipCounter, setShowScreenTip, setMinTip, setTipCounter }}>
      <ScreenLogEventContext.Provider
        value={{ logTapEvent: logTap }}>
        <IonPage ref={pageRef}>
          {renderHeader()}
          <IonContent
            scrollY={false}
            scrollX={false}>
            {renderCalendar()}
            {renderFireworks()}
            {renderEditButton()}
          </IonContent>
          {renderFooter()}
          {renderTip()}
          {renderParticipantDetailsModal()}
          {renderGetNotifiedModal()}
          {renderNewUserAddContactModal()}
          {renderInviteReminder()}
          {renderAddToCalendarModal()}
          {/* {renderConfirmedTimeNotify()} */}
          {/* {renderSelectAllTimesNotify()} */}
          {/* {renderDoneSelectingTimesNotify()} */}
          {/* {renderAutoBookNotify()} */}
          <IonToast
            isOpen={!!showToast}
            color='warning'
            onDidDismiss={() => setShowToast('')}
            message={showToast}
            position='bottom'
            duration={1000} />
        </IonPage>
      </ScreenLogEventContext.Provider>
    </ScreenTipsContext.Provider>
  )
}

export default ChooseAvailability
