import React, { CSSProperties } from 'react'
import { closeOutline } from 'ionicons/icons'
import { TimeRange } from 'types'
import { dayRangesToString } from 'services/time'

import ListItem from 'components/atoms/ListItem/ListItem'

const container: CSSProperties = {
  marginTop: 10,
}

export interface MeetingDaysProps {
  title?: string
  dayRanges: TimeRange[]
  tip?: boolean
  onClick?: () => void
  onClearRange?: () => void
}

const MeetingDays: React.FC<MeetingDaysProps> = ({ title, dayRanges, tip, onClick, onClearRange }) => {
  const day = dayRangesToString(dayRanges)
  let hasDetail

  if (onClick) {
    hasDetail = true
  } else if (!onClick && onClearRange) {
    hasDetail = false
  }

  return (
    <div style={container}>
      <ListItem
        testId='day-range'
        title={title}
        tip={tip}
        detail={hasDetail}
        label={day || 'Choose Range'}
        onClick={onClick}
        icon={onClearRange ? { name: closeOutline, size: 'large', slot: 'end', color: 'medium', onClickIcon: onClearRange } : undefined} />
    </div>
  )
}

export default MeetingDays
