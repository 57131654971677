import React from 'react'
import { useSpring } from 'react-spring'

import { ConnectionsScreenTips } from 'types/componentTips'
import TipMinButton from 'components/atoms/TipMinButton/TipMinButton'

import { useTips } from 'context/TipsContext/TipsContext'
import { useAnalytics, EventData, EventName } from 'context/Analytics/AnalyticsContext'
import { ConnectionsScreen } from 'services/store/tips/types'
import ScreenTipCard from 'components/molecules/ScreenTipCard/ScreenTipCard'
import EndTipsCard from 'components/organisms/EndTipsCard/EndTipsCard'

interface ScreenTipsProps {
  minTip?: boolean;
  showScreenTip?: ConnectionsScreenTips;
  onButton?: (restartTips?: boolean) => void;
  onClose?: () => void;
  onMinTip?: () => void;
}

export const ScreenTips: React.FC<ScreenTipsProps> = ({ minTip, showScreenTip, onMinTip, onButton, onClose }) => {
  // console.log('ScreenTips showScreenTip', showScreenTip)

  const { storeTips, updateStoreTips } = useTips()
  const { logEvent } = useAnalytics()

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: { ...eventData },
    })
  }

  function updateTips (tip: ConnectionsScreen): void {
    if (updateStoreTips) {
      updateStoreTips({
        connectionsScreen: {
          ...storeTips?.connectionsScreen, ...tip,
        },
      })
    }
  }

  return (
    <div>
      <TipMin
        show={minTip}
        onButton={() => {
          logTap({ component: 'TipMin', button: 'MinTip' })
          onMinTip && onMinTip() }} />
      <TipSelectPeople
        show={!minTip && showScreenTip === ConnectionsScreenTips.selectPeople}
        onClose={() => {
          logTap({ component: 'TipSelectPeople', button: 'Close' })
          onClose && onClose() }}
        onButton={() => {
          logTap({ component: 'TipSelectPeople', button: 'GotIt' })
          updateTips({ selectPeople: true })
          onButton && onButton()
        }} />
      <EndTips
        show={!minTip && showScreenTip === ConnectionsScreenTips.endTips}
        onClose={() => {
          logTap({ component: 'EndTips', button: 'Close' })
          onClose && onClose() }}
        onButtons={(button) => {
          if (button === 0) {
            logTap({ component: 'EndTips', button: 'Yes' })
            updateStoreTips && updateStoreTips({ connectionsScreen: {} })
            onButton && onButton(true)
          } else {
            logTap({ component: 'EndTips', button: 'No' })
            updateTips({ endTips: true })
            onClose && onClose()
          }
        }} />
    </div>
  )
}

interface ContainerProps {
  show?: boolean;
  onButton?: () => void;
  onClose?: () => void;
  onButtons?: (button: number) => void;
}

export const TipMin: React.FC<ContainerProps> = ({ show, onButton }) => {
  return (
    <TipMinButton
      show={show}
      onClick={onButton} />
  )
}

export const TipSelectPeople: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Easily start a meeting with people you\'ve previously met'
  const subtext = 'Select those you want to invite, and tap "New Meeting". Anyone else can be invited with the meeting link.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

const EndTips: React.FC<ContainerProps> = ({ show, onButtons, onClose }) => {
  const animatedProps = useSpring({
    ...(show ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  const text = 'You\'ve seen all the tips here.'
  const subtext = 'Would you like to see the tips for this screen again?'

  return (
    <EndTipsCard
      animatedProps={animatedProps}
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButtons={onButtons} />
  )
}
