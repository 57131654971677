import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'services/store'
import CalendarSettingsFreeBusy from './CalendarSettingsFreeBusy'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import paths from 'navigation/paths'

const CalendarSettingsFreeBusyScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { calendarSettings } = useSelector((state: RootState) => state.screens)

  function onDone (): void {
    history.push(paths.calendarSettingsDoneAddAnother)
  }

  function onSelectBookCalendar (): void {
    console.log('show auto times screen')
    history.push(paths.calendarSettingsBookCalendar)
  }

  function onSelectAutoTimes (): void {
    history.push(paths.calendarSettingsAutoTimes)
  }

  return (
    <CalendarsProvider>
      <CalendarSettingsFreeBusy
        calendarId={calendarSettings?.calendar}
        onSelectBookCalendar={onSelectBookCalendar}
        onSelectAutoTimes={onSelectAutoTimes}
        onDone={onDone} />
    </CalendarsProvider>
  )
}

export default CalendarSettingsFreeBusyScreen
