import React, { CSSProperties } from 'react'
import { IonIcon, IonItem, IonLabel, IonText, isPlatform } from '@ionic/react'
import { videocamOutline } from 'ionicons/icons'
import { VideoConference } from 'types'

const container: CSSProperties = {
  marginTop: 10,
}

const titleStyle: CSSProperties = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

export interface MeetingConferenceProps {
  videoConference?: VideoConference;
  onClick?: () => void;
}

const MeetingConference: React.FC<MeetingConferenceProps> = ({ videoConference, onClick }) => {
  let label = ''

  if (videoConference?.link) {
    label = videoConference.link.split('https://')[1]
  }

  return (
    <div style={container}>
      <IonText
        color='medium'
        style={titleStyle}>
        VIDEO CONFERENCE
      </IonText>
      <IonItem
        detail={!!onClick}
        onClick={onClick}
        lines='full'
        style={{ marginTop: 5 }}>
        <IonIcon
          icon={videocamOutline}
          style={{ left: -5 }}
          slot='start'
          color='primary'
          size='medium' />
        {label &&
          <IonLabel color='medium'>
            {label}
          </IonLabel>}
        {!label &&
          <IonLabel color='medium'>
            Video Link
          </IonLabel>}
      </IonItem>
    </div>
  )
}

export default MeetingConference
