import React from 'react'
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/react'

import 'theme/styles.css'
import { Meeting, MeetingTime, Participant, TimeSlot, User } from 'types'
import { warning } from 'ionicons/icons'
import { minutesToString, stringToMinutes } from 'services/time'
import moment from 'moment'

const messageStyle = {
  fontSize: 14,
  whiteSpace: 'pre-wrap',
}

export interface MeetingCardStatusProps {
  meeting: Meeting;
  participants: Participant[];
  timeSlots: TimeSlot[];
  tip?: boolean;
  user: User;
  onMeetingTime?: (meeting: Meeting, meetingTime: MeetingTime) => void;
  onClick: () => void;
}

// function participantNames (participants: Participant[], user: User): string {
//   let userIncluded = false
//   const nonUser = participants.filter(p => {
//     if (p.user !== user.id) {
//       return true
//     } else {
//       userIncluded = true
//     }
//   })

//   let names = userIncluded ? (nonUser.length ? 'you and ' : 'you') : ''

//   nonUser.forEach((participant, index) => {
//     if (index) {
//       if (index < participants.length - 1) {
//         names += ', '
//       } else {
//         names += ' and '
//       }
//     }
//     // participant.name.split(' ').find(t => t)?.substring(0, MAX_NAME_LENGTH)

//     const displayName = participant.name.split(' ').find(t => t)

//     names += displayName
//   })

//   return names
// }

const MeetingCardStatus: React.FC<MeetingCardStatusProps> = ({ user, meeting, participants, timeSlots }) => {
  const confirmed = meeting.meetingTimes?.find(meetingTime => meetingTime.status === 'confirmed')

  const duration = Number(stringToMinutes(meeting.duration))

  function renderStatus(): JSX.Element | undefined {
    let status = ''

    if (confirmed) {
      if (confirmed.updatedByUserId) {
        if (confirmed.updatedByUserId === user.id) {
          status = 'You\'ve manually confirmed this time'
        } else {
          const participant = participants.find(p => p.userId === confirmed.updatedByUserId)

          if (participant) {
            const displayName = participant.displayName.split(' ').find(t => t)

            status = `${displayName} has manually confirmed this time`
          }
        }
      }
    } else {
      const notResponded = participants?.filter(participant => {
        if (!timeSlots?.find(timeSlot => timeSlot.userId === participant.userId)) {
          return true
        }
      })

      const userNotResponded = notResponded.find(participant => participant.userId === user.id)

      let expectingInvitees = 0

      if (participants?.length && meeting?.participantsExpected) {
        if (meeting?.participantsExpected > participants?.length) {
          expectingInvitees = meeting.participantsExpected - participants.length
        }
      }

      // more detailed status
      // if (notResponded.length) {
      //   if (userNotResponded && notResponded.length === 1) {
      //     status = 'Waiting for you to choose your time preferences.'
      //   } else {
      //     const names = participantNames(notResponded, user)

      //     status = `Waiting for ${names} to choose time preferences.`
      //   }
      // } else if (expectingInvitees) {
      //   if (expectingInvitees > 1) {
      //     status = `Waiting for ${expectingInvitees.toString()} invitees to choose time preferences.`
      //   } else {
      //     status = 'Waiting for one invitee to choose time preferences.'
      //   }
      // } else {
      //   status = 'Everyone has chosen their time preferences.'
      // }

      // simpler implementation
      if (userNotResponded) {
        if (notResponded.length > 1 || expectingInvitees) {
          status = 'Waiting for you and others to choose time preferences.'
        } else {
          status = 'Waiting for you to choose your time preferences.'
        }
      } else if (notResponded.length || expectingInvitees) {
        status = 'Waiting for others to choose their time preferences.'
      } else {
        status = 'Everyone has responded.'
      }

      // everyone has picked some times
      if (meeting.meetingTimes?.length && !confirmed) {
        if (status) {
          status += '\n'
        }

        status += 'Below are the earliest trending times.'
      }

      if (!notResponded.length && !expectingInvitees) {
        if (meeting.meetingTimes?.length && !confirmed) {
          const meetingTime = meeting.meetingTimes[0]

          if (moment(meetingTime.endTime).diff(moment(meetingTime.startTime), 'minutes') < duration) {
            status = `The earliest trending time range is less than the meeting duration of ${minutesToString(meeting.duration)}. Consider changing the meeting duration or your availability, or select a trending time below and manually confirm the time.`
          } else {
            status = 'There is no time that works for everyone. Consider changing your availability or manually confirm a time.'
          }
        } else {
          status = 'Everyone has picked some times but none are overlapping. Consider changing your availability.'
        }
      }
    }

    if (status) {
      return (
        <IonItem
          lines='none'>
          <IonIcon
            slot='start'
            color='warning'
            icon={warning} />
          <IonLabel
            className='ion-text-wrap'>
            <IonText style={messageStyle}>
              {status}
            </IonText>
          </IonLabel>
        </IonItem>
      )
    }
  }

  if (participants?.length > 1) {
    return (
      <div>
        {renderStatus()}
      </div>
    )
  }

  return null
}

export default MeetingCardStatus
