import React, { useState, CSSProperties, useEffect } from 'react'
import {
  IonSpinner,
  IonContent, IonList, IonPage, IonItem, IonLabel, IonText, IonIcon, IonButton, IonButtons, IonHeader, IonTitle, IonToolbar, IonItemDivider, IonFooter,
} from '@ionic/react'

import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import { Connection } from 'types'

import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useConnections } from 'context/Connections/ConnectionsContext'
import { Avatar } from '@material-ui/core'
import AddParticipantsRow from 'components/organisms/AddParticipantsRow/AddParticipantsRow'
import { ellipseOutline, checkmarkCircle } from 'ionicons/icons'
import AnimatedPeople from 'components/atoms/AnimatedPeople/AnimatedPeople'
import { ScreenTips } from './ScreenTips'
import { ConnectionsScreenTips } from 'types/componentTips'
import { useTips } from 'context/TipsContext/TipsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { EventName, EventData, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

const title = 'People'

const largeHeader =
  <IonHeader collapse='condense'>
    <IonToolbar>
      <IonTitle size='large'>{title}</IonTitle>
    </IonToolbar>
  </IonHeader>

const container: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  paddingLeft: 40,
  paddingRight: 40,
}

const avatarStyle: CSSProperties = {
  width: 36,
  height: 36,
  backgroundColor: '#92949C',
  marginRight: 15,
}

const messageStyle: CSSProperties = {
  fontSize: 16,
  textAlign: 'center',
}

interface ComponentProps {
  onNewMeeting: (addParticipants: string[]) => void
}

const Connections: React.FC<ComponentProps> = ({ onNewMeeting }) => {
  const [showScreenTip, setShowScreenTip] = useState(ConnectionsScreenTips.noTip)
  const [minTip, setMinTip] = useState(false)
  const [selectedParticipants, setSelectedParticipants] = useState<Connection[]>([])
  // const [searchText, setSearchText] = useState('')
  const { loading, connections } = useConnections()
  const { storeTips } = useTips()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    startTips()
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'Connections' },
    })
  }, [])

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'Connections',
      },
    })
  }

  const componentStoreTips = storeTips?.connectionsScreen

  function startTips(): void {
    setTimeout(() => {
      if (storeTips?.startMinTip || componentStoreTips?.endTips) {
        setNextTip(ConnectionsScreenTips.noTip)
      } else {
        setNextTip(ConnectionsScreenTips.sequence)
      }
    }, 1000)
  }

  // function renderSearchBar (): JSX.Element | undefined {
  //   if (!loading && connections) {
  //     return (
  //       <IonSearchbar
  //         value={searchText}
  //         showCancelButton='focus'
  //         onIonChange={e => e.detail.value && setSearchText(e.detail.value)} />
  //     )
  //   }
  // }

  function setNextTip(tip: ConnectionsScreenTips, restartTips?: boolean): void {
    // console.log('Meeting screen setNextTip: ', tip)

    switch (tip) {
      // eslint-disable-next-line no-fallthrough
      case ConnectionsScreenTips.sequence:
      case ConnectionsScreenTips.selectPeople:
        if (restartTips || !componentStoreTips?.selectPeople) {
          if (connections?.length) {
            setShowScreenTip(ConnectionsScreenTips.selectPeople)
            break
          }
        }
      // eslint-disable-next-line no-fallthrough
      case ConnectionsScreenTips.endTips:
        if (!componentStoreTips?.endTips) {
          setShowScreenTip(ConnectionsScreenTips.endTips)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case ConnectionsScreenTips.noTip:
      default:
        if (connections?.length) {
          setShowScreenTip(ConnectionsScreenTips.endTips)
          setMinTip(true)
          break
        }
    }
  }

  // function updateTip (tip: StoreTips): void {
  //   if (updateStoreTips) {
  //     updateStoreTips({
  //       connectionsScreen: {
  //         ...componentStoreTips, ...tip,
  //       },
  //     })
  //   }
  // }

  function renderSelectedConnections(): JSX.Element {
    return (
      <AddParticipantsRow
        connections={selectedParticipants}
        onRemove={(connection) => {
          logTap({ component: 'AddParticipantsRow', button: 'RemoveConnection' })
          toggleSelected(connection)
        }} />
    )
  }

  function toggleSelected(connection: Connection): void {
    const selected = selectedParticipants.find(c => c.connectedToUserId === connection.connectedToUserId)

    if (!selected) {
      const update = selectedParticipants.slice()

      update.push(connection)
      console.log('ADD UPDATE: ', update)
      setSelectedParticipants(update)
    } else {
      const update = selectedParticipants.slice()

      setSelectedParticipants(update.filter(c => c.connectedToUserId !== connection.connectedToUserId))
    }
  }

  function renderConnection(connection: Connection, divider: boolean): JSX.Element {
    const { displayName, userHandle, connectedToUserId, photo } = connection
    // discard blank spaces in between
    const initials = displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase())
    const selected = selectedParticipants.find(c => c.connectedToUserId === connectedToUserId)

    return (
      <div key={userHandle}>
        {divider &&
          <IonItemDivider
            color='light'
            sticky>
            {initials[0]}
          </IonItemDivider>}
        <IonItem
          key={userHandle}
          lines='full'
          onClick={() => {
            logTap({ component: 'IonItem', button: 'SelectConnection' })
            toggleSelected(connection)
          }}>
          <Avatar
            slot='start'
            src={photo}
            style={avatarStyle}>
            <IonText style={{ fontSize: 16 }}>
              {initials}
            </IonText>
          </Avatar>
          <IonLabel>
            <h2>{displayName}</h2>
            <p>@{userHandle}</p>
          </IonLabel>
          {!selected &&
            <IonIcon
              slot='end'
              icon={ellipseOutline}
              color='medium' />}
          {selected &&
            <IonIcon
              slot='end'
              icon={checkmarkCircle}
              color='primary' />}
        </IonItem>
      </div>
    )
  }
  function renderConnections(): JSX.Element | undefined {
    if (!loading && connections?.length) {
      const showConnections = connections.slice().sort((a, b) => {
        return a.displayName.localeCompare(b.displayName)
      })

      return (
        <IonList>
          {showConnections.map((connection, i) => {
            let divider = false

            if (!i || showConnections[i - 1].displayName.charAt(0) !== connection.displayName.charAt(0)) {
              divider = true
            }

            return renderConnection(connection, divider)
          })}
        </IonList>
      )
    }
  }

  function renderNoConnections(): JSX.Element | undefined {
    if (!loading && connections && connections?.length === 0) {
      return (
        <div style={container}>
          <AnimatedPeople
            loop
            style={{ height: 300, width: 300 }} />
          <IonText
            color='medium'
            style={messageStyle}>
            When you meet with people on MeetingTime they will be added to your connections here.
          </IonText>
        </div>
      )
    }
  }

  function renderScreenTips(): JSX.Element | undefined {
    return (
      <ScreenTips
        minTip={minTip}
        showScreenTip={showScreenTip}
        onClose={() => {
          setMinTip(true)
        }}
        onMinTip={() => {
          setMinTip(false)

          // if we've not shown endTips then go ahead and start the sequence immediately
          if (!componentStoreTips?.endTips) {
            setNextTip(ConnectionsScreenTips.sequence)
          }
        }}
        onButton={(restartTips) => {
          if (showScreenTip === ConnectionsScreenTips.endTips) {
            if (restartTips) {
              console.log('Restart TIPS!')
              setNextTip(ConnectionsScreenTips.sequence, true)
            } else {
              setNextTip(ConnectionsScreenTips.noTip)
              setMinTip(true)
            }
          } else {
            setNextTip(showScreenTip + 1)
          }
        }} />
    )
  }

  function newMeeting(): void {
    if (selectedParticipants?.length) {
      const addParticipants = selectedParticipants.map(connection => connection.connectedToUserId)

      setSelectedParticipants([])
      onNewMeeting(addParticipants)
    }
  }

  function renderHeader(): JSX.Element {
    if (!selectedParticipants.length) {
      return (
        <ScreenHeader
          title={title} />
      )
    } else {
      return (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton
                onClick={() => {
                  logTap({ button: 'SetSelectedParticipants' })
                  setSelectedParticipants([])
                }}
                color='primary'>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
      )
    }
  }

  function renderFooter(): JSX.Element | undefined {
    if (selectedParticipants.length) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ button: 'NewMeeting' })
              newMeeting()
            }}>
            New Meeting
          </FooterButton>
        </IonFooter>
      )
    }
  }

  return (
    <IonPage>
      {renderHeader()}
      <IonContent>
        {largeHeader}
        {loading &&
          <ScreenCenter height='80%'>
            <IonSpinner name='dots' />
          </ScreenCenter>}
        {/* {renderSearchBar()} */}
        {renderSelectedConnections()}
        {renderConnections()}
        {renderNoConnections()}
      </IonContent>
      {renderFooter()}
      {renderScreenTips()}
    </IonPage>
  )
}

export default Connections
