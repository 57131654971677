
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FS_apiKey,
  authDomain: process.env.REACT_APP_FS_authDomain,
  databaseURL: process.env.REACT_APP_FS_databaseURL,
  projectId: process.env.REACT_APP_FS_projectId,
  storageBucket: process.env.REACT_APP_FS_storageBucket,
  messagingSenderId: process.env.REACT_APP_FS_messagingSenderId,
  appId: process.env.REACT_APP_FS_appId,
  measurementId: process.env.REACT_APP_FS_measurementId,
}

export default firebaseConfig
