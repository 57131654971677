import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'services/store'
import CalendarSettingsDoneAddAnother from './CalendarSettingsDoneAddAnother'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import paths from 'navigation/paths'

const CalendarSettingsDoneAddAnotherScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { calendarSettings, chooseAvailability } = useSelector((state: RootState) => state.screens)

  function onDone(): void {
    if (chooseAvailability?.meetingId) {
      history.push(paths.chooseAvailability)
    } else {
      history.push(paths.meetingsList)
    }
  }

  function onAddCalendar(authUrl: string): void {
    console.log('onAddCalendar: ', authUrl)
    window.open(authUrl, '_self')
  }

  return (
    <CalendarsProvider>
      <CalendarSettingsDoneAddAnother
        calendarId={calendarSettings?.calendar}
        onAddCalendar={onAddCalendar}
        onDone={onDone} />
    </CalendarsProvider>
  )
}

export default CalendarSettingsDoneAddAnotherScreen
