import React, { CSSProperties, useEffect } from 'react'
import moment from 'moment'
import { IonChip, IonText } from '@ionic/react'
import { Meeting } from 'types'
import TipTarget from 'components/atoms/TipTarget/TipTarget'
import { blue } from 'theme/styles'

// startTime: "2020-09-04T16:00:00.000Z", endTime: "2020-09-05T00:00:00.000Z"

const container: CSSProperties = {
  overflow: 'hidden',
  width: '100%',
  paddingBottom: 5,
}

const barStyle: CSSProperties = {
  display: 'flex',
  position: 'relative',
  height: 60,
  overflowX: 'scroll',
  flexWrap: 'nowrap',
  WebkitUserSelect: 'none',
}

const selectedLabelBox: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 5,
}

const selectedDate: CSSProperties = {
  fontSize: 16,
  border: `1px solid ${blue}`,
}

const weekdayStyle: CSSProperties = {
  fontSize: 11,
}
const dayStyle: CSSProperties = {
  fontSize: 14,
  fontWeight: 'bold',
}

const box: CSSProperties = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}

const overlayBox: CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  color: 'white',
}
const selectedDayCircle: CSSProperties = {
  width: 30,
  height: 30,
  borderRadius: 15,
  backgroundColor: blue,
  color: 'white',
}

interface ContainerProps {
  meeting?: Meeting;
  selectedTime: string;
  tip?: boolean;
  onSelectTime: (time: string) => void;
  autoDays?: string[];
}

const DayScheduleWeekBar: React.FC<ContainerProps> = ({ meeting, selectedTime, tip, onSelectTime }) => {
  // const dayBoxWidth = window.in / 7
  const dayBoxWidth = 50
  // console.log('SELECTED TIME: ', selectedTime)

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('weekBar')
      // selected time is in ISO string
      const startOfDay = moment().startOf('day').toISOString()
      const selectedDay = moment(selectedTime).startOf('day').diff(moment(startOfDay), 'days')

      if (el) {
        el.scrollLeft = selectedDay * dayBoxWidth
      }
    }, 400)
  }, [])

  function renderSelectedDayLabel(): JSX.Element {
    const selectedDay = moment(selectedTime).format('dddd, MMMM D \'YY')

    return (
      <IonChip
        color='primary'
        style={selectedDate}>
        {selectedDay}
      </IonChip>
    )
  }

  function onSelectDay(time: string): void {
    // note this is 12AM local in ISO time
    console.log('selected time:', time)

    // find number of minutes from start of day and add it to the selected time (begining of day)
    const minutes = moment(selectedTime).diff(moment(selectedTime).startOf('day'), 'minutes')

    const newSelectedTime = moment(time).add(minutes, 'minutes').toISOString()

    console.log('New selected time: ', newSelectedTime)
    onSelectTime(newSelectedTime)
  }

  const dayBox: CSSProperties = {
    minWidth: dayBoxWidth,
    width: dayBoxWidth,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }

  function renderDay(time: string): JSX.Element {
    // this time is 12AM local in ISO time
    const currentMoment = moment(time)
    const dayOfWeek = currentMoment.format('dd').charAt(0)
    const date = currentMoment.format('D')
    let isSelected = false

    if (moment(selectedTime).startOf('day').valueOf() === moment(time).valueOf()) {
      isSelected = true
    }

    return (
      <div
        key={time}
        style={dayBox}
        onClick={() => onSelectDay(time)}>
        <div style={box}>
          <IonText
            style={weekdayStyle}>
            {dayOfWeek}
          </IonText>
        </div>
        <div style={box}>
          {!isSelected &&
            <IonText
              style={dayStyle}>
              {date}
            </IonText>}
          {isSelected &&
            <div style={overlayBox}>
              <div style={selectedDayCircle} />
            </div>}
          {isSelected &&
            <div style={overlayBox}>
              <IonText
                style={dayStyle}>
                {date}
              </IonText>
            </div>}
        </div>
      </div>
    )
  }

  function renderDays(): JSX.Element[] {
    const days: JSX.Element[] = []

    if (meeting?.dayRanges?.length) {
      const sortedDayRanges = meeting.dayRanges.slice().sort((a, b) => {
        const timeA = moment(a.startTime).valueOf()
        const timeB = moment(b.startTime).valueOf()

        return timeA - timeB
      })
      const start = sortedDayRanges[0].startTime
      const end = sortedDayRanges[sortedDayRanges.length - 1].endTime
      const endValue = moment(end).valueOf()

      let time = start

      while (moment(time).valueOf() < endValue) {
        const value = moment(time).valueOf()

        if (sortedDayRanges.find(range => {
          if (value >= moment(range.startTime).valueOf() && value < moment(range.endTime).valueOf()) {
            return true
          }
        })) {
          days.push(renderDay(time))
        }

        time = moment(time).add(1, 'day').toISOString()
      }
    }

    return days
  }

  return (
    <div style={container}>
      <div
        id='weekBar'
        style={barStyle}>
        {renderDays()}
        {tip &&
          <TipTarget
            style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
      </div>
      <div style={selectedLabelBox}>
        {renderSelectedDayLabel()}
      </div>
    </div>

  )
}

export default DayScheduleWeekBar
