import React, { useState, useRef, CSSProperties, useEffect } from 'react'
import {
  IonContent, IonPage,
  IonLabel, IonItem, IonIcon, IonSpinner, IonButtons, IonHeader, IonTitle, IonToolbar, IonFooter,
} from '@ionic/react'
import './CalendarAccounts.css'
import moment from 'moment-timezone'

import 'screens/Settings.css'
import { calendarOutline, ellipse, addCircleOutline } from 'ionicons/icons'
import MeetingTimes from 'components/molecules/MeetingTimes/MeetingTimes'
import { CalendarAccount } from 'types'
import CalendarListItem from './CalendarListItem'
import CalendarAccountDetails from 'components/organisms/CalendarAccountDetails/CalendarAccountDetails'
import DefaultCalendar from '../../components/organisms/DefaultBookCalendar/DefaultBookCalendar'

import AutoBookSettingsModal from 'components/organisms/SelectMeetingAutobookSchedule/AutoBookSettingsModal'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import { usePreferences } from 'context/PreferencesContext/PreferencesContext'
import { AddCalendarInput, CalendarService, UpdateCalendarInput } from 'services/api'
import ScreenBackButton from 'components/atoms/ScreenBackButton/ScreenBackButton'
import ListItem from 'components/atoms/ListItem/ListItem'
import { encryptedAccessToken } from 'services/security/accessToken'
import { CalendarAccountTips } from 'types/componentTips'
import { useTips } from 'context/TipsContext/TipsContext'
import { CalendarAccounts as StoreTips } from 'services/store/tips/types'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { encryptedDeviceFingerPrint } from 'services/accounts'
import { EventName, EventData, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const iconStyle: CSSProperties = {
  fontSize: 50,
}

const iconBox: CSSProperties = {
  padding: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

interface ComponentProps {
  goBackTo: string;
  onBack: () => void
  onAddCalendar: (url: string) => void;
  onNext?: () => void;
}

const CalendarAccounts: React.FC<ComponentProps> = (
  { goBackTo, onAddCalendar, onBack, onNext }) => {
  const [showCalendarDetails, setShowCalendarDetails] = useState<string>()
  const [showDefaultCalendar, setShowDefaultCalendar] = useState(false)
  const [selectAutoTimes, setSelectAutoTimes] = useState(false)
  const [showScreenTip, setShowScreenTip] = useState(CalendarAccountTips.noTip)
  const [minTip, setMinTip] = useState(false)
  // const [autoTimeRanges, setAutoTimeRanges] = useState<TimeRange[]>()
  // const [autoDays, setAutoDays] = useState<string[]>()

  const pageRef = useRef()

  const { loading, calendars, defaultCalendar, autoTimes, autoDays, addCalendar, updateCalendar, reloadCalendars } = useCalendars()
  const { preferences } = usePreferences()
  const { storeTips, updateStoreTips } = useTips()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'CalendarAccounts' },
    })
    startTips()
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'CalendarAccounts' },
    })
  }, [])

  // logTap is returning undefined/AddCalendar ?
  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'CalendarAccounts',
      },
    })
  }

  const componentStoreTips = storeTips?.calendarAccounts

  function startTips(): void {
    setTimeout(() => {
      if (storeTips?.startMinTip || componentStoreTips?.endTips) {
        setNextTip(CalendarAccountTips.noTip)
      } else {
        setNextTip(CalendarAccountTips.sequence)
      }
    }, 1000)
  }

  function setNextTip(tip: CalendarAccountTips, restartTips?: boolean): void {
    // console.log('Meeting screen setNextTip: ', tip)

    switch (tip) {
      // eslint-disable-next-line no-fallthrough
      case CalendarAccountTips.sequence:
      case CalendarAccountTips.addCalendar:
        if (restartTips || !componentStoreTips?.addCalendar) {
          setShowScreenTip(CalendarAccountTips.addCalendar)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case CalendarAccountTips.scheduleCalendar:
        if (calendars?.length && !componentStoreTips?.scheduleCalendar) {
          setShowScreenTip(CalendarAccountTips.scheduleCalendar)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case CalendarAccountTips.autoTimeRanges:
        if (calendars?.length && !componentStoreTips?.autoTimeRanges) {
          setShowScreenTip(CalendarAccountTips.autoTimeRanges)
          break
        }

      // eslint-disable-next-line no-fallthrough
      case CalendarAccountTips.endTips:
        if (!componentStoreTips?.endTips) {
          setShowScreenTip(CalendarAccountTips.endTips)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case CalendarAccountTips.noTip:
      default:
        // pass it back to meetings screen
        setShowScreenTip(CalendarAccountTips.endTips)
        setMinTip(true)
        break
    }
  }

  function updateTip(tip: StoreTips): void {
    if (updateStoreTips) {
      updateStoreTips({
        calendarAccounts: {
          ...componentStoreTips, ...tip,
        },
      })
    }
  }

  async function addGoogleCalendar(): Promise<void> {
    const addToken = encryptedAccessToken() || ''
    const deviceId = encryptedDeviceFingerPrint()

    const state = {
      token: addToken,
      deviceId,
    }

    const input: AddCalendarInput = {
      service: CalendarService.Google,
      state: JSON.stringify(state),
    }
    const authUrl = await addCalendar(input)

    if (authUrl) {
      console.log('ADD CALENDAR RETURNED: ', authUrl)
      onAddCalendar(authUrl)
    }
  }

  function renderModals(): JSX.Element | undefined {
    // if (addCalendar) {
    //   return <AddCalender
    //     presentingElement={pageRef.current}
    //     onAddCalendar={onAddCalendar}
    //     onClose={() => {
    //       setAddCalendar(false)
    //     }} />
    // }

    if (showCalendarDetails && calendars?.length) {
      const calendar = calendars.find(cal => cal.id === showCalendarDetails)

      if (calendar) {
        return <CalendarAccountDetails
          calendar={calendar}
          presentingElement={pageRef.current}
          onClose={() => {
            setShowCalendarDetails(undefined)
          }}
          onRemove={() => {
            setShowCalendarDetails(undefined)
            reloadCalendars && reloadCalendars()
          }} />
      }
    }

    if (calendars && showDefaultCalendar && defaultCalendar) {
      return <DefaultCalendar
        calendars={calendars}
        selectedCalendar={defaultCalendar}
        presentingElement={pageRef.current}
        onClose={() => {
          setShowDefaultCalendar(false)
        }} />
    }

    if (selectAutoTimes) {
      return (
        <AutoBookSettingsModal
          noShowSwitch
          enabled
          timeRanges={autoTimes}
          days={autoDays}
          timeZone={preferences?.timeZone}
          presentingElement={pageRef.current}
          onClose={() => setSelectAutoTimes(false)}
          onUpdated={(ranges, days) => {
            console.log('AUTO TIMES: ', ranges, days)

            if (defaultCalendar) {
              const { account, id } = defaultCalendar

              const update: UpdateCalendarInput = {
                id: account,
                calendarId: id,
                timeRanges: ranges,
                autoDays: days,
              }

              updateCalendar(update)
              setSelectAutoTimes(false)
            }
          }} />
      )
    }
  }

  function renderDefaultCalendar(): JSX.Element | undefined {
    if (calendars?.length && defaultCalendar) {
      return (
        <div style={{ marginTop: 30 }}>
          <ListItem
            title='Default Schedule Calendar'
            label={defaultCalendar.name}
            icon={{ name: ellipse, slot: 'start', style: { color: defaultCalendar.backgroundColor }, size: 'small' }}
            tip={!minTip && showScreenTip === CalendarAccountTips.scheduleCalendar}
            onClick={() => {
              logTap({ component: 'ListItem', button: 'DefaultCalendar' })
              updateTip({ scheduleCalendar: true })
              setShowDefaultCalendar(true)
              setNextTip(showScreenTip + 1)
            }} />
        </div>
      )
    }
  }

  function renderAutoTimeRanges(): JSX.Element | undefined {
    if (calendars?.length && defaultCalendar && autoTimes) {
      return (
        <MeetingTimes
          title='automagic schedule times'
          tip={!minTip && showScreenTip === CalendarAccountTips.autoTimeRanges}
          timeRanges={autoTimes}
          timeZone={preferences?.timeZone}
          onClick={() => {
            logTap({ component: 'MeetingTimes', button: 'MeetingTimes' })
            updateTip({ autoTimeRanges: true })
            setSelectAutoTimes(true)
          }} />
      )
    }
  }

  console.log('CURRENT TIME ZONE: ', moment.tz.guess())
  console.log('GO BACK TO: ', goBackTo)

  function renderHeader(): JSX.Element {
    return (
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <ScreenBackButton
              goBackTo={goBackTo}
              onClick={() => onBack && onBack()} />
          </IonButtons>
          <IonTitle>Calendars</IonTitle>
        </IonToolbar>
      </IonHeader>
    )
  }

  function renderFooter(): JSX.Element | undefined {
    if (onNext) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ button: 'Next' })
              onNext()
            }}>
            Next
          </FooterButton>
        </IonFooter>

      )
    }
  }

  // function renderScreenTips (): JSX.Element | undefined {
  //   return (
  //     <ScreenTips
  //       minTip={minTip}
  //       showScreenTip={showScreenTip}
  //       onClose={() => {
  //         setMinTip(true)
  //       }}
  //       onMinTip={() => {
  //         setMinTip(false)

  //         // if we've not shown endTips then go ahead and start the sequence immediately
  //         if (!componentStoreTips?.endTips) {
  //           setNextTip(CalendarAccountTips.sequence)
  //         }
  //       }}
  //       onButton={(restartTips) => {
  //         if (showScreenTip === CalendarAccountTips.endTips) {
  //           if (restartTips) {
  //             console.log('Restart TIPS!')
  //             setNextTip(CalendarAccountTips.sequence, true)
  //           } else {
  //             setNextTip(CalendarAccountTips.noTip)
  //             setMinTip(true)
  //           }
  //         } else {
  //           setNextTip(showScreenTip + 1)
  //         }
  //       }} />
  //   )
  // }

  return (
    <IonPage ref={pageRef}>
      {renderHeader()}
      <IonContent>
        <div
          style={container}
          className='titleIconBox'>
          <div
            style={iconBox}
            className='titleIconBox'>
            <IonIcon
              style={iconStyle}
              icon={calendarOutline}
              color='secondary' />
          </div>

          {loading &&
            <IonItem
              lines='full'
              style={{ textAlign: 'center' }}>
              <IonLabel color='secondary'>
                <IonSpinner
                  name='dots' />
              </IonLabel>
            </IonItem>}

          {!loading && calendars && calendars.length > 0 &&
            calendars.map((calendar: CalendarAccount, i: number) => (
              <CalendarListItem
                key={i}
                calendar={calendar}
                onSelect={(cal) => {
                  logTap({ component: 'CalendarListItem', button: 'SelectCalendar' })
                  setShowCalendarDetails(cal)
                }} />
            ))}
          <ListItem
            label='Add Calendar'
            icon={{ name: addCircleOutline, slot: 'end', color: 'success' }}
            tip={!minTip && showScreenTip === CalendarAccountTips.addCalendar}
            onClick={() => {
              logTap({ component: 'ListItem', button: 'AddCalendar' })
              updateTip({ addCalendar: true })
              addGoogleCalendar()
            }} />
          {renderDefaultCalendar()}
          {renderAutoTimeRanges()}
          {renderModals()}
        </div>
      </IonContent>
      {renderFooter()}
      {/* {renderScreenTips()} */}
    </IonPage>
  )
}

export default CalendarAccounts
