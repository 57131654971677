import React, { useContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { GET_PREFERENCES, UpdatePreferencesInput, UPDATE_PREFERENCES } from 'services/api'
import { Preferences } from 'types'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

export interface PreferencesContextValue {
  preferences?: Preferences
  updatePreferences: (input: UpdatePreferencesInput) => Promise<void>
}

const initialValue: PreferencesContextValue = {
  updatePreferences: async (input: UpdatePreferencesInput) => {
    console.log('updatePreferences: ', input)
  },
}
// create and initialize context
export const PreferencesContext = React.createContext<PreferencesContextValue>(initialValue)

export function usePreferences (): PreferencesContextValue {
  return useContext(PreferencesContext)
}

export type PreferencesMockContextValue = Partial<PreferencesContextValue>

type MockProps = {
  value?: Partial<PreferencesContextValue>
}

export const PreferencesMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <PreferencesContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </PreferencesContext.Provider>
  )
}

const PreferencesProvider: React.FC = ({ children }) => {
  const { logEvent } = useAnalytics()

  const { data } = useQuery(GET_PREFERENCES, {
    fetchPolicy: 'cache-and-network',
  })
  const [updatePreferencesMutation] = useMutation(UPDATE_PREFERENCES)

  async function updatePreferences (input: UpdatePreferencesInput): Promise<void> {
    const { data } = await updatePreferencesMutation({ variables: { input } })

    logEvent({
      eventName: EventName.mutation,
      eventData: {
        mutation: 'updatePreferences',
      },
    })

    console.log('Updated preferences', data)
  }

  return (
    <PreferencesContext.Provider
      value={{
        preferences: data?.preferences,
        updatePreferences,
      }}>
      {children}
    </PreferencesContext.Provider>
  )
}

export default PreferencesProvider
