import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from 'services/store'

import 'screens/Settings.css'
import paths from 'navigation/paths'
import { updateScreens } from 'services/store/screens/actions'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import MeetingParticipants from './MeetingParticipants'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import TimeSlotsProvider from 'context/TimeSlotsContext/TimeSlotsContext'
import NotifyParticipantsProvider from 'context/NotifyParticipantsContext/NotifyParticipantsContext'

const MeetingParticipantsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { meetingParticipants } = useSelector((state: RootState) => state.screens)
  const dispatch = useDispatch()

  function onAdd(): void {
    if (meetingParticipants?.meetingId) {
      dispatch(updateScreens({ addParticipants: { meetingId: meetingParticipants?.meetingId } }))
      history.push(paths.addParticipants)
    }
  }

  function onInvite(): void {
    if (meetingParticipants?.meetingId) {
      dispatch(updateScreens({ shareMeetingInvite: { meetingId: meetingParticipants?.meetingId } }))
      history.push('/tabs/meetings/shareMeetingInvite')
    }
  }

  function goBack(): void {
    history.goBack()
  }

  return (
    <MeetingsProvider meetingId={meetingParticipants?.meetingId}>
      <InviteesProvider meetingId={meetingParticipants?.meetingId}>
        <ParticipantsProvider meetingId={meetingParticipants?.meetingId}>
          <TimeSlotsProvider meetingId={meetingParticipants?.meetingId}>
            <NotifyParticipantsProvider>
              <MeetingParticipants
                onAdd={onAdd}
                onInvite={onInvite}
                goBack={goBack} />
            </NotifyParticipantsProvider>
          </TimeSlotsProvider>
        </ParticipantsProvider>
      </InviteesProvider>
    </MeetingsProvider>
  )
}

export default MeetingParticipantsScreen
