import React, { CSSProperties } from 'react'
import {
  IonBackdrop,
  IonButton,
  IonCardContent,
  IonContent, IonModal, IonText,
} from '@ionic/react'

import { CalendarEvent, Participant } from 'types'
import { useSpring } from 'react-spring'
import ActionCard from '../ActionCard/ActionCard'
import { useTokens } from 'context/TokensContext/TokensContext'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: CalendarEvent[];
}

const box: CSSProperties = {
  padding: 20,
  textAlign: 'center',
}

const messageStyle = {
  fontSize: 14,
  textAlign: 'center',
}

interface ContainerProps {
  onClose: () => void;
  onNewUser: () => void;
  onLogin: (verifyToken: string) => void;
  presentingElement: HTMLElement | undefined;
}

const LoginModal: React.FC<ContainerProps> = ({ onNewUser, onLogin, onClose }) => {
  const { getVerifyToken } = useTokens()

  async function startLogin (): Promise<void> {
    if (getVerifyToken) {
      const verifyToken = await getVerifyToken()

      if (verifyToken) {
        console.log('GOT VERIFY TOKEN: ', verifyToken)
        onLogin(verifyToken)
      }
    }
  }

  const popoverProps = useSpring({
    ...(getVerifyToken ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderLoginCard (): JSX.Element | undefined {
    return (
      <ActionCard
        title='Welcome!'
        icon=''
        onClose={onClose}
        animatedProps={popoverProps}>
        <IonCardContent>
          <IonText
            color='medium'
            style={messageStyle}>
            MeetingTime can automagically find a time that works for everyone
          </IonText>
          <div style={box}>

            <IonText
              color='medium'
              style={messageStyle}>
              Have you used MeetingTime before?
            </IonText>

          </div>
          <div style={box}>
            <IonButton
              data-ci-id='login-modal-new'
              shape='round'
              expand='block'
              fill='solid'
              color='primary'
              onClick={onNewUser}>
              I&apos;m New
            </IonButton>
            <IonText
              color='medium'
              style={messageStyle}>
              {/* We are happy you would like to give us a try. */}
              Try MeetingTime for FREE<br />We hope you like it
            </IonText>
          </div>

          <div style={box}>
            <IonButton
              data-ci-id='login-modal-login'
              shape='round'
              expand='block'
              fill='outline'
              color='primary'
              onClick={startLogin}>
              Sign-In
            </IonButton>
            <IonText
              color='medium'
              style={messageStyle}>
              Welcome back!
            </IonText>
          </div>
        </IonCardContent>
      </ActionCard>
    )
  }

  return (
    <IonModal
      data-ci-id='login-modal'
      isOpen
      backdropDismiss
      cssClass='meetingTimeModal'
      onDidDismiss={onClose}>
      <IonContent>
        <IonBackdrop
          tappable
          visible />
        {renderLoginCard()}
      </IonContent>
    </IonModal>
  )
}

export default LoginModal
