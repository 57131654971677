import moment from 'moment'
import { TimeRange } from 'types'

export function compareTimes (a = '', b = ''): number {
  const timeStampA = moment(a).valueOf()
  const timeStampB = moment(b).valueOf()

  if (!timeStampA || !timeStampB) {
    /* undefined due_date is the farthest away. does it make sense? */
    if (!timeStampA && timeStampB) return -1

    if (timeStampA && !timeStampB) return 1

    if (!timeStampA && !timeStampB) return 0
  }

  return timeStampA - timeStampB
}

export function stringToMinutes (text: string): string | undefined {
  const min = new RegExp('min', 'i')
  const hr = new RegExp('hr', 'i')
  const hour = new RegExp('hour', 'i')

  const number = parseFloat(text)

  if (number) {
    if (min.test(text)) {
      return number.toString()
    } else if (hr.test(text) || hour.test(text)) {
      return (number * 60).toString()
    } else {
      // by default consider the number to be in minutes
      return number.toString()
    }
  }
}

export function minutesToString (text: string): string {
  const minutes = Number(stringToMinutes(text))
  const time = moment.utc().startOf('day').add(minutes, 'minutes')
  const hr = minutes >= 60 ? `${time.format('H')}hr` : ''
  const min = minutes % 60 > 0 ? `${time.format('mm')}min` : ''

  return `${hr} ${min}`
}

export function dayRangesToString (dayRanges?: TimeRange[]): string {
  // console.log('dayRangesToString: ', dayRanges)

  if (dayRanges?.length) {
    const sorted = dayRanges.slice().sort((a, b) => {
      const timeA = moment(a.startTime).valueOf()
      const timeB = moment(b.startTime).valueOf()

      return timeA - timeB
    })

    const start = moment(sorted[0].startTime).add(12, 'hours').format('MMM D')
    const end = moment(sorted[sorted.length - 1].endTime).subtract(12, 'hours').format('MMM D')

    // if it's a single range
    if (sorted.length === 1) {
      const range = sorted[0]

      // check if it's a single day
      if (moment(range.endTime).diff(moment(range.startTime), 'days') === 1) {
        return `${start}`
      } else {
        return `${start} - ${end}`
      }
    } else {
      // there are multiple fragmented ranges
      // return `${start}...${end}`
      // the dotted lines were confusing to some users just for simplicity used a dash
      return `${start} - ${end}`
    }
  }

  return ''
}

export function timeRangesToString (timeRanges?: TimeRange[]): string {
  // console.log('timeRangesToString: ', timeRanges)
  let timeString = ''

  if (timeRanges?.length) {
    timeRanges.forEach((range, i) => {
      const start = moment(range.startTime).format('h:mm a')
      const end = moment(range.endTime).format('h:mm a')

      timeString += `${start} - ${end}`

      if (i < timeRanges.length - 1) {
        timeString += ', '
      }
    })
  } else {
    timeString = 'anytime'
  }

  return timeString
}
