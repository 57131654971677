import { DeviceCalendarAction } from 'services/api'

export interface User {
  id?: string;
  displayName?: string;
  createTime?: string;
  handle?: string;
  __typename?: 'User';
}

export type MeetingTime = {
  id?: string;
  startTime: string;
  endTime: string;
  participantUserIds: string[];
  status: string;
  updatedByUserId?: string;
}

export interface Meeting {
  id: string;
  title: string;
  duration: string;
  description?: string;
  createTime?: string;
  createdByUserId?: string;
  updateTime?: string;
  participantUserIds?: string[];
  dayRanges?: TimeRange[];
  timeRanges?: TimeRange[];
  meetingTimes?: MeetingTime[];
  participantsExpected?: number;
  tentativeThreshold?: number;
  videoConference?: VideoConference;
  __typename?: 'Meeting';
}

export interface Invitation {
  id: string;
  meetingId: string;
  userId: string;
  createTime: string;
}

export interface MeetingInvite {
  id: string;
  meetingId: string;
  title: string;
  duration: string;
  participantCount: number;
  dayRanges?: TimeRange[];
  timeRanges?: TimeRange[];
  invitationId: string;
  displayName: string;
  photo?: string;
}

export enum InviteeStatus {
  invited = 'invited',
  received = 'received',
  seen = 'seen',
  declined = 'declined',
  accepted = 'accepted',
}

export interface Invitee {
  id: string;
  meetingId: string;
  userId: string;
  displayName: string;
  userHandle: string;
  photo?: string;
  invitationId?: string;
  invitedByUserId?: string;
  meetMe?: boolean;
  status: InviteeStatus
  createTime: string;
  updateTime: string;
}

export interface Participant {
  id: string;
  userId: string;
  userHandle: string;
  displayName: string;
  admin: boolean;
  creator: boolean;
  createTime: string;
  // this is the userHandle of the invitation creator
  invitedByUserHandle: string;
  photo?: string;
  calendars?: ParticipantCalendar[];
  bookCalendar: {
    account: string;
    calendarId: string;
    timeRanges?: TimeRange[];
    autoDays?: string[];
  };
  autoBook: {
    enabled: boolean;
    active: boolean;
  }
  readyToSchedule?: {
    ready: boolean;
    configureStartTime?: string;
    configureEndTime?: string;
  }
  meetMe?: boolean;
  deviceCalendar?: {
    action: DeviceCalendarAction,
    updateTime: string;
  }
}

export interface UserContact {
  id: string;
  email?: string;
  phone?: string;
  photo?: string;
  provider?: string;
  createTime: string;
}

export enum ContactType {
  email = 'email',
  phone = 'phone',
  google = 'google',
}
export interface TimeSlot {
  localId?: string;
  id?: string;
  userId?: string;
  meetingId: string;
  startTime: string;
  endTime: string;
  available?: boolean;
  deleted?: boolean;
  disabled?: boolean;
  autoCreated?: boolean;
  __typename?: 'TimeSlot';
}

export interface TimeRange {
  startTime: string;
  endTime: string;
  participantUserIds?: string[];
  timeSlotId?: string;
  __typename?: 'TimeRange';
}

export interface CalendarAccount {
  id: string;
  userId: string;
  service: string;
  account: string;
  photo: string;
  calendars: Calendar[];
  accessTokens: {
    accessToken: string;
    refreshToken: string;
    expireTime: string;
  };
  createTime: string;
}

interface Calendar {
  calendarId: string;
  enabled: boolean;
  freeBusy: boolean;
  primary?: boolean;
  timeZone?: string;
  timeRanges?: TimeRange[];
  autoDays?: string[]
}

export interface CalendarEvent {
  id: string;
  summary: string;
  startTime: string;
  endTime: string;
  backgroundColor?: string;
  foregroundColor?: string;
}

export interface SubCalendar {
  id: string;
  account: string;
  name?: string;
  primary?: boolean;
  enabled?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
  timeZone?: string;
  freeBusy?: boolean;
  hidden?: boolean;
  selected?: boolean;
}

export interface ParticipantCalendar {
  calendarId: string;
  account: string;
  enabled?: boolean;
  freeBusy?: boolean;
}

export interface UserDevice {
  id: string;
  deviceId: string;
  createTime: string;
  accessTime: string;
  browser: string;
  ip: string;
}

export interface Preferences {
  id: string;
  bookCalendar: {
    account: string;
    calendarId: string;
  };
  timeZone: string
}

export interface VideoConference {
  link: string;
}

export interface Connection {
  id: string;
  userId: string;
  connectedToUserId: string;
  displayName: string;
  userHandle: string;
  photo?: string;
  createTime: string;
}

export interface MeetUser {
  userId: string;
  displayName: string;
  userHandle: string;
  photo?: string;
}
