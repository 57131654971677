import { AppResetAction } from '../types'

export const UPDATE_TIP = 'UPDATE_TIP'
export const ENABLE_ALL_TIPS = 'ENABLE_ALL_TIPS'

export interface ChooseAvailability {
  invitePeople?: boolean;
  selectTimes?: boolean;
  selectTimesInitiator?: boolean;
  selectTimesInvitee?: boolean;
  selectTimesFirst?: boolean;
  selectTimesSecond?: boolean;
  selectAllTimes?: boolean;
  autoTimes?: boolean;
  othersTimes?: boolean;
  trendingTimes?: boolean;
  tentativeTimes?: boolean;
  nextWhenDone?: boolean;
  endTips?: boolean;
}

export interface MeetingsScreen {
  newInviteeJoin?: boolean;
  newInitiatorSuccess?: boolean;
  newInviteeSuccess?: boolean;
  newMeeting?: boolean;
  profile?: boolean;
  newInvitations?: boolean;
  endTips?: boolean;
}

export interface SelectDaySchedule {
  selectTimes?: boolean;
}
export interface MeetingInvite {
  inviteesCount?: boolean;
  shareLink?: boolean;
  endTips?: boolean;
}

export interface MeetingCard {
  chooseAvailability?: boolean;
  trendingTimes?: boolean,
  tentativeTimes?: boolean,
  confirmedTime?: boolean,
  changeAvailability?: boolean,
  turnOnAuto?: boolean,
  changeAuto?: boolean;
  availabilityCalendar?: boolean;
  inviteParticipants?: boolean;
  participants?: boolean;
  meetingTitle?: boolean;
  moreActions?: boolean;
}
export interface MeetingSettings {
  steps?: boolean;
  title?: boolean;
  duration?: boolean;
  dateRange?: boolean;
  timeRange?: boolean;
  availability?: boolean;
}

export interface AskConnectCalendar {
  connectCalendar?: boolean;
  selectTimes?: boolean;
  endTips?: boolean;
}

export interface AddParticipants {
  addParticipant?: boolean;
  shareLink?: boolean;
  endTips?: boolean;
}

export interface SettingsScreen {
  calendars?: boolean;
  devices?: boolean;
  helpTips?: boolean;
  endTips?: boolean;
}

export interface CalendarAccounts {
  addCalendar?: boolean;
  scheduleCalendar?: boolean;
  autoTimeRanges?: boolean;
  endTips?: boolean;
}

export interface CalendarAccountDetails {
  selectCalendar?: boolean;
}

export interface DaySchedule {
  selectTimes?: boolean;
  othersTimes?: boolean;
  trendingTimes?: boolean;
  tentativeTimes?: boolean;
  switchDays?: boolean;
  endTips?: boolean;
}

export interface AutoBookSettings {
  selectTimes?: boolean;
}

export interface SelectMeetingDays {
  selectDaysRange?: boolean;
  editDays?: boolean;
}
export interface SelectMeetingTimes {
  selectTimes?: boolean;
}

export interface AccountScreen {
  addContact?: boolean;
  editId?: boolean;
  endTips?: boolean;
}
export interface ConnectionsScreen {
  selectPeople?: boolean;
  startMeeting?: boolean;
  endTips?: boolean;
}

export interface AvailabilityCalendar {
  completed?: boolean;
}

export interface ConnectCalendars {
  completed?: boolean;
  skipped?: number;
}

export interface GooglePermissions {
  completed?: boolean;
}

export interface ConfigureCalendar {
  completed?: boolean;
}

export interface Tips {
  enabled?: boolean;
  startMinTip?: boolean;
  meetingsScreen?: MeetingsScreen;
  meetingCard?: MeetingCard;
  meetingSettings?: MeetingSettings;
  askConnectCalendar?: AskConnectCalendar;
  addParticipants?: AddParticipants;
  settingsScreen?: SettingsScreen;
  calendarAccounts?: CalendarAccounts;
  chooseAvailability?: ChooseAvailability,
  daySchedule?: DaySchedule;
  selectDaySchedule?: SelectDaySchedule
  meetingInvite?: MeetingInvite
  calendarAccountDetails?: CalendarAccountDetails
  autoBookSettings?: AutoBookSettings
  selectMeetingDays?: SelectMeetingDays
  selectMeetingTimes?: SelectMeetingTimes
  accountScreen?: AccountScreen
  connectionsScreen?: ConnectionsScreen
  tipsAvailabilityCalendar?: AvailabilityCalendar
  tipsAvailabilityCalendarAuto?: AvailabilityCalendar
  tipsConnectCalendars?: ConnectCalendars
  tipsGooglePermissions?: GooglePermissions
  tipsConfigureCalendar?: ConfigureCalendar
}

interface UpdateTipAction {
  type: typeof UPDATE_TIP;
  tips: Tips;
}

interface EnableAllTipsAction {
  type: typeof ENABLE_ALL_TIPS;
}

export type TipActions = UpdateTipAction | EnableAllTipsAction | AppResetAction
