import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import 'screens/Settings.css'
import TipsConfigureCalendar from './TipsConfigureCalendar'

const TipsConfigureCalendarScreen: React.FC<RouteComponentProps> = ({ history }) => {
  function goBack (): void {
    history.goBack()
  }

  return (
    <TipsConfigureCalendar
      goBack={goBack} />
  )
}

export default TipsConfigureCalendarScreen
