import { ADD_MEETING, UPDATE_MEETING, REMOVE_MEETING, Meeting, MeetingActions } from './types'

export function addMeeting(meeting: Meeting): MeetingActions {
  return {
    type: ADD_MEETING,
    meeting,
  }
}

export function updateMeeting(meeting: Meeting): MeetingActions {
  return {
    type: UPDATE_MEETING,
    meeting,
  }
}

export function removeMeeting(meeting: Meeting): MeetingActions {
  return {
    type: REMOVE_MEETING,
    meeting,
  }
}
