import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import 'screens/Settings.css'
import { RootState } from 'services/store'
import { updateScreens } from 'services/store/screens/actions'
import TipsAvailabilityCalendar from './TipsAvailabilityCalendar'
import paths from 'navigation/paths'

const TipsAvailabilityCalendarScreen: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { tipsAvailabilityCalendar } = useSelector((state: RootState) => state.screens)

  const dispatch = useDispatch()

  function onNext (): void {
    if (tipsAvailabilityCalendar?.newUser) {
      history.replace(paths.chooseAvailability)
    } else {
      dispatch(updateScreens({ tipsAvailabilityCalendar: { nextScreen: '' } }))
      history.replace(paths.chooseAvailability)
    }
  }

  function goBack (): void {
    dispatch(updateScreens({ tipsAvailabilityCalendar: { nextScreen: '' } }))
    history.goBack()
  }

  return (
    <TipsAvailabilityCalendar
      newUser={tipsAvailabilityCalendar?.newUser}
      onNext={tipsAvailabilityCalendar?.nextScreen ? onNext : undefined}
      goBack={goBack} />
  )
}

export default TipsAvailabilityCalendarScreen
