import React, { CSSProperties } from 'react'
import { IonText, IonIcon } from '@ionic/react'

import 'theme/styles.css'
import { checkmarkCircle, removeCircle, trendingUp, reload, lockClosed, calendar, eyeOff, swapHorizontal, flag, time } from 'ionicons/icons'
import AnimatedLightning from 'components/atoms/AnimatedLightning/AnimatedLightning'
import { logoColor } from 'theme/styles'

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.

const slide: CSSProperties = {
  display: 'flex',
  // overflowY: 'auto',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '10%',
  // paddingTop: 20,
}

const iconsBox: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
}

const bottomBox: CSSProperties = {
  marginTop: '5%',
  alignItems: 'flex-start',
  textAlign: 'start',
}

const cardTitle: CSSProperties = {
  fontSize: 20,
  fontWeight: 'bold',
}

const messageBox: CSSProperties = {
  marginTop: '2.5%',
  alignItems: 'flex-start',
  textAlign: 'start',
}

const cardMessage: CSSProperties = {
  fontSize: 15,
}

const animatedIconBox: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const box: CSSProperties = {
  paddingTop: '2.5%',
  paddingBottom: '5%',
  textAlign: 'center',
}

const wrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '80%',
}

const EnableAutomagicSchedulingSlides: React.FC = () => {
  function renderConnectCalendar (): JSX.Element | undefined {
    return (
      <div className='titleIconBox'>
        <div
          style={animatedIconBox}>
          <AnimatedLightning loop />
        </div>
        <div style={box}>
          <IonText style={{ fontWeight: 'bold', fontSize: 18 }}>
            Connect Your Calendars
          </IonText>
          <div style={{ marginTop: 10 }}>
            <IonText color='medium'>
              To use Automagic Scheduling
            </IonText>
          </div>
        </div>
      </div>
    )
  }

  function renderSlides (): JSX.Element | undefined {
    return (
      <div>
        <IntroSlide />
        <FreeBusyTimes />
        <Private />
        <TrendingTimes />
        <Reschedule />
      </div>
    )
  }

  return (
    <div style={wrapper}>
      {renderConnectCalendar()}
      {renderSlides()}
    </div>
  )
}

export const IntroSlide: React.FC = () => {
  return (
    <div style={slide}>
      <div style={iconsBox}>
        <IonIcon
          style={{ fontSize: 28, color: logoColor, marginRight: 10 }}
          icon={swapHorizontal} />
        <IonIcon
          style={{ fontSize: 32, color: logoColor }}
          icon={calendar} />
      </div>
      <div style={bottomBox}>
        <IonText style={cardTitle}>
          Benefits
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            Do you use a Google Calendar?
            <br /><br />
            <b> For the optimal experience, enable a secure connection to one or more of your Google Calendars. </b>
            <br /><br />
            Scroll down to see the benefits of automagic scheduling!
          </IonText>
        </div>
      </div>
    </div>
  )
}

export const FreeBusyTimes: React.FC = () => {
  const text = 'MeetingTime will note your free and busy times and automagically find the time you have in common with other participants.'

  return (
    <div style={slide}>
      <div style={iconsBox}>
        <IonIcon
          style={{ fontSize: 32, marginRight: 10 }}
          icon={checkmarkCircle}
          color='primary' />
        <IonIcon
          style={{ fontSize: 32 }}
          icon={removeCircle}
          color='danger' />
      </div>

      <div style={bottomBox}>
        <IonText style={cardTitle}>
          Free Busy Times
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            {text}
          </IonText>
        </div>
      </div>
    </div>
  )
}

export const Private: React.FC = () => {
  return (
    <div style={slide}>
      <div style={iconsBox}>
        <IonIcon
          style={{ fontSize: 30, marginRight: 10 }}
          icon={eyeOff}
          color='danger' />
        <IonIcon
          style={{ fontSize: 28 }}
          icon={lockClosed}
          color='danger' />
      </div>
      <div style={bottomBox}>
        <IonText style={cardTitle}>
          Private and Secure
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            The privacy and security of your data is paramount to us. MeetingTime reads your calendar in real-time and never stores the information.<br /><br />
            <b> Your calendar events are only visible to you and never displayed to others. </b>
          </IonText>
        </div>
      </div>
    </div>
  )
}

export const TrendingTimes: React.FC = () => {
  const text = 'MeetingTime will hold the first two trending times as tentative on your calendar until all participants have responded, and when confirmed, book it on your calendar.'

  return (
    <div style={slide}>
      <div style={iconsBox}>
        <IonIcon
          style={{ fontSize: 32, marginRight: 10 }}
          icon={trendingUp}
          color='secondary' />
        <IonIcon
          style={{ fontSize: 28 }}
          icon={flag}
          color='secondary' />
      </div>
      <div style={bottomBox}>
        <IonText style={cardTitle}>
          Hold Trending Times
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            {text}
          </IonText>
        </div>
      </div>
    </div>
  )
}

export const Reschedule: React.FC = () => {
  const text = 'When others change their plans, MeetingTime will automagically find a new time for you based on your up-to-date calendar events.'

  return (
    <div style={slide}>
      <div style={iconsBox}>
        <IonIcon
          style={{ fontSize: 32, color: logoColor, marginRight: 10 }}
          icon={time} />
        <IonIcon
          style={{ fontSize: 32, color: logoColor }}
          icon={reload} />
      </div>
      <div style={bottomBox}>
        <IonText style={cardTitle}>
          Automagic Rescheduling
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            {text}
          </IonText>
        </div>
      </div>
    </div>
  )
}

export default EnableAutomagicSchedulingSlides
