import moment from 'moment'
import { Meeting, Participant } from 'types'

function openFile(id: string, url: string): void {
  const el = document.createElement('a')

  el.href = url
  el.target = '_blank'
  el.download = 'MeetingTime-Meeting-' + id
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.click()
  document.body.removeChild(el)
}

// based on https://github.com/adamgibbons/ics/blob/master/src/utils/format-text.js
function formatText(text: string): string {
  return text
    .replace(/\\/gm, '\\\\')
    .replace(/\r?\n/gm, '\\n')
    .replace(/;/gm, '\\;')
    .replace(/,/gm, '\\,')
}

const bullet = '•'

const meetingUrl = process.env.REACT_APP_MEETINGS_APP + '/meeting/'

export function addToDeviceCalendar(meeting: Meeting, participants?: Participant[]): void {
  const confirmed = meeting?.meetingTimes?.find(mt => mt.status === 'confirmed')

  if (confirmed) {
    let description = 'Created by MeetingTime\n\n'

    description += '*** Please Note ***\nThe meeting might have changed since you updated your Calendar. Please check the link below for latest status of the meeting.\n'
    description += meetingUrl + meeting.id + '\n\n'

    if (meeting.videoConference) {
      description += `⇨ Video Conference:\n ${meeting.videoConference.link}\n\n`
    }

    if (participants?.length) {
      const rangeParticipants = confirmed.participantUserIds.map((u: string) => {
        return participants.find((p: Participant) => p.userId === u)
      }).filter(p => p)

      if (rangeParticipants?.length) {
        description += `Attendees: ${rangeParticipants.length}\n`
        rangeParticipants.forEach(participant => {
          description += (`${bullet} ${participant?.displayName}\n`)
        })
      }
    }

    if (meeting.description) {
      description += '\n\nNotes: \n'
      description += meeting.description
    }

    description = formatText(description)
    // console.log('DESCRIPTION=> ', description)

    const url = [
      'BEGIN:VCALENDAR',
      'BEGIN:VEVENT',
      'UID:' + meeting.id,
      'SEQUENCE:1',
      'DTSTART:' + moment.utc(confirmed?.startTime).format('YYYYMMDD[T]HHmm[00Z]'),
      'DTEND:' + moment.utc(confirmed?.endTime).format('YYYYMMDD[T]HHmm[00Z]'),
      'SUMMARY:' + `✅  ${meeting.title}`,
      'DESCRIPTION:' + description,
      'STATUS:CONFIRMED',
      'BEGIN:VALARM',
      'TRIGGER:-PT15M',
      'REPEAT:1',
      'DURATION:PT15M',
      'ACTION:DISPLAY',
      'DESCRIPTION:Reminder',
      'END:VALARM',
      'END:VEVENT',
      'END:VCALENDAR',
    ].filter(v => v).join('\n')

    // console.log('CONFIRMED TIME: ', confirmed)
    // console.log('ADD TO CALENDAR ====>')
    // console.log(url)

    // window.open(encodeURI('data:text/calendar;charset=utf8,' + url), `MeetingTime-Meeting-${meeting.id}`)
    openFile(meeting.id, encodeURI('data:text/calendar;charset=utf8,' + url))
  }
}

export function deleteFromDeviceCalendar(meetingId: string): void {
  const url = [
    'BEGIN:VCALENDAR',
    'METHOD:REQUEST',
    'BEGIN:VEVENT',
    'UID:' + meetingId,
    'SEQUENCE:2',
    'STATUS:CANCELLED',
    'END:VEVENT',
    'END:VCALENDAR',
  ].filter(v => v).join('\n')

  openFile(meetingId, encodeURI('data:text/calendar;charset=utf8,' + url))
}
