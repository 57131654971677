import React, { CSSProperties } from 'react'
import { IonIcon, IonLabel, IonSpinner, IonText } from '@ionic/react'

import { addCircle, checkmarkCircle, helpCircle } from 'ionicons/icons'

import 'theme/styles.css'

import { blue, logoColor } from 'theme/styles'
import { Invitee, InviteeStatus, Participant } from 'types'

import Avatar from '@material-ui/core/Avatar'

import InitiatorBadge from 'components/atoms/InitiatorBadge/InitiatorBadge'
import TipTarget from 'components/atoms/TipTarget/TipTarget'
import { useUser } from 'context/UserContext/UserContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import { withinDayRanges, withinTimeRanges } from '../ScheduleCalendar/utils'

const avatarColor = '#92949C'
const container: CSSProperties = {
  display: 'flex',
  overflowX: 'auto',
  flexWrap: 'nowrap',
  paddingLeft: 10,
  borderBottom: '2px solid gray',
}

const avatarStyle: CSSProperties = {
  width: 44,
  height: 44,
  marginBottom: 5,
}

const inviteeStyle = {
  width: 44,
  height: 44,
  // backgroundColor: avatarColor,
  marginBottom: 5,
}

const addPersonAvatarStyle: CSSProperties = {
  ...avatarStyle,
  backgroundColor: logoColor,
}

const addPersonIcon: CSSProperties = {
  fontSize: 44,
}

// const expectingPeopleIcon: CSSProperties = {
//   fontSize: 50,
//   color: avatarColor,
// }

const MAX_NAME_LENGTH = 8

interface ComponentProps {
  tip?: boolean;
  autoBook?: boolean;
  onInvite: (byLink?: boolean) => void
  onParticipant: (participant: string) => void;
}

const ParticipantAvatarsRow: React.FC<ComponentProps> = ({ tip, autoBook, onInvite, onParticipant }) => {
  // const [prefersDark, setPrefersDark] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)

  const { user } = useUser()
  const { meeting } = useMeetings()
  const { meetingInvitees } = useInvitees()
  const { loading, participants } = useParticipants()
  const { timeSlots } = useTimeSlots()

  // function handleDarkMode (event: MediaQueryListEvent): void {
  //   setPrefersDark(event.matches)
  // }

  // useEffect(() => {
  //   window.matchMedia('(prefers-color-scheme: dark)').addListener(handleDarkMode)

  //   return () => {
  //     window.matchMedia('(prefers-color-scheme: dark)').removeListener(handleDarkMode)
  //   }
  // }, [])


  const invitees = meetingInvitees?.filter(invitee => {
    // when an invitee is marked accepted  go ahead and render it as an invitee
    // until it's added to participant list as there might be a delay between
    // being marked as accepted and added to participants list
    // so to hide that delay show accepted invitee. Once it's added to participant
    // list we fill filter it out below.
    if (invitee.status === InviteeStatus.declined) {
      return false
    }

    if (participants && participants.find(p => p.userId === invitee.userId)) {
      return false
    }

    return true
  })


  function renderParticipant(participant: Participant, key: number): JSX.Element {
    const { displayName, photo } = participant
    let name = displayName.split(' ').find(t => t)?.substring(0, MAX_NAME_LENGTH)
    const initials = displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

    // if initials is > 2 characters then just pick first and last
    const displayInitials = initials.length > 2
      ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

    if (participant.userId === user?.id) {
      name = 'You'
    }

    let checked = false

    if (meeting?.meetingTimes?.length) {
      if (meeting.meetingTimes[0].status === 'confirmed') {
        if (meeting.meetingTimes[0].participantUserIds.indexOf(participant.userId) > -1) {
          checked = true
        }
      }
    }

    let selectedTime

    if (user?.id && participant.userId === user.id && autoBook) {
      selectedTime = true
    } else {
      selectedTime =
        timeSlots?.filter(timeSlot => timeSlot.userId === participant.userId)
          .filter(t => withinDayRanges(t.startTime, t.endTime, meeting?.dayRanges))
          .find(t => {
            if (!meeting?.timeRanges?.length) {
              // if meeting.timeRanges are not set then all time slots withinDayRanges are valid
              return true
            }

            if (withinTimeRanges(t.startTime, meeting?.timeRanges) || withinTimeRanges(t.endTime, meeting?.timeRanges)) {
              return true
            }
          })
    }

    const initiator = meeting?.createdByUserId === participant.userId

    return (
      <ProfileBox
        key={key}
        onClick={() => onParticipant(participant.userId)}>
        <Avatar
          data-ci-id={`participant-avatar-${key}`}
          src={photo}
          style={avatarStyle}>
          <IonText style={{ fontSize: 22 }}>
            {displayInitials}
          </IonText>
        </Avatar>
        <IonLabel>{name}</IonLabel>
        {checked && !initiator && <CheckIcon />}
        {!checked && !selectedTime && !initiator && <WaitingSelectTime />}
        {!checked && selectedTime && !initiator && <CheckIconNotConfirmed />}
        {initiator && <InitiatorBadge
          checked={checked}
          selected={!!selectedTime} />}
      </ProfileBox>
    )
  }

  function renderInvitee(invitee: Invitee, key: number): JSX.Element {
    const { displayName, photo } = invitee
    const name = displayName.split(' ').find(t => t)?.substring(0, MAX_NAME_LENGTH)
    const initials = displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

    // if initials is > 2 characters then just pick first and last
    const displayInitials = initials.length > 2
      ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

    return (
      <ProfileBox
        key={key}
        onClick={() => onParticipant(invitee.userId)}>
        <Avatar
          data-ci-id={`invitee-avatar-${key}`}
          src={photo}
          style={inviteeStyle}>
          <IonText style={{ fontSize: 22 }}>
            {displayInitials}
          </IonText>
        </Avatar>
        <IonLabel>{name}</IonLabel>
        <WaitingSelectTime />
      </ProfileBox>
    )
  }

  function renderExpectingMore(): JSX.Element | undefined {
    if (participants?.length && meeting?.participantsExpected && meetingInvitees) {
      if (meeting?.participantsExpected > participants?.length) {
        const invitees = meetingInvitees?.filter(invitee => {
          if (invitee.status === InviteeStatus.accepted ||
            invitee.status === InviteeStatus.declined) {
            return false
          }

          if (participants && participants.find(p => p.userId === invitee.userId)) {
            return false
          }

          return true
        })

        let count = meeting.participantsExpected - participants.length

        if (invitees.length && invitees.length <= count) {
          count = count - invitees.length
        }

        const expectingAvatarStyle: CSSProperties = {
          ...avatarStyle,
          // border: '2px dashed',
          // borderColor: prefersDark ? 'white' : '#92949C',
          // borderColor: '#92949C',
          // backgroundColor: prefersDark ? 'black' : 'white',
          backgroundColor: avatarColor,
          // backgroundColor: prefersDark ? '#555555' : '#c0c0c0',
        }

        // const expectingPeopleIcon: CSSProperties = {
        //   fontSize: 52,
        //   color: prefersDark ? 'black' : 'white',
        // }

        // if (count && (count > 1 || (participants.length > 1))) {
        if (count) {
          return (
            <ProfileBox onClick={() => onInvite(true)}>
              <Avatar
                data-ci-id='expecting-more-avatar'
                style={expectingAvatarStyle}>
                <IonText style={{ fontSize: 22 }}>
                  {count}
                </IonText>
              </Avatar>
              <IonLabel color='medium'>Awaiting</IonLabel>
              <WaitingSelectTime />
              {/* <ExpectingBadge count={count} /> */}
            </ProfileBox>
          )
        }
      }
    }
  }

  if (participants?.length) {
    return (
      <div
        style={container}>
        <ProfileBox onClick={() => onInvite(false)}>
          <Avatar
            data-ci-id='invite-button'
            style={addPersonAvatarStyle}>
            <IonIcon
              style={addPersonIcon}
              className='addParticipantButton'
              icon={addCircle} />
          </Avatar>
          <IonLabel>Invite</IonLabel>
          {tip &&
            <TipTarget style={{ top: 25, right: -5, position: 'absolute' }} />}
        </ProfileBox>
        {renderExpectingMore()}
        {participants.map((participant: Participant, i: number) => renderParticipant(participant, i))}
        {invitees?.map((invitee: Invitee, i: number) => renderInvitee(invitee, i))}
      </div>
    )
  } else if (loading) {
    return (
      <div
        style={container}>
        <ProfileBox>
          <IonSpinner name='dots' />
        </ProfileBox>
      </div>
    )
  }

  return null
}

interface BoxProps {
  onClick?: () => void
}

const ProfileBox: React.FC<BoxProps> = ({ children, onClick }) => {
  const container: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    marginRight: 15,
    width: 60,
    height: 70,
    position: 'relative',
  }

  return (
    <div
      onClick={onClick}
      style={container}>
      {children}
    </div>
  )
}

const CheckIcon: React.FC = () => {
  const icon: CSSProperties = {
    top: 25,
    right: 0,
    position: 'absolute',
    zIndex: 1,
    height: 22,
    width: 22,
    borderRadius: 11,
    backgroundColor: 'white',
  }

  return (
    <IonIcon
      color='success'
      icon={checkmarkCircle}
      style={icon} />
  )
}

const CheckIconNotConfirmed: React.FC = () => {
  const icon: CSSProperties = {
    top: 25,
    right: 0,
    position: 'absolute',
    zIndex: 1,
    height: 22,
    width: 22,
    borderRadius: 11,
    backgroundColor: 'white',
    color: blue,
  }

  return (
    <IonIcon
      icon={checkmarkCircle}
      style={icon} />
  )
}

// const InviteIcon: React.FC = () => {
//   const icon: CSSProperties = {
//     top: 25,
//     right: 0,
//     position: 'absolute',
//     zIndex: 1,
//     height: 22,
//     width: 22,
//     borderRadius: 11,
//     backgroundColor: 'white',
//     color: 'white',
//   }

//   return (
//     <IonIcon
//       icon={alertCircle}
//       style={icon} />
//   )
// }

const WaitingSelectTime: React.FC = () => {
  const icon: CSSProperties = {
    top: 25,
    right: 0,
    position: 'absolute',
    zIndex: 1,
    height: 22,
    width: 22,
    borderRadius: 11,
    backgroundColor: 'white',
    color: avatarColor,
  }

  return (
    <IonIcon
      icon={helpCircle}
      style={icon} />
  )
}

interface ExpectingProps {
  count: number
}

// const ExpectingBadge: React.FC<ExpectingProps> = ({ count }) => {
//   const container: CSSProperties = {
//     top: 25,
//     right: 0,
//     position: 'absolute',
//     zIndex: 1,
//   }

//   const circle: CSSProperties = {
//     height: 24,
//     width: 24,
//     borderRadius: 12,
//     border: '2px solid white',
//     backgroundColor: avatarColor,
//     // display: 'flex',
//     // alignItems: 'center',
//     // justifyContent: 'center',
//     textAlign: 'center',
//   }

//   const badge: CSSProperties = {
//     color: 'white',
//     // fontWeight: 'bold',
//     fontSize: 13,
//   }

//   return (
//     <div
//       style={container}>
//       <div
//         style={circle}>
//         <IonText style={badge}>
//           {count}
//         </IonText>
//       </div>
//     </div>
//   )
// }

export default ParticipantAvatarsRow
