import { combineReducers } from 'redux'
import { AppResetAction, APP_RESET } from './types'
import screensReducer from './screens'
import tipsReducer from './tips'
import meetingsReducer from './meetings'
import participantsReducer from './participants'
import accountsReducer from './accounts'

export const rootReducer = combineReducers({
  screens: screensReducer,
  tips: tipsReducer,
  meetings: meetingsReducer,
  participants: participantsReducer,
  accounts: accountsReducer,
})

export function resetApp (): AppResetAction {
  localStorage.removeItem('accessToken')

  console.log('RESET APP!!!')
  // NOTE we don't delete this else if the user verifies in a different tab
  // it could zap the device ID
  // localStorage.removeItem('device')
  // localStorage.removeItem('verifyToken')

  // note don't delete device and verifyToken
  return {
    type: APP_RESET,
  }
}

export type RootState = ReturnType<typeof rootReducer>
