import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateScreens } from 'services/store/screens/actions'

import 'screens/Settings.css'
import StartMeetUser from './StartMeetUser'
import MeetUserProvider from 'context/MeetUserContext/MeetUserContext'
import paths from 'navigation/paths'

type ScreenProps = RouteComponentProps<{
  userHandle: string;
}>

const StartMeetWithScreen: React.FC<ScreenProps> = ({ history, match }) => {
  const userHandle = match.params.userHandle

  const dispatch = useDispatch()

  function onNewUser(meetUser: string): void {
    dispatch(updateScreens({ newUserName: { meetUser } }))
    history.replace(paths.newUserName)
  }

  function onLogin(verifyToken: string, meetUser: string): void {
    dispatch(updateScreens({ loginFirebase: { verifyToken, meetUser } }))

    const url = `/login?verifyToken=${verifyToken}&meet=${meetUser}`

    history.replace(url)
  }

  function onSelectAccounts(meetUser: string): void {
    dispatch(updateScreens({ startScreen: { meetUser } }))
    history.replace(paths.welcome)
  }

  return (
    <MeetUserProvider userHandle={userHandle}>
      <StartMeetUser
        onNewUser={onNewUser}
        onLogin={onLogin}
        onSelectAccounts={onSelectAccounts} />
    </MeetUserProvider>
  )
}

export default StartMeetWithScreen
