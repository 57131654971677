import React, { CSSProperties } from 'react'
import { CalendarAccount, Participant } from 'types'
import ListItem from 'components/atoms/ListItem/ListItem'

const container: CSSProperties = {
  marginTop: 10,
}

export interface MeetingFreeBusyCalendarsProps {
  participant: Participant;
  accounts: CalendarAccount[];
  onClick: () => void;
}

const ViewCalendars: React.FC<MeetingFreeBusyCalendarsProps> = ({ participant, accounts, onClick }) => {
  const { calendars } = participant
  let count = 0

  // console.log('ViewCalendars: Participant: ', participant)
  accounts.forEach(account => {
    account.calendars.forEach(c => {
      if (Array.isArray(calendars)) {
        const calendar = calendars.find(cal => (cal.calendarId === c.calendarId && cal.account === account.id))

        if (calendar) {
          if (calendar.enabled) {
            count += 1
          }
        } else if (c.enabled) {
          count += 1
        }
      } else if (c.enabled) {
        count += 1
      }
    })
  })

  return (
    <div style={container}>
      <ListItem
        title='using availability calendars'
        detail
        label={`${count}`}
        onClick={onClick} />
    </div>

  )
}

export default ViewCalendars
