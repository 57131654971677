import React, { CSSProperties } from 'react'
import {
  IonButton,
  IonCard,
  IonIcon, IonItem, IonLabel, IonText,
} from '@ionic/react'
import ProfilePhoto from 'components/atoms/ProfilePhoto/ProfilePhoto'
import { checkmarkCircle } from 'ionicons/icons'
import { MeetUser } from 'types'

const welcomeText: CSSProperties = {
  fontSize: 20,
  textAlign: 'center',
}

const photoBox: CSSProperties = {
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const messageBox: CSSProperties = {
  padding: 20,
  textAlign: 'center',
}

interface ComponentProps {
  meetUser: MeetUser;
  onMeet: () => void;
}

function getInitials(name: string): string {
  return name.split(' ').map((t: string) => t.charAt(0) && t.charAt(0).toUpperCase()).join('')
}

const MeetMe: React.FC<ComponentProps> = ({ meetUser, onMeet }) => {
  function renderPhoto(): JSX.Element | undefined {
    if (meetUser) {
      const initials = getInitials(meetUser.displayName)

      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')

      const photoStyle = {
        width: 100,
        height: 100,
        border: '3px solid white',
        boxShadow: prefersDark?.matches ? '' : '0px 4px 8px gray',
      }

      return (
        <div
          style={photoBox}
          className='titleIconBox'>
          <ProfilePhoto
            style={photoStyle}
            fontSize={32}
            initials={initials}
            photo={meetUser.photo} />
          <IonText
            color='dark'
            style={welcomeText}>
            {meetUser.displayName}
          </IonText>
        </div>
      )
    }
  }

  function renderWelcomeMessage(): JSX.Element | undefined {
    if (meetUser) {
      return (
        <div style={messageBox}>
          <div>
            <IonText style={{ fontWeight: 'bold', fontSize: 22 }}>
              Hi!
            </IonText>
          </div>
          <div style={{ marginTop: 10 }}>
            <IonText>
              Setting up a meeting with me is easy
            </IonText>
          </div>
        </div>
      )
    }
  }

  function renderSteps(): JSX.Element | undefined {
    if (meetUser) {
      return (
        <IonCard>
          <IonItem
            lines='inset'>
            <IonText slot='start'>
              <strong>1.</strong>
            </IonText>
            <IonLabel
              className='ion-text-wrap'
              style={{ fontSize: 18 }}>
              Set your meeting parameters
            </IonLabel>
          </IonItem>
          <IonItem
            lines='inset'>
            <IonText slot='start'>
              <strong>2.</strong>
            </IonText>
            <IonLabel
              className='ion-text-wrap'
              style={{ fontSize: 18 }}>
              Select some convenient times for you
            </IonLabel>
          </IonItem>
          <IonItem
            lines='none'>
            <IonText slot='start'>
              <strong>3.</strong>
            </IonText>
            <IonLabel
              className='ion-text-wrap'
              style={{ fontSize: 18 }}>
              Invite others if you wish
            </IonLabel>
          </IonItem>
        </IonCard>
      )
    }
  }

  function renderMeetingTimeMessage(): JSX.Element | undefined {
    if (meetUser) {
      return (
        <div style={messageBox}>
          {/* <IonText
            slot='start'>
            <img
              src='assets/icon/MeetingTimeIcon.png'
              width='26'
              height='26'
              alt='' />
          </IonText> */}
          <IonIcon
            color='success'
            icon={checkmarkCircle}
            style={{ fontSize: 42 }} />
          <div style={{ marginTop: 10 }}>
            <IonText>
              MeetingTime will automagically find a time that works for everyone
            </IonText>
          </div>
        </div>
      )
    }
  }

  // const MAX_NAME_LENGTH = 8

  function renderStartButton(): JSX.Element | undefined {
    if (meetUser) {
      // const buttonName = meetUser.name.split(' ').find(t => t)?.substring(0, MAX_NAME_LENGTH)

      const txt = 'Let\'s find a time'

      return (
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <IonButton
            shape='round'
            expand='block'
            fill='solid'
            color='primary'
            onClick={onMeet}>
            {txt}
          </IonButton>
        </div>
      )
    }
  }

  return (
    <div>
      {renderPhoto()}
      {renderWelcomeMessage()}
      {renderSteps()}
      {renderMeetingTimeMessage()}
      {renderStartButton()}
    </div>
  )
}

export default MeetMe
