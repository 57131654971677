import React, { useContext } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'services/store'

import { Screens } from 'services/store/screens/types'
import { updateScreens } from 'services/store/screens/actions'

interface ScreensContextValue {
  screens: Screens,
  updateScreens: (screens: Screens) => Promise<void>
}

const initialValue: ScreensContextValue = {
  screens: {},
  updateScreens: async (screens: Screens) => {
    console.log('updateScreens: ', screens)
  },
}
// create and initialize context
export const ScreensContext = React.createContext<ScreensContextValue>(initialValue)

export function useScreens (): ScreensContextValue {
  return useContext(ScreensContext)
}

export type ScreensMockContextValue = Partial<ScreensContextValue>

type MockProps = {
  value?: Partial<ScreensContextValue>
}

export const ScreensMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <ScreensContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </ScreensContext.Provider>
  )
}

const ScreensProvider: React.FC = ({ children }) => {
  const screens = useSelector((state: RootState) => state.screens)
  const dispatch = useDispatch()

  async function onUpdateScreens (screens: Screens): Promise<void> {
    dispatch(updateScreens(screens))
  }

  return (
    <ScreensContext.Provider
      value={{
        screens,
        updateScreens: onUpdateScreens,
      }}>
      {children}
    </ScreensContext.Provider>
  )
}

export default ScreensProvider
