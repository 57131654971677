import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import 'screens/Settings.css'
import FirstMeeting from './FirstMeeting'
import { updateScreens } from 'services/store/screens/actions'
import paths from 'navigation/paths'

const FirstMeetingScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch()

  // function onCreateMeeting (): void {
  //   history.replace('/tabs/meetings')

  //   dispatch(updateScreens({ meetingSettings: { steps: true, tips: true } }))
  //   history.push(paths.meetingSettings)
  // }

  function onCreateMeeting(): void {
    // history.replace('/tabs/meetings')
    dispatch(updateScreens({
      meetingSettings: {
        meetingId: '',
        steps: true,
        tips: true,
      },
      meetingsList: {
        firstCreatedMeeting: true,
      },
    }))
    history.push(paths.meetingSettings)
  }

  return (
    <FirstMeeting
      onCreateMeeting={onCreateMeeting} />
  )
}

export default FirstMeetingScreen
