import React, { CSSProperties, useRef, useEffect } from 'react'
import moment from 'moment'
import { useDrag } from 'react-use-gesture'
import { useCalendarView } from 'components/organisms/ScheduleCalendar/CalendarViewContext'

const LONG_PRESS_THRESHOLD = 500
const TAP_END_DELAY_THRESHOLD = 6000

// max minutes range for a day
const MAX_MINUTES = 24 * 60

let singleTapDebounce = false
let singleTapDelay: NodeJS.Timeout

export interface DayColumnGesturesProps {
  startDay: number;
  day: number;
  scale: number;
  parentId: string;
  tip?: boolean;
  onSingleTap: (time: string) => void;
  onLongTap: (time: string) => void;
  onDrag: (fromTime: string, toTime: string) => void;
  onDragEnd: () => void;
  onTouchEndDelay: () => void;
}

const DayColumnGestures: React.FC<DayColumnGesturesProps> =
({
  children, startDay, day, scale, parentId, tip, onSingleTap, onLongTap, onDrag, onDragEnd, onTouchEndDelay,
}) => {
  const { timeSlotWidth = 90, timeSlotHeight = 50, calendarTopPadding = 20 } = useCalendarView()

  const gestureRef = useRef<HTMLDivElement>(null)

  const dayStartTime = moment()
    .add((startDay + day), 'days')
    .startOf('day')
    .toISOString()

  useEffect(() => {
    // on dismount stop timer
    return () => {
      clearTimeout(singleTapDelay)
    }
  }, [])

  function timeFromOffset (offset: number): string {
    // get the distance of the top of the day column relative to top of screen
    const topY = gestureRef.current?.getBoundingClientRect().top

    if (topY) {
      const Y = offset - topY
      let minutes = Math.floor((Y / timeSlotHeight) * scale)

      if (minutes < 0) minutes = 0
      else if (minutes > MAX_MINUTES) minutes = MAX_MINUTES

      minutes = Math.floor(minutes / 15) * 15

      const time = moment(dayStartTime).add(minutes, 'minutes').toISOString()

      // console.log('TIME: ', time)

      return time
    }

    return ''
  }

  function verticalPan (offset: number, deltaY: number): void {
    const calendarBox = document.getElementById(parentId)

    if (calendarBox) {
      const { scrollTop, clientHeight } = calendarBox
      const topY = gestureRef.current?.getBoundingClientRect().top

      // adjust topMarker for top padding so we pan up beyond the actuall top
      const topMarker = scrollTop - calendarTopPadding

      // client height includes padding so account for it
      // scroll a bit beyond the actual bottom
      const bottomMarker = scrollTop + clientHeight - calendarTopPadding - 20

      // console.log(`topY ${topY} offset ${offset} bottom ${scrollBottom}`)

      if (topY) {
        const Y = offset - topY

        // console.log(`PAN: scrollTop ${scrollTop} ${scrollBottom} Y ${Y} `)

        if (deltaY < 0 && Y < topMarker) {
          calendarBox.scrollTop = Y - 20
        }

        if (deltaY > 0 && Y > bottomMarker) {
          calendarBox.scrollTop = calendarBox.scrollTop + (Y - bottomMarker) + 10
        }
      }
    }
  }

  const bind = useDrag(({ down, tap, last, initial, xy, movement, elapsedTime }) => {
    // console.log(`DRAG: tap ${tap} down ${down} last ${last} initialXY ${initial} currentXY: ${xy} movement ${movement}`)
    // console.log('DIRECTION: ', direction)

    const startY = initial[1]
    const currentY = xy[1]
    const deltaY = movement[1]

    clearTimeout(singleTapDelay)

    if (tap) {
      // console.log(`single tap elapsedTime: ${elapsedTime}`)
      if (elapsedTime < LONG_PRESS_THRESHOLD) {
        if (!singleTapDebounce) {
          singleTapDebounce = true
          setTimeout(() => {
            singleTapDebounce = false
          }, 500)
          onSingleTap(timeFromOffset(currentY))
          singleTapDelay = setTimeout(() => {
            onTouchEndDelay()
          }, TAP_END_DELAY_THRESHOLD)
        }
      } else {
        onLongTap(timeFromOffset(currentY))
      }
    } else if (deltaY !== 0) {
      // if (deltaY > 0) {
      //   console.log('drag down')
      // } else {
      //   console.log('drag up')
      // }

      if (down && !last) {
        const fromTime = timeFromOffset(startY)
        const toTime = timeFromOffset(currentY)

        // console.log('DRAG TO TIME: ', toTime)

        if (moment(toTime).valueOf() > moment(dayStartTime).valueOf() &&
        moment(toTime).valueOf() < moment(dayStartTime).add(24, 'hours').valueOf()) {
          verticalPan(currentY, deltaY)
        }

        onDrag(fromTime, toTime)
      } else if (!down && last) {
        console.log('drag end')
        onDragEnd()
      }
    }
  }, { axis: 'y', drag: { delay: true } })

  const container: CSSProperties = {
    minWidth: timeSlotWidth,
    // we meed to use flexGrow so the container
    // can grow with the children
    // and the last empty timeSlot renders
    // at the correct position on iOS
    display: 'flexGrow',
    flexDirection: 'column',
    position: 'relative',
  }

  if (tip) {
    container.border = '2px solid #3880ff'
  }

  return (
    <div
      ref={gestureRef}
      {...bind()}
      style={container}>
      {children}
    </div>
  )
}

export default DayColumnGestures
