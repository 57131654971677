import React, { useContext } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'services/store'

import { Tips } from 'services/store/tips/types'
import { updateTips, enableAllTips } from 'services/store/tips/actions'
import { flagDisableDefaultTips } from 'flags'

export interface StoreTipsContextValue {
  storeTips: Tips,
  updateStoreTips: (tips: Tips) => Promise<void>
  enableAllTips: () => Promise<void>
}

export const initialValue: StoreTipsContextValue = {
  storeTips: {
    enabled: !flagDisableDefaultTips,
  },
  updateStoreTips: async (tips: Tips) => {
    console.log('updateStoreTips: ', tips)
  },
  enableAllTips: async () => {
    console.log('enableAllTips: ')

    return undefined
  },
}

// create and initialize context
export const StoreTipsContext = React.createContext<StoreTipsContextValue>(initialValue)

export function useTips(): StoreTipsContextValue {
  return useContext(StoreTipsContext)
}

export type StoreTipsMockContextValue = Partial<StoreTipsContextValue>

type MockProps = {
  value?: StoreTipsMockContextValue
}

export const StoreTipsMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <StoreTipsContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </StoreTipsContext.Provider>
  )
}

const StoreTipsContextProvider: React.FC = ({ children }) => {
  const storeTips = useSelector((state: RootState) => state.tips)
  const dispatch = useDispatch()

  async function onUpdateStoreTips(tips: Tips): Promise<void> {
    dispatch(updateTips(tips))
  }
  async function onEnableAllTips(): Promise<void> {
    dispatch(enableAllTips())
  }

  return (
    <StoreTipsContext.Provider
      value={{
        storeTips,
        updateStoreTips: onUpdateStoreTips,
        enableAllTips: onEnableAllTips,
      }}>
      {children}
    </StoreTipsContext.Provider>
  )
}

export default StoreTipsContextProvider
