
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from 'services/store'
import { updateScreens } from 'services/store/screens/actions'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import PreferencesProvider from 'context/PreferencesContext/PreferencesContext'
import Invitations from './Invitations'
import paths from 'navigation/paths'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
// import TipCard from 'components/organisms/TipCard'

type ScreenProps = RouteComponentProps<{
  id: string;
}>

const InvitationsScreen: React.FC<ScreenProps> = ({ history, match, location }) => {
  const storeTips = useSelector((state: RootState) => state.tips)

  const [meetingId, setMeetingId] = useState('')
  const invitation = match.params.id

  const dispatch = useDispatch()

  useEffect(() => {
    if (location.search) {
      console.log('Login: url: ', location.search)

      const params = queryString.parse(location.search)
      const meeting = params.meetingId as string

      if (meetingId) {
        setMeetingId(meetingId)
      }
    }
  }, [location.search])

  function goBack(): void {
    history.replace(paths.meetingsList)
  }

  function onChooseAvailability(args: {
    meetingId: string, connectCalendar?: boolean, autoBook?: boolean, inviteCount?: number; startEdit?: boolean
  }): void {
    const { meetingId, connectCalendar, autoBook, inviteCount } = args

    if (autoBook) {
      if (!storeTips?.tipsAvailabilityCalendarAuto?.completed) {
        dispatch(updateScreens({
          chooseAvailability: { meetingId, startEdit: true },
          meetingsList: {},
          tipsAvailabilityCalendarAuto: {
            nextScreen: paths.chooseAvailability,
          },
        }))
        history.push(paths.tipsAvailabilityCalendarAuto)
      }
    } else {
      if (!storeTips?.tipsAvailabilityCalendar?.completed) {
        dispatch(updateScreens({
          chooseAvailability: { meetingId, startEdit: true },
          meetingsList: {},
          tipsAvailabilityCalendar: {
            nextScreen: paths.chooseAvailability,
          },
        }))
        history.push(paths.tipsAvailabilityCalendar)

        return
      }

      if (connectCalendar) {
        if (!storeTips?.tipsConnectCalendars?.skipped ||
          storeTips?.tipsConnectCalendars?.skipped < 2) {
          dispatch(updateScreens({
            chooseAvailability: { meetingId, startEdit: true },
            askConnectCalendars: {
              meetingId,
              previous: paths.meetingsList,
              nextScreen: paths.addParticipants,
            },
          }))
          history.push(paths.askConnectCalendar)

          return
        }
      }
    }

    if (inviteCount && inviteCount > 1) {
      dispatch(updateScreens({ chooseAvailability: { meetingId, startEdit: true } }))
      history.push(paths.chooseAvailability)
    } else {
      dispatch(updateScreens({ chooseAvailability: { meetingId, previous: paths.meetingsList, startEdit: true } }))
      history.replace(paths.chooseAvailability)
    }
  }

  function onMeetingCalendarSettings(meetingId: string): void {
    dispatch(updateScreens({ meetingCalendarSettings: { meetingId, previous: paths.meetingsList } }))
    history.push(paths.meetingCalendarSettings)
  }

  function onAddParticipants(meetingId: string): void {
    dispatch(updateScreens({
      addParticipants: {
        meetingId,
        previous: paths.meetingsList,
        nextScreen: paths.meetingsList,
      },
    }))
    history.push(paths.addParticipants)
  }

  function onMeetingSettings(meetingId: string, meetMeInvitee?: boolean): void {
    dispatch(updateScreens({
      meetingSettings: {
        meetingId,
        meetMeInvitee,
      },
    }))
    history.push(paths.meetingSettings)
  }

  return (
    <PreferencesProvider>
      <CalendarsProvider>
        <InviteesProvider>
          <MeetingsProvider>
            <Invitations
              invitation={invitation}
              meetingId={meetingId}
              onChooseAvailability={onChooseAvailability}
              onMeetingCalendarSettings={onMeetingCalendarSettings}
              onAddParticipants={onAddParticipants}
              onMeetingSettings={onMeetingSettings}
              goBack={goBack} />
          </MeetingsProvider>
        </InviteesProvider>
      </CalendarsProvider>
    </PreferencesProvider>
  )
}

export default InvitationsScreen
