import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'

import { GET_CONNECTIONS } from 'services/api'
import { Connection } from 'types'

export interface ConnectionsContextValue {
  loading: boolean
  connections: Connection[]
}

const initialValue: ConnectionsContextValue = {
  loading: false,
  connections: [],
}
// create and initialize context
export const ConnectionsContext = React.createContext<ConnectionsContextValue>(initialValue)

export function useConnections (): ConnectionsContextValue {
  return useContext(ConnectionsContext)
}

export type ConnectionsMockContextValue = Partial<ConnectionsContextValue>

type MockProps = {
  value?: Partial<ConnectionsContextValue>
}

export const ConnectionsMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <ConnectionsContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </ConnectionsContext.Provider>
  )
}

const ConnectionsContextProvider: React.FC = ({ children }) => {
  const { loading, data } = useQuery(GET_CONNECTIONS, {
    fetchPolicy: 'cache-and-network',
  })

  return (
    <ConnectionsContext.Provider
      value={{
        loading,
        connections: data?.connections,
      }}>
      {children}
    </ConnectionsContext.Provider>
  )
}

export default ConnectionsContextProvider
