
import React, { CSSProperties } from 'react'
import { IonButton, IonCard, IonCardContent, IonIcon, IonText } from '@ionic/react'
import { chevronDown } from 'ionicons/icons'
import { animated } from 'react-spring'

import { useTips } from 'context/TipsContext/TipsContext'

const tipBox: CSSProperties = {
  position: 'absolute',
  width: '100%',
  // paddingLeft: 10,
  // paddingRight: 10,
  zIndex: 10,
}

// const card: CSSProperties = {
//   // border: `1px solid ${logoColor}`,
//   // border: '1px solid gold',
//   borderRadius: 10,
//   display: 'flex',
// }

const defaultCardStyle: CSSProperties = {
  margin: 0,
  borderRadius: 0,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  paddingBottom: 20,
  display: 'flex',
}

const box1: CSSProperties = {
  flex: 1,
  // padding: 16,
}

const topBox: CSSProperties = {
  display: 'flex',
  height: 30,
  // the IonCardContent above bottom box has a bottom padding of 20
  // and the buttons in bottombar have their own padding which add up
  // so lift up the bottom bar so that only the button padding is sufficient
  justifyContent: 'flex-end',
}

const bottomBox: CSSProperties = {
  display: 'flex',
  // the IonCardContent above bottom box has a bottom padding of 20
  // and the buttons in bottombar have their own padding which add up
  // so lift up the bottom bar so that only the button padding is sufficient
  // marginTop: -10,
  justifyContent: 'flex-end',
  // border: '1px solid red',
}

const closeButton: CSSProperties = {
  fontSize: 20,
  color: 'white',
  // position: 'absolute',
  // right: 4,
  // bottom: 8,
}

const cardContent: CSSProperties = {
  paddingLeft: 16,
  paddingRight: 32,
  paddingTop: 0,
  paddingBottom: 0,
  // border: '1px solid red',
}

interface ContainerProps {
  boxStyle?: CSSProperties,
  cardStyle?: CSSProperties,
  className?: string;
  onClose?: () => void,
  onButton?: () => void,
  buttonLabel?: string;
  closeIcon?: string;
  buttons?: string[];
  onButtons?: (button: number) => void;
  animatedProps?: CSSProperties,
}

const TipCard: React.FC<ContainerProps> = (
  { boxStyle, className, cardStyle = defaultCardStyle, buttonLabel, animatedProps, children, buttons, closeIcon, onClose, onButton, onButtons }) => {
  // const tips = useSelector((state: RootState) => state.tips) as Tips
  const { storeTips } = useTips()

  function renderClose (): JSX.Element | undefined {
    return (
      <div style={topBox}>
        {onClose &&
          <IonButton
            data-ci-id='tip-close'
            mode='md'
            fill='clear'
            color='dark'
            size='small'
            style={{ height: 30 }}
            onClick={() => onClose()}>
            <IonIcon
              slot='icon-only'
              icon={closeIcon || chevronDown}
              style={closeButton} />
          </IonButton>}
      </div>
    )
  }

  function renderButton (): JSX.Element | undefined {
    if (onButton) {
      const button = buttonLabel || 'Got It'

      return (
        <div style={bottomBox}>
          <IonButton
            data-ci-id={`tip-${button}`}
            mode='md'
            fill='clear'
            color='dark'
            size='small'
            onClick={() => onButton()}>
            <IonText style={{ color: 'white' }}>
              {button}
            </IonText>
          </IonButton>
        </div>
      )
    }
  }

  function renderButtons (): JSX.Element | undefined {
    if (buttons && onButtons) {
      return (
        <div style={bottomBox}>
          {buttons.map((button, i) => {
            return (
              <IonButton
                key={i}
                mode='md'
                fill='clear'
                color='dark'
                size='small'
                onClick={() => onButtons(i)}>
                <IonText style={{ color: 'white' }}>
                  {button}
                </IonText>
              </IonButton>
            )
          })}
        </div>
      )
    }
  }

  if (storeTips?.enabled) {
    return (
      <animated.div
        style={{ ...tipBox, ...boxStyle, ...animatedProps }}>
        <IonCard
          data-ci-id='tip-card'
          color='primary'
          className={className}
          style={cardStyle}>
          <div style={box1}>
            {renderClose()}
            <IonCardContent style={cardContent}>
              {children}
            </IonCardContent>
            {renderButton()}
            {renderButtons()}
          </div>
        </IonCard>
      </animated.div>
    )
  }

  return <div />
}

export default TipCard
