import React, { CSSProperties, useEffect, useState } from 'react'

import { TimeSlot as TimeSlotType, TimeRange, CalendarEvent, Participant } from 'types'
import moment from 'moment'

import {
  CreateTimeSlotInput, DeleteTimeSlotInput, UpdateTimeSlotInput,
} from 'services/api'
import DayColumn from '../DayColumn/DayColumn'

import { IonPopover, IonList, IonItem, IonLabel, IonText, IonIcon, IonToast } from '@ionic/react'
import { Avatar } from '@material-ui/core'

import { ellipse, checkmark } from 'ionicons/icons'
import { useUser } from 'context/UserContext/UserContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useCalendarView } from '../ScheduleCalendar/CalendarViewContext'
import { useTips } from 'context/TipsContext/TipsContext'
import { MeetingBookCalendar } from 'context/CalendarsContext/CalendarsContext'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'
// import { getUserTimeSlot } from '../ScheduleCalendar/utils'

// time grid height in minutes
const scale = 60
const calendarBoxId = 'selectDaySchedule'

const avatarStyle = {
  width: 30,
  height: 30,
  backgroundColor: '#92949C',
  marginLeft: -5,
  marginRight: 10,
}

type PopoverInfo = {
  time: string;
  participants?: Participant[];
  startTime?: string;
  endTime?: string;

  calendarEvents?: CalendarEvent[];
}

interface ComponentProps {
  id?: string;
  selectedTime: string;
  reload: number;
  calendarEvents?: CalendarEvent[];
  bookCalendar?: MeetingBookCalendar;
  tip?: boolean;
  onScroll: (top: number, left: number, id: string) => void;
}

const SelectDaySchedule: React.FC<ComponentProps> =
  ({ id = 'selectDaySchedule', tip, selectedTime, bookCalendar, calendarEvents, onScroll }) => {
    const [showInfoPopover, setShowInfoPopover] = useState(false)

    const [disableScroll, setDisableScroll] = useState(false)
    const [popoverInfo, setPopoverInfo] = useState<PopoverInfo>()
    const [editSlot, setEditSlot] = useState<TimeSlotType>()
    const [showActivity, setShowActivity] = useState<TimeSlotType>()
    const [showToast, setShowToast] = useState('')
    const [showBlockTimeToast, setShowBlockTimeToast] = useState(false)

    const { user } = useUser()
    const { meeting } = useMeetings()
    const { timeSlots, createTimeSlot, updateTimeSlot, deleteTimeSlot } = useTimeSlots()
    const { participants } = useParticipants()
    const { storeTips, updateStoreTips } = useTips()
    const { calendarTopPadding = 20, timeSlotHeight = 50 } = useCalendarView()
    const { logEvent } = useAnalytics()

    // selected time is in ISO string
    const startOfDay = moment().startOf('day').toISOString()
    const selectedDay = moment(selectedTime).startOf('day').diff(moment(startOfDay), 'days')

    // console.log('selectDaySchedule: start of day ', moment().startOf('day').toString())
    // console.log('selectDaySchedule: selectedTime ', selectedTime)
    // console.log('selectDaySchedule: selectedDay ', selectedDay)

    useEffect(() => {
      setTimeout(() => {
        const el = document.getElementById(id)

        // console.log('SelectDaySchedule start')

        if (el) {
          console.log('SelectDaySchedule found el selectedTime: ', selectedTime)

          const localTime = moment(selectedTime).format('H')

          // console.log('SELECTED TIME: ', selectedTime)
          console.log('SCROLL TO TIME: ', localTime, timeSlotHeight)
          el.scrollTop = timeSlotHeight * Number(localTime)
        }
      }, 1000)
    }, [])

    // useEffect(() => {
    //   // set edit slot depending on selected time
    //   if (user?.id && timeSlots && !editSlot) {
    //     const timeSlot = getUserTimeSlot(selectedTime, user.id, timeSlots)

    //     // that function will return a timeSlot if the time is exactly on the boundary
    //     // so make sure the selected time is actually inside the range and only then delete it
    //     // do'nt merge with busy slots
    //     if (timeSlot &&
    //     moment(selectedTime).valueOf() >= moment(timeSlot.startTime).valueOf() &&
    //     moment(selectedTime).valueOf() < moment(timeSlot.endTime).valueOf()) {
    //       startEditSlot(timeSlot)
    //     }
    //   }
    // }, [user, timeSlots])

    function logTap(eventData: EventData): void {
      logEvent({
        eventName: EventName.buttonTap,
        eventData: { ...eventData },
      })
    }

    function doScroll(): void {
      const el = document.getElementById(id)

      if (el) {
        onScroll(el.scrollTop, el.scrollLeft, id)
      }
    }

    function checkAutoRange(startTime?: string): boolean {
      let autoRange = false

      if (startTime && bookCalendar?.autoBook && bookCalendar?.autoDays && bookCalendar?.autoTimes) {
        const { autoTimes, autoDays } = bookCalendar
        const day = moment(startTime).format('ddd').toLowerCase()

        if (autoDays.find(d => d === day)) {
          // calculate start and end minute markers
          const startMinutes = moment(startTime).diff(moment(startTime).startOf('day'), 'minutes')

          autoTimes.every(range => {
            const startRange = moment(range.startTime).diff(moment(range.startTime).startOf('day'), 'minutes')
            const endRange = moment(range.endTime).diff(moment(range.startTime).startOf('day'), 'minutes')

            if (startMinutes >= startRange && startMinutes < endRange) {
              autoRange = true

              if (showBlockTimeToast) {
                const toast = 'Automagic Scheduling is ON from: \n' +
                  `${moment(range.startTime).format('h:mm a')} - ${moment(range.endTime).format('h:mm a')}\n` +
                  'You can mark a time range as Busy and MeetingTime will not schedule you then'

                setShowToast(toast)
              }

              return false
            }

            return true
          })
        }
      }

      return autoRange
    }

    function startEditSlot(timeSlot?: TimeSlotType): void {
      console.log('ON EDIT: DISABLE SCROLL')

      if (timeSlot) {
        if (timeSlot.available &&
          checkAutoRange(timeSlot.startTime) &&
          showBlockTimeToast) {
          // if in autoRange and showBlockToast enabled then ignore
          return
        }
        // Note: don't brute force disable scroll when edit is ON
        // disable only when dragging
        // setDisableScroll(true)
      } else {
        setDisableScroll(false)
      }

      setEditSlot(timeSlot)
    }

    function onEdit(): void {
      // console.log('ON EDIT: DISABLE SCROLL')
      // setDisableScroll(true)
    }

    function onEditEnd(): void {
      console.log('ON EDIT END: ENABLE SCROLL')
      setDisableScroll(false)
    }

    function onDragStart(fromTime: string): void {
      if (editSlot) {
        if (moment(fromTime).isAfter(moment(editSlot.startTime)) &&
          moment(fromTime).isBefore(moment(editSlot.endTime))) {
          setDisableScroll(true)
        }
      }
    }

    function onDragEnd(): void {
      setDisableScroll(false)
    }

    async function onCreateTimeSlot(startTime: string, endTime: string): Promise<void> {
      // console.log(`onCreateTimeSlot: ${startTime} to ${endTime}`)

      // create time slot with the server
      if (createTimeSlot && meeting?.id) {
        const available = !checkAutoRange(startTime)

        if (!available && showBlockTimeToast) {
          // if in autoRange and showBlockToast enabled then ignore
          return
        }

        const input: CreateTimeSlotInput = {
          meetingId: meeting.id,
          startTime,
          endTime,
          available,
        }

        // console.log('Creating time slots with server: ', input)
        setShowActivity(input)

        const timeSlot = await createTimeSlot(input)

        if (timeSlot) {
          startEditSlot(timeSlot)
        }

        if (!storeTips?.selectDaySchedule?.selectTimes && updateStoreTips) {
          updateStoreTips({ selectDaySchedule: { selectTimes: true } })
        }

        setShowActivity(undefined)
      }
    }

    async function onUpdateTimeSlot(timeSlot: TimeSlotType): Promise<void> {
      const { startTime, endTime, available } = timeSlot

      // console.log(`onUpdateTimeSlot: ${startTime} to ${endTime}`)

      if (timeSlot.id && updateTimeSlot && meeting?.id) {
        // create time slot with the server
        const input: UpdateTimeSlotInput = {
          id: timeSlot.id,
          startTime,
          endTime,
          available: !!available,
        }

        // console.log('Update time slot with server: ')

        setShowActivity({
          userId: user?.id,
          startTime,
          endTime,
          meetingId: meeting.id,
        })
        await updateTimeSlot(input)
        setShowActivity(undefined)
      }
    }

    async function onDeleteTimeSlot(timeSlot: TimeSlotType): Promise<void> {
      // const { startTime, endTime } = timeSlot
      // console.log(`Not available: ${startTime} to ${endTime}`)

      if (timeSlot?.id && deleteTimeSlot && meeting?.id) {
        const input: DeleteTimeSlotInput = {
          meetingId: meeting.id,
          id: timeSlot.id,
        }

        // console.log('Delete time slots with server: ', input)

        setShowActivity(timeSlot)
        await deleteTimeSlot(input)
        setShowActivity(undefined)
      }
    }

    // async function onBusyTimeSlot (timeSlot: TimeSlotType): Promise<void> {
    //   // console.log('onBusyTimeSlot', timeSlot)

    //   if (timeSlot && meeting?.id && createTimeSlot) {
    //     const { startTime, endTime } = timeSlot
    //     // create time slot with the server
    //     const input: CreateTimeSlotInput = {
    //       meetingId: meeting.id,
    //       startTime,
    //       endTime,
    //       available: false,
    //     }

    //     // console.log('Creating time slots with server: ', input)
    //     setShowActivity(input)
    //     await createTimeSlot(input)
    //     setShowActivity(undefined)
    //   }
    // }

    async function onBusyTimeSlot(timeSlot: TimeSlotType): Promise<void> {
      // console.log('onBusyTimeSlot', timeSlot)
      const { startTime, endTime } = timeSlot

      if (timeSlot.id && updateTimeSlot && meeting?.id) {
        // update time slot with the server
        const input: UpdateTimeSlotInput = {
          id: timeSlot.id,
          startTime,
          endTime,
          available: false,
        }

        // console.log('Update time slot with server: ')

        setShowActivity({
          userId: user?.id,
          startTime,
          endTime,
          meetingId: meeting.id,
        })
        await updateTimeSlot(input)
        setShowActivity(undefined)
      }
    }

    function onInfo(time: string, timeRange?: TimeRange): void {
      console.log('onInfo: time ', time)
      console.log('onInfo: timeRange ', timeRange)

      let info: PopoverInfo = { time }
      let rangeParticipants
      let startTime; let endTime

      if (participants && participants?.length) {
        if (meeting?.meetingTimes?.length) {
          const meetingTime = meeting.meetingTimes.find(meetingTime => {
            const start = moment(meetingTime.startTime).valueOf()
            const end = moment(meetingTime.endTime).valueOf()
            const selected = moment(time).valueOf()

            // Note: a calendar event can span across midnight
            // so include timeSlot if either start or end are within the day range
            if (selected >= start && selected <= end) {
              return true
            }
          })

          if (meetingTime && meetingTime.participantUserIds) {
            rangeParticipants = meetingTime.participantUserIds.map((u: string) => {
              return participants.find((p: Participant) => p.userId === u)
            })
            startTime = meetingTime.startTime
            endTime = meetingTime.endTime
          }
        } else if (timeRange && timeRange.participantUserIds) {
          rangeParticipants = timeRange.participantUserIds.map((u: string) => {
            return participants.find((p: Participant) => p.userId === u)
          })
          startTime = timeRange.startTime
          endTime = timeRange.endTime
        }

        if (rangeParticipants && startTime && endTime) {
          info = {
            ...info,
            participants: rangeParticipants as Participant[],
            startTime,
            endTime,
          }
        }
      }

      if (calendarEvents?.length) {
        info.calendarEvents = calendarEvents.filter((event: CalendarEvent) => {
          const start = moment(event.startTime).valueOf()
          const end = moment(event.endTime).valueOf()
          const selected = moment(time).valueOf()

          // Note: a calendar event can span across midnight
          // so include timeSlot if either start or end are within the day range
          if (selected >= start && selected <= end) {
            return true
          }
        })
      }

      if (info.participants?.length || info.calendarEvents?.length) {
        setPopoverInfo(info)
        setShowInfoPopover(true)
      }
    }

    // console.log('CALENDAR TOTAL PARTICIPANTS: ', totalParticipants)

    function getSlots(): TimeSlotType[] {
      // console.log('TIME SLOTS: ', timeSlots)

      if (timeSlots?.length) {
        return timeSlots
      }

      return []
    }

    function getDaySlots(): TimeSlotType[] {
      // 12AM start time - 1 minute so that 12AM selection fits in the isAfter range below.
      const dayStart = moment(selectedTime).startOf('day').valueOf()
      const dayEnd = moment(dayStart).add(24, 'hours').valueOf()

      // console.log('dayStart: ', moment(selectedTime).startOf('day').toISOString())
      // console.log('dayEnd: ', moment(dayStart).add(24, 'hours').toISOString())

      // console.log('dayStart Value: ', dayStart)
      // console.log('dayEnd value: ', dayEnd)

      // first find local changes
      let slots = getSlots()

      slots = slots.filter((t: TimeSlotType) => {
        const start = moment(t.startTime).valueOf()
        const end = moment(t.endTime).valueOf()

        // Note: a timeSlot can span across midnight
        // so include timeSlot if either start or end are within the day range
        if ((start >= dayStart && start < dayEnd) ||
          (end > dayStart && end <= dayEnd)) {
          return true
        }
      })

      // if (slots.length) {
      //   console.log('DAY SLOTS: for day:', moment(selectedTime).startOf('day').toISOString(), slots)
      // }

      return slots
    }

    // get all user's calendar events for this day
    function getDayCalendarEvents(): CalendarEvent[] {
      const dayStart = moment(selectedTime).startOf('day').valueOf()
      const dayEnd = moment(dayStart).add(24, 'hours').valueOf()
      let events: CalendarEvent[] = []

      if (meeting && meeting.id) {
        if (calendarEvents?.length) {
          events = calendarEvents.filter((event: CalendarEvent) => {
            const start = moment(event.startTime).valueOf()
            const end = moment(event.endTime).valueOf()

            // Note: a calendar event can span across midnight
            // so include timeSlot if either start or end are within the day range
            // console.log('calendar event: ', event)

            if ((start >= dayStart && start < dayEnd) ||
              (end > dayStart && end <= dayEnd)) {
              if ((start === dayStart && end >= dayEnd) ||
                (start <= dayStart && end === dayEnd)) {
                // don't include all day
                return false
              }

              return true
            }
          })
        }
      }

      return events
    }

    function renderDayColumn(): JSX.Element | undefined {
      if (meeting?.id && user?.id && participants) {
        const daySlots = getDaySlots().filter(s => s.userId === user.id)
        const dayEvents = getDayCalendarEvents()

        // console.log('check editSlot: ', editSlot)
        // console.log('daySlots:', daySlots)
        // console.log('dayEvents:', dayEvents)

        // if (editSlot && daySlots.find(slot => {
        //   if (editSlot.id && slot.id === editSlot.id) {
        //     return true
        //   } else if (editSlot.localId && slot.localId === editSlot.localId) {
        //     return true
        //   }
        // })) {
        //   // console.log('enable showEditSlot: ', editSlot)
        //   showEditSlot = editSlot
        // }

        let showTip

        if (tip) {
          // const currentTime = moment().format('H')
          const localTime = moment(selectedTime).format('H')

          showTip = Number(localTime)
        }

        return (
          <DayColumn
            parentId={calendarBoxId}
            userId={user?.id || ''}
            startDay={selectedDay}
            day={0}
            scale={scale}
            meeting={meeting}
            participants={participants}
            timeSlots={daySlots}
            calendarEvents={dayEvents}
            showActivity={showActivity}
            editSlot={editSlot}
            editCalendar
            tip={showTip}
            tipColumn={tip}
            bookCalendar={bookCalendar}
            currentTimeMarker
            meetingTimeMarkers
            setEditSlot={startEditSlot}
            onEdit={() => {
              logTap({ component: 'DayColumn', button: 'Edit' })
              onEdit()
            }}
            onEditEnd={onEditEnd}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onCreateTimeSlot={(startTime, endTime) => {
              logTap({ component: 'DayColumn', button: '' })
              onCreateTimeSlot(startTime, endTime)
            }}
            onUpdateTimeSlot={(timeSlot) => {
              logTap({ component: 'DayColumn', button: '' })
              onUpdateTimeSlot(timeSlot)
            }}
            onDeleteTimeSlot={(timeSlot) => {
              logTap({ component: 'DayColumn', button: '' })
              onDeleteTimeSlot(timeSlot)
            }}
            onBusyTimeSlot={(timeSlot) => {
              logTap({ component: 'DayColumn', button: '' })
              onBusyTimeSlot(timeSlot)
            }}
            onInfo={(time, timeRange) => {
              logTap({ component: 'DayColumn', button: '' })
              onInfo(time, timeRange)
            }} />
        )
      }
    }

    function renderPopoverTimeSlots(): JSX.Element | null {
      if (popoverInfo) {
        const { participants, startTime, endTime, calendarEvents } = popoverInfo

        if (participants?.length) {
          const start = moment(startTime).format('h:mm A')
          const end = moment(endTime).format('h:mm A')

          return (
            <div>
              {calendarEvents && calendarEvents.length > 0 &&
                <IonItem
                  lines='full'>
                  <IonLabel>
                    <p>{start} to {end}</p>
                  </IonLabel>
                </IonItem>}
              {participants.map((participant, i) => {
                const initials = participant.displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

                // if initials is > 2 characters then just pick first and last
                const displayInitials = initials.length > 2
                  ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials
                const name = user?.id === participant.userId ? 'You' : participant.displayName

                return (
                  <IonItem
                    key={i}
                    lines={(i < participants.length - 1) ? 'inset' : 'none'}>
                    <Avatar
                      slot='start'
                      src={participant.photo}
                      style={avatarStyle}>
                      <IonText style={{ fontSize: 12, fontWeight: 'bold' }}>
                        {displayInitials}
                      </IonText>
                    </Avatar>
                    <IonLabel>
                      {name}
                    </IonLabel>
                    <IonIcon
                      slot='end'
                      color='secondary'
                      style={{ fontSize: 22 }}
                      icon={checkmark} />
                  </IonItem>
                )
              })}
            </div>
          )
        }
      }

      return null
    }

    function renderPopoverEvents(): JSX.Element | null {
      if (popoverInfo) {
        const { calendarEvents } = popoverInfo

        if (calendarEvents?.length) {
          return (
            <div>
              {calendarEvents.map((event, i) => {
                const start = moment(event.startTime).format('h:mm A')
                const end = moment(event.endTime).format('h:mm A')

                return (
                  <IonItem
                    key={i}
                    lines={(i < calendarEvents.length - 1) ? 'inset' : 'none'}>
                    <IonIcon
                      size='small'
                      style={{ color: event.backgroundColor, marginRight: 10 }}
                      icon={ellipse} />
                    <IonLabel className='ion-text-wrap'>
                      <h2>{event.summary}</h2>
                      <p>{start} to {end}</p>
                    </IonLabel>
                  </IonItem>
                )
              })}
            </div>
          )
        }
      }

      return null
    }

    function renderInfoPopover(): JSX.Element | null {
      if (popoverInfo) {
        // Note: on android the list has a small padding at the bottom
        // and since we are using a slight shaded background for the select button
        // get rid of the bottom padding
        const { time, startTime, endTime, calendarEvents } = popoverInfo
        const day = moment(time).format('ddd D')
        const start = moment(startTime).format('h:mm A')
        const end = moment(endTime).format('h:mm A')

        return (
          <IonPopover
            isOpen={showInfoPopover}
            onDidDismiss={() => setShowInfoPopover(false)}>
            <IonList style={{ paddingBottom: 10 }}>
              <IonItem
                lines='full'>
                <IonLabel>
                  <h2 style={{ fontWeight: 'bold' }}>{day}</h2>
                  {!calendarEvents?.length && <p>{start} to {end}</p>}
                </IonLabel>
              </IonItem>
              {renderPopoverEvents()}
              {renderPopoverTimeSlots()}
            </IonList>
          </IonPopover>
        )
      }

      return null
    }

    function renderToast(): JSX.Element {
      return (
        <IonToast
          isOpen={!!showToast}
          color='primary'
          onDidDismiss={() => setShowToast('')}
          message={showToast}
          buttons={[
            {
              side: 'end',
              text: 'OK',
              handler: () => {
                setShowToast('')
                setShowBlockTimeToast(false)
              },
            },
          ]} />
      )
    }

    const container: CSSProperties = {
      flex: 1,
      display: 'flex',
      overflowY: 'scroll',
      flexWrap: 'nowrap',
      paddingTop: calendarTopPadding,
    }

    if (disableScroll) {
      container.overflowX = 'hidden'
      container.overflowY = 'hidden'
    }

    return (
      <div
        id={id || calendarBoxId}
        style={container}
        onScroll={doScroll}>
        {renderDayColumn()}
        {renderInfoPopover()}
        {renderToast()}
      </div>
    )
  }

export default SelectDaySchedule
