import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { updateScreens } from 'services/store/screens/actions'
import UserProvider from 'context/UserContext/UserContext'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import ContactsProvider from 'context/ContactsContext/ContactsContext'
import LoginFirebase from './LoginFirebase'
import paths from 'navigation/paths'
import { AuthTypes, useRoutes } from 'navigation/RouteContext'

const appUrl = process.env.REACT_APP_MEETINGS_APP

const LoginScreen: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [invitation, setInvitation] = useState('')
  const [verifyToken, setVerifyToken] = useState('')
  const [meetUser, setMeetUser] = useState('')
  const [inCallback, setInCallback] = useState(false)
  const [callbackUrl, setCallbackUrl] = useState('')

  const dispatch = useDispatch()
  const { setAuthenticated } = useRoutes()

  useEffect(() => {
    if (location.search) {
      console.log('Login: url: ', location.search)

      const params = queryString.parse(location.search, { parseBooleans: true })

      const token = params.verifyToken as string
      // const browserType = params.browser as string || browserName()
      const invite = params.invite as string
      const meet = params.meet as string
      let url

      // IF we are in the context of the callback URL then don't join meeting
      // the join meeting will happen in the context of the login initiator app
      // this situation could happen when user starts login in one tab and completed verification
      // in another tab. We do'nt want both tabs to join the meeting
      setInCallback(params.callbackUrl as boolean || false)

      if (token) {
        setVerifyToken(token)
        url = `${appUrl}${location.pathname}?callbackUrl=true&verifyToken=${token}`
      }

      if (invite) {
        setInvitation(invite)
        url += `&invite=${invite}`
      }

      if (meet) {
        setMeetUser(meet)
        url += `&meet=${meet}`
      }

      if (url) {
        setCallbackUrl(url)
      }
    }
  }, [location.search])

  function onLogin (): void {
    setAuthenticated && setAuthenticated(AuthTypes.authenticated)
    localStorage.removeItem('verifyToken')
    history.replace(paths.meetingsList)

    if (meetUser) {
      dispatch(updateScreens({
        meetingSettings: {
          addParticipants: [meetUser],
          meetMeInitiator: true,
        },
      }))
      history.push(paths.meetingSettings)
    }
  }

  function onTryAgain (): void {
    localStorage.removeItem('verifyToken')
    history.replace('/start')
  }

  function goBack (): void {
    localStorage.removeItem('verifyToken')
    history.goBack()
  }

  return (
    <UserProvider>
      <ContactsProvider>
        <MeetingsProvider>
          <InviteesProvider>
            <LoginFirebase
              invitation={invitation}
              verifyToken={verifyToken}
              callbackUrl={callbackUrl}
              inCallback={inCallback}
              goBack={goBack}
              onLogin={onLogin}
              onTryAgain={onTryAgain} />
          </InviteesProvider>
        </MeetingsProvider>
      </ContactsProvider>
    </UserProvider>
  )
}
export default LoginScreen
