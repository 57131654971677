import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { TimeSlot as TimeSlotType, TimeRange, Meeting, CalendarEvent, Participant, MeetingTime } from 'types'
import moment from 'moment'

import {
  CreateTimeSlotInput, DeleteTimeSlotInput, UpdateTimeSlotInput,
} from 'services/api'
import DayColumn from '../DayColumn/DayColumn'

import { IonToast } from '@ionic/react'
import { useUser } from 'context/UserContext/UserContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import { useCalendarView } from '../ScheduleCalendar/CalendarViewContext'
import { MeetingBookCalendar } from 'context/CalendarsContext/CalendarsContext'
import MeetingTimeModal from '../MeetingTimeModal/MeetingTimeModal'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

// time grid height in minutes
const scale = 60
const calendarBoxId = 'calendarBox'

type PopoverInfo = {
  time: string;
  meetingTime?: MeetingTime;
  timeRange?: TimeRange;
  participants?: Participant[];
  startTime?: string;
  endTime?: string;
  calendarEvents?: CalendarEvent[];
}

export interface CalendarBoxProps {
  meeting: Meeting;
  startDay: number;
  currentDay: number;
  endDay: number;
  reload?: number;
  bookCalendar?: MeetingBookCalendar;
  calendarEvents?: CalendarEvent[];
  tip?: boolean;
  editCalendar?: boolean;
  setCurrentDay: (day: number) => void;
  setStartDay: (day: number) => void;
  setEndDay: (day: number) => void;
  onSelectTime?: (time: string) => void;
  onStartEditCalendar: () => void;
}

const CalendarBox: React.FC<CalendarBoxProps> =
  ({
    startDay, currentDay, endDay, meeting, bookCalendar, calendarEvents, tip, editCalendar,
    setCurrentDay, setStartDay, setEndDay, onSelectTime, onStartEditCalendar,
  }) => {
    const [showInfoPopover, setShowInfoPopover] = useState(false)
    const [popoverInfo, setPopoverInfo] = useState<PopoverInfo>()
    const [editSlot, setEditSlot] = useState<TimeSlotType>()
    const [disableScroll, setDisableScroll] = useState(false)
    const [showActivity, setShowActivity] = useState<TimeSlotType>()
    const [showToast, setShowToast] = useState('')
    const [showBlockTimeToast, setShowBlockTimeToast] = useState(false)
    const [showTipTime, setShowTipTime] = useState(0)

    const { user } = useUser()
    const { timeSlots, createTimeSlot, updateTimeSlot, deleteTimeSlot } = useTimeSlots()
    const { participants } = useParticipants()
    const { logEvent } = useAnalytics()

    const pageRef = useRef<HTMLDivElement>(null)

    const { calendarTopPadding = 20, infiniteScroll = false, scrollWindow = 7, timeSlotWidth = 90, timeSlotHeight = 50 } = useCalendarView()

    function setCalendarStartDay(): void {
      if (meeting.dayRanges?.length) {
        const sortedTimeRanges = meeting.dayRanges.slice().sort((a, b) => {
          const timeA = moment(a.startTime).valueOf()
          const timeB = moment(b.startTime).valueOf()

          return timeA - timeB
        })
        // look up start days compared to today. It can be negative.
        let start = moment(sortedTimeRanges[0].startTime).diff(moment().startOf('day'), 'days')
        const end = moment(sortedTimeRanges[sortedTimeRanges.length - 1].endTime)
          .diff(moment().startOf('day'), 'days')

        // if the start day is in the future then include one day before so the user gets a nice
        // frame around start and end dates
        if (start > 0) {
          start = start - 1
        }

        // console.log('SET CALENDAR START DAY: ', start)
        setStartDay(start)

        // include the last day so add 1 and 1 more as right frame
        // console.log('SET CALENDAR END DAY: ', end)
        setEndDay(end + 1)
      }
    }

    useEffect(() => {
      setCalendarStartDay()
    }, [])

    useEffect(() => {
      setCalendarStartDay()
    }, [meeting])

    useEffect(() => {
      setTimeout(() => {
        const el = document.getElementById(calendarBoxId)

        if (el) {
          // 1. look for the first meetingTime which is current and start there, else use the last meetingTime
          // 2. if no meeting times, look for timeRange that is current, else use the last timeRange

          const currentTime = moment().add(1, 'hours')
          let targetTime

          if (meeting.meetingTimes?.length) {
            // reduce current time by 1hrs

            if (meeting.meetingTimes.every(meetingTime => {
              if (moment(meetingTime.endTime).isAfter(currentTime)) {
                // scroll to meetingTime.startTime or currentTime whichever is later
                if (moment(meetingTime.startTime).isAfter(currentTime)) {
                  targetTime = Number(moment(meetingTime.startTime).format('H'))
                } else {
                  targetTime = Number(currentTime.format('H'))
                }

                return false
              }

              return true
            })) {
              // no meeting time is current so use the last one
              const meetingTime = meeting.meetingTimes[meeting.meetingTimes.length - 1]

              targetTime = Number(moment(meetingTime.startTime).format('H'))
            }
          } else if (meeting.timeRanges?.length) {
            const sortedTimeRanges = meeting.timeRanges.slice().sort((a, b) => {
              const timeA = moment(a.startTime).valueOf()
              const timeB = moment(b.startTime).valueOf()

              return timeA - timeB
            })

            // reduce current time by 1hrs
            const current = Number(moment().add(1, 'hours').format('H'))

            if (sortedTimeRanges.every(timeRange => {
              const start = Number(moment(timeRange.startTime).format('H'))
              const end = Number(moment(timeRange.endTime).format('H'))

              if (end > current) {
                // scroll to timeRange.startTime or currentTime whichever is later
                if (start > current) {
                  targetTime = start
                } else {
                  targetTime = current
                }

                return false
              }

              return true
            })) {
              // no meeting time is current so use the last range
              const timeRange = sortedTimeRanges[sortedTimeRanges.length - 1]

              targetTime = Number(moment(timeRange.startTime).format('H'))
            }
          }

          if (targetTime) {
            el.scrollTop = timeSlotHeight * targetTime
            setShowTipTime(targetTime + 1)
          } else {
            const currentTime = moment().subtract(1, 'hours')

            targetTime = Number(currentTime.format('H'))
            el.scrollTop = timeSlotHeight * targetTime
            setShowTipTime(targetTime)
          }

          console.log('SCROLL TO TIME: ', targetTime)
        } else {
          console.log('DID NOT FOUND CALENDAR EL TO SCROLL TO')
        }
      }, 1000)
    }, [])

    // useEffect(() => {
    //   // console.log('TimeSlot Updated subsriptionData ', subscriptionData)
    //   // console.log('user ', user)
    //   // console.log('meeting', meetingId)

    //   if (subscriptionData && subscriptionData.timeSlotUpdated) {
    //     const { action, timeSlot } = subscriptionData.timeSlotUpdated

    //     if (data && refetchTimeSlots && refetchParticipants) {
    //       // console.log('HIT THE CACHE')

    //       setShowActivity(undefined)

    //       if (action && timeSlot && meeting.id) {
    //         const { timeSlots } = client.readQuery(
    //           {
    //             query: GET_TIME_SLOTS,
    //             variables: { meetingId: timeSlot.meetingId},
    //           }) as { timeSlots: [TimeSlotType]}

    //         if (action === 'created' && timeSlot) {
    //         // console.log('CREATE TIME SLOT: ', timeSlot)

    //           if (!timeSlots.find(t => t.id === timeSlot.id)) {
    //           // console.log('DIDNT FIND TIME SLOT SO CREATE IT: ', timeSlot.id)
    //             client.writeQuery({
    //               query: GET_TIME_SLOTS,
    //               variables: { meetingId: timeSlot.meetingId},
    //               data: { timeSlots: timeSlots.concat([timeSlot]) },
    //             })
    //           }
    //         } else if (action === 'deleted' && timeSlot) {
    //           client.writeQuery({
    //             query: GET_TIME_SLOTS,
    //             variables: { meetingId: timeSlot.meetingId},
    //             data: { timeSlots: timeSlots.filter(t => t.id !== timeSlot.id) },
    //           })
    //         }

    //         if (showActivity) {
    //           if (timeSlot.user === user?.id &&
    //             showActivity.startTime === timeSlot.startTime &&
    //             showActivity.endTime === timeSlot.endTime) {
    //             setShowActivity(undefined)
    //           }
    //         }
    //       } else if (action === 'user exited' || action === 'reload') {
    //         refetchTimeSlots()
    //         refetchParticipants()
    //       }
    //     }
    //   }
    // }, [subscriptionData, refetchTimeSlots, refetchParticipants]) // Only re-run the effect if subscriptionData changes

    // useEffect(() => {
    //   console.log('CalendarBox: reload calendar ', meeting.id)

    //   if (refetchTimeSlots) {
    //     refetchTimeSlots()
    //   }
    // }, [reload, refetchTimeSlots])

    function logTap(eventData: EventData): void {
      logEvent({
        eventName: EventName.buttonTap,
        eventData: {
          ...eventData,
        },
      })
    }

    function onScroll(): void {
      const el = document.getElementById('calendarBox')

      if (el) {
        // get scroll position in px
        // console.log(el.scrollLeft, el.scrollTop)

        const dayBar = document.getElementById('dayBar')
        const timeBar = document.getElementById('timeBar')

        if (dayBar) {
          dayBar.scrollLeft = el.scrollLeft
        }

        if (timeBar) {
          timeBar.scrollTop = el.scrollTop
        }

        const scrollDay = Math.floor(el.scrollLeft / timeSlotWidth)

        if (scrollDay !== currentDay) {
          if (infiniteScroll) {
            // console.log(`scrollDay: ${scrollDay}, startDay: ${startDay}, window: ${scrollWindow}`)

            if (scrollDay > currentDay) {
              if (scrollDay === scrollWindow) {
                setStartDay(startDay + scrollWindow - 1)
                setCurrentDay(1)
                el.scrollLeft = el.scrollLeft - ((scrollWindow - 1) * timeSlotWidth)
              } else {
                setCurrentDay(scrollDay)
              }
            } else {
              if (scrollDay === 0) {
                setStartDay(startDay - (scrollWindow - 1))
                setCurrentDay(scrollWindow - 1)
                el.scrollLeft = el.scrollLeft + ((scrollWindow - 1) * timeSlotWidth)
              } else {
                setCurrentDay(scrollDay)
              }
            }
          } else {
            setCurrentDay(scrollDay)
          }
        }
      }
    }

    function selectTime(time: string): void {
      if (onSelectTime) {
        const el = document.getElementById('calendarBox')

        if (el) {
          if (el.scrollTop > 0) {
            const minutes = (el.scrollTop / timeSlotHeight) * 60
            const scrollTopTime = moment(time).startOf('day').add(minutes, 'minutes').toISOString()

            console.log('SCROLL TOP TIME: ', scrollTopTime)

            onSelectTime(scrollTopTime)
          } else {
            onSelectTime(time)
          }
        } else {
          onSelectTime(time)
        }
      }
    }

    function checkAutoRange(startTime?: string, noToast?: boolean): boolean {
      let autoRange = false

      if (startTime &&
        bookCalendar?.autoBook && bookCalendar?.autoDays && bookCalendar?.autoTimes) {
        const { autoTimes, autoDays } = bookCalendar
        // calculate start and end minute markers
        const day = moment(startTime).format('ddd').toLowerCase()

        if (autoDays.find(d => d === day)) {
          const startMinutes = moment(startTime).diff(moment(startTime).startOf('day'), 'minutes')

          autoTimes.every(range => {
            const startRange = moment(range.startTime).diff(moment(range.startTime).startOf('day'), 'minutes')
            const endRange = moment(range.endTime).diff(moment(range.startTime).startOf('day'), 'minutes')

            if (startMinutes >= startRange && startMinutes < endRange) {
              autoRange = true

              if (showBlockTimeToast && !noToast) {
                const toast = 'Automagic Scheduling is ON from: \n' +
                  `${moment(range.startTime).format('h:mm a')} - ${moment(range.endTime).format('h:mm a')}\n` +
                  'You can mark a time range as Busy and MeetingTime will not schedule you then'

                setShowToast(toast)
              }

              return false
            }

            return true
          })
        }
      }

      return autoRange
    }

    function startEditSlot(timeSlot?: TimeSlotType): void {
      console.log('ON EDIT: DISABLE SCROLL')

      if (timeSlot) {
        if (timeSlot.available &&
          checkAutoRange(timeSlot.startTime) &&
          showBlockTimeToast) {
          // if in autoRange and showBlockToast enabled then ignore
          return
        }

        // setDisableScroll(true)
      } else {
        setDisableScroll(false)
      }

      logTap({ component: 'CalBox/DayColumn', button: 'EditSlot' })
      setEditSlot(timeSlot)
    }

    function onEdit(): void {
      // setEditingRange(true)
      // disableScroll = true
    }

    function onEditEnd(): void {
      // setEditingRange(false)
      setDisableScroll(false)
    }

    function onDragStart(fromTime: string): void {
      if (editSlot) {
        if (moment(fromTime).isAfter(moment(editSlot.startTime)) &&
          moment(fromTime).isBefore(moment(editSlot.endTime))) {
          setDisableScroll(true)
        }
      }
    }

    function onDragEnd(): void {
      setDisableScroll(false)
    }

    async function onCreateTimeSlot(startTime: string, endTime: string): Promise<void> {
      // console.log(`onCreateTimeSlot: ${startTime} to ${endTime}`)

      // create time slot with the server
      if (createTimeSlot) {
        const available = !checkAutoRange(startTime)

        if (!available && showBlockTimeToast) {
          // if in autoRange and showBlockToast enabled then ignore
          return
        }

        const input: CreateTimeSlotInput = {
          meetingId: meeting.id,
          startTime,
          endTime,
          available,
        }

        // console.log('Creating time slots with server: ', input)
        setShowActivity(input)

        const timeSlot = await createTimeSlot(input)

        if (timeSlot) {
          startEditSlot(timeSlot)
        }

        setShowActivity(undefined)
      }
    }

    async function onUpdateTimeSlot(timeSlot: TimeSlotType): Promise<void> {
      const { startTime, endTime, available } = timeSlot

      // console.log(`onUpdateTimeSlot: ${startTime} to ${endTime}`)

      if (timeSlot.id && updateTimeSlot) {
        if (checkAutoRange(startTime) && showBlockTimeToast) {
          // if in autoRange and showBlockToast enabled then ignore
          return
        }

        // create time slot with the server
        const input: UpdateTimeSlotInput = {
          id: timeSlot.id,
          startTime,
          endTime,
          available: !!available,
        }

        // console.log('Update time slot with server: ')

        setShowActivity({
          userId: user?.id,
          startTime,
          endTime,
          meetingId: meeting.id,
        })
        await updateTimeSlot(input)
        setShowActivity(undefined)
      }
    }

    async function onDeleteTimeSlot(timeSlot: TimeSlotType): Promise<void> {
      // const { startTime, endTime } = timeSlot
      // console.log(`Not available: ${startTime} to ${endTime}`)

      if (timeSlot?.id && deleteTimeSlot) {
        if (checkAutoRange(timeSlot.startTime) && showBlockTimeToast) {
          // if in autoRange and showBlockToast enabled then ignore
          return
        }

        const input: DeleteTimeSlotInput = {
          meetingId: meeting.id,
          id: timeSlot.id,
        }

        // console.log('Delete time slots with server: ', input)

        setShowActivity(timeSlot)
        await deleteTimeSlot(input)
        setShowActivity(undefined)
      }
    }

    async function onBusyTimeSlot(timeSlot: TimeSlotType): Promise<void> {
      // console.log('onBusyTimeSlot', timeSlot)

      if (timeSlot && meeting.id && createTimeSlot) {
        const { startTime, endTime } = timeSlot
        // create time slot with the server
        const input: CreateTimeSlotInput = {
          meetingId: meeting.id,
          startTime,
          endTime,
          available: false,
        }

        // console.log('Creating time slots with server: ', input)
        setShowActivity(input)
        await createTimeSlot(input)
        setShowActivity(undefined)
      }
    }
    function onInfo(time: string, timeRange?: TimeRange): void {
      console.log('onInfo: time ', time)
      console.log('onInfo: timeRange ', timeRange)

      let info: PopoverInfo = { time }
      let rangeParticipants
      let startTime; let endTime
      let meetingTime

      if (participants && participants?.length) {
        if (meeting.meetingTimes?.length) {
          meetingTime = meeting.meetingTimes.find(meetingTime => {
            const start = moment(meetingTime.startTime).valueOf()
            const end = moment(meetingTime.endTime).valueOf()
            const selected = moment(time).valueOf()

            // Note: a calendar event can span across midnight
            // so include timeSlot if either start or end are within the day range
            if (selected >= start && selected <= end) {
              return true
            }
          })

          if (meetingTime && meetingTime.participantUserIds) {
            rangeParticipants = meetingTime.participantUserIds.map((u: string) => {
              return participants.find((p: Participant) => p.userId === u)
            })
            startTime = meetingTime.startTime
            endTime = meetingTime.endTime
          }
        }

        if (!meetingTime && timeRange && timeRange.participantUserIds) {
          rangeParticipants = timeRange.participantUserIds.map((u: string) => {
            return participants.find((p: Participant) => p.userId === u)
          })
          startTime = timeRange.startTime
          endTime = timeRange.endTime
        }

        if (rangeParticipants && startTime && endTime) {
          info = {
            ...info,
            meetingTime,
            timeRange,
            participants: rangeParticipants as Participant[],
            startTime,
            endTime,
          }
        }
      }

      if (calendarEvents?.length) {
        info.calendarEvents = calendarEvents.filter((event: CalendarEvent) => {
          const start = moment(event.startTime).valueOf()
          const end = moment(event.endTime).valueOf()
          const selected = moment(time).valueOf()

          // Note: a calendar event can span across midnight
          // so include timeSlot if either start or end are within the day range
          if (selected >= start && selected <= end) {
            return true
          }
        })
      }

      if (info.participants?.length || info.calendarEvents?.length) {
        console.log('show popover info: ', info)
        setPopoverInfo(info)
        setShowInfoPopover(true)
      }
    }

    // console.log('CALENDAR TOTAL PARTICIPANTS: ', totalParticipants)

    function getSlots(): TimeSlotType[] {
      if (timeSlots?.length) {
        return timeSlots
      }

      return []
    }

    // function getMaxSlot (): number {
    //   let max = 0

    //   if (user?.id) {
    //     const slots = getSlots()
    //     const ranges = getTimeRanges({
    //       userId: user.id,
    //       timeSlots: slots.filter(s => !s.disabled),
    //       allowedTimeRanges: meeting.timeRanges,
    //     })

    //     ranges.forEach(range => {
    //       const count = range.participants?.length || 0

    //       if (count > max) {
    //         max = count
    //       }
    //     })
    //   }

    //   return max
    // }

    function getDaySlots(day: number): TimeSlotType[] {
      // 12AM start time - 1 minute so that 12AM selection fits in the isAfter range below.
      const dayStart = moment().add((startDay + day), 'days').startOf('day').valueOf()
      const dayEnd = moment(dayStart).add(24, 'hours').valueOf()
      // first find local changes
      let slots = getSlots()

      // issue #411 show timeSlots only if the user has selected some times
      // if (user?.id && slots.find(t => t.user === user?.id)) {
      if (user?.id) {
        slots = slots.filter((t: TimeSlotType) => {
          const start = moment(t.startTime).valueOf()
          const end = moment(t.endTime).valueOf()

          // Note: a timeSlot can span across midnight
          // so include timeSlot if either start or end are within the day range
          if ((start >= dayStart && start < dayEnd) ||
            (end > dayStart && end <= dayEnd)) {
            return true
          }
        })

        return slots
      }

      return []
      // if (slots.length) {
      //   console.log('DAY SLOTS: for day:', moment().add((startDay + day), 'days').startOf('day').toISOString(), slots)
      // }
    }

    // get all user's calendar events for this day
    function getDayCalendarEvents(day: number): CalendarEvent[] {
      const dayStart = moment().add((startDay + day), 'days').startOf('day').valueOf()
      const dayEnd = moment(dayStart).add(24, 'hours').valueOf()
      let events: CalendarEvent[] = []

      if (meeting && meeting.id) {
        if (calendarEvents?.length) {
          events = calendarEvents.filter((event: CalendarEvent) => {
            const start = moment(event.startTime).valueOf()
            const end = moment(event.endTime).valueOf()

            // Note: a calendar event can span across midnight
            // so include timeSlot if either start or end are within the day range
            // console.log('calendar event: ', event)

            if ((start >= dayStart && start < dayEnd) ||
              (end > dayStart && end <= dayEnd)) {
              if ((start === dayStart && end >= dayEnd) ||
                (start <= dayStart && end === dayEnd)) {
                // don't include all day
                return false
              }

              return true
            }
          })
        }
      }

      return events
    }

    // const bestRange = getMaxSlot()

    function showMeetingTimes(): boolean {
      // const slots = getSlots()

      // issue #411 show timeSlots only if the user has selected some times
      // if (user?.id && slots.find(t => t.user === user?.id)) {
      if (user?.id) {
        return true
      }

      return false
    }

    function renderDayColumn(day: number): JSX.Element | undefined {
      if (participants?.length) {
        const daySlots = getDaySlots(day)
        const dayEvents = getDayCalendarEvents(day)

        // console.log('check editSlot: ', editSlot)
        // console.log('daySlots:', daySlots)
        // console.log('dayEvents:', dayEvents)

        // if (editSlot && daySlots.find(slot => {
        //   if (editSlot.id && slot.id === editSlot.id) {
        //     return true
        //   } else if (editSlot.localId && slot.localId === editSlot.localId) {
        //     return true
        //   }
        // })) {
        //   // console.log('enable showEditSlot: ', editSlot)
        //   showEditSlot = editSlot
        // }
        let showTip

        if (tip) {
          if (!getSlots().length) {
            if (day === 1) showTip = showTipTime
            else if (day === 2) showTip = showTipTime
          }
        }

        return (
          <DayColumn
            parentId={calendarBoxId}
            userId={user?.id || ''}
            key={day}
            startDay={startDay}
            day={day}
            scale={scale}
            meeting={meeting}
            participants={participants}
            timeSlots={daySlots}
            calendarEvents={dayEvents}
            showActivity={showActivity}
            editSlot={editSlot}
            editCalendar={editCalendar}
            tip={showTip}
            bookCalendar={bookCalendar}
            currentTimeMarker
            showMeetingTimes={showMeetingTimes()}
            setEditSlot={startEditSlot}
            onEdit={() => {
              logTap({ component: 'DayColumn', button: 'EditSlot' })
              onEdit()
            }}
            onEditEnd={onEditEnd}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onSelectTime={(time) => {
              logTap({ component: 'DayColumn', button: 'SelectTime' })
              selectTime(time)
            }}
            onCreateTimeSlot={(startTime, endTime) => {
              logTap({ component: 'DayColumn', button: 'CreateSlot' })
              onCreateTimeSlot(startTime, endTime)
            }}
            onUpdateTimeSlot={(timeSlot) => {
              logTap({ component: 'DayColumn', button: 'UpdateSlot' })
              onUpdateTimeSlot(timeSlot)
            }}
            onDeleteTimeSlot={(timeSlot) => {
              logTap({ component: 'DayColumn', button: 'DeleteSlot' })
              onDeleteTimeSlot(timeSlot)
            }}
            onBusyTimeSlot={(timeSlot) => {
              logTap({ component: 'DayColumn', button: 'BusySlot' })
              onBusyTimeSlot(timeSlot)
            }}
            onInfo={(time, timeRange) => {
              logTap({ component: 'DayColumn', button: 'Info' })
              onInfo(time, timeRange)
            }} />
        )
      }
    }

    // function renderPopoverTimeSlots (): JSX.Element | null {
    //   if (popoverInfo) {
    //     const { participants, startTime, endTime, calendarEvents } = popoverInfo

    //     if (participants?.length) {
    //       const start = moment(startTime).format('h:mm A')
    //       const end = moment(endTime).format('h:mm A')

    //       return (
    //         <div>
    //           {calendarEvents && calendarEvents.length > 0 &&
    //             <IonItem
    //               lines='full'>
    //               <IonLabel>
    //                 <p>{start} to {end}</p>
    //               </IonLabel>
    //             </IonItem>}
    //           {participants.map((participant, i) => {
    //             const initials = participant.name.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

    //             // if initials is > 2 characters then just pick first and last
    //             const displayInitials = initials.length > 2
    //               ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials
    //             const name = user?.id === participant.user ? 'You' : participant.name

    //             return (
    //               <IonItem
    //                 key={i}
    //                 lines={(i < participants.length - 1) ? 'inset' : 'none'}>
    //                 <Avatar
    //                   slot='start'
    //                   src={participant.photo}
    //                   style={avatarStyle}>
    //                   <IonText style={{ fontSize: 12, fontWeight: 'bold' }}>
    //                     {displayInitials}
    //                   </IonText>
    //                 </Avatar>
    //                 <IonLabel>
    //                   {name}
    //                 </IonLabel>
    //                 <IonIcon
    //                   slot='end'
    //                   color='secondary'
    //                   style={{ fontSize: 22 }}
    //                   icon={checkmark} />
    //               </IonItem>
    //             )
    //           })}
    //         </div>
    //       )
    //     }
    //   }

    //   return null
    // }

    // function renderPopoverEvents (): JSX.Element | null {
    //   if (popoverInfo) {
    //     const { calendarEvents } = popoverInfo

    //     if (calendarEvents?.length) {
    //       return (
    //         <div>
    //           {calendarEvents.map((event, i) => {
    //             const start = moment(event.startTime).format('h:mm A')
    //             const end = moment(event.endTime).format('h:mm A')

    //             return (
    //               <IonItem
    //                 key={i}
    //                 lines={(i < calendarEvents.length - 1) ? 'inset' : 'none'}>
    //                 <IonIcon
    //                   size='small'
    //                   style={{ color: event.backgroundColor, marginRight: 10 }}
    //                   icon={ellipse} />
    //                 <IonLabel className='ion-text-wrap'>
    //                   <h2>{event.summary}</h2>
    //                   <p>{start} to {end}</p>
    //                 </IonLabel>
    //               </IonItem>
    //             )
    //           })}
    //         </div>
    //       )
    //     }
    //   }

    //   return null
    // }

    // function renderInfoPopover (): JSX.Element | null {
    //   if (popoverInfo) {
    //     // Note: on android the list has a small padding at the bottom
    //     // and since we are using a slight shaded background for the select button
    //     // get rid of the bottom padding
    //     const { time, startTime, endTime, calendarEvents } = popoverInfo
    //     const day = moment(time).format('ddd D')
    //     const start = moment(startTime).format('h:mm A')
    //     const end = moment(endTime).format('h:mm A')

    //     return (
    //       <IonPopover
    //         isOpen={showInfoPopover}
    //         onDidDismiss={() => setShowInfoPopover(false)}>
    //         <IonList style={{ paddingBottom: 10 }}>
    //           <IonItem
    //             lines='full'>
    //             <IonLabel>
    //               <h2 style={{ fontWeight: 'bold' }}>{day}</h2>
    //               {!calendarEvents?.length && <p>{start} to {end}</p>}
    //             </IonLabel>
    //           </IonItem>
    //           {renderPopoverEvents()}
    //           {renderPopoverTimeSlots()}
    //         </IonList>
    //       </IonPopover>
    //     )
    //   }

    //   return null
    // }

    function renderInfoModal(): JSX.Element | null {
      if (showInfoPopover && popoverInfo) {
        // Note: on android the list has a small padding at the bottom
        // and since we are using a slight shaded background for the select button
        // get rid of the bottom padding
        const { time, meetingTime, timeRange, calendarEvents } = popoverInfo

        const autoRange = checkAutoRange(time, true)

        return (
          <MeetingTimeModal
            selectedTime={time}
            meetingTime={meetingTime}
            timeRange={meetingTime ? undefined : timeRange}
            calendarEvents={calendarEvents}
            autoRange={autoRange}
            presentingElement={pageRef.current}
            onEdit={(timeSlot) => {
              logTap({ component: 'MeetingTimeModal', button: 'EditSlot' })
              setShowInfoPopover(false)
              setPopoverInfo(undefined)
              startEditSlot(timeSlot)
            }}
            onStartEditCalendar={() => {
              logTap({ component: 'MeetingTimeModal', button: 'EditCalendar' })
              onStartEditCalendar()
            }}
            onMore={() => {
              logTap({ component: 'MeetingTimeModal', button: 'More' })
              setShowInfoPopover(false)
              setPopoverInfo(undefined)
              onSelectTime && onSelectTime(time)
            }}
            onClose={() => {
              logTap({ component: 'MeetingTimeModal', button: 'Close' })
              setShowInfoPopover(false)
              setPopoverInfo(undefined)
            }} />
        )
      }

      return null
    }
    // function renderAlert (): JSX.Element {
    //   return (
    //     <IonAlert
    //       isOpen={!!showAlert}
    //       onDidDismiss={() => setShowAlert('')}
    //       header={showAlert}
    //       message='You can block a time range and MeetingTime will not schedule you then'
    //       buttons={[
    //         {
    //           text: 'OK',
    //           handler: () => {
    //             setShowAlert('')
    //           },
    //         },
    //       ]} />
    //   )
    // }

    function renderToast(): JSX.Element {
      return (
        <IonToast
          isOpen={!!showToast}
          color='warning'
          onDidDismiss={() => setShowToast('')}
          message={showToast}
          buttons={[
            {
              side: 'end',
              text: 'OK',
              handler: () => {
                setShowToast('')
                setShowBlockTimeToast(false)
              },
            },
          ]} />
      )
    }

    const container: CSSProperties = {
      flex: 1,
      display: 'flex',
      overflowX: 'scroll',
      overflowY: 'scroll',
      flexWrap: 'nowrap',
      paddingTop: calendarTopPadding,
    }

    if (disableScroll) {
      container.overflowX = 'hidden'
      container.overflowY = 'hidden'
    }

    return (
      <div
        id={calendarBoxId}
        data-ci-id='calendar-box'
        style={container}
        ref={pageRef}
        onScroll={onScroll}>
        {Array(...Array(endDay - startDay)).map((v, day) =>
          renderDayColumn(day),
        )}
        {/* {renderInfoPopover()} */}
        {renderInfoModal()}
        {renderToast()}
      </div>
    )
  }

// interface SwitchProps {
//   isActive: boolean;
//   onSelect: () => void;
//   onUnselect: () => void;
// }

// const SelectSwitch: React.FC<SwitchProps> = ({ isActive, onSelect, onUnselect }) => {
//   const [checked, setChecked] = useState(isActive)

//   return (
//     <IonToggle
//       color='success'
//       checked={checked}
//       onIonChange={() => {
//         if (isActive) {
//           setChecked(false)
//           onUnselect()
//         } else {
//           setChecked(true)
//           onSelect()
//         }
//       }} />
//   )
// }

export default CalendarBox
