import React, { CSSProperties } from 'react'

import {
  IonLabel, IonItem, IonIcon, IonChip, IonText,
} from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import TipTarget from 'components/atoms/TipTarget/TipTarget'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

const bottomBox: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 20,
}
const weekDays = [
  {
    label: 'Monday',
    value: 'mon',
  },
  {
    label: 'Tuesday',
    value: 'tue',
  },
  {
    label: 'Wednesday',
    value: 'wed',
  },
  {
    label: 'Thursday',
    value: 'thu',
  },
  {
    label: 'Friday',
    value: 'fri',
  },
  {
    label: 'Saturday',
    value: 'sat',
  },
  {
    label: 'Sunday',
    value: 'sun',
  },
]

export interface SelectAutoBookDaysProps {
  selected: string[];
  tip?: boolean;
  onSelect: (selected: string) => void;
  onUpdate: (selected: string[]) => void;
}

const SelectAutoBookDays: React.FC<SelectAutoBookDaysProps> = ({ selected, tip, onSelect, onUpdate }) => {
  const { logEvent } = useAnalytics()

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'SelectAutoBookDays',
      },
    })
  }

  function renderRanges (): JSX.Element {
    const weekdays = (selected.filter(d => d !== 'sat' && d !== 'sun').length === 5)
    const weekend = (selected.find(d => d === 'sat') && selected.find(d => d === 'sun'))

    return (
      <div style={bottomBox}>
        <IonChip
          onClick={() => {
            logTap({ button: 'Weekdays' })

            let days = selected.filter(d => (d === 'sun' || d === 'sat'))

            if (!weekdays) {
              days = days.concat(['mon', 'tue', 'wed', 'thu', 'fri'])
            }

            if (days.length > 0) {
              onUpdate(days)
            }
          }}
          color={weekdays ? 'secondary' : 'dark'}>
          <IonLabel>Weekdays</IonLabel>
        </IonChip>
        <IonChip
          onClick={() => {
            logTap({ button: 'Weekend' })

            let days = selected.filter(d => (d !== 'sun' && d !== 'sat'))

            if (!weekend) {
              days = days.concat(['sat', 'sun'])
            }

            if (days.length > 0) {
              onUpdate(days)
            }
          }}
          color={weekend ? 'secondary' : 'dark'}>
          <IonLabel>Weekend</IonLabel>
        </IonChip>
      </div>
    )
  }

  return (
    <div>
      {weekDays.map((day, i) => {
        const isSelected = selected.find(d => d === day.value)

        return (
          <IonItem
            key={i}
            button
            detail={false}
            lines='full'
            onClick={() => {
              logTap({ button: 'SelectDay' })
              onSelect(day.value)
            }}>
            <IonLabel>{day.label}</IonLabel>
            {tip && i === 0 &&
              <IonText slot='end'>
                <TipTarget />
              </IonText>}
            {isSelected &&
              <IonIcon
                slot='end'
                color='secondary'
                style={{ fontSize: 22 }}
                icon={checkmark} />}
          </IonItem>
        )
      })}
      {renderRanges()}
    </div>
  )
}

export default SelectAutoBookDays
