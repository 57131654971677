import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'

import 'screens/Settings.css'

import ProfilePhotoProvider from 'context/ProfilePhotoContext/ProfilePhotoContext'
import Account from './Account'
import ContactsProvider from 'context/ContactsContext/ContactsContext'
import TokensProvider from 'context/TokensContext/TokensContext'

const AccountScreen: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [showTip, setShowTip] = useState('')

  useEffect(() => {
    if (location.search) {
      console.log('URL: ', location.search)

      const params = queryString.parse(location.search)

      const tip = params.showTip as string

      console.log('TIP: ', tip)

      if (tip) {
        setTimeout(() => {
          setShowTip(tip)
        }, 1000)
      }
    }
  }, [])

  function onAddContact (url: string): void {
    history.push(url)
  }

  function onDeleteUser (): void {
    history.replace('/welcome')
    window.location.reload()
  }

  return (
    <ProfilePhotoProvider>
      <ContactsProvider>
        <TokensProvider>
          <Account
            showTip={showTip}
            setShowTip={setShowTip}
            onAddContact={onAddContact}
            onDeleteUser={onDeleteUser}
            goBack={() => {
              history.goBack()
            }} />
        </TokensProvider>
      </ContactsProvider>
    </ProfilePhotoProvider>
  )
}
export default AccountScreen
