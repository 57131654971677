import React, { CSSProperties, useState } from 'react'
import moment from 'moment'
import { IonText, IonItem, IonIcon, IonLabel, IonPopover, IonList } from '@ionic/react'
import CalendarEvents from '../../atoms/CalendarEvent/CalendarEvent'
import { CalendarEvent } from 'types'
import { ellipse } from 'ionicons/icons'
import { useCalendarView } from '../ScheduleCalendar/CalendarViewContext'

// startTime: "2020-09-04T16:00:00.000Z", endTime: "2020-09-05T00:00:00.000Z"

const container: CSSProperties = {
  display: 'flex',
  overflowX: 'hidden',
  flexWrap: 'nowrap',
  paddingBottom: 5,
  WebkitUserSelect: 'none',
}

interface ContainerProps {
  startDay: number;
  endDay: number;
  autoBook?: boolean;
  autoDays?: string[];
  calendarEvents?: CalendarEvent[];
  onSelectDay?: (time: string) => void;
}

const DaysBar: React.FC<ContainerProps> = ({ startDay, endDay, autoBook, autoDays, calendarEvents, onSelectDay }) => {
  const [showInfoPopover, setShowInfoPopover] = useState(false)
  const [popoverEvents, setPopoverEvents] = useState<CalendarEvent[]>()

  function renderPopoverEvents (): JSX.Element | null {
    if (popoverEvents) {
      if (popoverEvents?.length) {
        return (
          <div>
            {popoverEvents.map((event, i) => {
              // const start = moment(event.startTime).format('h:mm A')
              // const end = moment(event.endTime).format('h:mm A')

              return (
                <IonItem
                  key={i}
                  lines={(i < popoverEvents.length - 1) ? 'inset' : 'none'}>
                  <IonIcon
                    size='small'
                    style={{ color: event.backgroundColor, marginRight: 10 }}
                    icon={ellipse} />
                  <IonLabel className='ion-text-wrap'>
                    <h2>{event.summary}</h2>
                  </IonLabel>
                </IonItem>
              )
            })}
          </div>
        )
      }
    }

    return null
  }

  function renderInfoPopover (): JSX.Element | null {
    if (popoverEvents) {
      // Note: on android the list has a small padding at the bottom
      // and since we are using a slight shaded background for the select button
      // get rid of the bottom padding
      // const { time, startTime, endTime, calendarEvents } = popoverInfo
      const event = popoverEvents[0]
      const day = moment(event.startTime).format('ddd D')

      return (
        <IonPopover
          isOpen={showInfoPopover}
          onDidDismiss={() => setShowInfoPopover(false)}>
          <IonList style={{ paddingBottom: 10 }}>
            <IonItem
              lines='full'>
              <IonLabel>
                <h2 style={{ fontWeight: 'bold' }}>{day}</h2>
                <p>All day</p>
              </IonLabel>
            </IonItem>
            {renderPopoverEvents()}
          </IonList>
        </IonPopover>
      )
    }

    return null
  }

  return (
    <div
      id='dayBar'
      style={container}>
      {Array(...Array(endDay - startDay)).map((v, i) =>
        <div
          key={i}
          style={{ display: 'flex', flexDirection: 'column' }}>
          <DayLabel
            startDay={startDay}
            day={i}
            autoDays={autoDays}
            onSelectDay={onSelectDay} />
          {autoBook &&
            <DayEvents
              startDay={startDay}
              day={i}
              calendarEvents={calendarEvents}
              onSelectDay={onSelectDay}
              onSelectEvents={events => {
                if (events?.length) {
                  setPopoverEvents(events)
                  setShowInfoPopover(true)
                }
              }} />}
        </div>,
      )}
      {renderInfoPopover()}
    </div>
  )
}

interface DayLabelProps {
  startDay: number;
  day: number;
  autoDays?: string[]
  onSelectDay?: (time: string) => void
}

const DayLabel: React.FC<DayLabelProps> = ({ startDay, day, autoDays, onSelectDay }) => {
  const { timeSlotWidth = 90, dayLabelHeight = 30 } = useCalendarView()

  const container: CSSProperties = {
    position: 'relative',
    minWidth: timeSlotWidth,
    height: dayLabelHeight,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }

  const label: CSSProperties = {
    fontSize: 16,
  }
  const current = startDay + day
  const currentMoment = moment().add(current, 'days')
  const date = currentMoment.format('ddd D')
  const currentDay = currentMoment.format('ddd').toLowerCase()
  const previousDay = moment().add(current - 1, 'days').format('ddd').toLowerCase()
  const nextDay = moment().add(current + 1, 'days').format('ddd').toLowerCase()
  let auto = false
  let left = false
  let right = false

  if (Array.isArray(autoDays)) {
    if (autoDays.find(d => d === currentDay)) {
      auto = true
    }

    if ((current - 1 >= 0) && autoDays.find(d => d === previousDay)) {
      left = true
    }

    if (autoDays.find(d => d === nextDay)) {
      right = true
    }
  }

  return (
    <div
      style={container}
      onClick={() => onSelectDay && onSelectDay(currentMoment.toISOString())}>
      <IonText
        style={label}>
        {date}
      </IonText>
      {auto &&
        <Range
          left={left}
          right={right} />}
    </div>
  )
}

interface Props {
  left: boolean;
  right: boolean;
}

const Range: React.FC<Props> = ({ left, right }) => {
  const { timeSlotWidth = 90 } = useCalendarView()

  const radius = '1px'
  const container: CSSProperties = {
    position: 'absolute',
    bottom: 5,
    height: 2,
    width: timeSlotWidth,
    borderStyle: 'solid',
    borderWidth: '1px',
  }

  if (!left) {
    container.borderTopLeftRadius = radius
    container.borderBottomLeftRadius = radius
  }

  if (!right) {
    container.borderTopRightRadius = radius
    container.borderBottomRightRadius = radius
  }

  return (
    <div
      style={container}
      className='CalendarAAutoRange' />
  )
}

interface DayProps {
  startDay: number;
  day: number;
  calendarEvents?: CalendarEvent[];
  onSelectEvents: (calendarEvents?: CalendarEvent[]) => void;
  onSelectDay?: (time: string) => void;
}

const DayEvents: React.FC<DayProps> = ({ startDay, day, calendarEvents, onSelectEvents, onSelectDay }) => {
  let allDayEvents: CalendarEvent[] | undefined

  const { timeSlotWidth = 90, allDayEventsBoxHeight } = useCalendarView()

  const container: CSSProperties = {
    height: allDayEventsBoxHeight,
    minWidth: timeSlotWidth,
    position: 'relative',
  }

  const dayStart = moment().add((startDay + day), 'days').startOf('day').valueOf()
  const dayEnd = moment(dayStart).add(24, 'hours').valueOf()

  function renderAllDayEvents (): JSX.Element | null {
    if (calendarEvents?.length) {
      allDayEvents = calendarEvents.filter(event => {
        const start = moment(event.startTime).valueOf()
        const end = moment(event.endTime).valueOf()

        if (start === dayStart && end >= dayEnd) {
          return true
        }
      })

      if (allDayEvents.length) {
        const startTime = moment().startOf('day').toISOString()
        const endTime = moment(startTime).add(1, 'hour').toISOString()
        const timeRange = {
          startTime,
          endTime,
        }

        return (
          <CalendarEvents
            split={false}
            dayStartTime={startTime}
            scale={90}
            timeRange={timeRange}
            events={allDayEvents} />
        )
      }
    }

    return null
  }

  return (
    <div
      className='CalendarABorderRight'
      style={container}
      onClick={() => {
        if (allDayEvents?.length) {
          onSelectEvents(allDayEvents)
        } else {
          onSelectDay && onSelectDay(moment(dayStart).toISOString())
        }
      }}>
      {renderAllDayEvents()}
    </div>

  )
}

export default DaysBar
