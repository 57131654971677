import React, { CSSProperties, useEffect } from 'react'
import { IonSlide, IonContent, IonPage, IonText, IonFooter, IonIcon, IonLabel, IonItem } from '@ionic/react'

import 'theme/styles.css'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { ban, shieldCheckmark } from 'ionicons/icons'
import { encryptedDeviceFingerPrint } from 'services/accounts'
import { encryptedAccessToken } from 'services/security/accessToken'
import { AddCalendarInput, CalendarService } from 'services/api'
import AnimatedSwipeLeft from 'components/atoms/AnimatedSwipeLeft/AnimatedSwipeLeft'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

import { useTips } from 'context/TipsContext/TipsContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'

const slide: CSSProperties = {
  display: 'flex',
  overflowY: 'auto',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: 40,
  paddingTop: 10,
}

const title = 'Calendar Permissions'

const screenShot: CSSProperties = {
  maxHeight: '65%',
}
const bottomBox: CSSProperties = {
  width: '100%',
  // marginTop: 20,
  alignItems: 'flex-start',
  textAlign: 'start',
}

const cardTitle: CSSProperties = {
  fontSize: 20,
  fontWeight: 'bold',
}

const messageBox: CSSProperties = {
  marginTop: 20,
  alignItems: 'flex-start',
  textAlign: 'start',
}

const cardMessage: CSSProperties = {
  fontSize: 15,
}

const swipeLeft: CSSProperties = {
  position: 'absolute',
  top: '60%',
  right: 10,
}

interface ComponentProps {
  goBack: () => void;
  onNext?: () => void;
  onAddCalendar?: (url: string) => void;
}

const TipsGooglePermissions: React.FC<ComponentProps> = ({ goBack, onAddCalendar }) => {
  const { updateStoreTips } = useTips()

  const { meeting } = useMeetings()
  const { addCalendar } = useCalendars()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'TipsGooglePermissions', meetingId: meeting?.id },
    })
  }, [])

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'TipsGooglePermissions',
      },
    })
  }

  async function addGoogleCalendar(): Promise<void> {
    if (meeting?.id && onAddCalendar) {
      const addToken = encryptedAccessToken() || ''
      const deviceId = encryptedDeviceFingerPrint()

      const state = {
        token: addToken,
        deviceId,
        steps: true,
        // previous: goBackTo,
        // next: nextScreen,
        meetingId: meeting?.id,
      }
      const input: AddCalendarInput = {
        service: CalendarService.Google,
        state: JSON.stringify(state),
      }
      const authUrl = await addCalendar(input)

      if (authUrl) {
        console.log('ADD CALENDAR RETURNED: ', authUrl)
        onAddCalendar(authUrl)
      }
    }
  }

  function renderFooter(): JSX.Element | undefined {
    if (onAddCalendar) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ component: 'FooterButton', button: 'Proceed' })

              if (updateStoreTips) {
                updateStoreTips({ tipsGooglePermissions: { completed: true } })
              }

              addGoogleCalendar()
            }}>
            Proceed to Google
          </FooterButton>
        </IonFooter>
      )
    }
  }

  // function renderHeader (): JSX.Element {
  //   if (lastSlide || !onNext) {
  //     return (
  //       <ScreenHeader
  //         title={title}
  //         onBack={goBack} />
  //     )
  //   }

  //   return (
  //     <ScreenHeader
  //       title={title}
  //       nextButton='Skip'
  //       onBack={goBack}
  //       onNext={onNext} />
  //   )
  // }

  // for now disable the skip button
  function renderHeader(): JSX.Element {
    return (
      <ScreenHeader
        title={title}
        onBack={goBack} />
    )
  }

  return (
    <IonPage>
      {renderHeader()}
      <IonContent>
        <Intro addCalendar={!!onAddCalendar} />
        {/* <SelectAccount /> */}
        {/* <Permissions1 />
          <Permissions2 /> */}
        {/* <Permissions3 /> */}
        {/* <Permissions4 /> */}
      </IonContent>
      {renderFooter()}
    </IonPage>
  )
}

interface Props {
  addCalendar?: boolean;
}

export const Intro: React.FC<Props> = ({ addCalendar }) => {
  const introSlide: CSSProperties = {
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }

  const titleBox: CSSProperties = {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: 'center',
  }

  const box: CSSProperties = {
    paddingTop: 10,
    textAlign: 'center',
  }

  const box2: CSSProperties = {
    width: '100%',
    padding: 20,
    paddingTop: 10,
    alignItems: 'flex-start',
    textAlign: 'start',
  }

  return (
    <div style={introSlide}>
      <div
        style={titleBox}
        className='titleIconBox'>
        <img
          src='assets/images/GoogleLogo.svg'
          style={{ height: 40, width: 40 }}
          alt='' />
        <div style={box}>
          <IonText style={{ fontWeight: 'bold', fontSize: 18 }}>
            About Calendar Permissions
          </IonText>
        </div>
      </div>
      <div style={box2}>
        <IonText style={{ fontSize: 16 }}>
          {addCalendar && 'Next you will be redirected to Google to allow MeetingTime to access your calendars.'}
          Following are the permissions that MeetingTime will need and why.
        </IonText>
        <div style={messageBox}>
          <IonItem lines='full'>
            <IonIcon
              slot='start'
              icon={shieldCheckmark}
              color='success' />
            <IonLabel
              className='ion-text-wrap'
              style={{ fontSize: 15 }}>
              <b>Permission to read</b> your calendar events to determine your availability
            </IonLabel>
          </IonItem>
          <IonItem lines='full'>
            <IonIcon
              slot='start'
              icon={shieldCheckmark}
              color='success' />
            <IonLabel
              className='ion-text-wrap'
              style={{ fontSize: 15 }}>
              <b>Permission to create and edit</b> only those events associated with meetings scheduled by MeetingTime
            </IonLabel>
          </IonItem>

          <IonItem lines='full'>
            <IonIcon
              slot='start'
              icon={ban}
              color='danger' />
            <IonLabel
              className='ion-text-wrap'
              style={{ fontSize: 15 }}>
              <b>MeetingTime will never touch anything else on your calendar other than events created by the app</b>
            </IonLabel>
          </IonItem>
        </div>
        {/* {addCalendar &&
          <div style={messageBox}>
            <IonText
              style={{ fontSize: 14, marginTop: 10 }}>
              Swipe left to find out more or proceed to Google Accounts below.
              When you are done you will be redirected back to MeetingTime.
            </IonText>
          </div>} */}
      </div>
      {/* <div
        style={swipeLeft}>
        <AnimatedSwipeLeft
          delay={3000}
          loop />
      </div> */}
    </div>
  )
}

export const SelectAccount: React.FC = () => {
  return (
    <IonSlide style={slide}>
      <img
        src='assets/images/googleAccounts.png'
        style={{ maxHeight: '65%' }}
        alt='' />
      <div style={{ ...bottomBox, marginTop: 20 }}>
        <IonText style={cardTitle}>
          Select Calendar Account
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            On this screen either sign into or select the Google calendar account you would like to securely connect with MeetingTime.
            Google does not share your password with MeetingTime. You are signing into Google to give MeetingTime permission to access your calendar.
          </IonText>
        </div>
      </div>
      <div
        style={swipeLeft}>
        <AnimatedSwipeLeft
          delay={2000}
          loop />
      </div>
    </IonSlide>
  )
}

export const Permissions1: React.FC = () => {
  return (
    <IonSlide style={slide}>
      <img
        src='assets/images/googlePermissions1.png'
        style={screenShot}
        alt='' />
      <div style={bottomBox}>
        <IonText style={cardTitle}>
          MeetingTime Under Review
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            MeetingTime is currently under review by Google. On this screen please proceed by tapping on Advanced button highlighted.
          </IonText>
        </div>
      </div>
      <div
        style={swipeLeft}>
        <AnimatedSwipeLeft
          delay={2000}
          loop />
      </div>
    </IonSlide>
  )
}

export const Permissions2: React.FC = () => {
  return (
    <IonSlide style={slide}>
      <img
        src='assets/images/googlePermissions2.png'
        style={screenShot}
        alt='' />
      <div style={bottomBox}>
        <IonText style={cardTitle}>
          MeetingTime Under Review
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            On this screen please continue by tapping on Go to MeetingTime Meet button highlighted. Rest assured we handle your data with utmost care.
            <IonIcon
              icon={shieldCheckmark}
              style={{ marginLeft: 5, marginBottom: -4, fontSize: 18 }}
              color='success' />
          </IonText>
        </div>
      </div>
      <div
        style={swipeLeft}>
        <AnimatedSwipeLeft
          delay={2000}
          loop />
      </div>
    </IonSlide>
  )
}

export const Permissions3: React.FC = () => {
  return (
    <IonSlide style={slide}>
      <img
        src='assets/images/googlePermissions3.png'
        style={screenShot}
        alt='' />
      <div style={bottomBox}>
        <IonText style={cardTitle}>
          Grant Permissions
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            On this screen please note that even though google permission says you are allowing MeetingTime to see, edit, share and permanently delete all the calendars, MeetingTime will only touch events scheduled by MeetingTime on your behalf and nothing else.
          </IonText>
          <IonIcon
            icon={shieldCheckmark}
            style={{ marginLeft: 5, marginBottom: -4, fontSize: 18 }}
            color='success' />
        </div>
      </div>
      <div
        style={swipeLeft}>
        <AnimatedSwipeLeft
          delay={2000}
          loop />
      </div>
    </IonSlide>
  )
}

export const Permissions4: React.FC = () => {
  return (
    <IonSlide style={slide}>
      <img
        src='assets/images/googlePermissions4.png'
        style={screenShot}
        alt='' />
      <div style={{ ...bottomBox, marginTop: 20 }}>
        <IonText style={cardTitle}>
          Confirm Permissions
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            On this screen please proceed by confirming the permissions.
            You will be redirected back to MeetingTime.
            <IonIcon
              icon={shieldCheckmark}
              style={{ marginLeft: 5, marginBottom: -4, fontSize: 18 }}
              color='success' />
          </IonText>
        </div>
      </div>
    </IonSlide>
  )
}

export default TipsGooglePermissions
