
import React, { CSSProperties } from 'react'
import { IonText } from '@ionic/react'

import TipCard from 'components/organisms/TipCard/TipCard'
import AnimatedCheckmark from 'components/atoms/AnimatedCheckmark/AnimatedCheckmark'

const box: CSSProperties = {
  display: 'flex',
  marginLeft: -5,
}

const boxLeft: CSSProperties = {
  display: 'flex',
}

const boxRight: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: 10,
  fontWeight: 'bold',
}

const boxRightSubtext: CSSProperties = {
  flex: 1,
  display: 'flex',
  fontWeight: 'normal',
}

interface ContainerProps {
  animatedProps: CSSProperties,
  show?: boolean;
  text?: string;
  subtext?: string;
  buttons?: string[];
  onClose?: () => void;
  onButtons?: (button: number) => void;
}

export const EndTipsCard: React.FC<ContainerProps> = ({
  animatedProps, show, text, subtext, buttons, onButtons,
}) => {
  return (
    <TipCard
      animatedProps={animatedProps}
      buttons={buttons || ['yes', 'no']}
      onButtons={onButtons}>
      <div style={box}>
        {show &&
          <div style={boxLeft}>
            <AnimatedCheckmark
              loop
              style={{ width: 40, height: 40 }} />
          </div>}
        <div style={boxRight}>
          <IonText>
            {text}
          </IonText>
          <div style={boxRightSubtext}>
            {subtext &&
              <IonText>
                {subtext}
              </IonText>}
          </div>
        </div>
      </div>
    </TipCard>
  )
}

export default EndTipsCard
