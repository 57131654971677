import React, { CSSProperties, useEffect, useState } from 'react'
import {
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonItem, IonIcon,
  IonLabel, IonChip, IonText, IonButton, isPlatform, IonSpinner,
} from '@ionic/react'
import moment from 'moment'

import {
  calendarOutline, personCircleOutline,
  videocamOutline,
  flashOffOutline, warning, flash, downloadOutline,
} from 'ionicons/icons'
import { Meeting, MeetingTime, TimeRange } from 'types'

import { dayRangesToString, compareTimes, minutesToString, timeRangesToString } from 'services/time'
import MeetingTimeListItem from 'components/molecules/MeetingTimeListItem/MeetingTimeListItem'
import { useUser } from 'context/UserContext/UserContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import MeetingCreator from 'components/molecules/MeetingCreator/MeetingCreator'
import MeetingCardParticipants from 'components/molecules/MeetingCardParticipants/MeetingCardParticipants'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import { MeetingBookCalendar, useCalendars } from 'context/CalendarsContext/CalendarsContext'
import { logoColor } from 'theme/styles'
import TipTarget from 'components/atoms/TipTarget/TipTarget'
// import { useScreenTips } from 'context/ScreenTipsContext/ScreenTipsContext'
import { MeetingCardTips } from 'types/componentTips'
import { useTips } from 'context/TipsContext/TipsContext'
import { MeetingCard as StoreTips } from 'services/store/tips/types'
import { useScreenTips } from 'context/ScreenTipsContext/ScreenTipsContextNew'
import { useScreens } from 'context/ScreensContext/ScreensContext'
import MeetingCardStatus from 'components/molecules/MeetingCardStatus/MeetingCardStatus'
import { adjustTimeRangesForDay } from '../ScheduleCalendar/utils'
import MeetingCardDescription from 'components/molecules/MeetingCardDescription/MeetingCardDescription'
import { useScreenLogEvent } from 'context/ScreenLogEventContext/ScreenLogEventContext'
import { EventData } from 'context/AnalyticsContext/AnalyticsContext'
import ListItem from 'components/atoms/ListItem/ListItem'
import { addToDeviceCalendar, deleteFromDeviceCalendar } from 'services/deviceCalendar'
import { DeviceCalendarAction } from 'services/api'
import { useMeetingNotify } from 'context/MeetingNotifyContext/MeetingNotifyContext'
import Fireworks from 'components/atoms/Fireworks/Fireworks'

type BestRange = {
  timeRange: TimeRange;
  type: string;
}

const leftIconStyle: CSSProperties = {
  marginRight: 20,
}

const titleStyle: CSSProperties = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
  marginBottom: 10,
}

export interface MeetingCardProps {
  meeting: Meeting;
  inviteFrom?: string;
  showBestTime?: boolean;
  showJoining?: boolean;
  showVideo?: boolean;
  showTips?: boolean;
  firstJoinedMeeting?: boolean;
  onChooseAvailability?: (meeting: Meeting, time?: string, autoBook?: boolean) => void;
  onMeetingTime?: (meeting: Meeting, meetingTime: MeetingTime) => void;
  onEdit?: (meeting: Meeting) => void;
  onInvite?: (meeting: Meeting, byLink?: boolean) => void;
  onParticipants?: (meeting: Meeting) => void;
  onDelete?: (meeting: Meeting) => void;
  onMoreActions?: (meeting: Meeting) => void;
  onAddCalendar?: (meeting: Meeting) => void;
  onCalendarSettings?: (meeting: Meeting) => void;
  onDescription?: (meeting: Meeting) => void;
}

const MeetingCard: React.FC<MeetingCardProps> = (
  {
    meeting, inviteFrom, showBestTime, showVideo, firstJoinedMeeting, showTips,
    onEdit, onInvite, onChooseAvailability, onParticipants, onMeetingTime,
    onMoreActions, onAddCalendar, onCalendarSettings, onDescription,
  }) => {
  const { title, dayRanges, timeRanges, videoConference } = meeting
  // const peopleCount = meeting.participants && meeting.participants.length ? meeting.participants.length : 0
  const [bookCalendar, setBookCalendar] = useState<MeetingBookCalendar>()
  const [bookCalendarLoading, setBookCalendarLoading] = useState(true)
  const duration = minutesToString(meeting.duration)

  const { user } = useUser()
  const { participants, participant, loading: participantsLoading, updateParticipant } = useParticipants()
  const { meetingInvitees, loading: inviteesLoading } = useInvitees()
  const { timeSlots, loading: timeSlotsLoading } = useTimeSlots()
  const { calendars, loading: calendarsLoading, getMeetingCalendar } = useCalendars()
  const { showScreenTip, minTip, tipCounter = 0, setShowScreenTip, setTipCounter } = useScreenTips()
  const [allLoaded, setAllLoaded] = useState(false)
  const { storeTips, updateStoreTips } = useTips()
  const { screens } = useScreens()
  const { logTapEvent } = useScreenLogEvent()
  const { showFireworks, setShowFireworks } = useMeetingNotify()

  // function renderPhoto (): JSX.Element | undefined {
  //   return (
  //     <IonImg
  //       style={photoStyle}
  //       className='header-img'
  //       src='https://images.unsplash.com/photo-1584992236310-6edddc08acff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2534&q=80' />
  //   )
  // }

  function logTap(eventData: EventData): void {
    logTapEvent({
      ...eventData,
      component: 'MeetingCard',
      meetingId: meeting.id,
    })
  }

  const componentStoreTips = storeTips?.meetingCard

  async function getBookCalendar(): Promise<void> {
    if (calendars && participant) {
      const calendar = await getMeetingCalendar(participant)

      if (calendar) {
        setBookCalendar(calendar)
      }

      setBookCalendarLoading(false)
    }
  }
  // Note this optimization doesn't work well with bookCalendar
  useEffect(() => {
    if (participants && meetingInvitees && timeSlots && calendars && bookCalendar) {
      setAllLoaded(true)
    }
  }, [])
  useEffect(() => {
    if (!participantsLoading && !inviteesLoading && !timeSlotsLoading && !calendarsLoading && !bookCalendarLoading) {
      if (Array.isArray(participants) &&
        Array.isArray(meetingInvitees) &&
        Array.isArray(timeSlots) &&
        Array.isArray(calendars)) {
        setAllLoaded(true)
      }
    }
  }, [participantsLoading, inviteesLoading, timeSlotsLoading, calendarsLoading, bookCalendarLoading])

  const confirmed = meeting?.meetingTimes?.find(mt => mt.status === 'confirmed')

  function setNextTip(tip: MeetingCardTips): void {
    if (screens?.allScreens?.newInvitee) {
      setNextTipNewInvitee(tip)
    } else {
      setNextTipNormal(tip)
    }
  }

  function setNextTipNewInvitee(tip: MeetingCardTips): void {
    // console.log('Meeting screen setNextTip: ', tip)

    if (showTips && setShowScreenTip) {
      let trending
      let tentative

      if (meeting && meeting.meetingTimes?.length) {
        trending = meeting.meetingTimes.find(mt => mt.status === 'trending')
        tentative = meeting.meetingTimes.find(mt => mt.status === 'tentative')
      }

      switch (tip) {
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.sequence:
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.trendingTimes:
          if (!componentStoreTips?.trendingTimes) {
            if (trending && !tentative) {
              setShowScreenTip({ meetingCard: MeetingCardTips.trendingTimes })

              break
            }
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.tentativeTimes:
          if (!componentStoreTips?.tentativeTimes) {
            if (tentative) {
              setShowScreenTip({ meetingCard: MeetingCardTips.tentativeTimes })

              break
            }
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.confirmedTime:
          if (!componentStoreTips?.confirmedTime) {
            if (confirmed) {
              setShowScreenTip({ meetingCard: MeetingCardTips.confirmedTime })

              break
            }
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.chooseAvailability:
          if (!timeSlots?.find(slot => slot.userId === user?.id) &&
            (!bookCalendar || !bookCalendar.autoBook) &&
            !componentStoreTips?.chooseAvailability) {
            setShowScreenTip({ meetingCard: MeetingCardTips.chooseAvailability })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.changeAvailability:
          if (timeSlots?.find(slot => slot.userId === user?.id) &&
            (!bookCalendar || !bookCalendar.autoBook) &&
            !componentStoreTips?.changeAvailability) {
            setShowScreenTip({ meetingCard: MeetingCardTips.changeAvailability })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.done:
        default:
          // pass it back to meetings screen
          setShowScreenTip({})
          setTipCounter && setTipCounter(tipCounter + 1)
          break
      }
    }
  }

  function setNextTipNormal(tip: MeetingCardTips): void {
    // console.log('Meeting screen setNextTip: ', tip)

    if (showTips && setShowScreenTip) {
      let trending
      let tentative

      if (meeting && meeting.meetingTimes?.length) {
        trending = meeting.meetingTimes.find(mt => mt.status === 'trending')
        tentative = meeting.meetingTimes.find(mt => mt.status === 'tentative')
      }

      switch (tip) {
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.sequence:
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.trendingTimes:
          if (!componentStoreTips?.trendingTimes) {
            if (trending && !tentative) {
              setShowScreenTip({ meetingCard: MeetingCardTips.trendingTimes })

              break
            }
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.tentativeTimes:
          if (!componentStoreTips?.tentativeTimes) {
            if (tentative) {
              setShowScreenTip({ meetingCard: MeetingCardTips.tentativeTimes })

              break
            }
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.confirmedTime:
          if (!componentStoreTips?.confirmedTime) {
            if (confirmed) {
              setShowScreenTip({ meetingCard: MeetingCardTips.confirmedTime })

              break
            }
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.chooseAvailability:
          if (!timeSlots?.find(slot => slot.userId === user?.id) &&
            (!bookCalendar || !bookCalendar.autoBook) &&
            !componentStoreTips?.chooseAvailability) {
            setShowScreenTip({ meetingCard: MeetingCardTips.chooseAvailability })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.changeAvailability:
          if (timeSlots?.find(slot => slot.userId === user?.id) &&
            (!bookCalendar || !bookCalendar.autoBook) &&
            !componentStoreTips?.changeAvailability) {
            setShowScreenTip({ meetingCard: MeetingCardTips.changeAvailability })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.turnOnAuto:
          if ((!bookCalendar || !bookCalendar.autoBook) &&
            !componentStoreTips?.turnOnAuto) {
            setShowScreenTip({ meetingCard: MeetingCardTips.turnOnAuto })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.changeAuto:
          if ((bookCalendar && bookCalendar.autoBook) &&
            !componentStoreTips?.changeAuto) {
            setShowScreenTip({ meetingCard: MeetingCardTips.changeAuto })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.availabilityCalendar:
          if ((bookCalendar && bookCalendar.autoBook) &&
            !componentStoreTips?.availabilityCalendar) {
            setShowScreenTip({ meetingCard: MeetingCardTips.availabilityCalendar })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.inviteParticipants:
          if (participants && participants.length === 1 && !componentStoreTips?.inviteParticipants) {
            setShowScreenTip({ meetingCard: MeetingCardTips.inviteParticipants })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.participants:
          if (participants && participants.length > 1 && !componentStoreTips?.participants) {
            setShowScreenTip({ meetingCard: MeetingCardTips.participants })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.meetingTitle:
          if (!componentStoreTips?.meetingTitle) {
            setShowScreenTip({ meetingCard: MeetingCardTips.meetingTitle })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.moreActions:
          if (!componentStoreTips?.moreActions) {
            setShowScreenTip({ meetingCard: MeetingCardTips.moreActions })
            break
          }
        // eslint-disable-next-line no-fallthrough
        case MeetingCardTips.done:
        default:
          // pass it back to meetings screen
          setShowScreenTip({})
          setTipCounter && setTipCounter(tipCounter + 1)
          break
      }
    }
  }

  useEffect(() => {
    // console.log('tipCounter changed: ', tipCounter)

    if (tipCounter && allLoaded && showScreenTip?.meetingCard) {
      setNextTip(showScreenTip.meetingCard + 1)
    }
  }, [tipCounter])

  useEffect(() => {
    if (allLoaded && showScreenTip?.meetingCard === MeetingCardTips.sequence) {
      setNextTip(MeetingCardTips.sequence)
    }
  }, [allLoaded])

  useEffect(() => {
    if (participant) {
      getBookCalendar()
    }
  }, [calendars, participant])

  function updateTip(tip: StoreTips): void {
    if (updateStoreTips) {
      updateStoreTips({
        meetingCard: {
          ...componentStoreTips, ...tip,
        },
      })
    }
  }

  function openInNewTab(url: string): void {
    const win = window.open(url, '_blank')

    win && win.focus()
  }

  function renderMeetingConference(): JSX.Element | undefined {
    if (showVideo && videoConference && videoConference.link) {
      if (meeting?.meetingTimes?.length) {
        if (confirmed) {
          const { startTime, endTime } = confirmed
          const start = moment(startTime).subtract(10, 'minutes').valueOf()
          const end = moment(endTime).add(10, 'minutes').valueOf()
          const current = moment().valueOf()

          if (current > start && current < end) {
            return (
              <IonItem
                lines='none'
                detail={false}
                onClick={() => {
                  openInNewTab(videoConference.link)
                  logTap({ button: 'VideoConference' })
                }}>
                <IonIcon
                  slot='start'
                  color='primary'
                  size='medium'
                  icon={videocamOutline}
                  style={leftIconStyle} />
                <IonLabel>Join Video Call</IonLabel>
              </IonItem>
            )
          }
        }
      }
    }
  }
  function renderMeetingTime(meetingTime: MeetingTime, index: number): JSX.Element | undefined {
    const { participantUserIds } = meetingTime

    const active = !!participantUserIds?.find(u => u === user?.id)

    const tip = index === 0 && showTips && !minTip &&
      (showScreenTip?.meetingCard === MeetingCardTips.trendingTimes ||
        showScreenTip?.meetingCard === MeetingCardTips.tentativeTimes ||
        showScreenTip?.meetingCard === MeetingCardTips.confirmedTime)

    return (
      <MeetingTimeListItem
        meetingTime={meetingTime}
        active={active}
        // lines={last ? 'inset' : 'inset'}
        lines='none'
        tip={tip}
        onSelect={() => {
          onMeetingTime && onMeetingTime(meeting, meetingTime)
          logTap({ button: 'TrendingTime' })
        }}
        onDetails={() => {
          onMeetingTime && onMeetingTime(meeting, meetingTime)
          // Note in this case select and details do the same so just use a single log button type
          logTap({ button: 'TrendingTime' })
        }} />
    )
  }

  function renderConfirmedTime(): JSX.Element | undefined {
    if (meeting?.meetingTimes?.length) {
      if (confirmed) {
        const { participantUserIds } = confirmed

        const active = !!participantUserIds?.find(u => u === user?.id)

        return (
          <div>
            {/* <IonText
              color='medium'
              style={titleStyle}>
              MEETING CONFIRMED AT
            </IonText> */}
            <MeetingTimeListItem
              meetingTime={confirmed}
              active={active}
              lines='none'
              onSelect={() => {
                onMeetingTime && onMeetingTime(meeting, confirmed)
                logTap({ button: 'ConfirmedTime' })
              }}
              onDetails={() => onMeetingTime && onMeetingTime(meeting, confirmed)} />
          </div>
        )
      }
    }
  }

  function renderTrendingTimeRanges(): JSX.Element | undefined {
    if (meeting?.meetingTimes?.length) {
      if (!confirmed) {
        // show only times that have their endTimes in the future
        const currentValue = moment().valueOf()
        const sortedTimes = meeting.meetingTimes.slice().filter(meetingTime => {
          if (moment(meetingTime.endTime).valueOf() > currentValue) {
            return true
          }
        }).sort((a: MeetingTime, b: MeetingTime) => {
          const timeA = a.startTime
          const timeB = b.startTime

          return compareTimes(timeA, timeB)
        })
        let times: MeetingTime[] = []

        times = sortedTimes.filter(mt => mt.status === 'tentative')
        times = times.concat(sortedTimes.filter(mt => mt.status === 'trending'))

        // console.log('MEETING TIMES: ', times)

        const maxTimes = 2

        times = times.splice(0, maxTimes)

        if (times.length) {
          return (
            <div style={{ marginTop: 10 }}>
              <IonText
                color='medium'
                style={titleStyle}>
                TRENDING TIMES
              </IonText>
              {times.map((meetingTime, i) => {
                return (
                  <div key={i}>
                    {renderMeetingTime(meetingTime, i)}
                  </div>
                )
              })}
              {/* {renderMoreMeetingTimes()} */}
            </div>
          )
        }
      }
    }
  }

  let changeAvailability = false

  if (user && timeSlots) {
    if (timeSlots.find(t => t.userId === user.id)) {
      changeAvailability = true
    }
  }

  function checkAutoTimes(autoTimes?: TimeRange[]): TimeRange[] | undefined {
    if (autoTimes?.length && timeRanges) {
      const dayStartTime = moment().startOf('day').toISOString()
      const autoRanges = adjustTimeRangesForDay(dayStartTime, autoTimes)
      const meetingRanges = adjustTimeRangesForDay(dayStartTime, timeRanges)
      // check if any of meetingRanges are outside the autoRanges
      const outsideAutoRange = meetingRanges.find(meetingRange => {
        const startRange = moment(meetingRange.startTime).valueOf()
        const endRange = moment(meetingRange.endTime).valueOf()

        return autoRanges.find(autoRange => {
          const startAuto = moment(autoRange.startTime).valueOf()
          const endAuto = moment(autoRange.endTime).valueOf()

          if (startRange >= startAuto && endRange <= endAuto) {
            return false
          }

          return true
        })
      })

      if (outsideAutoRange) {
        const updateAutoTimes = [
          {
            startTime: timeRanges[0].startTime,
            endTime: timeRanges[timeRanges.length - 1].endTime,
          },
        ]

        return updateAutoTimes
      }
    }
  }

  function renderAutoScheduling(): JSX.Element | undefined {
    if (!confirmed) {
      if (bookCalendar) {
        // const { autoTimes, autoBook } = bookCalendar
        const { autoBook, autoTimes } = bookCalendar
        let label = ''

        if (autoTimes?.length) {
          autoTimes.forEach((range, i) => {
            const start = moment(range.startTime).format('h:mm a')
            const end = moment(range.endTime).format('h:mm a')

            label += `${start} - ${end}`

            if (i < autoTimes.length - 1) {
              label += ', '
            }
          })
        } else {
          label = 'Anytime'
        }

        const adjustedAutoTimes = checkAutoTimes(autoTimes)
        const warningMessage = adjustedAutoTimes ? 'Your automagic schedule times don\'t cover the entire meeting time ranges.' : ''

        const iconStyle = {
          ...leftIconStyle,
          color: autoBook ? logoColor : 'gray',
        }

        return (
          <div>
            <IonItem
              // lines={chooseAvailability ? 'none' : 'inset'}
              lines='none'
              onClick={() => {
                // onEdit && onEdit(meeting)
                onCalendarSettings && onCalendarSettings(meeting)
                updateTip({ changeAuto: true })
                logTap({ button: 'AutoScheduling' })
              }}>
              {showTips && !minTip &&
                (showScreenTip?.meetingCard === MeetingCardTips.turnOnAuto ||
                  showScreenTip?.meetingCard === MeetingCardTips.changeAuto) &&
                <TipTarget
                  style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
              <IonIcon
                style={iconStyle}
                icon={autoBook ? flash : flashOffOutline}
                slot='start' />
              {autoBook &&
                <IonLabel className='ion-text-wrap'>
                  Automagic Scheduling is On
                  <p>from {label}</p>
                  {adjustedAutoTimes &&
                    <p>
                      <IonIcon
                        icon={warning}
                        color='warning' />
                      <IonText
                        color='danger'>
                        {warningMessage}
                      </IonText>
                    </p>}
                </IonLabel>}
              {!autoBook &&
                <IonLabel>
                  Automagic Scheduling is Off
                  {/* <p>book on: {calendar.name}</p> */}
                </IonLabel>}
            </IonItem>
            {/* <MeetingCalendar
            calendar={bookCalendar.calendar}
            onClick={() => console.log('auto scheduling')} /> */}
          </div>
        )
      } else if (!screens?.allScreens?.newInvitee) {
        const iconStyle = {
          ...leftIconStyle,
          color: 'gray',
        }

        return (
          <div>
            <IonItem
              // lines={chooseAvailability ? 'none' : 'inset'}
              lines='none'
              onClick={() => {
                onAddCalendar && onAddCalendar(meeting)
                updateTip({ turnOnAuto: true })
              }}>
              {showTips && !minTip &&
                showScreenTip?.meetingCard === MeetingCardTips.turnOnAuto &&
                <TipTarget
                  style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
              <IonIcon
                style={iconStyle}
                icon={flashOffOutline}
                slot='start' />
              <IonLabel>
                Turn On Automagic Scheduling
              </IonLabel>
            </IonItem>
          </div>
        )
      }
    }
  }

  function renderCalendarButton(): JSX.Element | undefined {
    if (!firstJoinedMeeting && onChooseAvailability) {
      if (bookCalendar?.autoBook || changeAvailability) {
        // const label = bookCalendar?.autoBook ? 'Availability Calendar' : 'Change Your Availability'
        const label = 'Participant Availability'

        return (
          <IonItem
            lines='none'
            detail
            onClick={() => {
              // if (bookCalendar?.autoBook) {
              //   updateTip({ availabilityCalendar: true })
              // } else {
              //   updateTip({ changeAvailability: true })
              // }

              onChooseAvailability(meeting, '', bookCalendar?.autoBook)
              logTap({ button: 'ScheduleCalendar' })
            }}>
            {showTips && !minTip &&
              (showScreenTip?.meetingCard === MeetingCardTips.changeAvailability ||
                showScreenTip?.meetingCard === MeetingCardTips.availabilityCalendar) &&
              <TipTarget
                style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
            <IonIcon
              icon={calendarOutline}
              color='primary'
              style={leftIconStyle}
              slot='start' />
            <IonLabel>
              {label}
            </IonLabel>
          </IonItem>
        )
      }
    }
  }
  function renderStatus(): JSX.Element | undefined {
    if (user && participants && meetingInvitees && timeSlots) {
      return (
        <MeetingCardStatus
          user={user}
          meeting={meeting}
          participants={participants}
          timeSlots={timeSlots}
          onClick={() => {
            onParticipants && onParticipants(meeting)

            if (showScreenTip?.meetingCard === MeetingCardTips.participants) {
              updateTip({ participants: true })
              setNextTip(MeetingCardTips.participants)
            }
          }} />
      )
    }
  }

  function renderDescription(): JSX.Element | undefined {
    return (
      <MeetingCardDescription
        meeting={meeting}
        onClick={() => {
          onDescription && onDescription(meeting)
          logTap({ button: 'Description' })
        }} />
    )
  }

  function renderParticipants(): JSX.Element | undefined {
    if (user && participants && meetingInvitees) {
      return (
        <MeetingCardParticipants
          meeting={meeting}
          autoBook={bookCalendar?.autoBook}
          tip={showTips && !minTip &&
            (showScreenTip?.meetingCard === MeetingCardTips.participants ||
              showScreenTip?.meetingCard === MeetingCardTips.inviteParticipants)}
          onClick={() => {
            onParticipants && onParticipants(meeting)

            if (showScreenTip?.meetingCard === MeetingCardTips.participants) {
              updateTip({ participants: true })
              setNextTip(MeetingCardTips.participants)
            }

            logTap({ button: 'Participants' })
          }}
          onInvite={(byLink) => {
            onInvite && onInvite(meeting, byLink)

            if (showScreenTip?.meetingCard === MeetingCardTips.inviteParticipants) {
              updateTip({ inviteParticipants: true })
              setNextTip(MeetingCardTips.inviteParticipants)
            }

            logTap({ button: 'AddParticipants' })
          }} />
      )
    }
  }

  function renderChip(): JSX.Element | undefined {
    if (inviteFrom) {
      return (
        <IonChip
          className='nonClickable'
          color='primary'
          style={{ marginLeft: 10, marginTop: 10 }}>
          <IonIcon icon={personCircleOutline} />
          <IonLabel>{inviteFrom} is inviting you</IonLabel>
        </IonChip>
      )
    }
  }

  function renderChooseAvailabilityButton(): JSX.Element | undefined {
    // first wait for the time slots to load
    if (timeSlots &&
      !bookCalendar?.autoBook && !changeAvailability && onChooseAvailability) {
      return (
        <div style={{ paddingBottom: 20 }}>
          <div className='screenFooterButton'>
            <IonButton
              shape='round'
              fill='solid'
              expand='block'
              color='primary'
              style={{ margin: 0 }}
              onClick={() => {
                // updateTip({ chooseAvailability: true })
                onChooseAvailability(meeting)
                logTap({ button: 'FooterChooseAvailability' })
              }}>
              Choose Your Availability
              {showTips && !minTip &&
                showScreenTip?.meetingCard === MeetingCardTips.chooseAvailability &&
                !componentStoreTips?.chooseAvailability &&
                <TipTarget
                  style={{ position: 'absolute', bottom: 16, left: '42%' }} />}
            </IonButton>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ height: 20 }} />
      )
    }
  }

  function renderCreatedBy(): JSX.Element | undefined {
    if (participants?.length) {
      const participant = participants.find(p => p.userId === meeting.createdByUserId)

      if (participant) {
        const { displayName, photo } = participant

        return (
          <MeetingCreator
            name={displayName}
            you={participant.userId === user?.id}
            tip={showTips && !minTip && showScreenTip?.meetingCard === MeetingCardTips.moreActions}
            onMore={() => {
              updateTip({ moreActions: true })
              setNextTip(MeetingCardTips.done)
              // onDoneTips && onDoneTips()
              onMoreActions && onMoreActions(meeting)
              logTap({ button: 'Creator' })
            }}
            photo={photo} />
        )
      }
    }
  }

  function renderMeetMeBadge(): JSX.Element | undefined {
    if (participant?.meetMe) {
      return (
        <div style={{ marginBottom: 10 }}>
          <IonChip
            color='success'
            style={{ marginLeft: 10 }}>
            From your personal MeetingTime link
          </IonChip>
        </div>
      )
    }
  }

  function renderTimes(): JSX.Element | undefined {
    const timeRangesLabel = timeRanges?.length ? `${timeRangesToString(timeRanges)}` : ''

    if (!confirmed) {
      return (
        <>
          <IonChip
            color='warning'
            style={{ marginLeft: -8, marginTop: 10 }}>
            <IonLabel>
              Let&apos;s find a time to meet for {duration} between:
            </IonLabel>
          </IonChip>
          <IonCardSubtitle
            mode='md'>
            {dayRangesToString(dayRanges)}
          </IonCardSubtitle>
          {timeRangesLabel &&
            <IonCardSubtitle mode='md'>
              {timeRangesLabel}
            </IonCardSubtitle>}
        </>
      )
    }
  }

  function renderHeader(): JSX.Element {
    return (
      <IonItem
        lines='none'
        class='ion-no-padding'
        style={{ overflow: 'visible' }}>
        <IonCardHeader
          mode='ios'
          style={{ paddingLeft: 16, paddingTop: 10, paddingBottom: meeting.description ? 10 : 16 }}
          onClick={() => {
            updateTip({ meetingTitle: true })
            onEdit && onEdit(meeting)
            logTap({ button: 'Title' })
          }}>
          {/* {meeting.title === 'New Meeting' && onEdit &&
            <IonChip
              color='dark'
              style={{ marginLeft: -5, marginBottom: 10 }}>
              <IonIcon icon={bulb} />
              <IonLabel>Tap title to edit</IonLabel>
            </IonChip>} */}
          {/* {firstJoinedMeeting &&
            <IonCardSubtitle>You are participating in</IonCardSubtitle>} */}
          <IonCardTitle>
            {title}
            {showTips && !minTip && showScreenTip?.meetingCard === MeetingCardTips.meetingTitle &&
              <TipTarget style={{ bottom: 10 }} />}
            {!allLoaded &&
              <IonSpinner
                color='medium'
                name='dots' />}
          </IonCardTitle>
          {renderTimes()}
          {/* {confirmed && <IonCardSubtitle style={{ marginTop: 5 }}>For {duration} </IonCardSubtitle>} */}
        </IonCardHeader>

      </IonItem>
    )
  }

  function addToCalendar(): void {
    if (meeting && participants && participant) {
      addToDeviceCalendar(meeting, participants)
      updateParticipant({
        id: participant.id,
        deviceCalendar: {
          action: DeviceCalendarAction.updated,
        },
      })
    }
  }

  function deleteFromCalendar(): void {
    if (meeting && participant) {
      deleteFromDeviceCalendar(meeting.id)
      updateParticipant({
        id: participant.id,
        deviceCalendar: {
          action: DeviceCalendarAction.deleted,
        },
      })
    }
  }

  function renderDeviceCalendarOptions(): JSX.Element | undefined {
    if (participant && !bookCalendar?.autoBook && !isPlatform('android')) {
      const { deviceCalendar } = participant

      if (confirmed) {
        if (!deviceCalendar ||
          deviceCalendar.action === DeviceCalendarAction.deleted) {
          return (
            <ListItem
              lines='none'
              label='Add to Calendar'
              icon={{ name: downloadOutline, slot: 'end', style: { fontSize: 26 } }}
              onClick={addToCalendar} />
          )
        } else if (deviceCalendar.action === DeviceCalendarAction.updated &&
          moment(deviceCalendar.updateTime).isBefore(meeting.updateTime)) {
          return (
            <ListItem
              lines='none'
              label='Update Calendar'
              icon={{ name: downloadOutline, slot: 'end', style: { fontSize: 26 } }}
              onClick={addToCalendar} />
          )
        }
      } else if (deviceCalendar?.action === DeviceCalendarAction.updated &&
        moment(deviceCalendar.updateTime).isBefore(meeting.updateTime)) {
        return (
          <ListItem
            lines='none'
            label='Update Calendar'
            icon={{ name: downloadOutline, slot: 'end', style: { fontSize: 26 } }}
            onClick={deleteFromCalendar} />
        )
      }
    }
  }

  function renderFireworks(): JSX.Element | undefined {
    if (showFireworks) {
      const { meetingTimes } = meeting

      if (meetingTimes?.length) {
        const meetingTime = meetingTimes[0]

        if (meetingTime.status === 'confirmed' &&
          moment(meetingTime.endTime).valueOf() < moment().valueOf()) {
          // this is a past confirmed meeting so don't show fireworks
          return
        }
      }

      if (dayRanges?.length) {
        const sorted = dayRanges.slice().sort((a, b) => {
          const timeA = moment(a.startTime).valueOf()
          const timeB = moment(b.startTime).valueOf()

          return timeA - timeB
        })

        const lastDay = sorted[sorted.length - 1]

        if (moment(lastDay.endTime).valueOf() < moment().valueOf()) {
          // this is a past meeting so don't show fireworks
          return
        }
      }

      return (
        <div
          style={{
            position: 'absolute',
            top: -40,
            background: 'transparent',
            zIndex: 10,
          }}>
          <Fireworks
            loop={2}
            onComplete={() => setShowFireworks && setShowFireworks(false)} />
        </div>
      )
    }
  }

  return (
    <IonCard
      style={{ paddingTop: 10 }}>
      {/* {renderPhoto()} */}
      {renderChip()}
      {allLoaded && renderCreatedBy()}
      {renderMeetMeBadge()}
      {renderHeader()}
      {renderDescription()}
      {showBestTime && renderConfirmedTime()}
      {allLoaded && renderCalendarButton()}
      {allLoaded && renderStatus()}
      {allLoaded && renderParticipants()}
      {renderMeetingConference()}
      {showBestTime && renderTrendingTimeRanges()}
      {allLoaded && renderAutoScheduling()}
      {allLoaded && renderDeviceCalendarOptions()}
      {allLoaded && renderChooseAvailabilityButton()}
      {renderFireworks()}
    </IonCard>
  )
}

export default MeetingCard
