import React, { useEffect, useState, useContext } from 'react'
import { useQuery } from '@apollo/client'

import { GET_CALENDARS, GET_CONTACTS, GET_PREFERENCES, GET_USER } from 'services/api'
import { CalendarAccount, UserContact } from 'types'
import { account, updateStoreAccount } from 'services/accounts'

export interface ProfilePhotoContextValue {
  initials: string
  photo: string
}

const initialValue: ProfilePhotoContextValue = {
  initials: '',
  photo: '',
}

// create and initialize context
export const ProfilePhotoContext = React.createContext<ProfilePhotoContextValue>(initialValue)

export function useProfilePhoto(): ProfilePhotoContextValue {
  return useContext(ProfilePhotoContext)
}

export type ProfilePhotoMockContextValue = Partial<ProfilePhotoContextValue>

type MockProps = {
  value?: Partial<ProfilePhotoContextValue>
}

export const ProfilePhotoMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <ProfilePhotoContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </ProfilePhotoContext.Provider>
  )
}

function getInitials(name: string): string {
  const initials = name.split(' ').map((t: string) => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

  // if initials is > 2 characters then just pick first and last
  return initials.length > 2
    ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials
}

const ProfilePhotoProvider: React.FC = ({ children }) => {
  const { data: userData } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: calendarsData } = useQuery(GET_CALENDARS, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: preferencesData } = useQuery(GET_PREFERENCES, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: contactsData } = useQuery(GET_CONTACTS, {
    fetchPolicy: 'cache-and-network',
  })

  const [initials, setInitials] = useState('')
  const [photo, setPhoto] = useState('')

  useEffect(() => {
    if (userData?.user.displayName) {
      const { displayName, handle } = userData.user

      setInitials(getInitials(displayName))

      if (account && (account?.displayName !== displayName || account.handle !== handle)) {
        updateStoreAccount({
          ...account,
          displayName,
          handle,
        })
      }
    }
  }, [userData])

  function updatePhoto(photo: string): void {
    setPhoto(photo)

    if (account && account?.photo !== photo) {
      updateStoreAccount({
        ...account,
        photo,
      })
    }
  }

  useEffect(() => {
    if (preferencesData?.preferences?.bookCalendar && calendarsData?.calendars) {
      const { account } = preferencesData.preferences.bookCalendar
      const calendars = calendarsData.calendars as CalendarAccount[]

      if (account) {
        const calendar = calendars.find(c => c.id === account)

        if (calendar) {
          updatePhoto(calendar.photo)
        }
      }
    } else if (contactsData?.contacts) {
      const contacts = contactsData?.contacts as [UserContact]
      const contact = contacts.find(c => c.photo)

      if (contact?.photo) {
        updatePhoto(contact.photo)
      }
    }
  }, [preferencesData, calendarsData, contactsData])

  return (
    <ProfilePhotoContext.Provider value={{ initials, photo }}>
      {children}
    </ProfilePhotoContext.Provider>
  )
}

export default ProfilePhotoProvider
