import React, { useContext } from 'react'
import { ComponentTips } from 'types/componentTips'

type ScreenTipsContextValue = {
  showScreenTip: ComponentTips,
  minTip: boolean;
  disableMinTip?: boolean;
  tipCounter: number;
  setShowScreenTip: React.Dispatch<React.SetStateAction<ComponentTips>>
  setTipCounter: React.Dispatch<React.SetStateAction<number>>
  setMinTip: React.Dispatch<React.SetStateAction<boolean>>
}

const ScreenTipsContext = React.createContext<Partial<ScreenTipsContextValue>>({})

export function useScreenTips (): Partial<ScreenTipsContextValue> {
  return useContext(ScreenTipsContext)
}

export default ScreenTipsContext
