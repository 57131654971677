import React, { CSSProperties, useEffect, useState } from 'react'
import { TimeSlot as TimeSlotType, TimeRange as TimeRangeType, CalendarEvent, Participant } from 'types'
import moment from 'moment'

import DayColumn from '../DayColumn/DayColumn'

import { IonPopover, IonList, IonItem, IonLabel, IonText, IonIcon } from '@ionic/react'
import { Avatar } from '@material-ui/core'

import { ellipse, checkmark } from 'ionicons/icons'
import { useUser } from 'context/UserContext/UserContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useCalendarView } from '../ScheduleCalendar/CalendarViewContext'
import { useScreenTips } from 'context/ScreenTipsContext/ScreenTipsContext'
import { CombinedDayScheduleTips } from 'types/componentTips'
import { getTimeRanges } from '../ScheduleCalendar/utils'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

// time grid height in minutes
const scale = 60
const calendarBoxId = 'calendarBox'

const avatarStyle = {
  width: 30,
  height: 30,
  backgroundColor: '#92949C',
  marginLeft: -5,
  marginRight: 10,
}

type PopoverInfo = {
  time: string;
  participants?: Participant[];
  startTime?: string;
  endTime?: string;
  calendarEvents?: CalendarEvent[];
}

export interface CombinedDayScheduleProps {
  id?: string;
  selectedTime: string;
  reload: number;
  calendarEvents?: CalendarEvent[];
  tip?: boolean;
  onSelectTime?: (time: string) => void;
  onScroll: (top: number, left: number, id: string) => void;
}

const disableScroll = false

const CombinedDaySchedule: React.FC<CombinedDayScheduleProps> =
  ({ id, selectedTime, tip, calendarEvents, onSelectTime, onScroll }) => {
    const [showInfoPopover, setShowInfoPopover] = useState(false)
    const [popoverInfo, setPopoverInfo] = useState<PopoverInfo>()
    const { user } = useUser()
    const { meeting } = useMeetings()
    const { timeSlots } = useTimeSlots()
    const { participants } = useParticipants()
    const { showScreenTip, showNextTip = 0, setShowScreenTip, onDoneTips } = useScreenTips()
    const { logEvent } = useAnalytics()

    const { calendarTopPadding = 20 } = useCalendarView()

    // selected time is in ISO string
    const startOfDay = moment().startOf('day').toISOString()
    const selectedDay = moment(selectedTime).startOf('day').diff(moment(startOfDay), 'days')

    function doScroll(): void {
      // console.log('CombinedDaySchedule: doScroll')

      const el = document.getElementById(id || 'combinedDaySchedule')

      if (el) {
        onScroll(el.scrollTop, el.scrollLeft, id || 'combinedDaySchedule')
      }
    }

    function logTap(eventData: EventData): void {
      logEvent({
        eventName: EventName.buttonTap,
        eventData: { ...eventData },
      })
    }

    function setNextTip(tip: CombinedDayScheduleTips): void {
      // console.log('Combined day schedule setNextTip: ', tip)

      if (setShowScreenTip && meeting && user) {
        const daySlots = getDaySlots()
        let timeSlotRanges
        let combinedRange

        if (daySlots?.length) {
          timeSlotRanges = getTimeRanges({
            userId: user.id,
            timeSlots: daySlots.filter(s => !s.disabled),
            allowedTimeRanges: meeting.timeRanges,
          })
          combinedRange = timeSlotRanges.find(r => r.participantUserIds && r.participantUserIds?.length > 1)
        }

        switch (tip) {
          // eslint-disable-next-line no-fallthrough
          case CombinedDayScheduleTips.sequence:
          case CombinedDayScheduleTips.combinedSlots:
            if (combinedRange) {
              setShowScreenTip({ combinedDaySchedule: CombinedDayScheduleTips.combinedSlots })
              break
            }
          // eslint-disable-next-line no-fallthrough
          default:
            // pass it back to meetings screen
            onDoneTips && onDoneTips()
            break
        }
      }
    }

    useEffect(() => {
      console.log('showNextTip changed: ', showNextTip)

      if (showNextTip && timeSlots && showScreenTip?.combinedDaySchedule) {
        setNextTip(showScreenTip.combinedDaySchedule + 1)
      }
    }, [showNextTip, timeSlots])

    function onInfo(time: string, timeRange?: TimeRangeType): void {
      console.log('onInfo: time ', time)
      console.log('onInfo: timeRange ', timeRange)

      let info: PopoverInfo = { time }
      let rangeParticipants
      let startTime; let endTime

      if (participants && participants?.length) {
        if (meeting?.meetingTimes?.length) {
          const meetingTime = meeting.meetingTimes.find(meetingTime => {
            const start = moment(meetingTime.startTime).valueOf()
            const end = moment(meetingTime.endTime).valueOf()
            const selected = moment(time).valueOf()

            // Note: a calendar event can span across midnight
            // so include timeSlot if either start or end are within the day range
            if (selected >= start && selected <= end) {
              return true
            }
          })

          if (meetingTime && meetingTime.participantUserIds) {
            rangeParticipants = meetingTime.participantUserIds.map((u: string) => {
              return participants.find((p: Participant) => p.userId === u)
            })
            startTime = meetingTime.startTime
            endTime = meetingTime.endTime
          }
        } else if (timeRange && timeRange.participantUserIds) {
          rangeParticipants = timeRange.participantUserIds.map((u: string) => {
            return participants.find((p: Participant) => p.userId === u)
          })
          startTime = timeRange.startTime
          endTime = timeRange.endTime
        }

        if (rangeParticipants && startTime && endTime) {
          info = {
            ...info,
            participants: rangeParticipants as Participant[],
            startTime,
            endTime,
          }
        }
      }

      if (calendarEvents?.length) {
        info.calendarEvents = calendarEvents.filter((event: CalendarEvent) => {
          const start = moment(event.startTime).valueOf()
          const end = moment(event.endTime).valueOf()
          const selected = moment(time).valueOf()

          // Note: a calendar event can span across midnight
          // so include timeSlot if either start or end are within the day range
          if (selected >= start && selected <= end) {
            return true
          }
        })
      }

      if (info.participants?.length || info.calendarEvents?.length) {
        setPopoverInfo(info)
        setShowInfoPopover(true)
      }
    }

    // console.log('CALENDAR TOTAL PARTICIPANTS: ', totalParticipants)

    function getSlots(): TimeSlotType[] {
      // console.log('TIME SLOTS: ', timeSlots)

      if (timeSlots?.length) {
        return timeSlots
      }

      return []
    }

    // function getMaxSlot (): number {
    //   let max = 0

    //   if (user?.id && meetingId) {
    //     const slots = getSlots()
    //     const ranges = getTimeRanges({
    //       userId: user.id,
    //       timeSlots: slots.filter(s => !s.disabled),
    //       allowedTimeRanges: meeting.timeRanges,
    //     })

    //     ranges.forEach(range => {
    //       const count = range.participants?.length || 0

    //       if (count > max) {
    //         max = count
    //       }
    //     })
    //   }

    //   return max
    // }

    function getDaySlots(): TimeSlotType[] {
      // 12AM start time - 1 minute so that 12AM selection fits in the isAfter range below.
      const dayStart = moment(selectedTime).startOf('day').valueOf()
      const dayEnd = moment(dayStart).add(24, 'hours').valueOf()

      // console.log('dayStart: ', moment(selectedTime).startOf('day').toISOString())
      // console.log('dayEnd: ', moment(dayStart).add(24, 'hours').toISOString())

      // console.log('dayStart Value: ', dayStart)
      // console.log('dayEnd value: ', dayEnd)

      // first find local changes
      let slots = getSlots()

      // issue #411 show timeSlots only if the user has selected some times
      // if (user?.id && slots.find(t => t.user === user?.id)) {
      if (user?.id) {
        slots = slots.filter((t: TimeSlotType) => {
          const start = moment(t.startTime).valueOf()
          const end = moment(t.endTime).valueOf()

          // Note: a timeSlot can span across midnight
          // so include timeSlot if either start or end are within the day range
          if ((start >= dayStart && start < dayEnd) ||
            (end > dayStart && end <= dayEnd)) {
            return true
          }
        })

        return slots
      }

      // if (slots.length) {
      //   console.log('DAY SLOTS: for day:', moment(selectedTime).startOf('day').toISOString(), slots)
      // }

      return []
    }

    // get all user's calendar events for this day
    function getDayCalendarEvents(): CalendarEvent[] {
      const dayStart = moment(selectedTime).startOf('day').valueOf()
      const dayEnd = moment(dayStart).add(24, 'hours').valueOf()
      let events: CalendarEvent[] = []

      if (meeting && meeting.id) {
        if (calendarEvents?.length) {
          events = calendarEvents.filter((event: CalendarEvent) => {
            const start = moment(event.startTime).valueOf()
            const end = moment(event.endTime).valueOf()

            // Note: a calendar event can span across midnight
            // so include timeSlot if either start or end are within the day range
            // console.log('calendar event: ', event)

            if ((start >= dayStart && start < dayEnd) ||
              (end > dayStart && end <= dayEnd)) {
              if ((start === dayStart && end >= dayEnd) ||
                (start <= dayStart && end === dayEnd)) {
                // don't include all day
                return false
              }

              return true
            }
          })
        }
      }

      return events
    }

    // const bestRange = getMaxSlot()

    function renderDayColumn(): JSX.Element | undefined {
      if (meeting?.id && user?.id && participants) {
        const daySlots = getDaySlots()
        const dayEvents = getDayCalendarEvents()

        // console.log('check editSlot: ', editSlot)
        // console.log('renderDayColumn: daySlots:', daySlots)
        // console.log('renderDayColumn: dayEvents:', dayEvents)

        // if (editSlot && daySlots.find(slot => {
        //   if (editSlot.id && slot.id === editSlot.id) {
        //     return true
        //   } else if (editSlot.localId && slot.localId === editSlot.localId) {
        //     return true
        //   }
        // })) {
        //   // console.log('enable showEditSlot: ', editSlot)
        //   showEditSlot = editSlot
        // }

        let showTip

        // if (tip) {
        //   const currentTime = moment().format('H')

        //   if (!getSlots().length) {
        //     if (day === 1) showTip = Number(currentTime)
        //     else if (day === 2) showTip = Number(currentTime) + 2
        //   }
        // }

        return (
          <DayColumn
            parentId={calendarBoxId}
            userId={user?.id || ''}
            startDay={selectedDay}
            day={0}
            scale={scale}
            meeting={meeting}
            participants={participants}
            timeSlots={daySlots}
            calendarEvents={dayEvents}
            tip={showTip}
            tipColumn={tip}
            showMeetingTimes
            onSelectTime={(time) => {
              logTap({ component: 'DayColumn', button: 'SelectTime' })
              onSelectTime && onSelectTime(time)
            }}
            onInfo={(time, timeRange) => {
              logTap({ component: 'DayColumn', button: 'Info' })
              onInfo(time, timeRange)
            }} />
        )
      }
    }

    function renderPopoverTimeSlots(): JSX.Element | null {
      if (popoverInfo) {
        const { participants, startTime, endTime, calendarEvents } = popoverInfo

        if (participants?.length) {
          const start = moment(startTime).format('h:mm A')
          const end = moment(endTime).format('h:mm A')

          return (
            <div>
              {calendarEvents && calendarEvents.length > 0 &&
                <IonItem
                  lines='full'>
                  <IonLabel>
                    <p>{start} to {end}</p>
                  </IonLabel>
                </IonItem>}
              {participants.map((participant, i) => {
                const initials = participant.displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

                // if initials is > 2 characters then just pick first and last
                const displayInitials = initials.length > 2
                  ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials
                const name = user?.id === participant.userId ? 'You' : participant.displayName

                return (
                  <IonItem
                    key={i}
                    lines={(i < participants.length - 1) ? 'inset' : 'none'}>
                    <Avatar
                      slot='start'
                      src={participant.photo}
                      style={avatarStyle}>
                      <IonText style={{ fontSize: 12, fontWeight: 'bold' }}>
                        {displayInitials}
                      </IonText>
                    </Avatar>
                    <IonLabel>
                      {name}
                    </IonLabel>
                    <IonIcon
                      slot='end'
                      color='secondary'
                      style={{ fontSize: 22 }}
                      icon={checkmark} />
                  </IonItem>
                )
              })}
            </div>
          )
        }
      }

      return null
    }

    function renderPopoverEvents(): JSX.Element | null {
      if (popoverInfo) {
        const { calendarEvents } = popoverInfo

        if (calendarEvents?.length) {
          return (
            <div>
              {calendarEvents.map((event, i) => {
                const start = moment(event.startTime).format('h:mm A')
                const end = moment(event.endTime).format('h:mm A')

                return (
                  <IonItem
                    key={i}
                    lines={(i < calendarEvents.length - 1) ? 'inset' : 'none'}>
                    <IonIcon
                      size='small'
                      style={{ color: event.backgroundColor, marginRight: 10 }}
                      icon={ellipse} />
                    <IonLabel className='ion-text-wrap'>
                      <h2>{event.summary}</h2>
                      <p>{start} to {end}</p>
                    </IonLabel>
                  </IonItem>
                )
              })}
            </div>
          )
        }
      }

      return null
    }

    function renderInfoPopover(): JSX.Element | null {
      if (popoverInfo) {
        // Note: on android the list has a small padding at the bottom
        // and since we are using a slight shaded background for the select button
        // get rid of the bottom padding
        const { time, startTime, endTime, calendarEvents } = popoverInfo
        const day = moment(time).format('ddd D')
        const start = moment(startTime).format('h:mm A')
        const end = moment(endTime).format('h:mm A')

        return (
          <IonPopover
            isOpen={showInfoPopover}
            onDidDismiss={() => setShowInfoPopover(false)}>
            <IonList style={{ paddingBottom: 10 }}>
              <IonItem
                lines='full'>
                <IonLabel>
                  <h2 style={{ fontWeight: 'bold' }}>{day}</h2>
                  {!calendarEvents?.length && <p>{start} to {end}</p>}
                </IonLabel>
              </IonItem>
              {renderPopoverEvents()}
              {renderPopoverTimeSlots()}
            </IonList>
          </IonPopover>
        )
      }

      return null
    }

    const container: CSSProperties = {
      flex: 1,
      display: 'flex',
      overflowY: 'scroll',
      flexWrap: 'nowrap',
      paddingTop: calendarTopPadding,
    }

    if (disableScroll) {
      container.overflowX = 'hidden'
      container.overflowY = 'hidden'
    }

    return (
      <div
        id={id || 'combinedDaySchedule'}
        style={container}
        onScroll={doScroll}>
        {renderDayColumn()}
        {renderInfoPopover()}
      </div>
    )
  }

export default CombinedDaySchedule
