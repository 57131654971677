import React, { CSSProperties, useEffect, useState } from 'react'
import { IonContent, IonPage, IonText, IonFooter, IonIcon } from '@ionic/react'
import { Spring } from 'react-spring/renderprops'

import 'theme/styles.css'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { logoColor } from 'theme/styles'
import { time } from 'ionicons/icons'
import { useTips } from 'context/TipsContext/TipsContext'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.

const title = 'Automagic Calendar'

const slide: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
}

const imageBox: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
}
const screenShot: CSSProperties = {
  maxWidth: '50%',
}
const titleBox: CSSProperties = {
  marginTop: '5%',
  paddingLeft: '10%',
  paddingRight: '10%',
  marginBottom: '5%',
  textAlign: 'center',
}
const textBox: CSSProperties = {
  marginTop: '5%',
  paddingLeft: '10%',
  paddingRight: '10%',
  marginBottom: '5%',
}

const cardTitle: CSSProperties = {
  fontSize: 20,
  fontWeight: 'bold',
}

const cardMessage: CSSProperties = {
  fontSize: 15,
}

interface ComponentProps {
  goBack: () => void;
  onNext?: () => void;
}

const TipsAvailabilityCalendarAuto: React.FC<ComponentProps> = ({ goBack, onNext }) => {
  const [prefersDark, setPrefersDark] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)
  // const [lastSlide] = useState(false)
  const { updateStoreTips } = useTips()
  const { logEvent } = useAnalytics()

  function handleDarkMode (event: MediaQueryListEvent): void {
    setPrefersDark(event.matches)
  }

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'TipsAvailabilityCalendarAuto' },
    })

    window.matchMedia('(prefers-color-scheme: dark)').addListener(handleDarkMode)

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeListener(handleDarkMode)
    }
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'MeetWithUser',
      },
    })
  }

  function renderFooter (): JSX.Element | undefined {
    if (onNext) {
      // const txt = lastSlide ? 'Got It' : 'Skip'

      return (
        <Spring
          from={{ opacity: 0, bottom: -600 }}
          to={{ opacity: 1, bottom: 0 }}>
          {props =>
            <div style={props}>
              <IonFooter className='screenFooterButton'>
                <FooterButton
                  onClick={() => {
                    logTap({ component: 'FooterButton', button: 'GotIt' })

                    if (updateStoreTips) {
                      updateStoreTips({ tipsAvailabilityCalendarAuto: { completed: true } })
                    }

                    onNext && onNext()
                  }}>
                  Got It
                </FooterButton>
              </IonFooter>
            </div>}
        </Spring>
      )
    }
  }

  // function renderHeader (): JSX.Element {
  //   if (lastSlide || !onNext) {
  //     return (
  //       <ScreenHeader
  //         title={title}
  //         onBack={goBack} />
  //     )
  //   }

  //   return (
  //     <ScreenHeader
  //       title={title}
  //       nextButton='Skip'
  //       onBack={goBack}
  //       onNext={onNext} />
  //   )
  // }

  // for now disable the skip button
  function renderHeader (): JSX.Element {
    return (
      <ScreenHeader
        title={title}
        onBack={goBack} />
    )
  }

  return (
    <IonPage>
      {renderHeader()}
      <IonContent>
        <TipsCalendarEvents darkMode={prefersDark} />
        <BusyTimes darkMode={prefersDark} />
        <FreeTimes darkMode={prefersDark} />
        <TrendingTimes darkMode={prefersDark} />
        {/* <ConfirmedTime darkMode={prefersDark} /> */}
      </IonContent>
      {renderFooter()}
    </IonPage>
  )
}

interface ContainerProps {
  darkMode?: boolean;
}

export const TipsCalendarEvents: React.FC<ContainerProps> = ({ darkMode }) => {
  const text = 'Blank spaces are your free times, used to automagically schedule your meetings.'

  return (
    <div style={slide}>
      <div style={titleBox}>
        <IonText style={cardTitle}>
          Your Calendar Events
        </IonText>
      </div>
      <div style={imageBox}>
        <img
          src={`assets/images/calendarEvents.${darkMode ? 'dark' : 'light'}.png`}
          style={screenShot}
          alt='' />
      </div>
      <div style={textBox}>
        <IonText style={cardMessage}>
          Calendar events shown in the meeting availability calendar are <b> only ever visible to you. </b>
          {text}
        </IonText>
      </div>
    </div>
  )
}

export const BusyTimes: React.FC<ContainerProps> = ({ darkMode }) => {
  const text = 'If there are time slots that you don\'t want MeetingTime to consider you available for, you can mark them as busy with a tap. You can also drag the range to adjust it.'

  return (
    <div style={slide}>
      <div style={titleBox}>
        <IonText style={cardTitle}>
          Mark Busy Times
        </IonText>
      </div>
      <div style={imageBox}>
        <img
          src={`assets/images/markBusy.${darkMode ? 'dark' : 'light'}.png`}
          style={screenShot}
          alt='' />
      </div>
      <div style={textBox}>
        <IonText style={cardMessage}>
          {text}
        </IonText>
      </div>
    </div>
  )
}

export const FreeTimes: React.FC<ContainerProps> = ({ darkMode }) => {
  const text = 'If there are events on your calendar that you would like MeetingTime to consider you available for, you can override the calendar event and mark youself free with a tap.'

  return (
    <div style={slide}>
      <div style={titleBox}>
        <IonText style={cardTitle}>
          Mark Free Times
        </IonText>
      </div>
      <div style={imageBox}>
        <img
          src={`assets/images/markFree.${darkMode ? 'dark' : 'light'}.png`}
          style={screenShot}
          alt='' />
      </div>
      <div style={{ ...textBox, marginTop: 20 }}>
        <IonText style={cardMessage}>
          {text}
        </IonText>
      </div>
    </div>
  )
}

export const TrendingTimes: React.FC<ContainerProps> = ({ darkMode }) => {
  return (
    <div style={slide}>
      <div style={titleBox}>
        <IonText style={cardTitle}>
          Trending Times
        </IonText>
      </div>
      <div style={imageBox}>
        <img
          src={`assets/images/trendingTimesAuto.${darkMode ? 'dark' : 'light'}.png`}
          style={screenShot}
          alt='' />
      </div>
      <div style={textBox}>
        <IonText style={cardMessage}>
          When scheduling, the <b>earliest 3 common times that work for most participants will be marked as <span style={{ color: logoColor }}>Tentative</span></b> and highlighted in orange.
          <br /><br />
          <IonIcon
            icon={time}
            style={{ marginRight: 5, marginBottom: -4, fontSize: 18 }}
            color='medium' />
          In this example, you are waiting for Cal to finalize their availability.
        </IonText>
      </div>
    </div>
  )
}

export const ConfirmedTime: React.FC<ContainerProps> = ({ darkMode }) => {
  const text = 'Woohoo! Cal has now picked her times and MeetingTime found a time that works for everyone. MeetingTime will place an event on your calendar. If plans change MeetingTime will automagically find a new time that works for everyone.'

  return (
    <div style={slide}>
      <div style={titleBox}>
        <IonText style={cardTitle}>
          Confirmed Time
        </IonText>
      </div>
      <div style={imageBox}>
        <img
          src={`assets/images/confirmedTimeAuto.${darkMode ? 'dark' : 'light'}.png`}
          style={screenShot}
          alt='' />
      </div>
      <div style={textBox}>
        <IonText style={cardMessage}>
          {text}
        </IonText>
      </div>
    </div>
  )
}

export default TipsAvailabilityCalendarAuto
