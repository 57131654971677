import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import 'screens/Settings.css'
import TipsConnectCalendars from './TipsConnectCalendars'

const TipsConnectCalendarsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  function goBack (): void {
    history.goBack()
  }

  return (
    <TipsConnectCalendars
      goBack={goBack} />
  )
}

export default TipsConnectCalendarsScreen
