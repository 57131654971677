import React, { useState, useRef, useEffect } from 'react'
import {
  IonContent, IonPage,
} from '@ionic/react'
import moment from 'moment'

import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import DaySchedule from 'components/organisms/DaySchedule/DaySchedule'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { MeetingTime } from 'types'
import MeetingTimeModal from 'components/organisms/MeetingTimeModal'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

interface ComponentProps {
  selectedTime?: string;
  goBack: () => void;
}
// let appTimer: number

const DayAvailability: React.FC<ComponentProps> = (
  { selectedTime = '', goBack }) => {
  const [showMeetingTime, setShowMeetingTime] = useState<MeetingTime>()
  const { meeting } = useMeetings()
  const { logEvent } = useAnalytics()

  const pageRef = useRef()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'DayAvailability', meetingId: meeting?.id },
    })
  }, [])

  function renderHeader(): JSX.Element {
    return (
      <ScreenHeader
        title={meeting?.title || 'Day Availability'}
        onBack={goBack} />
    )
  }

  function onSelectTime(time: string): void {
    if (meeting?.meetingTimes?.length) {
      const value = moment(time).valueOf()
      const meetingTime = meeting.meetingTimes.find(mt => {
        if (value >= moment(mt.startTime).valueOf() && value <= moment(mt.endTime).valueOf()) {
          return true
        }
      })

      if (meetingTime) {
        setShowMeetingTime(meetingTime)
      }
    }
  }

  function renderModals(): JSX.Element | undefined {
    if (meeting && showMeetingTime) {
      return (
        <MeetingTimeModal
          meetingId={meeting.id}
          meetingTime={showMeetingTime}
          presentingElement={pageRef.current}
          onClose={() => {
            logEvent({
              eventName: EventName.buttonTap,
              eventData: { button: 'Close', component: 'MeetingTimeModal' },
            })
            setShowMeetingTime(undefined)
          }} />
      )
    }
  }

  return (
    <IonPage ref={pageRef}>
      {renderHeader()}
      <IonContent
        scrollY={false}
        scrollX={false}>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <DaySchedule
            time={selectedTime}
            onSelectTime={(time) => {
              logEvent({
                eventName: EventName.buttonTap,
                eventData: { component: 'DaySchedule', button: 'SelectTime' },
              })
              onSelectTime(time)
            }} />
        </div>
        {renderModals()}
      </IonContent>
    </IonPage>
  )
}

export default DayAvailability
