import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import 'screens/Settings.css'

import About from './About'

const AboutScreen: React.FC<RouteComponentProps> = ({ history }) => {
  function goBack (): void {
    history.goBack()
  }

  return (
    <About
      goBack={goBack} />
  )
}

export default AboutScreen
