import React from 'react'
import { IonButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import ScreenBackButton from 'components/atoms/ScreenBackButton/ScreenBackButton'

export interface ScreenHeaderProps {
  title: string;
  onBack?: () => void;
  goBackTo?: string;
  onNext?: () => void;
  nextButton?: string;
  goNextTo?: string;
}

const ScreenHeader: React.FC<ScreenHeaderProps> = ({ title, onBack, goBackTo, onNext, nextButton, goNextTo }) => {
  return (
    <IonHeader>
      <IonToolbar>
        {(onBack || goBackTo) &&
          <IonButtons slot='start'>
            <ScreenBackButton
              onClick={onBack}
              goBackTo={goBackTo} />
          </IonButtons>}
        <IonTitle>{title}</IonTitle>
        {(onNext || goNextTo) &&
          <IonButtons slot='end'>
            <IonButton
              fill='clear'
              routerLink={goNextTo}
              routerDirection='forward'
              onClick={onNext}>
              {nextButton}
            </IonButton>
          </IonButtons>}
      </IonToolbar>
    </IonHeader>
  )
}

export default ScreenHeader
