import React, { useEffect, useRef, useState } from 'react'
import useOnScreen from 'services/hooks/onScreen'
import MeetingCard, { MeetingCardProps } from './MeetingCard'

import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import TimeSlotsProvider from 'context/TimeSlotsContext/TimeSlotsContext'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import MeetingNotifyProvider from 'context/MeetingNotifyContext/MeetingNotifyContext'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'

interface ComponentProps extends MeetingCardProps {
  renderWithProviders?: (card: JSX.Element) => JSX.Element
}

const MeetingCardWrapper: React.FC<ComponentProps> = (props) => {
  const { meeting, renderWithProviders } = props

  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)
  const [enableDetails, setEnableDetails] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  // enable detail after a few seconds as the first render always seems to return isVisible
  useEffect(() => {
    setTimeout(() => {
      setEnableDetails(true)
    }, 500)
  }, [])

  useEffect(() => {
    if (enableDetails) {
      if (isVisible) {
        // once the card is visible and we have fetched details latch it and don't turn it off
        // else we will see card constantly changing in size in the scroll view as details get turned ON
        // and then off again
        setShowDetails(true)
        console.log(`Meeting ${meeting.title} is visible show details...`)
      }
    }
  }, [isVisible, enableDetails])

  function renderWithData(): JSX.Element {
    if (showDetails) {
      if (renderWithProviders) {
        return renderWithProviders(
          <MeetingCard {...props} />,
        )
      }

      return (
        <InviteesProvider meetingId={meeting.id}>
          <ParticipantsProvider meetingId={meeting.id}>
            <TimeSlotsProvider meetingId={meeting.id}>
              <MeetingsProvider meetingId={meeting.id}>
                <MeetingNotifyProvider>
                  <MeetingCard {...props} />
                </MeetingNotifyProvider>
              </MeetingsProvider>
            </TimeSlotsProvider>
          </ParticipantsProvider>
        </InviteesProvider>
      )
    }

    return (
      <MeetingCard {...props} />
    )
  }

  return (
    <div ref={ref}>
      {renderWithData()}
    </div>
  )
}

export default MeetingCardWrapper
