import React, { CSSProperties } from 'react'
import { useSpring, animated } from 'react-spring'
import { useTips } from 'context/TipsContext/TipsContext'
import { IonChip, IonIcon, IonLabel } from '@ionic/react'
import { bulb } from 'ionicons/icons'
import { logoColor } from 'theme/styles'

import './TipMinButton.css'

const tipColor = '#3880ff'

const chipStyle: CSSProperties = {
  border: `1px solid ${tipColor}`,
  zIndex: 1,
}

export interface TipMinButtonProps {
  show?: boolean,
  bottom?: number,
  style?: CSSProperties;
  onClick?: () => void;
}

const TipMinButton: React.FC<TipMinButtonProps> = ({ show, bottom, onClick }) => {
  const { storeTips } = useTips()

  const animatedProps = useSpring({
    ...(show ? { position: 'absolute', opacity: 1, right: 20, bottom: bottom || 10 } : { position: 'absolute', opacity: 0, right: 20, bottom: -600 }),
  })

  if (storeTips?.enabled) {
    return (
      <animated.div
        style={animatedProps}>
        <IonChip
          color='primary'
          style={chipStyle}
          className='tipMinButton'
          onClick={onClick}>
          <IonIcon
            icon={bulb}
            style={{ color: logoColor, fontSize: 20 }} />
          <IonLabel>
            Tips
          </IonLabel>
        </IonChip>
        {/* <IonFab
          vertical='bottom'
          horizontal='end'
          slot='fixed'>
          <IonFabButton
            color='light'
            style={minTipButton}
            onClick={onClick}>
            <IonIcon
              icon={bulb}
              style={{ color: logoColor }} />
          </IonFabButton>
        </IonFab> */}
      </animated.div>
    )
  }

  return <div />
}

export default TipMinButton
