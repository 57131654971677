
import React, { CSSProperties } from 'react'
import { IonText } from '@ionic/react'
import { useSpring } from 'react-spring'

import TipCard from 'components/organisms/TipCard/TipCard'
import AnimatedBulb from 'components/atoms/AnimatedBulb/AnimatedBulb'

const box: CSSProperties = {
  display: 'flex',
  marginLeft: -5,
}

const boxLeft: CSSProperties = {
  display: 'flex',
}

const boxRight: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: 10,
  fontWeight: 'bold',
}

const boxRightSubtext: CSSProperties = {
  flex: 1,
  display: 'flex',
  fontWeight: 'normal',
}

export interface ScreenTipCardProps {
  animatedProps?: CSSProperties,
  show?: boolean;
  text?: string;
  subtext?: string;
  buttonLabel?: string;
  closeIcon?: string;
  bottom?: number;
  onClose?: () => void;
  onButton?: () => void;
}

export const ScreenTipCard: React.FC<ScreenTipCardProps> = ({
  animatedProps, show, text, subtext, buttonLabel, bottom, closeIcon, onClose = () => console.log('close'), onButton, children,
}) => {
  const animated = useSpring(animatedProps || {
    ...(show ? { opacity: 1, bottom: bottom || 0 } : { opacity: 0, bottom: -600 }),
  })

  return (
    <TipCard
      animatedProps={animated}
      onClose={onClose}
      closeIcon={closeIcon}
      buttonLabel={buttonLabel || 'next tip'}
      className='screenTipCard'
      onButton={onButton}>
      <div style={box}>
        {show &&
          <div style={boxLeft}>
            <AnimatedBulb
              loop />
          </div>}
        <div style={boxRight}>
          {text &&
            <IonText>
              {text}
            </IonText>}
          <div style={boxRightSubtext}>
            {subtext &&
              <IonText>
                {subtext}
              </IonText>}
          </div>
          {children}
        </div>
      </div>
    </TipCard>
  )
}

export default ScreenTipCard
