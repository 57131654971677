import { UPDATE_TIP, Tips, TipActions, ENABLE_ALL_TIPS } from './types'

export function updateTips (tips: Tips): TipActions {
  return {
    type: UPDATE_TIP,
    tips,
  }
}

export function enableAllTips (): TipActions {
  return {
    type: ENABLE_ALL_TIPS,
  }
}
