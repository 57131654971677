import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import 'screens/Settings.css'

import ProfilePhotoProvider from 'context/ProfilePhotoContext/ProfilePhotoContext'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import DevicesContextProvider from 'context/DevicesContext/DevicesContext'
import Settings from './Settings'
import { useDispatch } from 'react-redux'
import { updateScreens } from 'services/store/screens/actions'

const SettingsScreen: React.FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch()

  function onCalendar (): void {
    // clear out chooseAvailability meeting so that in CalendarSettingsDoneAddAnother we go back home
    dispatch(updateScreens({ calendarAccounts: { previous: location.pathname }, chooseAvailability: { } }))
    history.push('/tabs/settings/calendarAccounts')
  }

  function onDevices (): void {
    history.push('/tabs/settings/devices')
  }
  function onAbout (): void {
    history.push('/tabs/settings/about')
  }

  return (
    <ProfilePhotoProvider>
      <DevicesContextProvider>
        <CalendarsProvider>
          <Settings
            onCalendar={onCalendar}
            onDevices={onDevices}
            onAbout={onAbout} />
        </CalendarsProvider>
      </DevicesContextProvider>
    </ProfilePhotoProvider>
  )
}

export default SettingsScreen
