import React, { CSSProperties, useState } from 'react'
import {
  IonBackdrop,
  IonContent, IonFooter, IonModal, IonText,
} from '@ionic/react'
import { flashOutline, flashOffOutline, flash, warning, timeOutline, ban, peopleOutline } from 'ionicons/icons'
import { useSpring } from 'react-spring'

import { CalendarEvent, Invitee, MeetingInvite, Participant, TimeRange } from 'types'
import ActionCard from '../ActionCard/ActionCard'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import ListItem from 'components/atoms/ListItem/ListItem'
import { logoColor } from 'theme/styles'
import AnimatedLightning from 'components/atoms/AnimatedLightning/AnimatedLightning'
import moment from 'moment'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: CalendarEvent[];
}

const iconBox: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const messageBox: CSSProperties = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
}

const titleStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 'bold',
  textAlign: 'center',
}

const messageStyle: CSSProperties = {
  fontSize: 14,
}

export type SelectedOptions = {
  adjustedAutoTimes?: boolean
  defaultAutoTimes?: boolean;
  calendarSettings?: boolean;
  disableAutoBook?: boolean;
  busyTimes?: boolean;
  addParticipants?: boolean;
  meetingSettings?: boolean;
}

interface ContainerProps {
  invite: MeetingInvite;
  invitee: Invitee;
  adjustedAutoTimes?: TimeRange[];
  defaultAutoTimes?: TimeRange[];
  onSelectedOptions: (selectedOptions?: SelectedOptions) => void;
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const InviteeActionsModal: React.FC<ContainerProps> = ({ invite, invitee, adjustedAutoTimes, defaultAutoTimes, onSelectedOptions, onClose }) => {
  const [showCard, setShowCard] = useState(adjustedAutoTimes ? 'selectAutoTimes' : 'selectBusyTimes')
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>()

  const { logEvent } = useAnalytics()

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'InviteeActionsModal',
      },
    })
  }

  function getTimeLabel (ranges: TimeRange[]): string {
    let timeLabel = ''

    ranges.forEach((range, i) => {
      const start = moment(range.startTime).format('h:mm a')
      const end = moment(range.endTime).format('h:mm a')

      timeLabel += `${start} - ${end}`

      if (i < ranges.length - 1) {
        timeLabel += ', '
      }
    })

    return timeLabel
  }

  const showAddParticipant = invitee.meetMe

  function renderSelectAdjustedTimes (): JSX.Element | undefined {
    if (adjustedAutoTimes) {
      const timeLabel = getTimeLabel(adjustedAutoTimes)

      return (
        <ListItem
          label='Schedule between'
          subLabel={timeLabel}
          lines='inset'
          detail
          icon={{ name: flash, slot: 'start', style: { color: logoColor } }}
          onClick={() => {
            logTap({ component: 'ListItem', button: 'ScheduleBetween' })
            setSelectedOptions({ adjustedAutoTimes: true })
            setShowCard('selectBusyTimes')
          }} />
      )
    }
  }

  function renderDefaultTimes (): JSX.Element | undefined {
    if (defaultAutoTimes) {
      const timeLabel = getTimeLabel(defaultAutoTimes)

      return (
        <ListItem
          label='Continue with default'
          subLabel={timeLabel}
          lines='inset'
          detail
          icon={{ name: flashOutline, slot: 'start', style: { color: logoColor } }}
          onClick={() => {
            logTap({ component: 'ListItem', button: 'ContinueDefault' })
            setSelectedOptions({ defaultAutoTimes: true })
            setShowCard('selectBusyTimes')
          }} />
      )
    }
  }

  function renderCustomizeTimes (): JSX.Element | undefined {
    return (
      <ListItem
        label='Customize for this meeting'
        lines='inset'
        detail
        icon={{ name: timeOutline, slot: 'start', color: 'primary' }}
        onClick={() => {
          logTap({ component: 'ListItem', button: 'Customize' })
          setShowCard('')
          onSelectedOptions({ calendarSettings: true })
        }} />
    )
  }

  function renderDisableAuto (): JSX.Element | undefined {
    return (
      <ListItem
        label='Disable for this meeting'
        subLabel='Select your availability manually'
        lines='none'
        detail
        icon={{ name: flashOffOutline, slot: 'start', color: 'medium' }}
        onClick={() => {
          logTap({ component: 'ListItem', button: 'DisableAuto' })
          setShowCard('')
          onSelectedOptions({ disableAutoBook: true })
        }} />
    )
  }

  function renderBusyTimes (): JSX.Element | undefined {
    return (
      <ListItem
        label='Mark Busy Times'
        lines={showAddParticipant ? 'inset' : 'full'}
        detail
        icon={{ name: ban, slot: 'start', color: 'primary' }}
        onClick={() => {
          logTap({ component: 'ListItem', button: 'MarkBusy' })
          onSelectedOptions({ ...selectedOptions, busyTimes: true })
          setShowCard('')
        }} />
    )
  }

  function renderAddParticipants (): JSX.Element | undefined {
    if (showAddParticipant) {
      return (
        <ListItem
          label='Add Participants'
          lines='full'
          detail
          icon={{ name: peopleOutline, slot: 'start', color: 'primary' }}
          onClick={() => {
            logTap({ component: 'ListItem', button: 'AddParticipants' })
            onSelectedOptions({ ...selectedOptions, addParticipants: true })
            setShowCard('')
          }} />
      )
    }
  }

  function renderSchedule (): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'Schedule' })
            onSelectedOptions(selectedOptions)
            setShowCard('')
          }}>
          Schedule
        </FooterButton>
      </IonFooter>
    )
  }

  const autoTimesProps = useSpring({
    ...(showCard === 'selectAutoTimes' ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderAutoTimeOptions (): JSX.Element | undefined {
    const message = 'However, your default schedule times don\'t cover the entire meeting time range. \n Select an option below'

    return (
      <ActionCard
        title={invite.title}
        icon={warning}
        onClose={() => {
          logTap({ component: 'AutoTimeActionCard', button: 'Close' })
          onClose()
        }}
        animatedProps={autoTimesProps}>
        <div
          className='ion-text-start'>
          <div style={iconBox}>
            <AnimatedLightning
              style={{ width: 60, height: 60 }}
              loop={2} />
            <IonText
              style={titleStyle}>
              Automagic Scheduling is On!
            </IonText>
          </div>
          <div
            style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              {message}
            </IonText>
          </div>
        </div>
        {renderSelectAdjustedTimes()}
        {renderDefaultTimes()}
        {renderCustomizeTimes()}
        {renderDisableAuto()}
      </ActionCard>
    )
  }

  const busyTimesProps = useSpring({
    ...(showCard === 'selectBusyTimes' ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderBusyTimesOptions (): JSX.Element | undefined {
    return (
      <ActionCard
        title='Invitee Actions'
        onClose={() => {
          logTap({ component: 'InviteeActionCard', button: 'Close' })
          onClose()
        }}
        animatedProps={busyTimesProps}>
        <div
          className='ion-text-start'>
          <div style={iconBox}>
            <AnimatedLightning
              style={{ width: 60, height: 60 }}
              loop={2} />
            <IonText
              style={titleStyle}>
              Automagic Scheduling is On! <br />
            </IonText>
          </div>
          <div
            style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              You can mark certain times busy before MeetingTime will automagically schedule you
            </IonText>
          </div>
        </div>
        {renderBusyTimes()}
        {renderAddParticipants()}
        {renderSchedule()}
      </ActionCard>
    )
  }

  return (
    <IonModal
      isOpen
      backdropDismiss
      cssClass='meetingTimeModal'
      onDidDismiss={() => {
        logTap({ component: 'MeetingTimeModal', button: 'GestureDismiss' })
        onClose()
      }}>
      <IonContent>
        <IonBackdrop
          tappable
          visible />
        {renderAutoTimeOptions()}
        {renderBusyTimesOptions()}
      </IonContent>
    </IonModal>
  )
}

export default InviteeActionsModal
