import React, { useState, useRef, useEffect } from 'react'
import {
  IonContent, IonPage, IonBackdrop,
} from '@ionic/react'
import moment from 'moment'

import { Meeting, MeetingTime } from 'types'

import MeetingTimeModal from 'components/organisms/MeetingTimeModal'
import MeetingCard from 'components/organisms/MeetingCard'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { compareTimes } from 'services/time'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import MeetingActionsModal from 'components/organisms/MeetingActionsModal'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

const title = 'Past Meetings'

type MeetingTimeInfo = {
  meetingId: string;
  meetingTime: MeetingTime;
}

interface ComponentProps {
  onEdit: (meetingId: string) => void;
  onParticipants: (meetingId: string) => void;
  onShareMeeting: (meetingId: string) => void;
  onChooseAvailability: (meetingId: string, time?: string, showList?: boolean) => void;
  goBack: () => void;
}

const PastMeetings: React.FC<ComponentProps> = ({ onEdit, onChooseAvailability, onShareMeeting, onParticipants, goBack }) => {
  const [showMeetingTime, setShowMeetingTime] = useState<MeetingTimeInfo>()
  const [showMeetingActions, setShowMeetingActions] = useState('')

  const pageRef = useRef()
  const { meetings = [] } = useMeetings()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'PastMeetings' },
    })
  }, [])

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'PastMeetings',
      },
    })
  }

  function renderMeeting(meeting: Meeting): JSX.Element {
    return (
      <MeetingCard
        key={meeting.id}
        meeting={meeting}
        showBestTime
        showVideo
        onChooseAvailability={(meeting, time, showList) => onChooseAvailability(meeting.id, time, showList)}
        onMeetingTime={(meeting, meetingTime) => setShowMeetingTime({ meetingId: meeting.id, meetingTime })}
        onParticipants={(meeting) => onParticipants(meeting.id)}
        onInvite={(meeting) => onShareMeeting(meeting.id)}
        onEdit={(meeting) => onEdit(meeting.id)}
        onMoreActions={(meeting) => setShowMeetingActions(meeting.id)} />
    )
  }

  function renderPast(): JSX.Element | undefined {
    if (meetings) {
      // first let's find meetings that have meetingTimes and are not currently in active list
      const pastMeetings = meetings
        .filter(meeting => {
          const { meetingTimes, dayRanges } = meeting

          // a meeting is in the past if it's confirmed timeRang is in the past
          // OR if the meetingDayRange end is in the past
          if (meetingTimes?.length) {
            const meetingTime = meetingTimes[0]

            if (meetingTime.status === 'confirmed' &&
              moment(meetingTime.endTime).valueOf() < moment().valueOf()) {
              return true
            }
          }

          if (dayRanges?.length) {
            const sorted = dayRanges.slice().sort((a, b) => {
              const timeA = moment(a.startTime).valueOf()
              const timeB = moment(b.startTime).valueOf()

              return timeA - timeB
            })

            const lastDay = sorted[sorted.length - 1]

            if (moment(lastDay.endTime).valueOf() < moment().valueOf()) {
              return true
            }
          }
        })
        .sort((a: Meeting, b: Meeting) => {
          const timeA = a.updateTime || a.createTime
          const timeB = b.updateTime || b.createTime

          return compareTimes(timeB, timeA)
        })

      if (pastMeetings.length) {
        return (
          <div>
            {pastMeetings.map((meetingId) => renderMeeting(meetingId))}
          </div>
        )
      }
    }
  }

  function renderModals(): JSX.Element | undefined {
    if (showMeetingTime) {
      const { meetingId, meetingTime } = showMeetingTime

      return (
        <MeetingTimeModal
          meetingId={meetingId}
          meetingTime={meetingTime}
          presentingElement={pageRef.current}
          onClose={() => {
            logTap({ component: 'MeetingTimeModal', button: 'Close', meetingId })
            setShowMeetingTime(undefined)
          }} />
      )
    }

    if (showMeetingActions) {
      return (
        <MeetingActionsModal
          meetingId={showMeetingActions}
          presentingElement={pageRef.current}
          onEdit={(meetingId) => {
            logTap({ component: 'MeetingActionsModal', button: 'Edit', meetingId })
            onEdit(meetingId)
            setTimeout(() => {
              setShowMeetingActions('')
            }, 1000)
          }}
          onClose={() => setShowMeetingActions('')} />
      )
    }
  }

  return (
    <IonPage ref={pageRef}>
      <ScreenHeader
        title={title}
        onBack={goBack} />
      <IonContent>
        {renderPast()}
        {(showMeetingTime) && <IonBackdrop visible />}
        {renderModals()}
      </IonContent>
    </IonPage>
  )
}

export default PastMeetings
