import React, { useState, useEffect } from 'react'
import {
  IonContent, IonFooter, IonPage, IonToast,
} from '@ionic/react'

import 'screens/Settings.css'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import TipsConnectCalendarsSlides from 'screens/TipsConnectCalendars/TipsConnectCalendarsSlides'
import { useTips } from 'context/TipsContext/TipsContext'
import { EventName, EventData, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

export interface MeetingActionsModalProps {
  goBackTo?: string;
  onAddCalendar: () => void;
  onChooseAvailability: () => void;
}

const AskConnectCalendars: React.FC<MeetingActionsModalProps> = ({ goBackTo, onAddCalendar, onChooseAvailability }) => {
  const { storeTips, updateStoreTips } = useTips()
  const [showToast, setShowToast] = useState(false)
  const { logEvent } = useAnalytics()

  useEffect(() => {
    setTimeout(() => {
      setShowToast(true)
    }, 1000)
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'AskConnectCalendars' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'AskConnectCalendars',
      },
    })
  }

  function renderFooter (): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'ConnectCalendar' })
            onAddCalendar()
          }}>
          Connect Google Calendar
        </FooterButton>
        <FooterButton
          fill='clear'
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'Skip' })

            if (updateStoreTips) {
              const skipped = storeTips?.tipsConnectCalendars?.skipped || 0

              updateStoreTips({
                tipsConnectCalendars: { skipped: skipped + 1 },
              })
            }

            onChooseAvailability()
          }}>
          Skip
        </FooterButton>
      </IonFooter>
    )
  }

  function renderToast (): JSX.Element | undefined {
    if (showToast) {
      return (
        <IonToast
          isOpen={showToast}
          color='warning'
          onDidDismiss={() => setShowToast(false)}
          message='Scroll down to see all the tips'
          duration={2000} />
      )
    }
  }

  return (
    <IonPage>
      <ScreenHeader
        title='Automagic Scheduling'
        goBackTo={goBackTo} />
      <IonContent scrollY={false}>
        <TipsConnectCalendarsSlides />
        {renderFooter()}
        {renderToast()}
      </IonContent>
    </IonPage>
  )
}

export default AskConnectCalendars
