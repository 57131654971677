import { ADD_ACCOUNT, REMOVE_ACCOUNT, Account, AccountActions, UPDATE_ACCOUNT } from './types'

export function addAccount (account: Account): AccountActions {
  return {
    type: ADD_ACCOUNT,
    account,
  }
}

export function updateAccount (account: Account): AccountActions {
  return {
    type: UPDATE_ACCOUNT,
    account,
  }
}

export function removeAccount (account: Account): AccountActions {
  return {
    type: REMOVE_ACCOUNT,
    account,
  }
}
