import React, { useState, CSSProperties, useEffect, useRef } from 'react'
import {
  IonSpinner, IonNote,
  IonContent, IonList, IonPage, IonItem, IonLabel, IonText, IonIcon, IonListHeader, IonItemDivider, IonFooter, IonSearchbar,
} from '@ionic/react'

import { Connection, InviteeStatus } from 'types'

import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useConnections } from 'context/Connections/ConnectionsContext'
import { checkmarkCircle, ellipseOutline, people } from 'ionicons/icons'
import ListItem from 'components/atoms/ListItem/ListItem'
import { Avatar } from '@material-ui/core'
import AddParticipantsRow from 'components/organisms/AddParticipantsRow/AddParticipantsRow'
import { CreateInviteeInput } from 'services/api'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import TipTarget from 'components/atoms/TipTarget/TipTarget'
import { AddParticipantsTips } from 'types/componentTips'
import { AddParticipants as StoreTips } from 'services/store/tips/types'
import { useTips } from 'context/TipsContext/TipsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { Spring } from 'react-spring/renderprops-universal'
import { useStoreParticipants } from 'context/StoreParticipantsContext/StoreParticipantsContext'
import { EventName, EventData, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'
import InvitationsSentModal from 'components/organisms/InvitationsSentModal/InvitationsSentModal'

const title = 'Invite Participants'

const avatarStyle: CSSProperties = {
  width: 36,
  height: 36,
  backgroundColor: '#92949C',
  marginRight: 15,
}

interface ComponentProps {
  goBackTo?: string;
  onInvite: () => void;
  goBack: () => void;
  onAdd: () => void;
}

const appUrl = process.env.REACT_APP_MEETINGS_APP + '/invitations/'

const AddParticipants: React.FC<ComponentProps> = ({ goBackTo, onInvite, goBack, onAdd }) => {
  const [selectedParticipants, setSelectedParticipants] = useState<Connection[]>([])
  const [showActivity, setShowActivity] = useState(false)
  const [showInviteSentModal, setShowInviteSentModal] = useState(false)
  const [showScreenTip, setShowScreenTip] = useState(AddParticipantsTips.noTip)
  const [searchParticipants, setSearchParticipants] = useState('')

  const [minTip, setMinTip] = useState(false)

  const { meeting } = useMeetings()
  const { participant, participants } = useParticipants()
  const { createInvitee, meetingInvitees } = useInvitees()
  const { loading, connections } = useConnections()
  const { storeTips, updateStoreTips } = useTips()
  const { storeParticipants, updateStoreParticipants } = useStoreParticipants()
  const { logEvent } = useAnalytics()

  const pageRef = useRef()

  useEffect(() => {
    startTips()
    logEvent({
      eventName: EventName.screenView,
      eventData: {
        screen: 'AddParticipants',
        meetingId: meeting?.id,
      },
    })
  }, [])

  useEffect(() => {
    if (participant?.readyToSchedule) {
      if (!participant.readyToSchedule.ready && storeParticipants && updateStoreParticipants) {
        if (!storeParticipants.notReadyToSchedule?.find(id => participant.id === id)) {
          updateStoreParticipants({
            notReadyToSchedule: storeParticipants.notReadyToSchedule
              ? [...storeParticipants.notReadyToSchedule, participant.id] : [participant.id],
          })
        }
      }
    }
  }, [participant])

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'AddParticipants',
        meetingId: meeting?.id,
      },
    })
  }

  const componentStoreTips = storeTips?.addParticipants

  function startTips(): void {
    setTimeout(() => {
      if (storeTips?.startMinTip || componentStoreTips?.endTips) {
        setNextTip(AddParticipantsTips.noTip)
      } else {
        setNextTip(AddParticipantsTips.sequence)
      }
    }, 1000)
  }

  function setNextTip(tip: AddParticipantsTips, restartTips?: boolean): void {
    // console.log('Meeting screen setNextTip: ', tip)

    switch (tip) {
      // eslint-disable-next-line no-fallthrough
      case AddParticipantsTips.sequence:
      case AddParticipantsTips.addParticipant:
        if (restartTips || !componentStoreTips?.addParticipant) {
          setShowScreenTip(AddParticipantsTips.addParticipant)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case AddParticipantsTips.shareLink:
        if (!componentStoreTips?.shareLink) {
          setShowScreenTip(AddParticipantsTips.shareLink)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case AddParticipantsTips.endTips:
        if (!componentStoreTips?.endTips) {
          setShowScreenTip(AddParticipantsTips.endTips)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case AddParticipantsTips.noTip:
      default:
        setShowScreenTip(AddParticipantsTips.endTips)
        setMinTip(true)
        break
    }
  }

  function updateTip(tip: StoreTips): void {
    if (updateStoreTips) {
      updateStoreTips({
        addParticipants: {
          ...componentStoreTips, ...tip,
        },
      })
    }
  }

  async function addParticipants(): Promise<void> {
    if (meeting && selectedParticipants.length) {
      const input: CreateInviteeInput = {
        meetingId: meeting.id,
        inviteUsers: selectedParticipants.map(p => p.connectedToUserId),
        appUrl,
      }

      setShowActivity(true)
      await createInvitee(input)
      setShowActivity(false)
      setShowInviteSentModal(true)
      setTimeout(() => {
        setShowInviteSentModal(false)
        setTimeout(() => {
          onAdd()
        }, 1000)
      }, 4000)
    }
  }

  function renderParticipantCount(): JSX.Element | undefined {
    if (meeting && meeting?.participantsExpected && meeting.participantUserIds) {
      // find the current count of participants and invitees
      const inviteesCount = meetingInvitees?.filter(invitee => invitee.status !== InviteeStatus.accepted &&
        invitee.status !== InviteeStatus.declined).length || 0

      const currentCount = meeting.participantUserIds?.length + inviteesCount

      let count = meeting.participantsExpected

      if (selectedParticipants?.length + currentCount > count) {
        count = selectedParticipants?.length + currentCount
      }
      // const count = meeting.participantsExpected + selectedParticipants?.length

      return (
        <ListItem
          testId='expected-participant-count'
          title='total expected participants (including you)'
          label={count.toString()}
          detail={!selectedParticipants?.length}
          icon={{
            name: people,
            color: 'medium',
            slot: 'start',
          }}
          onClick={() => {
            logTap({ component: 'ListItem', button: 'ExpectedCount' })

            if (!selectedParticipants?.length) {
              onInvite()
            }
          }} />
      )
    }
  }

  function renderSearchBar(): JSX.Element {
    return (
      <IonSearchbar
        value={searchParticipants}
        showCancelButton='focus'
        onIonCancel={() => setSearchParticipants('')}
        onIonChange={e => {
          if (e.detail.value) {
            setSearchParticipants(e.detail.value)
          } else {
            setSearchParticipants('')
          }
        }} />
    )
  }

  function renderSelectedConnections(): JSX.Element {
    return (
      <AddParticipantsRow
        connections={selectedParticipants}
        onRemove={(connection) => {
          logTap({ component: 'AddParticipantsRow', button: 'RemoveConnection' })
          toggleSelected(connection)
        }} />
    )
  }

  // function renderInviteLink (): JSX.Element {
  //   return (
  //     <ListItem
  //       label='Invite Others via Link'
  //       tip={!minTip && showScreenTip === AddParticipantsTips.shareLink}
  //       icon={{ name: linkOutline, slot: 'start', color: 'primary' }}
  //       onClick={() => {
  //         updateTip({ shareLink: true })
  //         setNextTip(showScreenTip + 1)
  //         onInvite()
  //       }} />
  //   )
  // }

  function toggleSelected(connection: Connection): void {
    const selected = selectedParticipants.find(c => c.connectedToUserId === connection.connectedToUserId)

    if (!selected) {
      const update = selectedParticipants.slice()

      update.push(connection)
      console.log('ADD UPDATE: ', update)
      setSelectedParticipants(update)
    } else {
      const update = selectedParticipants.slice()

      setSelectedParticipants(update.filter(c => c.connectedToUserId !== connection.connectedToUserId))
      updateTip({ addParticipant: true })
      setNextTip(showScreenTip + 1)
    }
  }

  function renderConnection(connection: Connection, divider: boolean, tip: boolean): JSX.Element {
    const { connectedToUserId, displayName, userHandle, photo } = connection
    // discard blank spaces in between
    const initials = displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase())
    const selected = selectedParticipants.find(c => c.connectedToUserId === connectedToUserId)

    const participating = !!participants?.find(p => p.userId === connection.connectedToUserId)
    const invitee = !participating && !!meetingInvitees?.find(invitee => invitee.userId === connection.connectedToUserId)

    return (
      <div key={userHandle}>
        {divider &&
          <IonItemDivider
            color='light'
            sticky>
            {initials[0]}
          </IonItemDivider>}
        <IonItem
          key={userHandle}
          lines='full'
          disabled={participating || invitee}
          onClick={() => {
            logTap({ button: 'SelectConnection' })
            toggleSelected(connection)
          }}>
          {tip &&
            <TipTarget
              style={{ position: 'absolute', bottom: 30, left: '35%' }} />}
          <Avatar
            slot='start'
            src={photo}
            style={avatarStyle}>
            <IonText style={{ fontSize: 16 }}>
              {initials}
            </IonText>
          </Avatar>
          <IonLabel>
            <h2>{displayName}</h2>
            <p>@{userHandle}</p>
          </IonLabel>
          {participating &&
            <IonNote
              slot='end'>
              participant
            </IonNote>}
          {invitee &&
            <IonNote
              slot='end'>
              invited
            </IonNote>}
          {!selected && !participating && !invitee &&
            <IonIcon
              slot='end'
              icon={ellipseOutline}
              color='medium' />}
          {selected &&
            <IonIcon
              slot='end'
              icon={checkmarkCircle}
              color='primary' />}

        </IonItem>
      </div>
    )
  }
  function renderConnections(): JSX.Element | undefined {
    if (connections && participants && meetingInvitees) {
      // const showConnections = connections.filter(connection => {
      //   if (participants.find(p => p.userId=== connection.connectedTo)) {
      //     return false
      //   }

      //   if (meetingInvitees.find(invitee => invitee.userId=== connection.connectedTo)) {
      //     return false
      //   }

      //   return true
      // }).slice().sort((a, b) => {
      //   return a.name.localeCompare(b.name)
      // })
      if (connections.length < 1) {
        return (
          <IonText
            color='medium'
            style={
              { display: 'flex', padding: '5%' }
            }>
            Once you have met with someone, they&apos;ll be listed here.
          </IonText>
        )
      }

      let showConnections = connections.slice().sort((a, b) => {
        return a.displayName.localeCompare(b.displayName)
      })

      if (searchParticipants) {
        // match on word boundary, case insensitive and multiline
        const pattern = new RegExp('\\b' + searchParticipants, 'im')

        showConnections = showConnections.filter(function (connection) {
          return pattern.test(connection.displayName)
        })
      }

      return (
        <IonList class='ion-no-padding'>
          {showConnections.map((connection, i) => {
            let divider = false

            if (!i || showConnections[i - 1].displayName.charAt(0) !== connection.displayName.charAt(0)) {
              divider = true
            }

            return renderConnection(connection, divider, (i === 0 && !minTip && showScreenTip === AddParticipantsTips.addParticipant))
          })}
        </IonList>
      )
    }
  }

  function renderHeader(): JSX.Element {
    if (selectedParticipants.length) {
      return (
        <ScreenHeader title={meeting?.title || title} />
      )
    } else {
      return (
        <ScreenHeader
          title={meeting?.title || title}
          onBack={goBackTo ? undefined : goBack}
          goBackTo={goBackTo} />
      )
    }
  }

  function renderFooter(): JSX.Element | undefined {
    if (selectedParticipants.length) {
      if (showActivity) {
        return (
          <IonFooter className='screenFooterButton'>
            <FooterButton>
              <IonSpinner name='dots' />
            </FooterButton>
          </IonFooter>
        )
      }

      return (
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}>
          {props =>
            <div style={props}>
              <IonFooter className='screenFooterButton'>
                <FooterButton
                  onClick={() => {
                    logTap({ component: 'FooterButton', button: 'InviteSelected' })
                    addParticipants()
                  }}>
                  Invite Selected
                </FooterButton>
                <FooterButton
                  fill='clear'
                  onClick={() => {
                    logTap({ component: 'FooterButton', button: 'ClearSelected' })
                    setSelectedParticipants([])
                  }}>
                  Clear Selected
                </FooterButton>
              </IonFooter>
            </div>}
        </Spring>
      )
    }

    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          fill='outline'
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'InviteViaLink' })
            onInvite()
          }}>
          Invite to Meeting via Link
        </FooterButton>
      </IonFooter>
    )
  }
  // function renderScreenTips (): JSX.Element | undefined {
  //   return (
  //     <ScreenTips
  //       minTip={minTip}
  //       showScreenTip={showScreenTip}
  //       onClose={() => {
  //         setMinTip(true)
  //       }}
  //       onMinTip={() => {
  //         setMinTip(false)

  //         // if we've not shown endTips then go ahead and start the sequence immediately
  //         if (!componentStoreTips?.endTips) {
  //           setNextTip(AddParticipantsTips.sequence)
  //         }
  //       }}
  //       onButton={(restartTips) => {
  //         if (showScreenTip === AddParticipantsTips.endTips) {
  //           if (restartTips) {
  //             console.log('Restart TIPS!')
  //             setNextTip(AddParticipantsTips.sequence, true)
  //           } else {
  //             setNextTip(AddParticipantsTips.noTip)
  //             setMinTip(true)
  //           }
  //         } else {
  //           setNextTip(showScreenTip + 1)
  //         }
  //       }} />
  //   )
  // }

  function renderModals(): JSX.Element | undefined {
    if (showInviteSentModal) {
      const names = selectedParticipants.map(connection => connection.displayName)

      return (
        <InvitationsSentModal
          names={names}
          presentingElement={pageRef.current}
          onClose={() => {
            setShowInviteSentModal(false)
          }} />
      )
    }
  }

  return (
    <IonPage ref={pageRef}>
      {renderHeader()}
      <IonContent>

        {/* {renderParticipantCountInput()} */}
        {renderParticipantCount()}
        <IonListHeader
          style={{ marginBottom: 10 }}
          mode='ios'>
          Invite Participants
          {loading &&
            <IonSpinner name='dots' />}
        </IonListHeader>
        {renderSearchBar()}
        {renderSelectedConnections()}
        {/* {renderInviteLink()} */}
        {renderConnections()}
      </IonContent>
      {renderFooter()}
      {renderModals()}
      {/* {renderScreenTips()} */}
    </IonPage>
  )
}

export default AddParticipants
