import React, { CSSProperties, useEffect, useState } from 'react'
import {
  IonChip,
  IonContent, IonFooter, IonIcon, IonLabel, IonModal, IonText,
} from '@ionic/react'
import moment from 'moment'

import 'react-dates/initialize'
import { DayPickerSingleDateController, CalendarDay } from 'react-dates'

import 'react-dates/lib/css/_datepicker.css'
import './SelectMeetingDays.css'

import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'
import { TimeRange } from 'types'
import { animated, useSpring } from 'react-spring'
import MeetingDays from 'components/molecules/MeetingDays/MeetingDays'
import { closeCircleOutline } from 'ionicons/icons'
import { ScreenTips } from './ScreenTips'
import { SelectMeetingDaysTips } from 'types/componentTips'
import { useTips } from 'context/TipsContext/TipsContext'
import { blue } from 'theme/styles'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { Spring } from 'react-spring/renderprops'

const container: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}
const bottomBox: CSSProperties = {
  paddingTop: 20,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const messageBox: CSSProperties = {
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
}

const messageStyle = {
  fontSize: 14,
}

const chipStyle: CSSProperties = {
  border: `1px solid ${blue}`,
}

const title = 'Day Ranges'

export interface SelectMeetingDaysProps {
  days: TimeRange[];
  doneButton?: string;
  onClose: () => void;
  onChangeDayRanges: (dayRanges: TimeRange[]) => void;
  presentingElement: HTMLElement | undefined;
}

function sortedDays (days: TimeRange[]): TimeRange[] {
  return days.slice().sort((a, b) => {
    const timeA = moment(a.startTime).valueOf()
    const timeB = moment(b.startTime).valueOf()

    return timeA - timeB
  })
}

const SelectMeetingDays: React.FC<SelectMeetingDaysProps> = ({ days, doneButton, onClose, onChangeDayRanges, presentingElement }) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const [showScreenTip, setShowScreenTip] = useState(SelectMeetingDaysTips.noTip)
  const [minTip, setMinTip] = useState(false)
  const [dayRanges, setDayRanges] = useState(sortedDays(days))
  // const [rangeSelected, setRangeSelected] = useState(0)
  const [startDate, setStartDate] = useState(days?.length ? sortedDays(days)[0].startTime : '')
  const [endDate, setEndDate] = useState(days?.length ? sortedDays(days)[days.length - 1].endTime : '')

  const { storeTips } = useTips()

  useEffect(() => {
    setTimeout(() => {
      setShowCalendar(true)
    }, 500)

    startTips()
  }, [])

  const componentStoreTips = storeTips?.selectMeetingDays

  function startTips (): void {
    setTimeout(() => {
      setNextTip(SelectMeetingDaysTips.sequence)
    }, 1000)
  }

  // function inRange (day: moment.Moment): boolean {
  //   if (dayRanges.find(range => {
  //     if (day?.isAfter(moment(range.startTime)) && day.isBefore(range.endTime)) {
  //       return true
  //     }
  //   })) {
  //     return true
  //   }

  //   return false
  // }

  function setNextTip (tip: SelectMeetingDaysTips, restartTips?: boolean): void {
    // console.log('Meeting screen setNextTip: ', tip)

    switch (tip) {
      // eslint-disable-next-line no-fallthrough
      case SelectMeetingDaysTips.sequence:
      case SelectMeetingDaysTips.selectDaysRange:
        if ((!componentStoreTips?.selectDaysRange || restartTips) && (!startDate || !endDate)) {
          setShowScreenTip(SelectMeetingDaysTips.selectDaysRange)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case SelectMeetingDaysTips.editDays:
        if ((!componentStoreTips?.editDays || restartTips) && days?.length) {
          setShowScreenTip(SelectMeetingDaysTips.editDays)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case SelectMeetingDaysTips.noTip:
      default:
        // in the modal case reset the tips back to sequence
        setShowScreenTip(SelectMeetingDaysTips.sequence)
        setMinTip(false)
        break
    }
  }

  console.log('DAY RANGES: ', dayRanges)

  function onDatesChange (selectedDate: moment.Moment | null): void {
    console.log('onDateChange: selectedDate: ', selectedDate?.toString())
    // console.log('onDateChange: endDate: ', endDate?.toString())

    if (selectedDate && selectedDate.isAfter(moment().startOf('day'))) {
      // Note: react-dates returns times at midday of chosen date. So set start and end
      // times as startOf and endOf days.
      const ranges = dayRanges.slice()

      if ((!startDate) ||
        (!endDate && selectedDate?.isBefore(moment(startDate)))) {
        const startTime = moment(selectedDate).startOf('day').toISOString()
        const range = {
          startTime,
          endTime: moment(selectedDate).startOf('day').add(24, 'hours').toISOString(),
        }

        setDayRanges([range])
        setStartDate(startTime)
        setEndDate('')
        // setRangeSelected(1)
      } else if ((!endDate) && selectedDate?.isAfter(moment(startDate))) {
        const endTime = moment(selectedDate).startOf('day').add(24, 'hours').toISOString()
        const range = {
          startTime: startDate,
          endTime,
        }

        setDayRanges([range])
        setEndDate(endTime)
        // setRangeSelected(2)
      } else {
        const index = ranges.findIndex(range => {
          if (selectedDate.isAfter(moment(range.startTime)) && selectedDate.isBefore(range.endTime)) {
            return true
          }
        })

        if (index > -1) {
          const range = ranges[index]

          // if it's a single day then just clip it
          if (moment(range.endTime).diff(moment(range.startTime), 'days') === 1) {
            ranges.splice(index, 1)
          } else if (selectedDate.isSame(moment(range.startTime), 'day')) {
            // if the selected day is in the beginning of the range then just clip the range start
            range.startTime = moment(selectedDate).startOf('day').add(24, 'hours').toISOString()
          } else if (moment(selectedDate).add(1, 'day').isSame(moment(range.endTime), 'day')) {
            // not that moment.add muated the date so create a new momment ob
            // if the selected day is in the end of the range then just clip the range end
            range.endTime = moment(selectedDate).startOf('day').toISOString()
          } else {
            // selectedDay is in the middle of a range so we need to split it
            const newRange = {
              startTime: moment(selectedDate).startOf('day').add(24, 'hours').toISOString(),
              endTime: range.endTime,
            }

            range.endTime = moment(selectedDate).startOf('day').toISOString()
            ranges.splice(index + 1, 0, newRange)
          }
        } else {
          // check if we need to merge with previous range
          // this could happen if the user de-selects a day within a range that causes a split and then
          // selects the day again which should merge ranges or extend a range
          const index = ranges.findIndex(range => {
            if (selectedDate.isSame(moment(range.endTime), 'day')) {
              return true
            }
          })

          if (index > -1) {
            // extend the previous range
            const range = ranges[index]

            range.endTime = moment(selectedDate).startOf('day').add(24, 'hours').toISOString()

            // check if we need to merge with the next range
            if (index < ranges.length - 1) {
              const nextRange = ranges[index + 1]

              if (moment(range.endTime).isSame(moment(nextRange.startTime), 'day')) {
                // merge with the next range
                range.endTime = nextRange.endTime
                // remove the next range
                ranges.splice(index + 1, 1)
              }
            }
          } else {
            // check if we need to merge with next range
            const nextSelectedDate = moment(selectedDate).add(1, 'day')

            const index = ranges.findIndex(range => {
              if (nextSelectedDate.isSame(moment(range.startTime), 'day')) {
                return true
              }
            })

            if (index > -1) {
              // extend the next range
              const range = ranges[index]

              range.startTime = moment(selectedDate).startOf('day').toISOString()
            } else {
              // there is no matching range so just create a new standalong range
              const startTime = moment(selectedDate).startOf('day').toISOString()
              const range = {
                startTime,
                endTime: moment(selectedDate).startOf('day').add(24, 'hours').toISOString(),
              }

              ranges.push(range)
            }
          }
        }

        setDayRanges(ranges)

        console.log('NEW RANGES: ', ranges)
      }

      // setEdited(true)
    }
  }

  function clearSelection (): void {
    setDayRanges([])
    setStartDate('')
    setEndDate('')
    // setEdited(true)
  }
  // function updateDayRanges (startDate: string, endDate: string): void {
  //   const startTime = moment(startDate).startOf('day').toISOString()
  //   const endTime = moment(endDate).startOf('day').add(24, 'hours').toISOString()

  //   console.log('DAY RANGES: ', startTime, endTime)
  //   setDayRanges([{
  //     startTime,
  //     endTime,
  //   }])
  // }

  function onDone (): void {
    onChangeDayRanges(dayRanges)
    onClose()
  }

  function renderHeader (): JSX.Element {
    return (
      <ModalHeader
        title={title} />
    )
  }

  function renderClearButton (): JSX.Element | undefined {
    if (startDate !== '') {
      return (
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}>
          {props =>
            <div style={{ ...props, textAlign: 'center', marginTop: 10 }}>
              <IonChip
                color='primary'
                style={chipStyle}
                onClick={() => clearSelection()}>
                <IonIcon icon={closeCircleOutline} />
                <IonLabel>clear selection</IonLabel>
              </IonChip>
            </div>}
        </Spring>
      )
    }
  }

  function renderFooter (): JSX.Element | undefined {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}>
        {props =>
          <div style={props}>
            <IonFooter className='screenFooterButton'>
              <FooterButton
                onClick={onDone}
                disabled={!startDate}>
                {doneButton || 'Done'}
              </FooterButton>
              <FooterButton
                fill='clear'
                onClick={() => {
                  onClose && onClose()
                }}>
                Cancel
              </FooterButton>
            </IonFooter>
          </div>}
      </Spring>
    )
  }

  const calendarAnimation = useSpring({
    ...(showCalendar ? { opacity: 1 } : { opacity: 0 }),
  })

  function renderCalendar (): JSX.Element | undefined {
    if (showCalendar) {
      return (
        <animated.div
          style={{ ...bottomBox, ...calendarAnimation }}>
          <DayPickerSingleDateController
            date={dayRanges?.length ? moment(dayRanges[0].startTime) : null}
            numberOfMonths={1}
            focused
            initialVisibleMonth={() => {
              if (startDate && moment(startDate).isAfter(moment().subtract(1, 'day'))) {
                console.log('initial month: ', startDate)

                return moment(startDate)
              }

              return moment()
            }}
            hideKeyboardShortcutsPanel
            onDateChange={onDatesChange} // PropTypes.func.isRequired
            isOutsideRange={day => day.isBefore(moment().startOf('day')) || day.isAfter(moment().add(3, 'M'))}
            // isDayHighlighted={(day) => day.isSame(moment(), 'day')}
            renderCalendarDay={(props) => {
              const { day } = props

              // console.log('ranges: ', dayRanges)

              let modifiers = props.modifiers

              if (dayRanges.find(range => {
                if (day?.isAfter(moment(range.startTime)) && day.isBefore(range.endTime)) {
                  return true
                }
              })) {
                // console.log('mark day selected: ', day?.toISOString())
                // we need to make a new copy of modifiers else React will not
                // update the CalendarDay component
                // update modifiers only when the selected state changes
                if (!modifiers?.has('selected')) {
                  modifiers = new Set(modifiers)
                  modifiers.add('selected')
                }
                // console.log('MODIFIERS: ', modifiers)
              } else {
                // we need to make a new copy of modifiers else React will not
                // update the CalendarDay component
                // update modifiers only when the selected state changes
                if (modifiers?.has('selected')) {
                  modifiers = new Set(modifiers)
                  modifiers.delete('selected')
                }
              }

              return (
                <CalendarDay
                  {...props}
                  modifiers={modifiers} />
              )
            }}
            onFocusChange={() => {
              // console.log('onFocusChange: ', focusedInput)

              // if (focusedInput) {
              //   setFocusedInput(focusedInput)
              // } else {
              //   setFocusedInput('startDate')
              // }
            }} />
        </animated.div>
      )
    }
  }

  function renderScreenTips (): JSX.Element | undefined {
    return (
      <ScreenTips
        minTip={minTip}
        showScreenTip={showScreenTip}
        onClose={() => {
          setMinTip(true)
        }}
        onMinTip={() => {
          setMinTip(false)
          setNextTip(SelectMeetingDaysTips.sequence, true)
        }}
        onButton={() => {
          // setMinTip(false)
          setNextTip(showScreenTip + 1)
        }} />
    )
  }

  return (
    <IonModal
      id='meeting-day-modal'
      isOpen
      swipeToClose
      presentingElement={presentingElement}
      onDidDismiss={onClose}>
      {renderHeader()}
      <IonContent
        scrollX={false}>
        <div style={container}>
          <div style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              Select the day range for the meeting.
            </IonText>
          </div>
          <MeetingDays
            dayRanges={dayRanges}
            onClearRange={() => clearSelection()} />
          {renderCalendar()}
        </div>
      </IonContent>
      {renderFooter()}
      {renderScreenTips()}
    </IonModal>
  )
}

export default SelectMeetingDays
