
import { CalendarViewContextValue } from './CalendarViewContext'

const config: CalendarViewContextValue = {
  timeLabelWidth: 60,
  timeSlotHeight: 50,
  timeSlotWidth: 90,
  dayLabelHeight: 30,
  calendarTopPadding: 20,
  calendarBottomPadding: 50,
  allDayEventsBoxHeight: 30,
  scrollWindow: 7,
  infiniteScroll: false,
  defaultDuration: 60,
}

export default config
