
import React, { CSSProperties } from 'react'
import { useCalendarView } from 'components/organisms/ScheduleCalendar/CalendarViewContext'

export interface GridBoxProps {
  disable?: boolean;
  last?: boolean;
}

const GridBox: React.FC<GridBoxProps> = ({ disable, last }) => {
  const { timeSlotHeight = 50 } = useCalendarView()

  const container: CSSProperties = {
    minHeight: timeSlotHeight,
  }

  if (disable) {
    return (
      <div
        className='CalendarATimeSlotDisabled'
        style={container} />
    )
  } else if (last) {
    container.bottom = 0

    return (
      <div
        className='CalendarALastTimeSlot'
        style={container} />
    )
  }

  return (
    <div
      className='CalendarALines'
      style={container} />
  )
}

export default GridBox
