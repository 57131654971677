import { CSSProperties } from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import lottieJson from './bulb.json'

export interface AnimatedBulbProps {
  delay?: number;
  loop?: number | boolean;
  style?: CSSProperties;
  onComplete?: () => void;
}

const AnimatedBulb: React.FC<AnimatedBulbProps> = ({ delay, style, loop }) => {
  const [play, setPlay] = useState(!delay)

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setPlay(true)
      }, delay)
    }
  }, [])

  return (
    <Lottie
      loop={loop || 1}
      play={play}
      animationData={lottieJson}
      style={style || { width: 30, height: 30 }} />
  )
}
export default AnimatedBulb
