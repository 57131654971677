import React, { CSSProperties } from 'react'
import {
  IonButton, IonIcon, IonItem, IonText, isPlatform,
} from '@ionic/react'

import 'theme/styles.css'
import { logoColor } from 'theme/styles'
import { Avatar } from '@material-ui/core'
import { Invitee, InviteeStatus, Meeting, Participant } from 'types'
import { addCircleOutline } from 'ionicons/icons'
import TipTarget from 'components/atoms/TipTarget/TipTarget'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import { useUser } from 'context/UserContext/UserContext'
import { withinDayRanges, withinTimeRanges } from 'components/organisms/ScheduleCalendar/utils'

const avatarColor = '#92949C'

const container: CSSProperties = {
  marginTop: 10,
  display: 'flex',
}

const box: CSSProperties = {
  flex: 1,
  overflow: 'hidden',
}

const avatarStyle: CSSProperties = {
  width: 30,
  height: 30,
  backgroundColor: avatarColor,
  border: '2px solid #d7d8da',
  marginRight: -10,
}

const avatarTxt: CSSProperties = {
  fontSize: 12,
}

const addButton: CSSProperties = {
  width: 30,
  height: 30,
  color: logoColor,
}

const titleStyle: CSSProperties = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
  marginBottom: 10,
}

const expectingCircle: CSSProperties = {
  width: 30,
  height: 30,
  borderRadius: 15,
  // border: '1px dashed gray',
  backgroundColor: 'orange',
  border: '2px solid #d7d8da',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
}

const expectingBadge: CSSProperties = {
  color: 'white',
  fontWeight: 'bold',
  fontSize: 13,
}

interface MeetingCardParticipantsProps {
  meeting: Meeting;
  tip?: boolean;
  autoBook?: boolean;
  onClick: () => void;
  onInvite: (byLink?: boolean) => void;
}

const MeetingCardParticipants: React.FC<MeetingCardParticipantsProps> = ({ meeting, tip, autoBook, onClick, onInvite }) => {
  const { meetingInvitees } = useInvitees()
  const { participants } = useParticipants()
  const { timeSlots } = useTimeSlots()
  const { user } = useUser()

  const confirmed = meeting.meetingTimes?.find(meetingTime => meetingTime.status === 'confirmed')

  const invitees = meetingInvitees?.filter(invitee => {
    // when an invitee is marked accepted  go ahead and render it as an invitee
    // until it's added to participant list as there might be a delay between
    // being marked as accepted and added to participants list
    // so to hide that delay show accepted invitee. Once it's added to participant
    // list we fill filter it out below.
    if (invitee.status === InviteeStatus.declined) {
      return false
    }

    if (participants && participants.find(p => p.userId === invitee.userId)) {
      return false
    }

    return true
  })

  const notResponded: Participant[] = []
  const responded = participants?.filter(participant => {
    if (user?.id && participant.userId === user.id && autoBook) {
      return true
    }

    const selectedTime =
      timeSlots?.filter(timeSlot => timeSlot.userId === participant.userId)
        .filter(t => withinDayRanges(t.startTime, t.endTime, meeting?.dayRanges))
        .find(t => {
          if (!meeting?.timeRanges?.length) {
            // if meeting.timeRanges are not set then all time slots withinDayRanges are valid
            return true
          }

          if (withinTimeRanges(t.startTime, meeting?.timeRanges) || withinTimeRanges(t.endTime, meeting?.timeRanges)) {
            return true
          }
        })

    if (selectedTime) {
      return true
    }

    notResponded.push(participant)

    return false
  })

  let expectingInvitees = 0

  if (participants?.length && meeting?.participantsExpected) {
    if (meeting?.participantsExpected > participants?.length) {
      expectingInvitees = meeting.participantsExpected - participants.length

      if (invitees?.length && invitees.length <= expectingInvitees) {
        expectingInvitees = expectingInvitees - invitees.length
      }
    }
  }

  const attending = participants?.filter(p => {
    if (confirmed && confirmed?.participantUserIds.indexOf(p.userId) > -1) {
      return true
    }
  })

  const notAttending = participants?.filter(p => {
    if (confirmed && confirmed.participantUserIds.indexOf(p.userId) === -1) {
      return true
    }
  })

  function renderParticipant(participant: Participant, key: number): JSX.Element {
    const { displayName, photo } = participant
    const initials = displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

    // if initials is > 2 characters then just pick first and last
    const displayInitials = initials.length > 2
      ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

    return (
      <Avatar
        key={key}
        src={photo}
        style={avatarStyle}>
        <IonText style={avatarTxt}>
          {displayInitials}
        </IonText>
      </Avatar>
    )
  }

  function renderInvitee(invitee: Invitee, key: number): JSX.Element {
    const { displayName, photo } = invitee
    const initials = displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

    // if initials is > 2 characters then just pick first and last
    const displayInitials = initials.length > 2
      ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

    return (
      <Avatar
        key={key}
        src={photo}
        style={avatarStyle}>
        <IonText style={avatarTxt}>
          {displayInitials}
        </IonText>
      </Avatar>
    )
  }

  function renderExpecting(): JSX.Element | undefined {
    if (expectingInvitees) {
      return (
        <div
          style={expectingCircle}>
          <IonText style={expectingBadge}>
            +{expectingInvitees}
          </IonText>
        </div>
      )
    }
  }

  function renderResponded(): JSX.Element | undefined {
    if (responded?.length) {
      const showAddButton = !notResponded?.length && !invitees?.length && !expectingInvitees

      const itemStyle: CSSProperties = {}

      if (tip) {
        itemStyle.overflow = 'visible'
      }

      return (
        <div
          style={box}>
          <IonText
            style={titleStyle}
            color='medium'>
            RESPONDED {responded.length}
          </IonText>
          <IonItem
            lines='none'
            style={itemStyle}
            onClick={onClick}>
            {tip &&
              <TipTarget
                style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
            {responded.map((participant: Participant, i: number) => renderParticipant(participant, i))}
            {showAddButton &&
              <IonButton
                fill='clear'
                slot='end'
                style={{ marginRight: 0 }}
                onClick={(e) => {
                  e.stopPropagation()
                  onInvite && onInvite()
                }}>
                <IonIcon
                  icon={addCircleOutline}
                  style={addButton}
                  slot='icon-only' />
              </IonButton>}
          </IonItem>
        </div>
      )
    }
  }

  function renderNotResponded(): JSX.Element | undefined {
    if (notResponded?.length || invitees?.length || expectingInvitees) {
      const count = (notResponded ? notResponded.length : 0) + (invitees ? invitees.length : 0) + expectingInvitees

      return (
        <div style={box}>
          <IonText
            style={titleStyle}>
            AWAITING {count}
          </IonText>
          <IonItem
            lines='none'
            className={responded?.length ? 'meetingCardItemBorderLeft' : ''}
            onClick={onClick}>
            {notResponded?.map((participant: Participant, i: number) => renderParticipant(participant, i))}
            {invitees?.map((invitee: Invitee, i: number) => renderInvitee(invitee, i))}
            {renderExpecting()}
            <IonButton
              fill='clear'
              slot='end'
              onClick={(e) => {
                e.stopPropagation()
                onInvite && onInvite()
              }}>
              <IonIcon
                icon={addCircleOutline}
                style={addButton}
                slot='icon-only' />
            </IonButton>
          </IonItem>
        </div>
      )
    }
  }

  // function renderNoParticipants (): JSX.Element | undefined {
  //   return (
  //     <div style={box}>
  //       <IonItem
  //         lines='full'
  //         style={{ overflow: 'visible' }}
  //         onClick={() => onInvite()}>
  //         <IonIcon
  //           icon={peopleOutline}
  //           style={leftIconStyle}
  //           slot='start' />
  //         {tip &&
  //           <TipTarget
  //             style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
  //         <IonLabel>
  //           Invite Participants
  //         </IonLabel>
  //         <IonButton
  //           fill='clear'
  //           slot='end'
  //           style={{ marginRight: 0 }}
  //           onClick={(e) => {
  //             e.stopPropagation()
  //             onInvite && onInvite()
  //           }}>
  //           <IonIcon
  //             icon={addCircleOutline}
  //             style={addButton}
  //             slot='icon-only' />
  //         </IonButton>
  //       </IonItem>
  //     </div>
  //   )
  // }

  function renderAttending(): JSX.Element | undefined {
    if (confirmed && attending?.length) {
      const showAddButton = !notResponded?.length && !invitees?.length && !expectingInvitees && !notAttending?.length

      const itemStyle: CSSProperties = {}

      if (tip) {
        itemStyle.overflow = 'visible'
      }

      return (
        <div
          style={box}>
          <IonText
            style={titleStyle}>
            ATTENDING {attending.length}
          </IonText>
          <IonItem
            lines='none'
            style={itemStyle}
            onClick={onClick}>
            {tip &&
              <TipTarget
                style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
            {attending.map((participant: Participant, i: number) => renderParticipant(participant, i))}
            {showAddButton &&
              <IonButton
                fill='clear'
                slot='end'
                style={{ marginRight: 0 }}
                onClick={(e) => {
                  e.stopPropagation()
                  onInvite && onInvite()
                }}>
                <IonIcon
                  icon={addCircleOutline}
                  style={addButton}
                  slot='icon-only' />
              </IonButton>}
          </IonItem>
        </div>
      )
    }
  }

  function renderNotAttending(): JSX.Element | undefined {
    if (confirmed && participants?.length) {
      if (notAttending?.length || invitees?.length || expectingInvitees) {
        const count = (notAttending ? notAttending.length : 0) + (invitees ? invitees.length : 0) + expectingInvitees

        return (
          <div style={box}>
            <IonText
              style={titleStyle}>
              NOT ATTENDING {count}
            </IonText>
            <IonItem
              lines='none'
              className={attending?.length ? 'meetingCardItemBorderLeft' : ''}
              onClick={onClick}>
              {notAttending?.map((participant: Participant, i: number) => renderParticipant(participant, i))}
              {invitees?.map((invitee: Invitee, i: number) => renderInvitee(invitee, i))}
              {renderExpecting()}
              <IonButton
                fill='clear'
                slot='end'
                onClick={(e) => {
                  e.stopPropagation()
                  onInvite && onInvite()
                }}>
                <IonIcon
                  icon={addCircleOutline}
                  style={addButton}
                  slot='icon-only' />
              </IonButton>
            </IonItem>
          </div>
        )
      }
    }
  }

  return (
    <div>
      {/* //   {renderExpectingParticipants()} */}
      <div style={container}>
        {!confirmed && renderResponded()}
        {!confirmed && renderNotResponded()}
        {confirmed && renderAttending()}
        {confirmed && renderNotAttending()}
        {/* {renderNoParticipants()} */}
      </div>
    </div>
  )
}

export default MeetingCardParticipants
