
import React, { CSSProperties } from 'react'
import { MeetingTime } from 'types'

import moment from 'moment'
import { useCalendarView } from 'components/organisms/ScheduleCalendar/CalendarViewContext'
import { logoColor } from 'theme/styles'

const scale = 60

const confirmedColor = '#37CF4C'
// const tentativeColor = logoColor
const trendingColor = logoColor

export interface MeetingTimeMarkerLinesProps {
  meetingTime: MeetingTime;
}

const MeetingTimeMarkerLines: React.FC<MeetingTimeMarkerLinesProps> =
  ({ meetingTime }) => {
    const { timeSlotHeight = 50 } = useCalendarView()
    const top =
      moment(meetingTime.startTime)
        .diff(moment(meetingTime.startTime).startOf('day'), 'minutes') * (timeSlotHeight / scale)
    const { startTime, endTime, status } = meetingTime
    const minutes = moment(endTime).diff(moment(startTime), 'minutes')
    const height = minutes * (timeSlotHeight / scale)

    const borderColor = status === 'confirmed' ? confirmedColor : trendingColor
    const border = `2px solid ${borderColor}`
    const container: CSSProperties = {
      position: 'absolute',
      top,
      height,
      width: '100%',
      display: 'flex',
      borderTop: border,
      borderBottom: border,
    }

    return (
      <div
        style={container} />
    )
  }

export default MeetingTimeMarkerLines
