import React, { CSSProperties, useState } from 'react'
import {
  IonContent, IonFooter, IonItem, IonText, isPlatform, IonLabel, IonModal,
} from '@ionic/react'
import Linkify from 'react-linkify'

import FooterButton from 'components/atoms/FooterButton/FooterButton'
import TextAreaInput from 'components/atoms/TextAreaInput/TextAreaInput'
import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'

const title = 'Meeting Description'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const titleStyle = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

interface ContainerProps {
  meetingDescription?: string;
  onClose: () => void;
  onUpdated: (description: string) => void;
  presentingElement: HTMLElement | undefined;
}

const MeetingDescription: React.FC<ContainerProps> = ({ meetingDescription, onClose, onUpdated, presentingElement }) => {
  const [description, setDescription] = useState(meetingDescription || '')
  const [edited, setEdited] = useState(!meetingDescription)

  function renderFooter (): JSX.Element | undefined {
    if (edited) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => onUpdated(description)}>
            Update Description
          </FooterButton>
          <FooterButton
            fill='clear'
            onClick={() => onClose()}>
            Cancel
          </FooterButton>
        </IonFooter>
      )
    } else {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => setEdited(true)}>
            Edit Description
          </FooterButton>
          <FooterButton
            fill='clear'
            onClick={() => onClose()}>
            Close
          </FooterButton>
        </IonFooter>
      )
    }
  }

  function renderDescription (): JSX.Element | undefined {
    if (!edited) {
      return (
        <div style={{ paddingTop: 20 }}>
          <IonText
            color='medium'
            style={titleStyle}>
            MEETING DESCRIPTION
          </IonText>
          <IonItem
            lines='none'
            style={{ marginTop: 5 }}>
            <IonLabel>
              <IonText style={{ whiteSpace: 'pre-wrap' }}>
                <Linkify>
                  {description}
                </Linkify>
              </IonText>
            </IonLabel>
          </IonItem>
        </div>

      )
    }
  }

  function renderInput (): JSX.Element | undefined {
    if (edited) {
      return (
        <TextAreaInput
          title='meeting description'
          placeholder='Tap here to enter meeting description'
          value={description}
          onChange={(value) => {
            if (value) {
            // const formatted = value.replace(/[^a-z0-9-_]/gi, '').toLowerCase()

              setDescription(value)
            } else {
              setDescription('')
            }

            setEdited(true)
          }} />
      )
    }
  }

  function renderHeader (): JSX.Element {
    return (
      <ModalHeader
        title={title} />
    )
  }

  return (
    <IonModal
      isOpen
      presentingElement={presentingElement}
      onDidDismiss={onClose}>
      {renderHeader()}
      <IonContent
        scrollX={false}>
        <div
          style={container}
          className='titleIconBox'>
          {renderDescription()}
          {renderInput()}
        </div>
      </IonContent>
      {renderFooter()}
    </IonModal>
  )
}

export default MeetingDescription
