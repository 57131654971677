import React from 'react'

import 'screens/Settings.css'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import MeetingActionsModal from './MeetingActionsModal'
import TimeSlotsProvider from 'context/TimeSlotsContext/TimeSlotsContext'

interface ContainerProps {
  meetingId: string;
  onEdit: (meetingId: string) => void;
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const MeetingActionsModalScreen: React.FC<ContainerProps> = ({ meetingId, onEdit, onClose, presentingElement }) => {
  console.log('MeetingActionsModal for meetingId: ', meetingId)

  return (
    <MeetingsProvider meetingId={meetingId}>
      <ParticipantsProvider meetingId={meetingId}>
        <TimeSlotsProvider meetingId={meetingId}>
          <MeetingActionsModal
            onEdit={onEdit}
            onClose={onClose}
            presentingElement={presentingElement} />
        </TimeSlotsProvider>
      </ParticipantsProvider>
    </MeetingsProvider>
  )
}

export default MeetingActionsModalScreen
