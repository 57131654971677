export const ADD_ACCOUNT = 'ADD_ACCOUNT'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT'

export interface Account {
  id: string;
  deviceId: string;
  verifyToken?: string;
  accessToken?: string;
  displayName?: string;
  photo?: string;
  handle?: string;
}

interface AddAccountAction {
  type: typeof ADD_ACCOUNT;
  account: Account;
}

interface UpdateAccountAction {
  type: typeof UPDATE_ACCOUNT;
  account: Account;
}

interface RemoveAccountAction {
  type: typeof REMOVE_ACCOUNT;
  account: Account;
}

export type AccountActions = AddAccountAction | UpdateAccountAction | RemoveAccountAction
