import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { chevronBackOutline } from 'ionicons/icons'

export interface ScreenBackButtonProps {
  onClick?: () => void;
  goBackTo?: string;
}

const ScreenBackButton: React.FC<ScreenBackButtonProps> = ({ onClick, goBackTo }) => {
  if (goBackTo) {
    return (
      <IonButton
        data-ci-id='back-to-button'
        fill='clear'
        routerLink={goBackTo}
        routerDirection='back'
        onClick={onClick}>
        <IonIcon
          slot='icon-only'
          icon={chevronBackOutline}
          color='dark' />
      </IonButton>
    )
  }

  return (
    <IonButton
      data-ci-id='back-button'
      fill='clear'
      routerDirection='back'
      onClick={onClick}>
      <IonIcon
        slot='icon-only'
        icon={chevronBackOutline}
        color='dark' />
    </IonButton>
  )
}

export default ScreenBackButton
