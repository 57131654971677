import React, { useContext } from 'react'
import calendarConfig from './config'

export interface CalendarViewContextValue {
  timeLabelWidth: number
  timeSlotHeight: number
  timeSlotWidth: number
  dayLabelHeight: number
  calendarTopPadding: number
  calendarBottomPadding: number
  allDayEventsBoxHeight: number
  scrollWindow: number
  infiniteScroll: boolean
  defaultDuration: number
}

export const CalendarViewContext = React.createContext<Partial<CalendarViewContextValue>>(calendarConfig)

export function useCalendarView (): Partial<CalendarViewContextValue> {
  return useContext(CalendarViewContext)
}

export type CalendarViewMockContextValue = Partial<CalendarViewContextValue>

type MockProps = {
  value?: Partial<CalendarViewContextValue>
}

export const CalendarViewMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <CalendarViewContext.Provider
      value={{
        ...calendarConfig,
        ...value,
      }}>
      {children}
    </CalendarViewContext.Provider>
  )
}
