import React, { CSSProperties } from 'react'
import {
  IonText,
} from '@ionic/react'

import 'screens/Settings.css'
import { Avatar } from '@material-ui/core'

const avatarStyle = {
  width: 44,
  height: 44,
  marginTop: 10,
  marginBottom: 10,
  backgroundColor: '#92949C',
}

const txtStyle = {
  fontSize: 16,
}

export interface ProfilePhotoProps {
  style?: CSSProperties,
  fontSize?: number;
  initials?: string;
  photo?: string;
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ style, fontSize, initials, photo }) => {
  return (
    <Avatar
      slot='start'
      src={photo}
      style={{ ...avatarStyle, ...style }}>
      <IonText style={{ ...txtStyle, fontSize }}>
        {initials}
      </IonText>
    </Avatar>
  )
}

export default ProfilePhoto
