import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
  IonActionSheet, IonSpinner, IonButtons, IonButton, IonIcon, IonToast, IonFooter,
} from '@ionic/react'

import { addCircleOutline, removeCircleOutline, chevronBackOutline, linkOutline } from 'ionicons/icons'

import { UserContact } from 'types'
import ProfilePhoto from 'components/atoms/ProfilePhoto/ProfilePhoto'
import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import { useProfilePhoto } from 'context/ProfilePhotoContext/ProfilePhotoContext'
import ListItem from 'components/atoms/ListItem/ListItem'
import { useUser } from 'context/UserContext/UserContext'
import { useContacts } from 'context/ContactsContext/ContactsContext'
import { useTokens } from 'context/TokensContext/TokensContext'
import ChangeIDModal from 'components/organisms/ChangeIDModal/ChangeIDModal'
import { copyToClipboard } from 'components/molecules/ShareOptions/utils'
import { AuthTypes, useRoutes } from 'navigation/RouteContext'
import { AccountScreenTips } from 'types/componentTips'
import { useTips } from 'context/TipsContext/TipsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import ChangeUserNameModal from 'components/organisms/ChangeUserNameModal/ChangeUserNameModal'
import { EventData, EventName, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'
// import { AccountScreen as StoreTips } from 'services/store/tips/types'

const title = 'Profile'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const photoStyle = {
  width: 80,
  height: 80,
}

const photoBox: CSSProperties = {
  padding: 20,
  paddingBottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

interface ComponentProps {
  showTip?: string;
  setShowTip: (tip: string) => void;
  onAddContact: (url: string) => void;
  onDeleteUser: () => void
  goBack: () => void;
}

const appUrl = process.env.REACT_APP_MEETINGS_APP

const Account: React.FC<ComponentProps> = (
  {
    setShowTip,
    onAddContact, goBack, onDeleteUser,
  }) => {
  const [logingOut, setLogingOut] = useState(false)
  const [showChangeIDModal, setShowChangeIDModal] = useState(false)
  const [showChangeNameModal, setShowChangeNameModal] = useState(false)
  const [showDeleteAction, setShowDeleteActions] = useState(false)
  const [showLogoutAction, setShowLogoutActions] = useState(false)
  const [showContactActions, setShowContactActions] = useState<UserContact>()
  const [deletingContact, setDeletingContact] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [showScreenTip, setShowScreenTip] = useState(AccountScreenTips.noTip)
  const [minTip, setMinTip] = useState(false)
  const [disableMinTip, setDisableMinTip] = useState(false)

  const { user, logoutUser, deleteUser } = useUser()
  const profilePhoto = useProfilePhoto()
  const { contacts, deleteContact } = useContacts()
  const { getVerifyToken } = useTokens()
  const { setAuthenticated } = useRoutes()

  const { storeTips } = useTips()
  const { logEvent } = useAnalytics()

  const componentStoreTips = storeTips?.accountScreen

  const pageRef = useRef()
  const contentRef = useRef<HTMLIonContentElement>(null)

  useEffect(() => {
    startTips()
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'Account' },
    })
  }, [])

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'Account',
      },
    })
  }

  function startTips(): void {
    setTimeout(() => {
      if (storeTips?.startMinTip || componentStoreTips?.endTips) {
        setNextTip(AccountScreenTips.noTip)
      } else {
        setNextTip(AccountScreenTips.sequence)
      }
    }, 1000)
  }

  function setNextTip(tip: AccountScreenTips, restartTips?: boolean): void {
    // console.log('Meeting screen setNextTip: ', tip)

    switch (tip) {
      // eslint-disable-next-line no-fallthrough
      case AccountScreenTips.sequence:
      case AccountScreenTips.addContact:
        if (restartTips || !componentStoreTips?.addContact) {
          setShowScreenTip(AccountScreenTips.addContact)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case AccountScreenTips.editId:
        if (restartTips || !componentStoreTips?.editId) {
          setShowScreenTip(AccountScreenTips.editId)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case AccountScreenTips.endTips:
        if (!componentStoreTips?.endTips) {
          setShowScreenTip(AccountScreenTips.endTips)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case AccountScreenTips.noTip:
      default:
        // in the modal case reset the tips back to sequence
        setShowScreenTip(AccountScreenTips.endTips)
        setMinTip(true)
        break
    }
  }

  // function onScroll (): void {
  //   const el = document.getElementById('screen')

  //   if (el) {
  //     const { scrollTop, scrollHeight, clientHeight } = el

  //     // console.log('scroll: ', scrollTop, scrollHeight, clientHeight)

  //     const bottomMarker = scrollHeight - (scrollTop + clientHeight)

  //     // console.log('bottomMarker: ', bottomMarker)

  //     if (bottomMarker < 150) {
  //       !disableMinTip && setDisableMinTip(true)
  //     } else {
  //       disableMinTip && setDisableMinTip(false)
  //     }
  //   }
  // }
  async function onScroll(): Promise<void> {
    if (contentRef.current) {
      const el = await contentRef.current.getScrollElement()
      const { scrollTop, scrollHeight, clientHeight } = el

      // console.log('scroll: ', scrollTop, scrollHeight, clientHeight)

      const bottomMarker = scrollHeight - (scrollTop + clientHeight)

      // console.log('bottomMarker: ', bottomMarker)

      if (bottomMarker < 150) {
        !disableMinTip && setDisableMinTip(true)
      } else {
        disableMinTip && setDisableMinTip(false)
      }
    }
  }

  // function updateTip (tip: StoreTips): void {
  //   if (updateStoreTips) {
  //     updateStoreTips({
  //       accountScreen: {
  //         ...storeTips?.accountScreen, ...tip,
  //       },
  //     })
  //   }
  // }

  async function addContact(contactType: string): Promise<void> {
    if (getVerifyToken) {
      const verifyToken = await getVerifyToken()

      if (verifyToken) {
        const url = `/addContact?contactType=${contactType}&verifyToken=${verifyToken}`

        onAddContact(url)
      }
    }
  }

  async function onDeleteContact(contact: string): Promise<void> {
    console.log('deleteContact: ', contact)

    if (contact) {
      setDeletingContact(contact)
      await deleteContact(contact)
      setTimeout(() => {
        setDeletingContact('')
      }, 1000)
    }
  }

  async function _deleteUser(): Promise<void> {
    if (deleteUser) {
      setLogingOut(true)
      setShowDeleteActions(false)
      await deleteUser()
      setAuthenticated && setAuthenticated(AuthTypes.notAuthenticated)
      onDeleteUser()
    }
  }
  async function onLogoutUser(): Promise<void> {
    if (logoutUser) {
      setLogingOut(true)
      setShowDeleteActions(false)
      await logoutUser()
      setAuthenticated && setAuthenticated(AuthTypes.notAuthenticated)
      onDeleteUser()
    }
  }
  // function renderLogout (): JSX.Element | undefined {
  //   if (contacts?.length) {
  //     return (
  //       <div
  //         style={{ padding: 20, paddingBottom: 10 }}
  //         className='titleIconBox'>
  //         <IonButton
  //           data-ci-id='delete-account'
  //           shape='round'
  //           expand='block'
  //           fill='solid'
  //           color='primary'
  //           onClick={() => {
  //             setShowLogoutActions(true)
  //           }}>
  //           Logout
  //         </IonButton>
  //       </div>
  //     )
  //   }
  // }
  function renderDelete(): JSX.Element {
    return (
      <div
        style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}
        className='titleIconBox'>
        <IonButton
          data-ci-id='delete-account'
          shape='round'
          expand='block'
          fill='outline'
          color='danger'
          onClick={() => {
            setShowDeleteActions(true)
          }}>
          Delete Account
        </IonButton>
      </div>
    )
  }

  function renderEmails(): JSX.Element | undefined {
    const emails = contacts?.filter(contact => contact.email && !contact.provider) || []

    // console.log('EMails: ', contacts)

    return (
      <div style={{ marginTop: 20 }}>
        {emails.map((contact, i) => {
          const deleting = deletingContact === contact.id

          return (
            <ListItem
              title={i === 0 ? 'emails' : ''}
              key={i}
              lines='inset'
              label={contact.email}
              icon={{ name: removeCircleOutline, slot: 'end', color: 'medium', activity: deleting }}
              onClick={() => setShowContactActions(contact)} />
          )
        })}
        <ListItem
          title={!emails.length ? 'emails' : ''}
          label='add email'
          tip={!minTip && showScreenTip === AccountScreenTips.addContact}
          icon={{ name: addCircleOutline, slot: 'end', color: 'success' }}
          onClick={() => {
            logTap({ button: 'AddEmail' })
            addContact('email')
          }} />

      </div>
    )
  }

  function renderPhones(): JSX.Element | undefined {
    const phones = contacts?.filter(contact => contact.phone && !contact.provider) || []

    // console.log('EMails: ', contacts)

    return (
      <div style={{ marginTop: 20 }}>
        {phones.map((contact, i) => {
          const deleting = deletingContact === contact.id

          return (
            <ListItem
              title={i === 0 ? 'phones' : ''}
              key={i}
              lines='inset'
              label={contact.phone}
              icon={{ name: removeCircleOutline, slot: 'end', color: 'medium', activity: deleting }}
              onClick={() => setShowContactActions(contact)} />
          )
        })}
        <ListItem
          title={!phones.length ? 'phones' : ''}
          label='add phone'
          icon={{ name: addCircleOutline, slot: 'end', color: 'success' }}
          onClick={() => {
            logTap({ button: 'AddPhone' })
            addContact('phone')
          }} />
      </div>
    )
  }

  // function renderLinkedAccounts (): JSX.Element | undefined {
  //   const linked = contacts?.filter(contact => contact.email && contact.provider) || []

  //   // console.log('EMails: ', contacts)

  //   return (
  //     <div style={{ marginTop: 20 }}>
  //       {linked.map((contact, i) => {
  //         const deleting = deletingContact === contact.id

  //         return (
  //           <ListItem
  //             title={i === 0 ? 'signin with' : ''}
  //             key={i}
  //             lines='inset'
  //             label={contact.email}
  //             subLabel={`@${contact.provider}`}
  //             icon={{ name: removeCircleOutline, slot: 'end', color: 'medium', activity: deleting }}
  //             onClick={() => setShowContactActions(contact)} />
  //         )
  //       })}
  //       <ListItem
  //         title={!linked.length ? 'sign-in with' : ''}
  //         label='add sign-in'
  //         icon={{ name: addCircleOutline, slot: 'end', color: 'success' }}
  //         onClick={() => addContact('google')} />
  //     </div>
  //   )
  // }

  // function renderLogoutButton (): JSX.Element | undefined {
  //   if (contacts?.length) {
  //     return (
  //       <IonButtons slot='end'>
  //         <IonButton
  //           fill='clear'
  //           color='primary'
  //           onClick={() => setShowLogoutActions(true)}>
  //           <IonIcon
  //             icon={logOutOutline}
  //             color='primary'
  //             slot='start' />
  //           Logout
  //         </IonButton>
  //       </IonButtons>
  //     )
  //   }
  // }

  function renderFooter(): JSX.Element | undefined {
    if (contacts?.length) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ button: 'Logout' })
              setShowLogoutActions(true)
            }}>
            Logout
          </FooterButton>
        </IonFooter>
      )
    }
  }

  // function renderScreenTips (): JSX.Element | undefined {
  //   return (
  //     <ScreenTips
  //       minTip={minTip}
  //       disableMinTip={disableMinTip}
  //       showScreenTip={showScreenTip}
  //       onClose={() => {
  //         setMinTip(true)
  //       }}
  //       onMinTip={() => {
  //         setMinTip(false)

  //         // if we've not shown endTips then go ahead and start the sequence immediately
  //         if (!componentStoreTips?.endTips) {
  //           setNextTip(AccountScreenTips.sequence)
  //         }
  //       }}
  //       onButton={(restartTips) => {
  //         if (showScreenTip === AccountScreenTips.endTips) {
  //           if (restartTips) {
  //             console.log('Restart TIPS!')
  //             setNextTip(AccountScreenTips.sequence, true)
  //           } else {
  //             setNextTip(AccountScreenTips.noTip)
  //             setMinTip(true)
  //           }
  //         } else {
  //           setNextTip(showScreenTip + 1)
  //         }
  //       }} />
  //   )
  // }

  if (logingOut) {
    return (
      <ScreenCenter height='80%'>
        <img
          src='assets/icon/MeetingTimeIcon.png'
          width='60'
          height='60'
          alt='' />
        <IonSpinner
          name='dots'
          color='medium' />
      </ScreenCenter>
    )
  }

  function renderModals(): JSX.Element | undefined {
    if (showChangeIDModal) {
      return (
        <ChangeIDModal
          presentingElement={pageRef.current}
          onClose={() => setShowChangeIDModal(false)} />
      )
    }

    if (showChangeNameModal) {
      return (
        <ChangeUserNameModal
          presentingElement={pageRef.current}
          onClose={() => setShowChangeNameModal(false)} />
      )
    }
  }

  const meetMe = (user?.handle ? `/meet/${user.handle}` : '')

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              fill='clear'
              routerDirection='back'
              onClick={() => {
                setShowTip('')
                goBack()
              }}>
              <IonIcon
                slot='icon-only'
                icon={chevronBackOutline}
                color='dark' />
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          {/* {renderLogoutButton()} */}
        </IonToolbar>
      </IonHeader>
      <IonContent
        ref={contentRef}
        scrollEvents
        onIonScroll={onScroll}>
        <div
          style={container}
          className='titleIconBox'>
          <div
            style={photoBox}
            className='titleIconBox'>
            <ProfilePhoto
              style={photoStyle}
              fontSize={32}
              initials={profilePhoto.initials}
              photo={profilePhoto.photo} />
          </div>
          {user?.displayName &&
            <ListItem
              testId='display-name'
              title='display name'
              label={user.displayName}
              onClick={() => {
                logTap({ button: 'EditDisplayName' })
                setShowChangeNameModal(true)
              }} />}

          {user?.handle &&
            <ListItem
              testId='userHandle-id'
              title='Handle'
              label={user.handle}
              endLabel='edit'
              tip={!minTip && showScreenTip === AccountScreenTips.editId}
              onClick={() => {
                logTap({ button: 'Edithandle' })
                setShowChangeIDModal(true)
              }} />}

          {user?.handle &&
            <ListItem
              testId='userHandle-id'
              title='personal MeetingTime link'
              label={meetMe}
              icon={{ name: linkOutline, slot: 'end', color: 'medium' }}
              onClick={() => {
                logTap({ button: 'CopyMeetLink' })
                copyToClipboard(appUrl + meetMe)
                setShowToast(true)
              }} />}

          {renderEmails()}
          {renderPhones()}
          {/* {renderLinkedAccounts()} */}
          {/* {renderLogout()} */}
          {renderDelete()}
        </div>
        {renderModals()}
      </IonContent>
      {renderFooter()}
      {/* {renderScreenTips()} */}
      {/* {renderModals()} */}
      <IonActionSheet
        id='delete-action'
        isOpen={showDeleteAction}
        onDidDismiss={() => setShowDeleteActions(false)}
        buttons={[{
          text: 'Confirm delete account?',
          role: 'destructive',
          handler: () => {
            logTap({ button: 'DeleteAccount' })
            _deleteUser()
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          },
        }]} />
      <IonActionSheet
        isOpen={showLogoutAction}
        onDidDismiss={() => setShowLogoutActions(false)}
        buttons={[{
          text: 'Logout?',
          handler: () => {
            logTap({ button: 'Logout' })
            onLogoutUser()
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          },
        }]} />
      <IonActionSheet
        isOpen={!!showContactActions}
        onDidDismiss={() => setShowContactActions(undefined)}
        buttons={[{
          text: 'Remove ' + (showContactActions ? (showContactActions.email || showContactActions.phone) : 'Contact'),
          role: 'destructive',
          handler: () => {
            logTap({ button: 'DeleteContact' + showContactActions?.email ? 'Email' : showContactActions?.phone ? 'Phone' : undefined })
            showContactActions && onDeleteContact(showContactActions.id)
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          },
        }]} />
      <IonToast
        isOpen={showToast}
        color='warning'
        onDidDismiss={() => setShowToast(false)}
        message='Meet Me link copied to clipboard'
        duration={2000} />
    </IonPage>
  )
}

export default Account
