import React, { useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { GET_MEETING_INVITE, UpdateInvitationInput, UPDATE_INVITATION } from 'services/api'
import { MeetingInvite } from 'types'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

interface MeetingInviteContextValue {
  loading: boolean
  meetingInvite?: MeetingInvite
  updateInvitation: (input: UpdateInvitationInput) => Promise<string | undefined>
  error?: string,
}

const initialValue: MeetingInviteContextValue = {
  loading: false,
  updateInvitation: async (input: UpdateInvitationInput) => {
    console.log('updateInvitation: ', input)

    return undefined
  },
}

// create and initialize context
export const MeetingInviteContext = React.createContext<MeetingInviteContextValue>(initialValue)

export function useMeetingInvite(): MeetingInviteContextValue {
  return useContext(MeetingInviteContext)
}

export type MeetingInviteMockContextValue = Partial<MeetingInviteContextValue>

type MockProps = {
  value?: Partial<MeetingInviteContextValue>
}

export const MeetingInviteMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <MeetingInviteContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </MeetingInviteContext.Provider>
  )
}

interface ProviderProps {
  invitationId?: string,
}

const MeetingInviteProvider: React.FC<ProviderProps> = ({ invitationId, children }) => {
  const { logEvent } = useAnalytics()

  const { loading, data, error } = useQuery(GET_MEETING_INVITE, {
    variables: { invitationId },
    fetchPolicy: 'cache-and-network',
  })

  const [updateInvitationMutation] = useMutation(UPDATE_INVITATION)

  async function updateInvitation(input: UpdateInvitationInput): Promise<string | undefined> {
    const { data } = await updateInvitationMutation({ variables: { input } })

    if (data?.updateInvitation?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'updateInvitation',
          invitation: input.id,
          status: input.status,
        },
      })

      return data?.updateInvitation
    }
  }

  return (
    <MeetingInviteContext.Provider
      value={{
        loading,
        meetingInvite: data?.meetingInvite,
        updateInvitation,
        error: error?.message,
      }}>
      {children}
    </MeetingInviteContext.Provider>
  )
}

export default MeetingInviteProvider
