import React, { CSSProperties } from 'react'
import Spinner from 'react-spinkit'
import { logoColor } from 'theme/styles'

import { useTips } from 'context/TipsContext/TipsContext'

const tipColor = logoColor
// const tipColor = '#3880ff'

const container: CSSProperties = {
  display: 'inline-block',
  bottom: 5,
  marginLeft: 20,
}

export interface ContainerProps {
  style?: CSSProperties;
}

const TipTarget: React.FC<ContainerProps> = ({ style }) => {
  const { storeTips } = useTips()

  if (storeTips?.enabled) {
    return (
      <Spinner
        style={{ ...container, ...style }}
        name='ball-scale-multiple'
        color={tipColor} />
    )
  }

  return <div />
}

export default TipTarget
