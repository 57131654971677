import React, { CSSProperties, useState } from 'react'
import moment from 'moment'
import { PickerColumn } from '@ionic/core'
import { IonPicker } from '@ionic/react'
import { stringToMinutes } from 'services/time'
import ListItem from 'components/atoms/ListItem/ListItem'
import { mdiUnfoldMoreHorizontal } from '@mdi/js'

const container: CSSProperties = {
  marginTop: 10,
  maxWidth: '100%',
}

interface ContainerProps {
  duration: string;
  tip?: boolean;
  onOpen: () => void;
  onChange: (value: string) => void;
  onCancel: () => void;
}

const SelectMeetingDuration: React.FC<ContainerProps> = ({ duration: meetingDuration, tip, onOpen, onCancel, onChange }) => {
  const [duration, setDuration] = useState(stringToMinutes(meetingDuration))
  const [showPicker, setShowPicker] = useState(false)

  const minutes = Number(duration)
  const time = moment.utc().startOf('day').add(minutes, 'minutes')
  const hr = minutes >= 60 ? `${time.format('H')}hr` : ''
  const min = minutes % 60 > 0 ? `${time.format('mm')}min` : ''
  const label = `${hr} ${min}`

  const options = Array(...Array(24)).map((v, i) => {
    return {
      text: `${i}hr`,
      value: i,
    }
  })
  const HoursColumn: PickerColumn = {
    name: 'hours',
    options,
    selectedIndex: Math.floor(minutes / 60),
  }

  const MinutesColumn: PickerColumn = {
    name: 'minutes',
    options: [
      { text: '-', value: 0 },
      { text: '15min', value: 15 },
      { text: '30min', value: 30 },
      { text: '45min', value: 45 },
    ],
    selectedIndex: Math.floor((minutes % 60) / 15),
  }

  return (
    <div style={container}>
      <ListItem
        testId='meeting-duration'
        title='meeting duration'
        tip={tip}
        label={label}
        icon={{
          type: 'mdi',
          name: mdiUnfoldMoreHorizontal,
          color: '#c8c8c8',
          slot: 'end',
        }}
        onClick={() => {
          onOpen()
          setShowPicker(!showPicker)
        }} />
      {/* <IonText
        style={titleStyle}
        color='medium'>
        DURATION
      </IonText>
      <IonItem
        lines='full'
        onClick={() => setShowPicker(!showPicker)}
        style={{ marginTop: 5, overflow: 'visible', border: tip ? '1px solid #3880ff' : '' }}>
        <IonLabel
          data-ci-id='meeting-duration'
          style={labelStyle}>
          {label}
          {tip &&
            <TipTarget />}
        </IonLabel>

      </IonItem> */}
      {/*
        Note: data-ci-id does not get applied to IonPicker.
        Does not seem to accept props outside ones defined in interface
        */}
      {showPicker &&
        <IonPicker
          id='duration-picker'
          data-ci-id='duration-picker'
          isOpen={showPicker}
          columns={[HoursColumn, MinutesColumn]}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                setShowPicker(false)
                onCancel()
              },
            },
            {
              text: 'Confirm',
              handler: (value) => {
                console.log('Valued picked: ', value)

                const { hours, minutes } = value
                const mins = (hours.value * 60 + minutes.value).toString()

                setDuration(mins)
                setShowPicker(false)
                onChange(mins)
              },
            },
          ]} />}
    </div>

  )
}

export default SelectMeetingDuration
