import React from 'react'

export interface ScreenCenterProps {
  height?: string;
}

const ScreenCenter: React.FC<ScreenCenterProps> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: props.height ? props.height : '100%',
      }}>
      {props.children}
    </div>
  )
}

export default ScreenCenter
