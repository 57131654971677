import React from 'react'
import { IonToolbar, IonTitle, IonButton, IonHeader, IonButtons, IonSpinner } from '@ionic/react'

export interface ModalHeaderProps {
  title: string;
  onClose?: () => void;
  showActivity?: boolean;
  doneButton?: string;
  cancel?: boolean;
  onDone?: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, onClose, showActivity, cancel, doneButton, onDone }) => {
  if (doneButton && onDone) {
    return (
      <IonHeader>
        <IonToolbar>
          {cancel !== false &&
            <IonButtons slot='start'>
              <IonButton
                data-ci-id='modal-close'
                onClick={onClose}
                color='primary'>Cancel
              </IonButton>
            </IonButtons>}
          <IonTitle>{title}</IonTitle>
          <IonButtons slot='end'>
            {!showActivity &&
              <IonButton
                data-ci-id='modal-done'
                onClick={onDone}
                color='primary'>
                {doneButton}
              </IonButton>}
            {showActivity &&
              <IonButton
                color='primary'>
                <IonSpinner name='dots' />
              </IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    )
  }

  if (cancel) {
    return (
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              data-ci-id='modal-close'
              onClick={onClose}
              color='primary'>Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
    )
  }

  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>{title}</IonTitle>
        {onClose &&
          <IonButtons slot='end'>
            <IonButton
              data-ci-id='modal-close'
              fill='clear'
              onClick={onClose}
              color='primary'>
              Close
            </IonButton>
          </IonButtons>}
      </IonToolbar>
    </IonHeader>
  )
}

export default ModalHeader
