import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'

import { updateScreens } from 'services/store/screens/actions'
import ContactsProvider from 'context/ContactsContext/ContactsContext'
import AddContactFirebase from './AddContactFirebase'
import paths from 'navigation/paths'

const appUrl = process.env.REACT_APP_MEETINGS_APP

const AddContactsScreen: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [contactType, setContactType] = useState('')
  const [verifyToken, setVerifyToken] = useState('')
  const [inCallback, setInCallback] = useState(false)
  const [callbackUrl, setCallbackUrl] = useState('')
  const [newUser, setNewUser] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    if (location.search) {
      console.log('Login: url: ', location.search)

      const params = queryString.parse(location.search, { parseBooleans: true })

      const token = params.verifyToken as string
      // const browserType = params.browser as string || browserName()
      const contact = params.contactType as string

      const newUserType = params.newUser as string

      setInCallback(params.callbackUrl as boolean || false)

      let url

      if (token) {
        setVerifyToken(token)
        url = `${appUrl}${location.pathname}?callbackUrl=true&verifyToken=${token}`
      }

      if (newUserType) {
        setNewUser(newUserType)
        url += `&newUser=${newUserType}`
      }

      if (contact) {
        setContactType(contact)
        url += `&contactType=${contact}`
      }

      if (url) {
        setCallbackUrl(url)
      }
    }
  }, [location.search])

  function onBack (): void {
    localStorage.removeItem('verifyToken')
    history.goBack()
  }

  function onNext (): void {
    localStorage.removeItem('verifyToken')

    if (newUser) {
      // if (newUser === 'initiator') {
      //   history.replace(paths.startFirstMeeting)
      // } else if (newUser === 'invitee') {
      //   history.replace(paths.meetingsList)
      // }

      history.replace(paths.meetingsList)

      dispatch(updateScreens({
        allScreens: {
          newUser: false,
          newInitiator: false,
          newInvitee: false,
        },
      }))
    }
  }

  return (
    <ContactsProvider>
      <AddContactFirebase
        contactType={contactType}
        verifyToken={verifyToken}
        callbackUrl={callbackUrl}
        inCallback={inCallback}
        newUser={newUser}
        onNext={newUser ? onNext : undefined}
        onBack={onBack} />
    </ContactsProvider>
  )
}
export default AddContactsScreen
