import React, { CSSProperties } from 'react'
import moment from 'moment'
import Spinner from 'react-spinkit'
import { IonItem, IonLabel, IonIcon, IonChip, IonBadge, IonText } from '@ionic/react'
import { MeetingTime } from 'types'
import { trendingUp, checkmarkCircleOutline, checkmarkCircle } from 'ionicons/icons'
import TipTarget from 'components/atoms/TipTarget/TipTarget'

export type ItemIcon = {
  name: string;
  color: string;
  slot: string;
  activity?: boolean;
}

const iconStyle: CSSProperties = {
  marginRight: 20,
}

export interface MeetingTimeListItemProps {
  meetingTime: MeetingTime;
  active?: boolean;
  tip?: boolean;
  name?: string;
  lines?: 'full' | 'inset' | 'none'
  onSelect?: () => void;
  onDetails?: () => void;
}

const MeetingTimeListItem: React.FC<MeetingTimeListItemProps> = (
  { meetingTime, active, name, lines, tip, onSelect, onDetails }) => {
  const { startTime, endTime, participantUserIds, status } = meetingTime
  const count = participantUserIds ? participantUserIds.length : 0
  const day = moment(startTime).format('ddd, MMM D')
  const start = moment(startTime).format('h:mm a')
  const end = moment(endTime).format('h:mm a')

  // Note: on android the list has a small padding at the bottom
  // and since we are using a slight shaded background for the select button
  // get rid of the bottom padding
  const tentative = status === 'tentative'
  const confirmed = status === 'confirmed'
  const trending = status === 'trending'
  let happeningNow = false

  if (confirmed) {
    const start = moment(startTime).valueOf()
    const end = moment(endTime).valueOf()
    const current = moment().valueOf()

    if (start <= current && end > current) {
      happeningNow = true
    }
  }

  let countLabel = name || count.toString()

  if (active) {
    if (name) {
      countLabel = `You + ${name}`
    } else if (count > 1) {
      countLabel = `You + ${count - 1}`
    } else {
      countLabel = 'You'
    }
  }

  function renderTrending(): JSX.Element | undefined {
    if (trending) {
      return (
        <IonIcon
          icon={trendingUp}
          style={iconStyle}
          color={active ? 'secondary' : 'medium'}
          slot='start'
          onClick={(e) => {
            e.stopPropagation()
            onSelect && onSelect()
          }} />
      )
    }
  }

  function renderTentative(): JSX.Element | undefined {
    if (tentative) {
      return (
        <IonIcon
          icon={trendingUp}
          style={iconStyle}
          color={active ? 'warning' : 'medium'}
          slot='start'
          onClick={(e) => {
            e.stopPropagation()
            onSelect && onSelect()
          }} />
      )
    }
  }

  function renderBeacon(): JSX.Element | undefined {
    if (happeningNow) {
      return (
        <IonText
          slot='start'
          style={iconStyle}>
          <Spinner
            name='double-bounce'
            color='red' />
        </IonText>
      )
    }
  }

  function renderConfirmed(): JSX.Element | undefined {
    if (confirmed && !happeningNow) {
      return (
        <IonIcon
          icon={active ? checkmarkCircle : checkmarkCircleOutline}
          style={iconStyle}
          color={active ? 'success' : 'medium'}
          slot='start'
          onClick={(e) => {
            e.stopPropagation()
            onSelect && onSelect()
          }} />
      )
    }
  }

  function renderBadge(): JSX.Element | undefined {
    if (happeningNow) {
      return (
        <IonChip
          className='nonClickable'
          color='danger'>
          in progress
        </IonChip>
      )
    } else if (confirmed) {
      return (
        <IonChip
          className='nonClickable'
          color='success'>
          confirmed
        </IonChip>
      )
    } else {
      return (
        <IonBadge
          mode='ios'
          color={active ? 'primary' : 'medium'}>
          {countLabel}
        </IonBadge>
      )
    }
    // else if (tentative) {
    //   return (
    //     <IonChip
    //       className='nonClickable'
    //       color='warning'>
    //       tentative
    //     </IonChip>
    //   )
    // } else if (trending) {
    //   return (
    //     <IonChip
    //       className='nonClickable'
    //       color='secondary'>
    //       trending
    //     </IonChip>
    //   )
    // }
  }

  return (
    <IonItem
      lines={lines}
      color='transparent'
      onClick={onDetails}>
      {renderConfirmed()}
      {renderTrending()}
      {renderTentative()}
      {renderBeacon()}
      {tip &&
        <TipTarget
          style={{ position: 'absolute', top: 40, left: '42%' }} />}
      <IonLabel>
        <h2 style={{ fontWeight: 'bold' }}>{day}</h2>
        <p>
          {start} to {end}
        </p>
        {/* {!confirmed &&
          <IonBadge
            mode='ios'
            color='primary'>
            {countLabel}
          </IonBadge>} */}
      </IonLabel>
      {renderBadge()}
    </IonItem>
  )
}

export default MeetingTimeListItem
