import React from 'react'
import { IonItem, IonIcon, IonLabel, IonText } from '@ionic/react'
import { linkOutline } from 'ionicons/icons'
import { copyToClipboard } from '../ShareOptions/utils'
import TipTarget from 'components/atoms/TipTarget/TipTarget'

export interface InviteLinkProps {
  invitation: string;
  copy: string;
  tip?: boolean;
  onCopy: () => void;
}

const InviteLink: React.FC<InviteLinkProps> = ({ invitation, copy, tip, onCopy }) => {
  return (
    <IonItem
      data-ci-id='meeting-invite-link'
      lines='full'
      onClick={() => {
        copyToClipboard(copy)
        onCopy()
      }}>
      {tip &&
        <TipTarget
          style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
      <IonLabel style={{ overflow: 'visible' }}>
        Copy Link
      </IonLabel>
      <IonText
        data-ci-id='meeting-invite-id'
        color='medium'>
        {invitation}
      </IonText>
      <IonIcon
        icon={linkOutline}
        slot='end' />
    </IonItem>
  )
}

export default InviteLink
