import React, { CSSProperties, useState, useRef } from 'react'
import { IonText, IonItem, IonInput, isPlatform, IonSpinner, IonIcon } from '@ionic/react'
import TipTarget from '../TipTarget/TipTarget'

const titleStyle: CSSProperties = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

// const labelStyle: CSSProperties = {
//   fontSize: 18,
// }

const container: CSSProperties = {
  paddingTop: 20,
}

export type ItemIcon = {
  iconName: string;
  color: string;
  slot: string;
  activity?: boolean;
}

export interface ListItemInputProps {
  testId?: string
  value?: string
  placeholder?: string
  enterkeyhint?: 'next' | 'done' | 'go' | 'send' | 'search'
  inputMode?: 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
  style?: CSSProperties
  title?: string
  label?: string
  subLabel?: string
  icon?: ItemIcon
  lines?: 'full' | 'inset' | 'none'
  type?: 'number' | 'tel'
  tip?: boolean;
  capitalizeWords?: boolean;
  onFocus?: () => void;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  onDone?: (value: string) => void;
}

function capitalize(input: string): string {
  return input.split(' ').map(w => w.charAt(0) && w.charAt(0).toUpperCase() + w.slice(1, w.length)).join(' ')
}

const ListItemInput: React.FC<ListItemInputProps> = (
  { testId, value, placeholder, enterkeyhint, inputMode, style, title, icon, lines = 'full', capitalizeWords, tip, type, onFocus, onBlur, onChange, onDone }) => {
  const [inputValue, setInputValue] = useState(value)
  const [changed, setChanged] = useState(false)
  const ref = useRef<HTMLIonInputElement | null>(null)

  function renderIcon(): JSX.Element | undefined {
    if (icon) {
      const { iconName, color, slot, activity = false } = icon

      if (activity) {
        return (
          <IonSpinner
            slot={slot}
            color='medium'
            name='dots' />
        )
      }

      return (
        <IonIcon
          slot={slot}
          color={color}
          icon={iconName} />
      )
    }
  }

  const boxStyle = style || (title ? container : {})
  const itemStyle: CSSProperties = title ? { marginTop: 5 } : {}

  if (tip) {
    itemStyle.overflow = 'visible'
    itemStyle.border = '2px solid #3880ff'
  }

  return (
    <div style={boxStyle}>
      {title &&
        <ItemTitle title={title} />}
      <IonItem
        lines={lines}
        style={itemStyle}>
        {tip && (!changed || !inputValue?.length) &&
          <TipTarget
            style={{ position: 'absolute', bottom: 22, left: '42%' }} />}
        <IonInput
          {...(testId && { 'data-ci-id': testId })}
          mode='md'
          type={type}
          ref={ref}
          placeholder={placeholder}
          autocapitalize='entered'
          value={inputValue}
          enterkeyhint={enterkeyhint || 'next'}
          inputMode={inputMode || 'text'}
          clearInput
          onKeyUp={e => {
            if (e.key.toLowerCase() === 'enter' || e.key.toLowerCase() === 'done') {
              if (ref?.current) {
                console.log('blurring the input')
                ref.current.getInputElement().then((input) => input.blur())
              }

              onDone && onDone(inputValue || '')
            }
          }}
          onIonBlur={() => {
            console.log('On blur')
            onBlur && onBlur(inputValue || '')
          }}
          onIonFocus={() => {
            console.log('On focus')
            onFocus && onFocus()
          }}
          onIonChange={e => {
            // setShowTip(false)
            const newValue = capitalizeWords ? capitalize(e.detail.value || '') : e.detail.value || ''

            setInputValue(newValue)
            setChanged(true)

            if (onChange) {
              onChange(newValue)
            }
          }} />

        {renderIcon()}
      </IonItem>
    </div>
  )
}

interface TitleProps {
  title?: string;
}

export const ItemTitle: React.FC<TitleProps> = ({ title }) => {
  return (
    <IonText
      style={titleStyle}
      color='medium'>
      {title?.toUpperCase()}
    </IonText>
  )
}
export default ListItemInput
