import React, { useContext } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'services/store'

import { StoreParticipants } from 'services/store/participants/types'
import { updateParticipants } from 'services/store/participants/actions'

interface StoreParticipantsContextValue {
  storeParticipants: StoreParticipants,
  updateStoreParticipants: (participants: StoreParticipants) => Promise<void>
}

const initialValue: StoreParticipantsContextValue = {
  storeParticipants: {},
  updateStoreParticipants: async (participants: StoreParticipants) => {
    console.log('updateStoreParticipants: ', participants)

    return undefined
  },
}

// create and initialize context
export const ParticipantsContext = React.createContext<StoreParticipantsContextValue>(initialValue)

export function useStoreParticipants (): StoreParticipantsContextValue {
  return useContext(ParticipantsContext)
}

const StoreParticipantsProvider: React.FC = ({ children }) => {
  const storeParticipants = useSelector((state: RootState) => state.participants)
  const dispatch = useDispatch()

  async function updateStoreParticipants (participants: StoreParticipants): Promise<void> {
    dispatch(updateParticipants(participants))
  }

  return (
    <ParticipantsContext.Provider
      value={{
        storeParticipants,
        updateStoreParticipants,
      }}>
      {children}
    </ParticipantsContext.Provider>
  )
}

export default StoreParticipantsProvider
