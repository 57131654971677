import React, { CSSProperties, useState } from 'react'
import {
  IonActionSheet,
  IonContent, IonItem, IonLabel, IonModal, IonSpinner, IonText, isPlatform,
} from '@ionic/react'

import 'screens/Settings.css'
import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'

const messageBox: CSSProperties = {
  paddingTop: 20,
  paddingLeft: isPlatform('ios') ? 20 : 16,
  paddingRight: 20,
  paddingBottom: 10,
}

const messageStyle = {
  fontSize: 14,
}

export interface MeetingActionsModalProps {
  onEdit: (meetingId: string) => void;
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const MeetingActionsModal: React.FC<MeetingActionsModalProps> = ({ onEdit, onClose, presentingElement }) => {
  const [showDeleteAction, setShowDeleteActions] = useState(false)
  const [showExitActions, setShowExitActions] = useState(false)
  const [showDeleting, setShowDeleting] = useState(false)

  const { meeting, deleteMeeting, exitMeeting } = useMeetings()
  const { participant } = useParticipants()

  function renderHeader(): JSX.Element | undefined {
    if (meeting?.title) {
      return (
        <ModalHeader
          title={meeting.title}
          onClose={onClose} />
      )
    }
  }

  async function onDeleteMeeting(): Promise<void> {
    if (meeting?.id && deleteMeeting) {
      console.log('Deleting meeting => ', meeting)
      setShowDeleting(true)
      await deleteMeeting(meeting.id)
      onClose()
    }
  }

  async function onExitMeeting(): Promise<void> {
    if (meeting?.id && exitMeeting) {
      console.log('exit meeting => ', meeting)
      setShowDeleting(true)
      await exitMeeting(meeting.id)
      onClose()
    }
  }

  function renderEditSettings(): JSX.Element {
    return (
      <div>
        <div style={messageBox}>
          <IonText
            color='medium'
            style={messageStyle}>
            Edit meeting parameters such as title, duration, time restrictions etc.
          </IonText>
        </div>
        <IonItem
          lines='none'
          onClick={() => meeting && onEdit(meeting.id)}>
          <IonLabel>
            Edit Meeting
          </IonLabel>
        </IonItem>
      </div>

    )
  }

  function renderDeleteMeeting(): JSX.Element | undefined {
    if (participant?.admin) {
      return (
        <div>
          <div style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              If you would like to reschedule this meeting then please pick your preferred times in the meeting Availability Calendar.
              Deleting this meeting will terminate it for all participants.
            </IonText>
          </div>
          {!showDeleting &&
            <IonItem
              lines='none'
              onClick={() => setShowDeleteActions(true)}>
              <IonLabel color='danger'>
                Delete Meeting
              </IonLabel>
            </IonItem>}
          {showDeleting &&
            <IonItem
              lines='none'>
              <IonLabel color='danger'>
                Delete Meeting
              </IonLabel>
              <IonSpinner
                slot='end'
                color='danger'
                name='dots' />
            </IonItem>}
        </div>
      )
    }
  }

  function renderExitMeeting(): JSX.Element | undefined {
    if (participant && !participant.admin) {
      return (
        <div>
          <div style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              If you would like to reschedule this meeting please pick your preferred times in the meeting Availability Calendar.
              If you dont want to attend this meeting at any time you can stop participating.
            </IonText>
          </div>
          {!showDeleting &&
            <IonItem
              lines='none'
              onClick={() => setShowExitActions(true)}>
              <IonLabel color='danger'>
                Stop Participating
              </IonLabel>
            </IonItem>}
          {showDeleting &&
            <IonItem
              lines='none'>
              <IonLabel color='danger'>
                Stop participating in this meeting
              </IonLabel>
              <IonSpinner
                slot='end'
                color='danger'
                name='dots' />
            </IonItem>}
        </div>
      )
    }
  }

  function renderDeleteActions(): JSX.Element | undefined {
    return (
      <IonActionSheet
        isOpen={showDeleteAction}
        onDidDismiss={() => setShowDeleteActions(false)}
        buttons={[{
          text: `Delete ${meeting?.title} ?`,
          role: 'destructive',
          handler: () => {
            setShowDeleteActions(false)
            onDeleteMeeting()
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          },
        }]} />
    )
  }

  function renderExitActions(): JSX.Element | undefined {
    return (
      <IonActionSheet
        isOpen={showExitActions}
        onDidDismiss={() => setShowExitActions(false)}
        buttons={[{
          text: `Exit ${meeting?.title} ?`,
          role: 'destructive',
          handler: () => {
            setShowExitActions(false)
            onExitMeeting()
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          },
        }]} />
    )
  }

  return (
    <IonModal
      isOpen
      swipeToClose
      presentingElement={presentingElement}
      onDidDismiss={onClose}>
      {renderHeader()}
      <IonContent>
        <div
          style={{ flex: 1, height: '100%' }}
          className='SettingsDivider'>
          {/* <IonListHeader
            style={{ marginBottom: 10 }}>
            <h2>Meeting Actions</h2>
          </IonListHeader> */}
          {renderEditSettings()}
          {renderDeleteMeeting()}
          {renderExitMeeting()}
          {renderDeleteActions()}
          {renderExitActions()}
        </div>
      </IonContent>
    </IonModal>
  )
}

export default MeetingActionsModal
