import React, { CSSProperties, useState, useLayoutEffect, useEffect } from 'react'
import { v1 as uuidv1 } from 'uuid'
import {
  IonContent, IonText, IonFooter,
} from '@ionic/react'
import moment from 'moment'

import 'screens/Settings.css'

import TimeBar from 'components/organisms/CalendarTimeBar/TimeBar'
import { TimeSlot, TimeRange } from 'types'
import DayColumn from 'components/organisms/DayColumn/DayColumn'
import { adjustTimeRangesForDay, getTimeRanges } from 'components/organisms/ScheduleCalendar/utils'
import { compareTimes } from 'services/time'
import SelectAutoBookDays from './SelectAutoBookDays'
import { useCalendarView } from '../ScheduleCalendar/CalendarViewContext'
import { AutoBookSettingsTips } from 'types/componentTips'
import { useTips } from 'context/TipsContext/TipsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import MeetingTimes from 'components/molecules/MeetingTimes/MeetingTimes'
import { Spring } from 'react-spring/renderprops'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'
// import { AutoBookSettings as StoreTips } from 'services/store/tips/types'

const user = 'user'
const meeting = 'meeting'
const scale = 60

const defaultMeeting = {
  id: meeting,
  title: meeting,
  duration: '60',
  participantUserIds: [user],
}

const container: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}
const bottomBox: CSSProperties = {
  flex: 1,
  display: 'flex',
  overflow: 'hidden',
}

const daysBox: CSSProperties = {
  height: '100%',
  overflowY: 'scroll',
}

const messageBox: CSSProperties = {
  padding: 20,
}

const messageStyle = {
  fontSize: 14,
}

let dayStartTime = ''

function initTimeSlots(timeRanges?: TimeRange[]): TimeSlot[] {
  dayStartTime = moment().add(1, 'day').startOf('day').toISOString()

  if (Array.isArray(timeRanges)) {
    const ranges = adjustTimeRangesForDay(dayStartTime, timeRanges)

    console.log('AutoBookSettings: initTimeSlots: ', ranges)

    return ranges.map(range => {
      return {
        localId: uuidv1(),
        meetingId: meeting,
        userId: user,
        startTime: range.startTime,
        endTime: range.endTime,
        available: true,
      }
    })
  }

  return []
}

function initDays(days?: string[]): string[] {
  return days || ['mon', 'tue', 'wed', 'thu', 'fri']
}

export interface AutoBookSettingsProps {
  enabled: boolean;
  timeRanges?: TimeRange[];
  days?: string[];
  noShowSwitch?: boolean;
  timeZone?: string;
  doneButton?: string;
  onClose?: () => void;
  onUpdated: (timeRanges: TimeRange[], days: string[], enabled: boolean) => void;
}

const AutoBookSettings: React.FC<AutoBookSettingsProps> = ({ enabled, timeRanges, days, timeZone, doneButton, onClose, onUpdated }) => {
  const [showScreenTip, setShowScreenTip] = useState(AutoBookSettingsTips.noTip)
  const [minTip, setMinTip] = useState(false)
  const [editSlot, setEditSlot] = useState<TimeSlot>()
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>(initTimeSlots(timeRanges))
  const [selectedDays, setSelectedDays] = useState<Array<string>>(initDays(days))
  const [autoEnabled] = useState(enabled)
  const [disableScroll, setDisableScroll] = useState(false)

  const { timeLabelWidth = 60, calendarTopPadding = 20, timeSlotHeight = 50, timeSlotWidth = 90 } = useCalendarView()

  const { storeTips } = useTips()
  const { logEvent } = useAnalytics()

  const timeColumnContainer: CSSProperties = {
    width: timeLabelWidth + timeSlotWidth + 20,
    display: 'flex',
    position: 'relative',
    borderRight: '1px solid lightgray',
  }

  const rightBox: CSSProperties = {
    position: 'absolute',
    top: 0,
    right: 0,
    left: timeLabelWidth,
    bottom: 0,
    display: 'flex',
  }

  const calendarBox: CSSProperties = {
    flex: 1,
    display: 'flex',
    overflowX: 'scroll',
    overflowY: 'scroll',
    flexWrap: 'nowrap',
    paddingTop: calendarTopPadding,
  }

  useEffect(() => {
    startTips()
  }, [])

  function logTap(eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'AutoBookSettings',
      },
    })
  }

  function startTips(): void {
    setTimeout(() => {
      if (storeTips?.startMinTip) {
        setNextTip(AutoBookSettingsTips.noTip)
      } else {
        setNextTip(AutoBookSettingsTips.sequence)
      }
    }, 1000)
  }

  useLayoutEffect(() => {
    let hour = '9'

    if (timeRanges?.length) {
      hour = moment(timeRanges[0].startTime).format('HH')
    }

    setTimeout(() => {
      const el = document.getElementById('selectTimesBox')

      if (el) {
        console.log('SCROLL TO HOUR: ', hour)

        el.scrollTop = timeSlotHeight * Number(hour)
      }
    }, 500)
  }, [])

  function setNextTip(tip: AutoBookSettingsTips): void {
    // console.log('Meeting screen setNextTip: ', tip)

    switch (tip) {
      // eslint-disable-next-line no-fallthrough
      case AutoBookSettingsTips.sequence:
      case AutoBookSettingsTips.selectTimes:
        setShowScreenTip(AutoBookSettingsTips.selectTimes)
        break
      // eslint-disable-next-line no-fallthrough
      case AutoBookSettingsTips.noTip:
      default:
        // in the modal case reset the tips back to sequence
        setShowScreenTip(AutoBookSettingsTips.sequence)
        setMinTip(true)
        break
    }
  }

  // function updateTip (tip: StoreTips): void {
  //   if (updateStoreTips) {
  //     updateStoreTips({
  //       autoBookSettings: {
  //         ...storeTips?.autoBookSettings, ...tip,
  //       },
  //     })
  //   }
  // }

  function updatedTimeRanges(): void {
    const sortedTimeSlots = timeSlots.slice().sort((a: TimeSlot, b: TimeSlot) => {
      const timeA = a.startTime
      const timeB = b.startTime

      return compareTimes(timeA, timeB)
    })

    onUpdated(sortedTimeSlots.map(slot => {
      return {
        startTime: slot.startTime,
        endTime: slot.endTime,
      }
    }), selectedDays, autoEnabled)
  }

  function renderFooter(): JSX.Element | undefined {
    const label = doneButton || 'Done'

    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}>
        {props =>
          <div style={props}>
            <IonFooter className='screenFooterButton'>
              <FooterButton
                onClick={() => {
                  logTap({ component: 'FooterButton', button: 'UpdateTimeRanges' })
                  updatedTimeRanges()
                }}>
                {label}
              </FooterButton>
              {onClose &&
                <FooterButton
                  fill='clear'
                  onClick={() => {
                    logTap({ component: 'FooterButton', button: 'Cancel' })
                    onClose && onClose()
                  }}>
                  Cancel
                </FooterButton>}
            </IonFooter>
          </div>}
      </Spring>
    )
  }

  function onScroll(): void {
    const el = document.getElementById('selectTimesBox')

    if (el) {
      const timeBar = document.getElementById('selectTimesBar')

      if (timeBar) {
        timeBar.scrollTop = el.scrollTop
      }
    }
  }

  function onDragStart(fromTime: string): void {
    if (editSlot) {
      if (moment(fromTime).isAfter(moment(editSlot.startTime)) &&
        moment(fromTime).isBefore(moment(editSlot.endTime))) {
        setDisableScroll(true)
      }
    }
  }

  function onDragEnd(): void {
    setDisableScroll(false)
  }

  function onCreateTimeSlot(startTime: string, endTime: string): void {
    console.log(`onCreateTimeSlot: ${startTime} to ${endTime}`)

    const slots = timeSlots.slice()
    const slot = {
      localId: uuidv1(),
      meetingId: meeting,
      userid: user,
      startTime,
      endTime,
      available: true,
    }

    slots.push(slot)
    setTimeSlots(slots)
    setEditSlot(slot)
    // setEdited(true)
  }

  function onUpdateTimeSlot(timeSlot: TimeSlot): void {
    console.log('onUpdateTimeSlot: ', timeSlot)

    setTimeSlots(timeSlots.map(slot => {
      if (slot.localId === timeSlot.localId) {
        return {
          ...slot,
          startTime: timeSlot.startTime,
          endTime: timeSlot.endTime,
        }
      } else {
        return slot
      }
    }))

    // if (!edited) {
    //   setEdited(true)
    // }
  }

  function onDeleteTimeSlot(timeSlot: TimeSlot): void {
    console.log('onDeleteTimeSlot: ', timeSlot)

    setTimeSlots(timeSlots.filter(slot => {
      if (slot.localId === timeSlot.localId) {
        return false
      } else {
        return true
      }
    }))

    // if (!edited) {
    //   setEdited(true)
    // }
  }

  function renderDayColumn(): JSX.Element | undefined {
    if (!autoEnabled) {
      return
    }

    const calendarBoxStyle: CSSProperties = {
      ...calendarBox,
    }

    if (disableScroll) {
      calendarBoxStyle.overflowX = 'hidden'
      calendarBoxStyle.overflowY = 'hidden'
    }

    let ranges
    let slots: TimeSlot[] = []

    slots = timeSlots.slice()

    if (slots.length) {
      slots.push({
        meetingId: meeting,
        userId: 'disabled',
        startTime: dayStartTime,
        endTime: moment(dayStartTime).add(24, 'hours').toISOString(),
      })

      // console.log('SLOTS: ', slots)
      // console.log('EDIT SLOT: ', editSlot)

      ranges = getTimeRanges({ timeSlots: slots, editSlot })

      slots = timeSlots.slice()

      // console.log('RANGES: ', ranges)
      ranges.map(range => {
        if (range.participantUserIds?.length === 1) {
          slots.push({
            meetingId: meeting,
            userId: 'disabled',
            startTime: range.startTime,
            endTime: range.endTime,
            disabled: true,
          })
        }
      })
    }

    // console.log('TIME SLOTS: ', slots)

    return (
      <div style={timeColumnContainer}>
        <TimeBar
          id='selectTimesBar'
          timeRanges={ranges?.filter(r => r.participantUserIds?.length === 2)} />
        <div style={rightBox}>
          <div
            id='selectTimesBox'
            style={calendarBoxStyle}
            onScroll={onScroll}>
            <DayColumn
              parentId='selectTimesBox'
              userId={user}
              startDay={0}
              day={1}
              tip={!minTip && showScreenTip === AutoBookSettingsTips.selectTimes ? 10 : undefined}
              scale={scale}
              meeting={defaultMeeting}
              timeSlots={slots}
              calendarEvents={[]}
              participants={[]}
              showOpen
              editCalendar
              editSlot={editSlot}
              setEditSlot={setEditSlot}
              onEdit={() => {
                logTap({ component: 'DayColumn', button: 'EditSlot' })
                console.log('onEdit')
              }}
              onEditEnd={() => {
                setDisableScroll(false)
                setEditSlot(undefined)
              }}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onCreateTimeSlot={(startTime, endTime) => {
                logTap({ component: 'DayColumn', button: 'CreateSlot' })
                onCreateTimeSlot(startTime, endTime)
              }}
              onUpdateTimeSlot={(timeSlot) => {
                logTap({ component: 'DayColumn', button: 'UpdateSlot' })
                onUpdateTimeSlot(timeSlot)
              }}
              onDeleteTimeSlot={
                timeSlots.length > 1
                  ? (timeSlot) => {
                    logTap({ component: 'DayColumn', button: 'DeleteSlot' })
                    onDeleteTimeSlot(timeSlot)
                  }
                  : undefined
              }
              onInfo={() => {
                logTap({ component: 'DayColumn', button: 'Info' })
                console.log('onInfo')
              }} />
          </div>
        </div>
      </div>
    )
  }

  function renderSelectDays(): JSX.Element | undefined {
    if (!autoEnabled) {
      return
    }

    return (
      <div style={daysBox}>
        <SelectAutoBookDays
          selected={selectedDays}
          tip={!minTip && showScreenTip === AutoBookSettingsTips.selectTimes}
          onSelect={(day) => {
            logTap({ component: 'SelectAutoBookDays', button: 'SelectDay' })
            console.log('on selected: ', day)

            const update = selectedDays.slice()
            const index = selectedDays.findIndex(d => d === day)

            if (index > -1) {
              // dont allow zero days selected
              if (update.length > 1) {
                update.splice(index, 1)
              }
            } else {
              update.push(day)
            }

            setSelectedDays(update)
            // setEdited(true)
          }}
          onUpdate={update => {
            logTap({ component: 'SelectAutoBookDays', button: 'Update' })
            setSelectedDays(update)
            // setEdited(true)
          }} />
      </div>
    )
  }

  // function renderScreenTips (): JSX.Element | undefined {
  //   return (
  //     <ScreenTips
  //       minTip={minTip}
  //       showScreenTip={showScreenTip}
  //       onClose={() => {
  //         setMinTip(true)
  //       }}
  //       onMinTip={() => {
  //         setMinTip(false)
  //         setNextTip(showScreenTip + 1)
  //       }}
  //       onButton={() => {
  //         setMinTip(true)
  //         setNextTip(showScreenTip + 1)
  //       }} />
  //   )
  // }

  function renderTimesRanges(): JSX.Element | undefined {
    const sortedTimeSlots = timeSlots.slice().sort((a: TimeSlot, b: TimeSlot) => {
      const timeA = a.startTime
      const timeB = b.startTime

      return compareTimes(timeA, timeB)
    })

    const timeRanges = sortedTimeSlots.map(slot => {
      return {
        startTime: slot.startTime,
        endTime: slot.endTime,
      }
    })

    return (
      <MeetingTimes
        timeRanges={timeRanges}
        timeZone={timeZone} />
    )
  }

  return (
    <div
      style={container}>
      <IonContent
        scrollY={false}
        scrollX={false}>
        <div style={container}>
          <div style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              Select the times of day and days of week when you want MeetingTime to schedule a time
              based on your availabilty on this calendar.
            </IonText>
          </div>
          {renderTimesRanges()}
          <div style={bottomBox}>
            {renderDayColumn()}
            {renderSelectDays()}
          </div>
        </div>
      </IonContent>
      {renderFooter()}
      {/* {renderScreenTips()} */}
    </div>
  )
}

export default AutoBookSettings
