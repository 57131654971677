import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'

import { CreateContactInput, CREATE_CONTACT, DELETE_CONTACT, GET_CONTACTS, VerifyContactInput, VERIFY_CONTACT, VERIFY_SUBSCRIPTION } from 'services/api'
import { UserContact } from 'types'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

type Tokens = {
  verifyToken: string;
  accessToken: string;
}

export interface ContactsContextValue {
  loading?: boolean
  contacts?: UserContact[]
  createCompleted?: CreateContactInput
  verifyContact: (input: VerifyContactInput) => Promise<Tokens | undefined>
  createContact: (input: CreateContactInput) => Promise<UserContact | undefined>
  deleteContact: (contact: string) => Promise<void>
}

const initialValue: ContactsContextValue = {
  loading: false,
  contacts: [],
  verifyContact: async (input: VerifyContactInput) => {
    console.log('verifyContact: ', input)

    return undefined
  },
  createContact: async (input: CreateContactInput) => {
    console.log('createContact: ', input)

    return undefined
  },
  deleteContact: async (contact: string) => { console.log('deleteContact: ', contact) },
}
// create and initialize context
export const ContactsContext = React.createContext<ContactsContextValue>(initialValue)

export function useContacts (): ContactsContextValue {
  return useContext(ContactsContext)
}

export type ContactsMockContextValue = Partial<ContactsContextValue>

type MockProps = {
  value?: Partial<ContactsContextValue>
}

export const ContactsMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <ContactsContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </ContactsContext.Provider>
  )
}

const ContactsProvider: React.FC = ({ children }) => {
  const [createCompleted, setCreateCompleted] = useState<CreateContactInput>()
  const { logEvent } = useAnalytics()

  const { data, refetch } = useQuery(GET_CONTACTS, {
    fetchPolicy: 'cache-and-network',
  })
  const [verifyContactMutation] = useMutation(VERIFY_CONTACT)
  const [createContactMutation] = useMutation(CREATE_CONTACT)
  const [deleteContactMutation] = useMutation(DELETE_CONTACT)

  const { data: verifyCompletedData } = useSubscription(VERIFY_SUBSCRIPTION)

  // Only re-run the effect if subscriptionData changes
  useEffect(() => {
    if (verifyCompletedData?.verifyCompleted) {
      console.log('Contacts CONTEXT: VERIFY COMPLETED:  ', verifyCompletedData.verifyCompleted)

      const { verifyToken, action, email, phone } = verifyCompletedData.verifyCompleted

      // if this is a contact verify without accessToken then it must be for
      if (verifyToken && action && action === 'createContact') {
        refetch()
        // set createCompleted with verifyToken so that it's different every time
        setCreateCompleted({
          verifyToken,
          email,
          phone,
        })
      }
    }
  }, [verifyCompletedData])

  async function verifyContact (input: VerifyContactInput): Promise<Tokens | undefined > {
    console.log('onVerifyContact input: ', input)

    const { phone, email } = input
    const { data } = await verifyContactMutation({ variables: { input } })
    const contactType = phone ? 'phone' : (email ? 'email' : 'unknown')

    if (data?.verifyContact?.verifyToken) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'verifyContact',
          contactType,
        },
      })

      return data.verifyContact
    }
  }

  async function createContact (input: CreateContactInput): Promise<UserContact | undefined> {
    const { data } = await createContactMutation({ variables: { input } })
    const { phone, email, provider } = input
    const contactType = phone ? 'phone' : (email ? 'email' : (provider || 'unknown'))

    if (data?.createContact) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'createContact',
          contact: data?.createContact?.id,
          contactType,
        },
      })

      return data.createContact as UserContact
    }
  }

  async function deleteContact (contact: string): Promise<void> {
    if (contact) {
      await deleteContactMutation({ variables: { input: { contact } } })
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'deleteContact',
          contact,
        },
      })
      refetch()
    }
  }

  return (
    <ContactsContext.Provider
      value={{
        contacts: data?.contacts || [],
        createCompleted,
        verifyContact,
        createContact,
        deleteContact,
      }}>
      {children}
    </ContactsContext.Provider>
  )
}

export default ContactsProvider
