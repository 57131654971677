import React, { useEffect, useState } from 'react'
import { ApolloError, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { resetApp } from 'services/store'
import { GET_USER } from 'services/api'
import { IonReactRouter } from '@ionic/react-router'
import { AppTabs } from './AppTabs'
import { IonApp, IonRouterOutlet, IonSpinner } from '@ionic/react'
import { Route, Redirect, Switch } from 'react-router'
import paths from './paths'
import { getAccessToken } from 'services/security/accessToken'
import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'

import LoginFirebase from 'screens/LoginFirebase'
import NewUserName from 'screens/NewUserName'
import Start from 'screens/Start'
import StartInvite from 'screens/StartInvite'
import AddContactFirebase from 'screens/AddContactFirebase'
import StartMeetUser from 'screens/StartMeetUser'
import RouteContext, { AuthTypes } from './RouteContext'
import ScreensProvider from 'context/ScreensContext/ScreensContext'
import StoreTipsProvider from 'context/TipsContext/TipsContext'
import StoreMeetingsProvider from 'context/StoreMeetingsContext/StoreMeetingsContext'
import ConnectCalendar from 'screens/ConnectCalendar'
import AnalyticsProvider from 'context/AnalyticsContext/AnalyticsContext'
import UserProvider from 'context/UserContext/UserContext'
import { setUser as firebaseSetUser } from 'services/firebase'

type AppTabDef = {
  tab: string;
  href: string;
  icon: string;
  label: string;
  show: boolean;
}

interface ComponentProps {
  resetApollo: () => void
}

export const AppRouter: React.FC<ComponentProps> = ({ resetApollo }) => {
  // const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(AuthTypes.loading)
  const [accessToken] = useState(getAccessToken())

  const dispatch = useDispatch()

  console.log('APP ROUTER')

  function onError (error: ApolloError): void {
    if (error.graphQLErrors.find(err => err.extensions?.code === 'UNAUTHENTICATED')) {
      console.log('APP ROUTER ERROR: UNAUTHENTICATED reset app...')
      setAuthenticated(AuthTypes.notAuthenticated)
      dispatch(resetApp())
    }
  }

  const { data: userData } = useQuery(GET_USER, {
    onError,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    console.log('APP ROUTER: USER DATA: ', userData)

    if (userData?.user?.id && authenticated !== AuthTypes.authenticated) {
      console.log('Authenticated User: ', userData.user)

      const token = getAccessToken()

      if (token === accessToken) {
        firebaseSetUser(userData.user.id)
        setAuthenticated(AuthTypes.authenticated)
      } else {
        // if accessToken has changed reset all connections
        // NOTE don't set authenticated state here. That will be set in the appropriate screen
        console.log('NEW ACCESS TOKEN ... old token: ', accessToken)
        console.log('NEW ACCESS TOKEN ... new token: ', token)
        resetApollo()
      }
    } else if (userData && !userData.user?.id) {
      setAuthenticated(AuthTypes.notAuthenticated)
      dispatch(resetApp())
    }
  }, [userData])

  // NOTE NOTE: /login is a special path which is used both
  // auth and non auth routes. Don't use /start prefix for login
  function renderUnauthenticated (): JSX.Element | undefined {
    if (authenticated === AuthTypes.notAuthenticated) {
      return (
        <AnalyticsProvider>
          <IonRouterOutlet>
            <Switch>
              <Route
                path={paths.welcome}
                component={Start}
                exact />
              <Route
                path={paths.newUserName}
                component={NewUserName}
                exact />
              <Route
                path={paths.login}
                component={LoginFirebase}
                exact />
              <Route
                path={paths.inviteUnauthenticated}
                component={StartInvite}
                exact />
              <Route
                path={paths.meetUserUnauthenticated}
                component={StartMeetUser}
                exact />
              <Route
                path={paths.addContact}
                component={AddContactFirebase}
                exact />
              <Route
                path={paths.connectCalendar}
                component={ConnectCalendar}
                exact />
              <Route path='*'>
                <Redirect to='/welcome' />
              </Route>
            </Switch>
            <Redirect
              exact
              from={paths.invite}
              to={paths.inviteUnauthenticated} />
            <Redirect
              exact
              from={paths.meetUser}
              to={paths.meetUserUnauthenticated} />
            <Redirect
              exact
              from='/'
              to={paths.welcome} />
            <Redirect
              from='/meetings'
              to={paths.welcome} />
            <Redirect
              from='/settings'
              to={paths.welcome} />
          </IonRouterOutlet>
        </AnalyticsProvider>
      )
    }
  }

  function renderAuthenticated (): JSX.Element | undefined {
    if (authenticated === AuthTypes.authenticated) {
      // Note that AnalyticsProvider uses the user context so add it after UserProvider
      return (
        <UserProvider>
          <AnalyticsProvider>
            <AppTabs />
          </AnalyticsProvider>
        </UserProvider>
      )
    }
  }

  if (authenticated === AuthTypes.loading) {
    return (
      <IonApp>
        <ScreenCenter height='80%'>
          <img
            src='assets/icon/MeetingTimeLogo.png'
            width='60'
            height='60'
            alt='' />
          <IonSpinner
            name='dots'
            color='medium' />
        </ScreenCenter>
      </IonApp>
    )
  }

  return (
    <IonReactRouter>
      <RouteContext.Provider value={{ authenticated, setAuthenticated }}>
        <ScreensProvider>
          <StoreTipsProvider>
            <StoreMeetingsProvider>
              {renderUnauthenticated()}
              {renderAuthenticated()}
            </StoreMeetingsProvider>
          </StoreTipsProvider>
        </ScreensProvider>
      </RouteContext.Provider>
    </IonReactRouter>
  )
}
