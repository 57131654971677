import React, { CSSProperties } from 'react'
import moment from 'moment'
import { IonText } from '@ionic/react'
import { MeetingTime, TimeRange } from 'types'
import { useCalendarView } from '../ScheduleCalendar/CalendarViewContext'
import MeetingTimeMarkerLines from 'components/atoms/MeetingTimeMarkerLines/MeetingTimeMarkerLine'
import { MeetingBookCalendar } from 'context/CalendarsContext/CalendarsContext'

// startTime: "2020-09-04T16:00:00.000Z", endTime: "2020-09-05T00:00:00.000Z"

const box: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}

// const timeRanges = [
//   {
//     startTime: '2020-09-04T15:00:00.000Z',
//     endTime: '2020-09-04T23:00:00.000Z',
//   },
//   {
//     startTime: '2020-09-05T01:00:00.000Z',
//     endTime: '2020-09-05T03:00:00.000Z',
//   },
// ]

interface ContainerProps {
  id?: string;
  timeRanges?: TimeRange[];
  meetingTimes?: MeetingTime[];
  selectedTime?: string;
  bookCalendar?: MeetingBookCalendar;
}

const TimeBar: React.FC<ContainerProps> = ({ id, meetingTimes, timeRanges, bookCalendar, selectedTime }) => {
  const { timeLabelWidth = 60, calendarTopPadding = 20 } = useCalendarView()

  const container: CSSProperties = {
    display: 'flex',
    overflowY: 'hidden',
    flexWrap: 'nowrap',
    width: timeLabelWidth,
    paddingTop: calendarTopPadding,
  }

  function renderMeetingTimeMarkers (): JSX.Element[] | undefined {
    // 12AM start time - 1 minute so that 12AM selection fits in the isAfter range below.

    const markers: JSX.Element[] = []

    if (meetingTimes && selectedTime) {
      const dayStartTime = moment(selectedTime).startOf('day').toISOString()
      const dayEndTime = moment(selectedTime).startOf('day').add(24, 'hours').toISOString()

      const dayStart = moment(selectedTime).startOf('day').valueOf()
      const dayEnd = moment(selectedTime).startOf('day').add(24, 'hours').valueOf()

      meetingTimes.forEach((meetingTime, i) => {
        let { startTime, endTime } = meetingTime
        const start = moment(startTime).valueOf()
        const end = moment(endTime).valueOf()

        if ((start >= dayStart && start < dayEnd) ||
          (end > dayStart && end <= dayEnd)) {
          if (start < dayStart) {
            startTime = dayStartTime
          }

          if (end > dayEnd) {
            endTime = dayEndTime
          }

          markers.push(
            <MeetingTimeMarkerLines
              key={i}
              meetingTime={meetingTime} />,
          )
        }
      })
    }

    return markers
  }

  let autoTimes = timeRanges

  if (bookCalendar?.autoBook && bookCalendar?.autoDays && bookCalendar?.autoTimes) {
    // calculate start and end minute markers
    if (selectedTime) {
      const day = moment(selectedTime).format('ddd').toLowerCase()

      if (bookCalendar.autoDays?.find(d => d === day)) {
        autoTimes = bookCalendar?.autoTimes
      }
    } else {
      autoTimes = bookCalendar?.autoTimes
    }
  }

  return (
    <div
      id={id || 'timeBar'}
      style={container}>
      <div
        style={box}>
        {Array(...Array(25)).map((v, i) =>
          <TimeLabel
            key={i}
            time={i} />,
        )}
        {autoTimes && autoTimes.map((range, i) =>
          <Range
            key={i}
            scale={60}
            range={range} />,
        )}
        {renderMeetingTimeMarkers()}
      </div>
    </div>
  )
}

interface TimeLabelProps {
  time: number;
}

const TimeLabel: React.FC<TimeLabelProps> = ({ time }) => {
  const { timeSlotHeight = 50 } = useCalendarView()

  const container: CSSProperties = {
    minHeight: timeSlotHeight,
    position: 'relative',
    borderTopWidth: 0,
  }
  const label: CSSProperties = {
    position: 'absolute',
    top: -8,
    right: 14,
    fontSize: 12,
    WebkitUserSelect: 'none',
  }
  // const tick: CSSProperties = {
  //   position: 'absolute',
  //   top: 0,
  //   right: 0,
  //   width: 10,
  // }
  const timeLabel = moment().startOf('day').add(time, 'hours').format('h A')

  return (
    <div
      className='CalendarALines'
      style={container}>
      <IonText
        className='CalendarATimeLabel'
        style={label}>
        {timeLabel}
      </IonText>
      {/* <div
        className='CalendarALines'
        style={tick} /> */}
    </div>
  )
}

interface Props {
  scale: number;
  range: TimeRange;
}

const Range: React.FC<Props> = ({ scale, range }) => {
  const { timeSlotHeight = 50 } = useCalendarView()

  const dayStart = moment(range.startTime).startOf('day')
  // calculate start and end minute markers
  const startMinutes = moment(range.startTime).diff(dayStart, 'minutes')
  const endMinutes = moment(range.endTime).diff(dayStart, 'minutes')
  const top = startMinutes * (timeSlotHeight / scale)
  const height = (endMinutes - startMinutes) * (timeSlotHeight / scale)

  const container: CSSProperties = {
    position: 'absolute',
    top,
    right: 5,
    height,
    width: 2,
    display: 'flex',
    borderRadius: '1px',
    borderStyle: 'solid',
    borderWidth: '1px',
  }

  // const label: CSSProperties = {
  //   position: 'absolute',
  //   top: 5,
  //   right: 10,
  //   fontSize: 12,
  // }

  return (
    <div
      style={container}
      className='CalendarAAutoRange' />
  )
}

export default TimeBar
