import { CSSProperties } from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import lottieJsonWhite from './meeting-white.json'
import lottieJsonOrange from './meeting-orange.json'

// https://lottiefiles.com/46698-book-an-appointment
export interface AnimatedMeetingProps {
  delay?: number;
  color?: string;
  loop?: number | boolean;
  style?: CSSProperties;
  onComplete?: () => void;
}

const AnimatedMeeting: React.FC<AnimatedMeetingProps> = ({ delay, style, color, loop }) => {
  const [play, setPlay] = useState(!delay)

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setPlay(true)
      }, delay)
    }
  }, [])

  return (
    <Lottie
      loop={loop || 1}
      play={play}
      animationData={color === 'white' ? lottieJsonWhite : lottieJsonOrange}
      style={style || { width: 100, height: 100 }} />
  )
}
export default AnimatedMeeting
