import React from 'react'
import { Invitee, Participant } from 'types'
import { IonIcon, IonItem, IonLabel, IonNote, IonSpinner, IonText } from '@ionic/react'

import { Avatar } from '@material-ui/core'
import { useUser } from 'context/UserContext/UserContext'
import { removeCircleOutline } from 'ionicons/icons'

const avatarStyle = {
  width: 36,
  height: 36,
  backgroundColor: '#92949C',
  marginRight: 15,
}

const noteStyle = {
  fontSize: 14,
}

export interface InviteeListItemProps {
  invitee: Invitee;
  participants?: Participant[];
  lines: boolean;
  showRemoving: boolean;
  onSelect: (invitee: Invitee) => void;
}

const selfName = 'You'

const InviteeListItem: React.FC<InviteeListItemProps> = (
  {
    invitee, participants, lines, onSelect, showRemoving,
  }) => {
  const { displayName, invitedByUserId, photo, userHandle } = invitee
  let invitationName
  const name = displayName

  const { user } = useUser()

  // discard blank spaces in between
  const initials = name.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase())

  // console.log('Invited by: ', invitedByUseruserHandle)
  // console.log('participants: ', participants)

  if (invitedByUserId && participants) {
    const invitationParticipant = participants.find(p => p.userId === invitedByUserId)

    if (invitationParticipant) {
      if (!invitationParticipant.admin) {
        if (invitationParticipant.userId === user?.id) {
          invitationName = selfName
        } else {
          invitationName = invitationParticipant.userId
        }
      }
    }
  }

  let canRemove = false

  if (participants) {
    const userParticipant = participants.find(p => p.userId === user?.id)

    if (userParticipant && userParticipant.admin) {
      canRemove = true
    }
  }

  // console.log('ParticipantListItem: participant: ', participant)
  function selected(): void {
    console.log('selected participant: ', invitee)
    console.log('canRemove: ', canRemove)
    canRemove && onSelect(invitee)
  }

  return (
    <IonItem
      lines={lines ? 'inset' : 'none'}
      onClick={selected}>
      <Avatar
        slot='start'
        src={photo}
        style={avatarStyle}>
        <IonText style={{ fontSize: 16 }}>
          {initials}
        </IonText>
      </Avatar>
      <IonLabel>
        <h2>{displayName}</h2>
        <p>@{userHandle}</p>
        {invitationName &&
          <p>
            by {invitationName}
          </p>}
      </IonLabel>
      {!showRemoving &&
        <IonNote
          slot='end'
          style={noteStyle}>
          {invitee.status}
        </IonNote>}
      {showRemoving &&
        <IonNote
          slot='end'
          style={noteStyle}>
          <IonSpinner name='dots' />
        </IonNote>}
      {!showRemoving && canRemove &&
        <IonIcon
          slot='end'
          icon={removeCircleOutline}
          color='danger' />}
    </IonItem>
  )
}

export default InviteeListItem
