import React, { useEffect, useState, useContext } from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'

import {
  AddCalendarInput, ADD_CALENDAR, CALENDAR_SUBSCRIPTION, CreateCalendarInput,
  CREATE_CALENDAR, GET_CALENDARS, GET_PREFERENCES, RemoveCalendarInput, REMOVE_CALENDAR, UpdateCalendarInput, UPDATE_CALENDAR,
  ACTIVATE_CALENDAR,
  ActivateCalendarInput,
} from 'services/api'
import { CalendarAccount, CalendarEvent, Participant, SubCalendar, TimeRange } from 'types'
import { compareTimes } from 'services/time'
import { getSubCalendars, getEvents, GetEventsInput } from 'services/googleCalendar'
import moment from 'moment'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'
import { flagDisableDefaultAutobook } from 'flags'

function defaultTimeRanges(timeZone?: string): TimeRange[] {
  if (timeZone) {
    return [
      {
        startTime: moment().tz(timeZone).startOf('day').add(9, 'hours').toISOString(),
        endTime: moment().tz(timeZone).startOf('day').add(17, 'hours').toISOString(),
      },
    ]
  } else {
    return [
      {
        startTime: moment().startOf('day').add(9, 'hours').toISOString(),
        endTime: moment().startOf('day').add(17, 'hours').toISOString(),
      },
    ]
  }

  return []
}

function defaultAutoDays(): string[] {
  return ['mon', 'tue', 'wed', 'thu', 'fri']
}

export interface MeetingBookCalendar {
  calendar: SubCalendar
  autoTimes?: TimeRange[]
  autoDays?: string[]
  autoBook?: boolean;
}

export type CalendarUpdated = {
  account: string
  calendarId: string
}

export interface CalendarsContextValue {
  loading: boolean
  calendars?: CalendarAccount[]
  defaultCalendar?: SubCalendar
  autoTimes?: TimeRange[]
  autoDays?: string[]
  calendarUpdated?: CalendarUpdated
  addCalendar: (input: AddCalendarInput) => Promise<string | undefined>
  createCalendar: (input: CreateCalendarInput) => Promise<CalendarAccount | undefined>
  activateCalendar: (input: ActivateCalendarInput) => Promise<CalendarAccount | undefined>
  updateCalendar: (input: UpdateCalendarInput) => Promise<void>
  removeCalendar: (calendar: CalendarAccount) => Promise<void>
  reloadCalendars: () => void;
  getCalendarDetails: (calendar: CalendarAccount, writable?: boolean) => Promise<SubCalendar[]>;
  getMeetingCalendar: (participant: Participant) => Promise<MeetingBookCalendar | undefined>;
  getCalendarEvents: (input: GetEventsInput) => Promise<CalendarEvent[]>
}

const initialValue: CalendarsContextValue = {
  loading: false,
  addCalendar: async (input: AddCalendarInput) => {
    console.log('addCalendar: ', input)

    return undefined
  },
  createCalendar: async (input: CreateCalendarInput) => {
    console.log('createCalendar: ', input)

    return undefined
  },
  activateCalendar: async (input: ActivateCalendarInput) => {
    console.log('activateCalendar: ', input)

    return undefined
  },
  updateCalendar: async (input: UpdateCalendarInput) => {
    console.log('updateCalendar: ', input)

    return undefined
  },
  getCalendarDetails: async (calendar: CalendarAccount, writable?: boolean) => {
    console.log('getCalendarDetails: ', calendar, writable)

    return []
  },
  getCalendarEvents: async (input: GetEventsInput) => {
    console.log('getCalendarEvents: ', input)

    return []
  },
  getMeetingCalendar: async (participant: Participant) => {
    console.log('getCalendarEvents: ', participant)

    return undefined
  },
  removeCalendar: async (calendar: CalendarAccount) => {
    console.log('removeCalendar: ', calendar)

    return undefined
  },
  reloadCalendars: () => {
    console.log('reloadCalendars: ')
  },
}

// create and initialize context
export const CalendarsContext = React.createContext<CalendarsContextValue>(initialValue)

export function useCalendars(): CalendarsContextValue {
  return useContext(CalendarsContext)
}

export type CalendarsMockContextValue = Partial<CalendarsContextValue>

type MockProps = {
  value?: CalendarsMockContextValue
}

export const CalendarsMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <CalendarsContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </CalendarsContext.Provider>
  )
}

const CalendarsProvider: React.FC = ({ children }) => {
  const [calendars, setCalendars] = useState<CalendarAccount[]>([])
  const [defaultCalendar, setDefaultCalendar] = useState<SubCalendar>()
  const [autoTimes, setAutoTimes] = useState<TimeRange[]>()
  const [autoDays, setAutoDays] = useState<string[]>()
  const { logEvent } = useAnalytics()

  const { loading, data: calendarsData, refetch: refetchCalendars } = useQuery(GET_CALENDARS, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: preferencesData, refetch: refetchPreferences } = useQuery(GET_PREFERENCES, {
    fetchPolicy: 'cache-and-network',
  })

  const [addCalendarMutation] = useMutation(ADD_CALENDAR)
  const [createCalendarMutation] = useMutation(CREATE_CALENDAR)
  const [activateCalendarMutation] = useMutation(ACTIVATE_CALENDAR)
  const [updateCalendarMutation] = useMutation(UPDATE_CALENDAR)
  const [removeCalendarMutation] = useMutation(REMOVE_CALENDAR)

  const { data: calendarSubscriptionData } = useSubscription(CALENDAR_SUBSCRIPTION)

  useEffect(() => {
    if (calendarSubscriptionData?.calendarUpdated) {
      refetchCalendars()
    }
  }, [calendarSubscriptionData])

  async function getDefaultCalendar(calendars: CalendarAccount[]): Promise<void> {
    const { bookCalendar } = preferencesData.preferences

    if (bookCalendar && Array.isArray(calendars) && calendars.length) {
      const account = calendars.find(cal => cal.id === bookCalendar.account)

      if (account) {
        const calendar = account.calendars.find(cal => cal.calendarId === bookCalendar.calendarId)

        const subCalendars = await getSubCalendars({ account, writable: true })

        // console.log('getDefaultCalendar: get sub calendars info ', subCalendars)

        if (calendar && subCalendars) {
          const cal = subCalendars.find(c => c.id === bookCalendar.calendarId)

          if (cal) {
            setDefaultCalendar(cal)

            const { timeRanges, autoDays } = calendar

            if (Array.isArray(timeRanges) && timeRanges.length) {
              setAutoTimes(timeRanges)
              setAutoDays(autoDays)
            } else {
              setAutoTimes(defaultTimeRanges(cal.timeZone))
              setAutoDays(defaultAutoDays())
            }
          }
        }
      }
    } else {
      setAutoTimes([])
      setAutoDays([])
    }
  }

  useEffect(() => {
    if (calendarsData?.calendars) {
      // console.log('calendardData: ', calendarsData?.calendars)

      const calendars = calendarsData.calendars.slice().sort((a: CalendarAccount, b: CalendarAccount) =>
        compareTimes(a.createTime, b.createTime))

      setCalendars(calendars)

      // console.log('GOT User preferences: ', preferencesData.preferences)
      if (preferencesData?.preferences?.bookCalendar) {
        getDefaultCalendar(calendars)
      }
    }
  }, [preferencesData, calendarsData])

  async function getCalendarDetails(calendar: CalendarAccount, writable?: boolean): Promise<SubCalendar[]> {
    const subCalendars = await getSubCalendars({ account: calendar, writable })

    console.log('SUB CALENDARS: ', subCalendars)
    console.log('CALENDAR ACCOUNTS: ', calendar)

    subCalendars.forEach(c => {
      const cal = calendar.calendars.find(cal => cal.calendarId === c.id)

      if (cal) {
        c.enabled = cal.enabled
        c.freeBusy = cal.freeBusy
      }
    })

    // console.log('SUB CALENDARS: ', subCalendars.filter(cal => cal.name !== calendar.account))

    const primaryCal = subCalendars.splice(subCalendars.findIndex(cal => cal.primary), 1)

    // put primary calendar at the top
    // setSubCalendars(calendars.filter(cal => cal.name !== calendar.account))
    return primaryCal.concat(subCalendars)
  }
  async function getMeetingCalendar(participant: Participant): Promise<MeetingBookCalendar | undefined> {
    let bookCalendar

    if (participant.bookCalendar) {
      bookCalendar = participant.bookCalendar
    } else if (preferencesData?.preferences?.bookCalendar) {
      bookCalendar = preferencesData.preferences.bookCalendar
    }

    if (bookCalendar) {
      const { account, calendarId } = bookCalendar
      let meetingCalendar
      let autoTimes
      let autoDays
      let autoBook = false

      if (account && calendarId) {
        const calendarAccount = calendars.find(cal => cal.id === account)

        if (calendarAccount) {
          const calendar = calendarAccount.calendars.find(cal => cal.calendarId === calendarId)

          const subCalendars = await getSubCalendars({ account: calendarAccount, writable: true })

          // console.log('getMeetingCalendar: get sub calendars info ', subCalendars)

          if (calendar && subCalendars) {
            const cal = subCalendars.find(c => c.id === calendarId)

            if (cal) {
              // console.log('MEETING CALENDAR: ', cal)
              meetingCalendar = cal

              if (participant.bookCalendar?.timeRanges?.length) {
                autoTimes = participant.bookCalendar?.timeRanges
                autoDays = participant.bookCalendar.autoDays
              } else {
                if (calendar.timeRanges?.length) {
                  // remove __typename from calendar.timeRanges
                  autoTimes = Array.isArray(calendar.timeRanges) ? calendar.timeRanges.map(r => {
                    return {
                      startTime: r.startTime,
                      endTime: r.endTime,
                    }
                  }) : []
                  autoDays = calendar.autoDays
                } else {
                  autoTimes = defaultTimeRanges(cal.timeZone)
                }
              }

              if (participant.autoBook) {
                autoBook = participant.autoBook.enabled
              } else {
                autoBook = flagDisableDefaultAutobook ? false : true
              }

              return {
                calendar: meetingCalendar,
                autoTimes,
                autoDays,
                autoBook,
              }
            }
          }
        }
      }
    }
  }

  async function addCalendar(input: AddCalendarInput): Promise<string | undefined> {
    const { data } = await addCalendarMutation({ variables: { input } })

    if (data?.addCalendar?.authUrl) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'addCalendar',
          service: input.service,
        },
      })

      return data.addCalendar.authUrl
    }
  }
  async function createCalendar(input: CreateCalendarInput): Promise<CalendarAccount | undefined> {
    const { data } = await createCalendarMutation({ variables: { input } })

    if (data?.createCalendar?.id) {
      const calendar = data.createCalendar as CalendarAccount

      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'createCalendar',
          calendar: calendar.id,
        },
      })

      return calendar
    }
  }
  async function activateCalendar(input: ActivateCalendarInput): Promise<CalendarAccount | undefined> {
    const { data } = await activateCalendarMutation({ variables: { input } })

    if (data?.activateCalendar?.id) {
      const calendar = data.activateCalendar as CalendarAccount

      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'activateCalendar',
          calendar: calendar.id,
        },
      })

      return calendar
    }
  }
  async function updateCalendar(input: UpdateCalendarInput): Promise<void> {
    if (input.timeRanges) {
      setAutoTimes(input.timeRanges)
    }

    if (input.autoDays) {
      setAutoDays(input.autoDays)
    }

    await updateCalendarMutation({ variables: { input } })

    logEvent({
      eventName: EventName.mutation,
      eventData: {
        mutation: 'updateCalendar',
        calendar: input.id,
      },
    })
  }

  function reloadCalendars(): void {
    refetchCalendars()
    refetchPreferences && refetchPreferences()
  }

  async function removeCalendar(calendar: CalendarAccount): Promise<void> {
    // console.log('remove calendar: ', calendar.id)

    const input: RemoveCalendarInput = {
      id: calendar.id,
    }

    await removeCalendarMutation({ variables: { input } })
    reloadCalendars()

    logEvent({
      eventName: EventName.mutation,
      eventData: {
        mutation: 'removeCalendar',
        calendar: calendar.id,
      },
    })
  }

  async function getCalendarEvents(args: GetEventsInput): Promise<CalendarEvent[]> {
    return getEvents(args)
  }

  return (
    <CalendarsContext.Provider
      value={{
        loading,
        calendars,
        defaultCalendar,
        autoTimes,
        autoDays,
        calendarUpdated: calendarSubscriptionData?.calendarUpdated,
        getCalendarDetails,
        getMeetingCalendar,
        addCalendar,
        createCalendar,
        activateCalendar,
        updateCalendar,
        reloadCalendars,
        removeCalendar,
        getCalendarEvents,
      }}>
      {children}
    </CalendarsContext.Provider>
  )
}

export default CalendarsProvider
