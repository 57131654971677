import React, { useEffect } from 'react'
import { IonPage, IonSpinner } from '@ionic/react'

import 'screens/Settings.css'

import { usePreferences } from 'context/PreferencesContext/PreferencesContext'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { ActivateCalendarInput, UpdateCalendarInput } from 'services/api'
import AutoBookSettings from 'components/organisms/SelectMeetingAutobookSchedule/AutoBookSettings'
import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import { TimeRange } from 'types'
import { EventName, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

// import { AutoBookSettings as StoreTips } from 'services/store/tips/types'
interface ContainerProps {
  calendarId?: string;
  onDone: () => void;
}

const CalendarSettingsAutoTimes: React.FC<ContainerProps> = ({ calendarId, onDone }) => {
  const { loading, defaultCalendar, autoTimes, autoDays, activateCalendar, updateCalendar } = useCalendars()
  const { preferences } = usePreferences()
  const { logEvent } = useAnalytics()

  console.log('CalendarSettingsAutoTimes autoTimes: ', autoTimes)
  console.log('CalendarSettingsAutoTimes autoDays: ', autoDays)

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'CalendarSettingsAutoTimes' },
    })
  }, [])

  async function onActivateCalendar (): Promise<void> {
    if (calendarId && activateCalendar) {
      console.log('onActivateCalendar: ')

      const input: ActivateCalendarInput = {
        id: calendarId,
        active: true,
      }

      try {
        const calendar = await activateCalendar(input)

        console.log('ACTIVATE GOOGLE CALENDAR', calendar)
      } catch (error) {
        if (error instanceof Error) {
          console.log('ACTIVATE CALENDAR ERROR: ', error.message)
        }
      }
    }
  }

  async function onUpdateCalendar (ranges: TimeRange[], days: string[]): Promise<void> {
    console.log('AUTO TIMES: ', ranges, days)

    if (defaultCalendar && updateCalendar) {
      const { account, id } = defaultCalendar

      const update: UpdateCalendarInput = {
        id: account,
        calendarId: id,
        timeRanges: ranges,
        autoDays: days,
      }

      updateCalendar(update)
    }

    await onActivateCalendar()
    onDone()
  }

  function renderLoading (): JSX.Element | undefined {
    if (loading) {
      return (
        <ScreenCenter height='80%'>
          <IonSpinner name='dots' />
        </ScreenCenter>
      )
    }
  }

  function renderAutoBookSettings (): JSX.Element | undefined {
    if (!loading && autoTimes) {
      return (
        <AutoBookSettings
          noShowSwitch
          enabled
          timeRanges={autoTimes}
          days={autoDays}
          timeZone={preferences?.timeZone}
          doneButton='Next'
          onUpdated={(ranges, days) => onUpdateCalendar(ranges, days)} />
      )
    }
  }

  return (
    <IonPage>
      <ScreenHeader title='Calendar Preferences' />
      {renderLoading()}
      {renderAutoBookSettings()}
    </IonPage>
  )
}

export default CalendarSettingsAutoTimes
