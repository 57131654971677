import React, { useContext } from 'react'
import { ComponentTips } from 'types/componentTips'

export interface ScreenTipsContextValue {
  showScreenTip: ComponentTips,
  minTip: boolean;
  showNextTip: number;
  onDoneTips: () => void;
  setShowScreenTip: React.Dispatch<React.SetStateAction<ComponentTips>>
}

const ScreenTipsContext = React.createContext<Partial<ScreenTipsContextValue>>({})

export function useScreenTips (): Partial<ScreenTipsContextValue> {
  return useContext(ScreenTipsContext)
}

export default ScreenTipsContext
