import React, { CSSProperties, useState } from 'react'
import {
  IonBackdrop,
  IonContent, IonFooter, IonModal, IonText,
} from '@ionic/react'
import { notifications } from 'ionicons/icons'
import { useSpring } from 'react-spring'

import { CalendarEvent, Participant } from 'types'
import ActionCard from '../ActionCard/ActionCard'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import AnimatedPeople from 'components/atoms/AnimatedPeople/AnimatedPeople'
import { useScreenLogEvent } from 'context/ScreenLogEventContext/ScreenLogEventContext'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: CalendarEvent[];
}

const iconBox: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const messageBox: CSSProperties = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
}

const titleStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 'bold',
  textAlign: 'center',
}

const messageStyle: CSSProperties = {
  fontSize: 14,
}

interface ContainerProps {
  onClose: () => void;
  onInviteParticipants: () => void;
  presentingElement: HTMLElement | undefined;
}

const InviteParticipantsReminderModal: React.FC<ContainerProps> = ({ onInviteParticipants, onClose }) => {
  const [showCard] = useState(true)

  const { meeting } = useMeetings()
  const { logTapEvent: logTap } = useScreenLogEvent()

  function renderStatus(): JSX.Element | undefined {
    return (
      <div
        className='ion-text-start'>
        <div style={iconBox}>
          <AnimatedPeople
            loop
            style={{ width: 100, height: 100 }} />
          <IonText
            style={titleStyle}>
            Great! Let&apos;s invite some people.
          </IonText>
        </div>
        <div
          style={messageBox}>
          <IonText
            color='medium'
            style={messageStyle}>
            Once they&apos;ve selected their availabiliy, MeetingTime will automagically find a time that works for everybody.
          </IonText>
        </div>
      </div>
    )
  }

  function renderActionButtons(): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          testId='invite-participants-reminder-modal-confirm'
          onClick={() => {
            logTap({ button: 'InviteParticipants', component: 'InviteParticipantsModal' })
            onInviteParticipants()
          }}>
          Invite Participants
        </FooterButton>
      </IonFooter>
    )
  }

  const popoverProps = useSpring({
    ...(showCard ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderActionsCard(): JSX.Element | undefined {
    if (meeting) {
      return (
        <ActionCard
          title={meeting?.title}
          icon=''
          onClose={onClose}
          // boxStyle={{ height: '100%' }}
          animatedProps={popoverProps}>
          {renderStatus()}
          {renderActionButtons()}
        </ActionCard>
      )
    }
  }

  return (
    <IonModal
      isOpen
      backdropDismiss
      cssClass='meetingTimeModal'
      onDidDismiss={() => {
        logTap({ button: 'CloseModal', component: 'InviteParticipantsModal' })
        onClose()
      }}>
      <IonContent>
        <IonBackdrop
          tappable
          visible />
        {renderActionsCard()}
      </IonContent>
    </IonModal>
  )
}

export default InviteParticipantsReminderModal
