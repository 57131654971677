import React, { CSSProperties, useEffect, useState } from 'react'
import {
  IonButton, IonContent, IonText,
  IonPage, IonItem, IonSpinner, IonIcon, IonLabel,
} from '@ionic/react'
import { checkmarkCircle } from 'ionicons/icons'

import { VerifyContactInput, VerifyContactError, CreateInviteeInput, InviteeStatus, JoinMeetingInput } from 'services/api'
import { firebaseLogin, UserInfo } from 'services/firebase'
import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useContacts } from 'context/ContactsContext/ContactsContext'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useUser } from 'context/UserContext/UserContext'
import { useTabs } from 'navigation/TabsContext'
import { useTips } from 'context/TipsContext/TipsContext'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'
import { generateDeviceFingerprint } from 'services/accounts'
import { storeAccessToken } from 'services/security/accessToken'

const container: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  padding: 40,
}
const logo: CSSProperties = {
  textAlign: 'center',
}

const box: CSSProperties = {
  padding: 20,
  textAlign: 'center',
}

const iconBox: CSSProperties = {
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const iconStyle: CSSProperties = {
  fontSize: 50,
}

const messageStyle = {
  fontSize: 14,
  textAlign: 'center',
}

interface ComponentProps {
  verifyToken: string;
  callbackUrl: string;
  invitation?: string;
  inCallback: boolean;
  goBack: () => void;
  onLogin: () => void;
  onTryAgain: () => void;
}

const LoginFirebase: React.FC<ComponentProps> = (
  { invitation, verifyToken, callbackUrl, inCallback, goBack, onLogin, onTryAgain }) => {
  const [userInfo, setUserInfo] = useState<UserInfo>()
  const [differentDevice, setDifferentDevice] = useState(false)
  const [showError, setShowError] = useState('')
  const [activity, setActivity] = useState(false)
  const [logedIn, setLogedIn] = useState(false)

  const { user, loginCompleted } = useUser()
  const { verifyContact } = useContacts()
  const { createInvitee } = useInvitees()
  const { joinMeeting } = useMeetings()
  const { setShowTabs } = useTabs()
  const { updateStoreTips } = useTips()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'LoginFirebase' },
    })
  }, [])

  async function onVerifyContact(info: UserInfo): Promise<void> {
    setUserInfo(info)
    setActivity(true)

    const { email, phone, uid, idToken } = info
    const input: VerifyContactInput = {
      email,
      phone,
      verifyFirebase: {
        uid,
        idToken,
      },
      verifyToken,
    }

    try {
      if (inCallback) {
        // if running in a callback create a different deviceId than the current instance
        // so if the user verifies in a different tab and there is already an existing account active
        // we don't overwrite the current account
        input.verifyDevice = await generateDeviceFingerprint()
      }

      const tokens = await verifyContact(input)

      if (tokens) {
        console.log('Verify Contact success: ', tokens)
        console.log(input.verifyDevice)
        console.log('InCallback ', inCallback)
        if (inCallback && input.verifyDevice && tokens.accessToken) {
          // setDifferentDevice(true)
          // setActivity(false)
          // setShowTabs && setShowTabs(false)
          localStorage.removeItem('verifyToken')
          console.log('ON LOGIN ')
          storeAccessToken(input.verifyDevice, tokens.accessToken)
          onLogin()
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log('Verify contact error: ', error.message)
        setShowError(error.message)
      }
    }
  }

  async function onFirebaseSuccess(info: UserInfo): Promise<void> {
    console.log('Verify Firebase User: ', info)

    onVerifyContact(info)
  }

  function showFirebase(): void {
    const el = document.getElementById('firebaseui')

    if (el) {
      console.log('Login callback URL: ', callbackUrl)
      firebaseLogin('#firebaseui', callbackUrl, onFirebaseSuccess)
    } else {
      setTimeout(() => {
        console.log('Login callback URL: ', callbackUrl)
        firebaseLogin('#firebaseui', callbackUrl, onFirebaseSuccess)
      }, 1000)
    }
  }

  useEffect(() => {
    if (verifyToken) {
      showFirebase()
    }
  }, [verifyToken])

  async function onJoinMeeting(): Promise<void> {
    if (invitation && joinMeeting) {
      const input: CreateInviteeInput = {
        invitationId: invitation,
        status: InviteeStatus.accepted,
      }

      const data = await createInvitee(input)

      if (Array.isArray(data)) {
        const invitee = data[0]

        console.log('LOGIN: joining meeting')

        if (invitee.id) {
          const input: JoinMeetingInput = {
            inviteeId: invitee.id,
          }

          const meeting = await joinMeeting(input)

          if (meeting) {
            setTimeout(() => {
              setActivity(false)
              onLogin()
            }, 1000)
          }
        }
      }
    }
  }

  useEffect(() => {
    console.log('LOGIN USER DATA: ', user)
    console.log('login completed: ', loginCompleted)
    // do this only if we are not in callback and on the same device
    // as the callback browser could be different from the initiating one

    if (loginCompleted && loginCompleted === verifyToken) {
      // we could come here twice as apolo reset happens when user logs in.
      // prevent joining twice by setting logedIn state
      console.log('LOGIN COMPLETED: ')

      if (user?.id && !logedIn) {
        setLogedIn(true)

        // dont join if we are in login verify callback.
        // the login initiating app will do the join
        if (invitation && !inCallback) {
          onJoinMeeting()
        } else {
          setTimeout(() => {
            setActivity(false)
            onLogin()
          }, 1000)
        }

        updateStoreTips && updateStoreTips({ enabled: true, startMinTip: true })
      }
    }
  }, [user, loginCompleted])

  function renderError(): JSX.Element | undefined {
    if (showError === VerifyContactError.contactNotFound) {
      return (
        <div style={container}>
          <div style={logo}>
            <img
              src='assets/icon/MeetingTimeIcon.png'
              width='100'
              height='100'
              alt='' />
          </div>

          <div style={box}>
            <IonText
              color='medium'
              style={messageStyle}>
              Contact Not Found
            </IonText>
          </div>

          <div style={box}>
            <IonButton
              shape='round'
              expand='block'
              fill='solid'
              color='primary'
              onClick={onTryAgain}>
              Try Again
            </IonButton>
            <IonText
              color='medium'
              style={messageStyle}>
              Select a different login
            </IonText>
          </div>
        </div>
      )
    }
  }

  function renderDifferentDevice(): JSX.Element | undefined {
    if (differentDevice && userInfo) {
      const { email, phone, provider } = userInfo

      let msg = ''

      if (email) {
        msg = 'Email has been verified '
      } else if (phone) {
        msg = 'Phone has beedn verified'
      } else if (provider === 'google') {
        msg = 'Google account has been verified'
      }

      return (
        <div
          style={{ flex: 1, height: '100%' }}
          className='titleIconBox'>
          <div
            style={iconBox}>
            <IonIcon
              style={iconStyle}
              icon={checkmarkCircle}
              color='success' />
            <IonLabel style={{ textAlign: 'center', marginTop: 10 }}>
              <h2>{email || phone}</h2>
              {provider && <p>@{provider}</p>}
            </IonLabel>
          </div>
          <IonItem
            lines='none'
            style={{ textAlign: 'center' }}>
            <IonLabel>
              <IonText
                color='medium'
                style={messageStyle}>
                <strong>{msg}</strong> <br /><br />
                You have signed in from another tab or browser<br />
                You can safely close this screen and continue there
              </IonText>
            </IonLabel>
          </IonItem>
        </div>
      )
    }
  }

  function renderLoggingIn(): JSX.Element | undefined {
    if (activity) {
      return (
        <ScreenCenter height='80%'>
          {/* <img
            src='assets/icon/MeetingTimeIcon.png'
            width='60'
            height='60'
            alt='' /> */}
          <IonSpinner
            name='dots'
            color='medium' />
        </ScreenCenter>
      )
    }
  }

  function renderHeader(): JSX.Element | undefined {
    const title = 'Login'

    if (differentDevice) {
      return (
        <ScreenHeader
          title={title} />
      )
    } else {
      return (
        <ScreenHeader
          title={title}
          onBack={goBack} />
      )
    }
  }

  return (
    <IonPage>
      {renderHeader()}
      <IonContent>
        {!activity &&
          <div id='firebaseui' />}
        {renderError()}
        {renderLoggingIn()}
        {renderDifferentDevice()}
      </IonContent>
    </IonPage>
  )
}

export default LoginFirebase
