import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from 'services/store'

import 'screens/Settings.css'
import { updateScreens } from 'services/store/screens/actions'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import AddParticipants from './AddParticipants'
import ConnectionsProvider from 'context/Connections/ConnectionsContext'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import StoreParticipantsProvider from 'context/StoreParticipantsContext/StoreParticipantsContext'

const AddParticipantsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { addParticipants } = useSelector((state: RootState) => state.screens)
  const dispatch = useDispatch()

  function onInvite(): void {
    if (addParticipants?.meetingId) {
      dispatch(updateScreens({ shareMeetingInvite: { meetingId: addParticipants?.meetingId } }))
      history.push('/tabs/meetings/shareMeetingInvite')
    }
  }

  function goBack(): void {
    history.goBack()
  }

  function onAdd(): void {
    if (addParticipants?.nextScreen) {
      history.push(addParticipants?.nextScreen)
    } else {
      history.goBack()
    }
  }

  return (
    <ConnectionsProvider>
      <InviteesProvider meetingId={addParticipants?.meetingId}>
        <MeetingsProvider meetingId={addParticipants?.meetingId}>
          <ParticipantsProvider meetingId={addParticipants?.meetingId}>
            <StoreParticipantsProvider>
              {addParticipants?.meetingId &&
                <AddParticipants
                  onInvite={onInvite}
                  onAdd={onAdd}
                  goBack={goBack}
                  goBackTo={addParticipants?.previous} />}
            </StoreParticipantsProvider>
          </ParticipantsProvider>
        </MeetingsProvider>
      </InviteesProvider>
    </ConnectionsProvider>
  )
}

export default AddParticipantsScreen
