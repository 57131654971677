import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'

import { GET_INVITATIONS } from 'services/api'
import { Invitation } from 'types'

export interface MeetingShareContextValue {
  loading: boolean
  invitations?: Invitation[]
  error?: string,
}

const initialValue: MeetingShareContextValue = {
  loading: false,
}
// create and initialize context
export const MeetingShareContext = React.createContext<MeetingShareContextValue>(initialValue)

export function useMeetingShare(): MeetingShareContextValue {
  return useContext(MeetingShareContext)
}

export type MeetingShareMockContextValue = Partial<MeetingShareContextValue>

type MockProps = {
  value?: Partial<MeetingShareContextValue>
}

export const MeetingShareMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <MeetingShareContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </MeetingShareContext.Provider>
  )
}

interface ProviderProps {
  meetingId?: string,
}

const MeetingShareProvider: React.FC<ProviderProps> = ({ meetingId, children }) => {
  const { loading, data, error } = useQuery(GET_INVITATIONS, {
    variables: { meetingId },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <MeetingShareContext.Provider value={{ loading, invitations: data?.invitations, error: error?.message }}>
      {children}
    </MeetingShareContext.Provider>
  )
}

export default MeetingShareProvider
