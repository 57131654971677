import React, { CSSProperties, useEffect, useState, useRef } from 'react'
import {
  IonButton,
  IonCard,
  IonContent, IonIcon, IonItem, IonLabel, IonPage, IonText,
} from '@ionic/react'

import { useTokens } from 'context/TokensContext/TokensContext'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'
import { account, getAccounts } from 'services/accounts'
import { Account } from 'services/store/accounts/types'
import { Avatar } from '@material-ui/core'
import { add, personCircleOutline } from 'ionicons/icons'

const container: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  padding: '10%',
}
const logo: CSSProperties = {
  textAlign: 'center',
}

const box: CSSProperties = {
  padding: '5%',
  textAlign: 'center',
}

const welcomeText: CSSProperties = {
  fontSize: 20,
  textAlign: 'center',
}

const messageStyle = {
  fontSize: 16,
  textAlign: 'center',
}

const avatarStyle = {
  width: 30,
  height: 30,
  backgroundColor: '#92949C',
  marginRight: 10,
}

interface ComponentProps {
  onNewUser: () => void;
  onLogin: (verifyTokens: string) => void;
  onSelectAccount: (id: string) => void;
}

const Start: React.FC<ComponentProps> = ({ onNewUser, onLogin, onSelectAccount }) => {
  const [showSelectAccounts, setShowSelectAccounts] = useState(false)
  const [accounts, setAccounts] = useState<Account[]>()

  const { getVerifyToken } = useTokens()
  const { logEvent } = useAnalytics()

  const pageRef = useRef()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'Start' },
    })

    if (!account?.accessToken) {
      let storeAccounts = getAccounts()?.filter(a => a.handle)

      if (storeAccounts?.length) {
        // there are cases where a user could verify/login into same account from different sessions
        // so there could be duplicate accounts for the same user
        storeAccounts =
          storeAccounts?.filter((a, index) =>
            storeAccounts?.findIndex(ac => ac.handle === a.handle) === index)

        if (storeAccounts?.length) {
          setAccounts(storeAccounts)
          setShowSelectAccounts(true)
        }
      }
    }
  }, [])

  async function startLogin(): Promise<void> {
    if (getVerifyToken) {
      const verifyToken = await getVerifyToken()

      if (verifyToken) {
        console.log('GOT VERIFY TOKEN: ', verifyToken)
        onLogin(verifyToken)
      }
    }
  }

  function logTap(button: string): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        screen: 'Start',
        button,
      },
    })
  }

  function renderAccount(account: Account, lines: boolean): JSX.Element | undefined {
    const { id, photo, displayName, handle } = account

    if (displayName) {
      const initials = displayName.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase())

      return (
        <IonItem
          key={id}
          lines={lines ? 'inset' : 'none'}
          onClick={() => onSelectAccount(id)}>
          <Avatar
            slot='start'
            src={photo}
            style={avatarStyle}>
            <IonText style={{ fontSize: 16 }}>
              {initials}
            </IonText>
          </Avatar>
          <IonLabel>
            <h2>{displayName}</h2>
            <p>@{handle}</p>
          </IonLabel>
        </IonItem>
      )
    }
  }

  function renderOptions(): JSX.Element {
    return (
      <IonCard style={{ marginTop: 20 }}>
        <IonItem
          onClick={() => {
            logTap('Sign-In')
            startLogin()
          }}>
          <Avatar
            slot='start'
            style={avatarStyle}>
            <IonIcon
              icon={personCircleOutline} />
          </Avatar>
          <IonLabel>
            <h2>Use another account</h2>
          </IonLabel>
        </IonItem>
        <IonItem
          lines='none'
          onClick={() => {
            logTap('NewUser')
            onNewUser()
          }}>
          <Avatar
            slot='start'
            style={avatarStyle}>
            <IonIcon
              icon={add} />
          </Avatar>
          <IonLabel>
            <h2>Create a new account</h2>
          </IonLabel>
        </IonItem>
      </IonCard>
    )
  }

  function renderSelectAccounts(): JSX.Element | undefined {
    if (showSelectAccounts) {
      return (
        <div>
          <div style={box}>
            <IonText
              color='medium'
              style={messageStyle}>
              Welecome back to MeetingTime!<br /> <br />
            </IonText>
            <IonText
              style={welcomeText}>
              Choose an Account
            </IonText>
          </div>
          <IonCard>
            {accounts?.map((account, index) => renderAccount(account, index < accounts.length - 1))}
          </IonCard>

          {renderOptions()}
        </div>
      )
    }
  }

  function renderLogin(): JSX.Element | undefined {
    if (!showSelectAccounts) {
      return (
        <div>
          <div style={box}>
            <IonText
              color='dark'
              style={welcomeText}>

              On behalf of everyone at MeetingTime<br /> <br />
              <span style={{ fontWeight: 'bold', fontSize: 22 }}> Greetings!</span><br />

            </IonText>
          </div>
          <div style={box}>
            <IonButton
              shape='round'
              expand='block'
              fill='solid'
              color='primary'
              onClick={() => {
                logTap('NewUser')
                onNewUser()
              }}
              data-ci-id='start-new-user'>
              I&apos;m New
            </IonButton>
            <IonText
              color='medium'
              style={messageStyle}>
              {/* We are happy you would like to give us a try. */}
              Try MeetingTime for FREE
            </IonText>
          </div>

          <div style={box}>
            <IonButton
              shape='round'
              expand='block'
              fill='outline'
              color='primary'
              onClick={() => {
                logTap('Sign-In')
                startLogin()
              }}
              data-ci-id='start-sign-in'>
              Sign-In
            </IonButton>
            <IonText
              color='medium'
              style={messageStyle}>
              Welcome back!
            </IonText>
          </div>
        </div>
      )
    }
  }

  return (
    <IonPage ref={pageRef}>
      <IonContent>
        <div style={container}>
          <div style={logo}>
            <img
              src='assets/icon/MeetingTimeIcon.png'
              width='100'
              height='100'
              alt='' />
          </div>
          {renderLogin()}
          {renderSelectAccounts()}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Start
