
import { CalendarViewContextValue } from '../ScheduleCalendar/CalendarViewContext'

const config: Partial<CalendarViewContextValue> = {
  timeLabelWidth: 60,
  timeSlotHeight: 50,
  timeSlotWidth: 90,
  dayLabelHeight: 30,
  calendarTopPadding: 20,
  calendarBottomPadding: 50,
  allDayEventsBoxHeight: 30,
}

export default config
