import React, { CSSProperties } from 'react'
import {
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/react'

import { Meeting } from 'types'

const container: CSSProperties = {
  marginTop: 0,
  overflow: 'hidden',
}

const messageStyle: CSSProperties = {
  fontSize: 16,
}

export interface MeetingCardDescriptionProps {
  meeting: Meeting;
  onClick: () => void;
}

const MeetingCardDescription: React.FC<MeetingCardDescriptionProps> = ({ meeting, onClick }) => {
  if (meeting.description) {
    return (
      <IonItem
        lines='none'
        onClick={onClick}>
        <IonLabel style={container}>
          <IonText
            style={messageStyle}
            color='medium'
            className='meetingCardDescription'>
            {meeting.description.trim()}
          </IonText>
        </IonLabel>
      </IonItem>
    )
  }

  return null
}

export default MeetingCardDescription
