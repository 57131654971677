import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { compareTimes } from 'services/time'
import { MeetingTime } from 'types'
import { useStoreMeetings } from 'context/StoreMeetingsContext/StoreMeetingsContext'

export interface MeetingNotifyContextValue {
  showNotify: NotifyTypes,
  notifyMessage: string,
  showFireworks: boolean;
  setShowNotify: React.Dispatch<React.SetStateAction<NotifyTypes>>
  setShowFireworks: React.Dispatch<React.SetStateAction<boolean>>
}

// create and initialize context
export const MeetingNotifyContext = React.createContext<Partial<MeetingNotifyContextValue>>({})

export function useMeetingNotify(): Partial<MeetingNotifyContextValue> {
  return useContext(MeetingNotifyContext)
}

interface ProviderProps {
  meeting?: string,
}

export enum NotifyTypes {
  'none',
  'confirmedTime',
  'selectAllTimes',
  'doneSelectingTimes',
  'autoBookOn',
}

const MeetingNotifyContextProvider: React.FC = ({ children }) => {
  const [showNotify, setShowNotify] = useState(NotifyTypes.none)
  const [notifyMessage, setNotifyMessage] = useState('')
  const [showFireworks, setShowFireworks] = useState(false)

  const { meeting } = useMeetings()
  const { storeMeetings, addStoreMeeting, updateStoreMeeting } = useStoreMeetings()

  useEffect(() => {
    if (meeting && meeting.id) {
      console.log('MeetingNotifyContextProvider: meeting updated: ', meeting)

      if (meeting.meetingTimes?.length) {
        const { meetingTimes } = meeting

        const newSortedTimes = meetingTimes.slice().sort((a: MeetingTime, b: MeetingTime) => {
          const timeA = a.startTime
          const timeB = b.startTime

          return compareTimes(timeA, timeB)
        })
        const newMeetingTime = newSortedTimes[0]
        const { startTime, endTime, participantUserIds, status } = newMeetingTime

        let oldMeetingTime: MeetingTime | undefined

        const storeMeeting = storeMeetings?.find(m => m.id === meeting.id)

        if (storeMeeting?.meetingTimes?.length) {
          const oldSortedTimes = storeMeeting?.meetingTimes.slice().sort((a: MeetingTime, b: MeetingTime) => {
            const timeA = a.startTime
            const timeB = b.startTime

            return compareTimes(timeA, timeB)
          })

          oldMeetingTime = oldSortedTimes[0]
        }

        console.log('NEW MEETING TIME: ', newMeetingTime)
        console.log('OLD MEETING TIME: ', oldMeetingTime)

        // find out what changed and then show toast message
        const day = moment(startTime).format('ddd, MMM D')
        const start = moment(startTime).format('h:mm a')
        const end = moment(endTime).format('h:mm a')
        const count = participantUserIds?.length || 0

        if (!oldMeetingTime ||
          oldMeetingTime.startTime !== startTime ||
          oldMeetingTime.endTime !== endTime ||
          oldMeetingTime.status !== status) {
          let message = ''

          if (status === 'confirmed') {
            console.log('NEW CONFIRMED TIME show fireworks')
            message = `Meeting confirmed on\n${day} ${start} - ${end}\n${count} attending`
            setShowNotify(NotifyTypes.confirmedTime)
            setShowFireworks(true)
            setNotifyMessage(message)
          } else if (status === 'tentative') {
            console.log('NEW TENTATIVE TIME show fireworks')
            message = `Meeting tentative on\n${day} ${start} - ${end}\n${count} attending`
          }

          if (!storeMeeting && addStoreMeeting) {
            addStoreMeeting({
              id: meeting.id,
              meetingTimes: meeting.meetingTimes,
            })
          } else if (updateStoreMeeting) {
            updateStoreMeeting({
              id: meeting.id,
              meetingTimes: meeting.meetingTimes,
            })
          }
        }
      }
    }
  }, [meeting]) // Only re-run the effect if meeting changes

  return (
    <MeetingNotifyContext.Provider
      value={{
        showNotify,
        notifyMessage,
        showFireworks,
        setShowNotify,
        setShowFireworks,
      }}>
      {children}
    </MeetingNotifyContext.Provider>
  )
}

export default MeetingNotifyContextProvider
