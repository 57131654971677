import { AppResetAction } from '../types'

export const UPDATE_PARTICIPANTS = 'UPDATE_PARTICIPANTS'

export interface StoreParticipants {
  notReadyToSchedule?: string[]
}

interface UpdateParticipantsAction {
  type: typeof UPDATE_PARTICIPANTS;
  participants: StoreParticipants;
}

export type ParticipantActions = UpdateParticipantsAction | AppResetAction
