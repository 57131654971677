import React, { useEffect } from 'react'
import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText,
} from '@ionic/react'

import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import 'screens/Settings.css'
import { EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

const ErrorPage: React.FC = () => {
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'ErrorPage' },
    })
  }, [])

  // console.log('Please investigate environmental variables.')

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Meetings Error</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ScreenCenter>
          <IonText class='ion-text-center'>
            <p>Sorry, we&#39;re down for maintenance.</p>
            <p>We&#39;ll be back shortly</p>
            <p>🐶</p>
          </IonText>
        </ScreenCenter>
      </IonContent>
    </IonPage>
  )
}

export default ErrorPage
