import React, { useContext, useEffect } from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'

import { DELETE_DEVICE, GET_DEVICES, USER_SUBSCRIPTION } from 'services/api'
import { UserDevice } from 'types'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

export interface DevicesContextValue {
  loading: boolean
  devices: UserDevice[]
  deleteDevice: (device: string) => Promise<string | void>
}

const initialValue: DevicesContextValue = {
  loading: false,
  devices: [],
  deleteDevice: async (device: string) => { console.log('deleteDevice: ', device) },
}
// create and initialize context
export const DevicesContext = React.createContext<DevicesContextValue>(initialValue)

export function useDevices (): DevicesContextValue {
  return useContext(DevicesContext)
}

export type DevicesMockContextValue = Partial<DevicesContextValue>

type MockProps = {
  value?: Partial<DevicesContextValue>
}

export const DevicesMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <DevicesContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </DevicesContext.Provider>
  )
}

const DevicesContextProvider: React.FC = ({ children }) => {
  const { logEvent } = useAnalytics()

  const { loading, data, refetch } = useQuery(GET_DEVICES, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: userSubscriptionData } = useSubscription(USER_SUBSCRIPTION)

  useEffect(() => {
    if (userSubscriptionData && userSubscriptionData.userUpdated) {
      console.log('devices: USER UPDATED: ', userSubscriptionData.userUpdated)
      refetch()
    }
  }, [userSubscriptionData])

  const [deleteDeviceMutation] = useMutation(DELETE_DEVICE,
    {
      update (cache, { data: { deleteDevice } }) {
        console.log('deleteDevice: Update cache')

        const { devices } = cache.readQuery(
          { query: GET_DEVICES }) as { devices: [UserDevice]}

        cache.writeQuery({
          query: GET_DEVICES,
          data: { devices: devices.filter(device => device.id !== deleteDevice.id) },
        })
      },
    })

  async function deleteDevice (device: string): Promise<string | undefined> {
    const { data } = await deleteDeviceMutation(
      { variables: { input: { id: device } } })

    if (data?.deleteDevice?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'deleteDevice',
          device,
        },
      })

      return data?.deleteDevice?.id
    }
  }

  return (
    <DevicesContext.Provider
      value={{
        loading,
        devices: data?.devices,
        deleteDevice,
      }}>
      {children}
    </DevicesContext.Provider>
  )
}

export default DevicesContextProvider
