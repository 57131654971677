import React from 'react'
import Lottie from 'react-lottie-player'
import lottieJson from './smily.json'

interface ContainerProps {
  onComplete?: () => void;
}

const AnimatedSmily: React.FC<ContainerProps> = () => {
  return (
    <Lottie
      loop={1}
      play
      animationData={lottieJson}
      style={{ width: 200, height: 200 }} />
  )
}
export default AnimatedSmily
