import { EventData } from 'context/AnalyticsContext/AnalyticsContext'
import React, { useContext } from 'react'

type ScreenLogEventContextValue = {
  logTapEvent: (eventData: EventData) => void;
}

const initialValue: ScreenLogEventContextValue = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logTapEvent: () => {},
}

const ScreenLogEventContext = React.createContext<ScreenLogEventContextValue>(initialValue)

export function useScreenLogEvent (): ScreenLogEventContextValue {
  return useContext(ScreenLogEventContext)
}

export default ScreenLogEventContext
