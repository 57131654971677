export enum MeetingCardTips {
  'noTip' = 1,
  'sequence',
  'trendingTimes',
  'tentativeTimes',
  'confirmedTime',
  'chooseAvailability',
  'changeAvailability',
  'turnOnAuto',
  'changeAuto',
  'availabilityCalendar',
  'inviteParticipants',
  'participants',
  'meetingTitle',
  'moreActions',
  'done'
}

export enum MeetingsScreenTips {
  'noTip' = 1,
  'sequence',
  'newInviteeJoin',
  'newInitiatorSuccess',
  'newInviteeSuccess',
  'meetingCardSequence',
  'newMeeting',
  'profile',
  'newInvitations',
  'endTips'
}

export enum MeetingSettingsTips {
  'noTip' = 1,
  'sequence',
  'title',
  'duration',
  'dateRange',
  'timeRange',
}

export enum AskConnectCalendarTips {
  'noTip' = 1,
  'sequence',
  'connectCalendar',
  'selectTimes',
  'endTips'
}

export enum AddParticipantsTips {
  'noTip' = 1,
  'sequence',
  'addParticipant',
  'shareLink',
  'endTips'
}

export enum SettingsScreenTips {
  'noTip' = 1,
  'sequence',
  'addCalendars',
  'calendars',
  'devices',
  'helpTips',
  'endTips'
}

export enum CalendarAccountTips {
  'noTip' = 1,
  'sequence',
  'addCalendar',
  'scheduleCalendar',
  'autoTimeRanges',
  'endTips'
}

export enum ChooseAvailabilityTips {
  'noTip' = 1,
  'sequence',
  'selectTimes',
  'selectTimesFirst',
  'selectTimesSecond',
  'selectAllTimes',
  'autoTimes',
  'othersTimes',
  'trendingTimes',
  'tentativeTimes',
  'tentativeTimesAuto',
  'invitePeople',
  'nextWhenDone',
  'endTips'
}

export enum DayScheduleTips {
  'noTip' = 1,
  'sequence',
  'selectTimes',
  'othersTimes',
  'combinedColumn',
  'trendingTimes',
  'tentativeTimes',
  'tentativeTimesAuto',
  'switchDays',
  'endTips'
}

export enum CombinedDayScheduleTips {
  'noTip' = 1,
  'sequence',
  'combinedSlots',
}

export enum CalendarAccountDetailsTips{
  'noTip' = 1,
  'sequence',
  'selectCalendar',
}

export enum AutoBookSettingsTips{
  'noTip' = 1,
  'sequence',
  'selectTimes',
}

export enum SelectMeetingDaysTips {
  'noTip' = 1,
  'sequence',
  'selectDaysRange',
  'editDays',
}
export enum SelectMeetingTimesTips {
  'noTip' = 1,
  'sequence',
  'selectTimes',
}

export enum AccountScreenTips {
  'noTip' = 1,
  'sequence',
  'addContact',
  'editId',
  'endTips'
}

export enum MeetingInviteTips {
  'noTip' = 1,
  'sequence',
  'inviteesCount',
  'shareLink',
  'endTips'
}

export enum ConnectionsScreenTips {
  'noTip' = 1,
  'sequence',
  'selectPeople',
  'startMeeting',
  'endTips'
}

export interface ComponentTips {
  minTip?: boolean;
  meetingCard?: MeetingCardTips
  meetingsScreen?: MeetingsScreenTips
  meetingSettings?: MeetingSettingsTips
  askConnectCalendar?: AskConnectCalendarTips
  addParticipants?: AddParticipantsTips
  calendarAccounts?: CalendarAccountTips
  chooseAvailability?: ChooseAvailabilityTips
  meetingInvite?: MeetingInviteTips
  daySchedule?: DayScheduleTips
  combinedDaySchedule?: CombinedDayScheduleTips
  connectionsScreen?: ConnectionsScreenTips
}
