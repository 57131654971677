import React, { CSSProperties } from 'react'
import { IonText, IonItem, isPlatform, IonTextarea } from '@ionic/react'

const titleStyle: CSSProperties = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

// const labelStyle: CSSProperties = {
//   fontSize: 18,
// }

const container: CSSProperties = {
  paddingTop: 20,
}

export type ItemIcon = {
  iconName: string;
  color: string;
  slot: string;
  activity?: boolean;
}

interface ContainerProps {
  testId?: string;
  value?: string;
  placeholder?: string;
  style?: CSSProperties;
  title?: string;
  lines?: 'full' | 'inset' | 'none',
  autofocus?: boolean;
  onFocus?: () => void;
  onChange?: (value: string) => void;
}

const TextAreaInput: React.FC<ContainerProps> = (
  { testId, value, placeholder, style, title, lines = 'full', autofocus, onFocus, onChange }) => {
  const boxStyle = style || (title ? container : {})
  const itemStyle: CSSProperties = title ? { marginTop: 5 } : {}

  return (
    <div style={boxStyle}>
      {title &&
        <ItemTitle title={title} />}
      <IonItem
        lines={lines}
        style={itemStyle}>
        <IonTextarea
          {...(testId && { 'data-ci-id': testId })}
          placeholder={placeholder}
          autocapitalize='entered'
          value={value}
          autofocus={autofocus}
          autoGrow
          onIonFocus={() => {
            console.log('On focus')
            onFocus && onFocus()
          }}
          onIonChange={e => {
            // setShowTip(false)

            if (onChange) {
              onChange(e.detail.value || '')
            }
          }} />
      </IonItem>
    </div>
  )
}

interface TitleProps {
  title?: string;
}

export const ItemTitle: React.FC<TitleProps> = ({ title }) => {
  return (
    <IonText
      style={titleStyle}
      color='medium'>
      {title?.toUpperCase()}
    </IonText>
  )
}
export default TextAreaInput
