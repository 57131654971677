import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import 'screens/Settings.css'
import TipsList from './TipsList'

const TipsListScreen: React.FC<RouteComponentProps> = ({ history }) => {
  function onGoTo (path: string): void {
    if (path) {
      history.push(path)
    }
  }

  return (
    <TipsList onGoTo={onGoTo} />
  )
}

export default TipsListScreen
