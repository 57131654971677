import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'services/store'

import { updateScreens } from 'services/store/screens/actions'
import 'screens/Settings.css'
import TokensProvider from 'context/TokensContext/TokensContext'
import Start from './Start'
import paths from 'navigation/paths'
import { AuthTypes, useRoutes } from 'navigation/RouteContext'
import { initSession } from 'services/accounts'

const StartScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { startScreen: params } = useSelector((state: RootState) => state.screens)

  const dispatch = useDispatch()
  const { setAuthenticated } = useRoutes()

  function onNewUser (): void {
    dispatch(updateScreens({
      newUserName: {
        meetUser: params?.meetUser,
        invitation: params?.invitation,
      },
      allScreens: {
        newInitiator: true,
      },
    }))
    history.push(paths.newUserName)
  }

  function onLogin (verifyToken: string): void {
    let url = `/login?verifyToken=${verifyToken}`

    if (params?.meetUser) {
      url += `&meet=${params?.meetUser}`
    } else if (params?.invitation) {
      url += `&invite=${params?.invitation}`
    }

    dispatch(updateScreens({
      newUserName: {},
      allScreens: {},
      loginFirebase: {
        meetUser: params?.meetUser,
        invitation: params?.invitation,
      },
    }))

    history.push(url)
  }

  function onSelectAccount (id: string): void {
    // console.log('ACCOUNT SELECTED: ', id)
    initSession(id)
    setAuthenticated && setAuthenticated(AuthTypes.authenticated)

    if (params?.meetUser) {
      dispatch(updateScreens({
        meetingSettings: {
          addParticipants: [params.meetUser],
          meetMeInitiator: true,
        },
      }))
      history.push(paths.meetingSettings)
    } else if (params?.invitation) {
      history.push(`/invitation/${params.invitation}`)
    } else {
      history.replace(paths.meetingsList)
    }
  }

  return (
    <TokensProvider>
      <Start
        onNewUser={onNewUser}
        onLogin={onLogin}
        onSelectAccount={onSelectAccount} />
    </TokensProvider>
  )
}

export default StartScreen
